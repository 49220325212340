import {Bounds, toBounds} from './Bounds';
import {LatLng} from './LatLng';
import {Envelope}  from './Envelope';


export class Node {
  constructor(feature, bounds, next) {
    this.Feature = feature;
    this.Bounds = bounds;
    this.Next = next;
  }
}

export class QuadTree
{
  constructor(bounds)
  {
    this._bounds = bounds;
    this._node = null;
    this._count = 0;

    this._topLeft =  null;
    this._topRight =  null;
    this._bottomLeft =  null;
    this._bottomRight =  null;
  }
   
  clear()
  {
    this._node = null;
    this._count = 0;

    this._topLeft =  null;
    this._topRight =  null;
    this._bottomLeft =  null;
    this._bottomRight =  null;
  }

  // @region Properties
  get Bounds() {
    return this._bounds; 
  }

  get Node() {
    return this._node; 
  }

  get Count() {
    return this._count; 
  }
  // @endregion Properties

  // @region Methods  
  /// <summary>
  /// Insert the given node.
  /// </summary>
  /// <param name="feature">The wrapped node.</param>
  /// <param name="rcFeature">The bounds of that node.</param>
  /// <param name="priority">The priority of that node.</param>
  /// <param name="depth">The recursive depth of this call, to avoid stack overflows.</param>
  /// <returns>The quadrant that ultimately holds the node.</returns>            
  insertFeature(feature, rcFeature, depth)
  {
    var child = null;

    // Only drill down the tree for positive sized bounds, otherwise we could drill forever.
    // Todo: We can remove this restriction if we choose to only split quads when "full".
    if (depth <= QuadTree.MaxTreeDepth && (rcFeature.Width > 0 || rcFeature.Height > 0))
    {
      let xMid = (this._bounds.MinX + this._bounds.MaxX) / 2.0;
      let yMid = (this._bounds.MinY + this._bounds.MaxY) / 2.0;

      let topLeft = this.toEnvelopeFromRect(this._bounds.MinX, this._bounds.MinY, xMid, yMid);
      let topRight = this.toEnvelopeFromRect(xMid, this._bounds.MinY, this._bounds.MaxX, yMid);
      let bottomLeft = this.toEnvelopeFromRect(this._bounds.MinX, yMid, xMid, this._bounds.MaxY);
      let bottomRight = this.toEnvelopeFromRect(xMid, yMid, this._bounds.MaxX, this._bounds.MaxY);

      // See if any child quadrants completely contain this node.
      if (topLeft.contains(rcFeature))
      {
        if (this._topLeft == null)
          this._topLeft = new QuadTree(topLeft);

        child = this._topLeft;
      }
      else if (topRight.contains(rcFeature))
      {
        if (this._topRight == null)
          this._topRight = new QuadTree(topRight);

        child = this._topRight;
      }
      else if (bottomLeft.contains(rcFeature))
      {
        if (this._bottomLeft == null)
          this._bottomLeft = new QuadTree(bottomLeft);

        child = this._bottomLeft;
      }
      else if (bottomRight.contains(rcFeature))
      {
        if (this._bottomRight == null)
          this._bottomRight = new QuadTree(bottomRight);

        child = this._bottomRight;
      }
    }

    if (child != null)
      child.insertFeature(feature, rcFeature, depth + 1);
    else
      this._node = new Node(feature, rcFeature, this._node);

    this._count++;
  }

  toBoundsFromRect(x1, y1, x2, y2) {
    return new Bounds(new LatLng(y1, x1), new LatLng(y2, x2))
  }

  toEnvelopeFromRect(x1, y1, x2, y2) {
    return new Envelope(x1, y1, x2, y2);
  }
  /// <summary>
  /// Returns all nodes in this quadrant that intersect the given bounds.
  /// The nodes are returned in order of descending priority.
  /// </summary>
  /// <param name="extents">The bounds that intersects the nodes you want returned.</param>
  /// <returns>A lazy list of nodes along with the new potential of this quadrant.</returns>
  getIntersectingFeatures(features, extents)
  {
    // add our features first
    var node = this._node;

    while (node != null) {
      if (node.Bounds.intersects(extents))
        features.push(node.Feature);

      node = node.Next;
    }

    // add features from the quadrants

    if (this._topLeft != null && this._topLeft.Bounds.intersects(extents))
      this._topLeft.getIntersectingFeatures(features, extents);

    if (this._topRight != null && this._topRight.Bounds.intersects(extents))
      this._topRight.getIntersectingFeatures(features, extents);

    if (this._bottomLeft != null && this._bottomLeft.Bounds.intersects(extents))
      this._bottomLeft.getIntersectingFeatures(features, extents);

    if (this._bottomRight != null && this._bottomRight.Bounds.intersects(extents))
      this._bottomRight.getIntersectingFeatures(features, extents);
  }

  /// <summary>
  /// Return true if there are any nodes in this QuadTree that intersect the given bounds.
  /// </summary>
  /// <param name="extents">The bounds to test</param>
  /// <returns><c>true</c> if this quadrant or its subquadrants has nodes intersecting the bounds; otherwise, <c>false</c>.</returns>
  hasIntersectingFeatures(extents) {
    // check our nodes first
    var node = this._node;

    while (node != null) {
      if (node.Bounds.Intersects(extents))
        return true;

      node = node.Next;
    }
    
    // now check our quadrants
    if (this._topLeft != null && this._topLeft.Bounds.intersects(extents) && this._topLeft.hasIntersectingFeatures(extents))
      return true;

    if (this._topRight != null && this._topRight.Bounds.intersects(extents) && this._topRight.hasIntersectingFeatures(extents))
      return true;

    if (this._bottomLeft != null && this._bottomLeft.Bounds.intersects(extents) && this._bottomLeft.hasIntersectingFeatures(extents))
      return true;

    if (this._bottomRight != null && this._bottomRight.Bounds.intersects(extents) && this._bottomRight.hasIntersectingFeatures(extents))
      return true;

    return false;
  }

  // @endregion
}

QuadTree.MaxTreeDepth = 50;
