import {Markup, MarkupPoint} from './markup';
import {toBounds} from '../geometry/Bounds';
import MapEnums from '../map/enums';

export class PointMarkup extends  Markup {
  constructor(map, viewport, id) {
    super(map, viewport, id);
    this.geometryType = MapEnums.GeometryTypes.POINT;
  }

  //#region -- VIRTUAL FUNCTIONS
  render(context) {
    if (!this.isEmpty) {
      let current = this.linePoints[0];
      current.ClientPoint = this.editor.viewport.worldToClient(current.WorldCoordinate.X, current.WorldCoordinate.Y);

      if (!this.selectedForEditing)
        this.editor.renderPoint(current.ClientPoint, context, this.isHovered, this.selected)              
      else 
        this.editor.drawPoint(current.ClientPoint, false, context);      
    }
  }

  isStartPoint(point) {
    return true;
  }

  isLastPoint(point) {
    return true;
  }

  isPointOnLine(point) {
    return -1
  }

  handleMouseClick(x, y) {
    if (this.isEmpty) {
      let pt = new Point(x, y);
      this.addPoint(pt);        
    }
    
    this.isGeometryComplete = true;
  }
  
  handleMouseDoubleClick(x, y) {
    if (this.isEmpty) {
      let pt = new Point(x, y);
      this.addPoint(pt);        
      this.isGeometryComplete = true;
    }
  }

  clear() {

  }

  delete() {
    
  }

  toWKT(addTag) {
    if (this.linePoints.length > 0) {
      let coordinate = this.linePoints[0].WorldCoordinate;
      return addTag ? `POINT (${coordinate.X} ${coordinate.Y})` : `(${coordinate.X} ${coordinate.Y})`; 
    }
    return "";
  }

  //#endregion - VIRTUAL FUNCTIONS 

  addPoint(clientPoint) {        
    var worldCoordinate = this.editor.viewport.pointToWorldCoordinate(clientPoint);

    let newPoint = new MarkupPoint(clientPoint, worldCoordinate, false, 'black')
    this.linePoints.push(newPoint);

    return newPoint;    
  }
  
  withinBounds(topLeftPoint, bottomRightPoint) {
    let bounds = toBounds(topLeftPoint, bottomRightPoint); 
    if (this.linePoints.length > 0) 
      return bounds.contains(this.linePoints[0].ClientPoint);        
  }
}