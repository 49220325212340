import {TileSource} from './tilesource';

export class WMTSTileSource extends TileSource
{
  // @section Constructors

  constructor(map) {
    super(map);
  }

  // @section Properties

  // @section TileSource Methods

  setLayer(layer){
    this._layer = layer.replace(" ", "_");
  }

  getTileUrl(level, col, row) {
    let url = "https://tiles.metromap.com.au/wmts";
    let username = 'hmcdonald';
    let password = 'Mipela2021';
    let version = 'Auto';
    let service = 'WMTS';
    let request = 'GetTile'
    let style= 'default';
    let format = 'image/png';
    let tileMatrixSet = 'GoogleMapsCompatibleExt:epsg:3857'
    let tileMatrix = level;

    // have to double the tiles for wmts

    col = col;
    row = row;

   
    let domains = ['a','b','c'];

    let i = (col + row) % domains.length;

    var sTileUrl = `${url}?service=${service}&request=${request}&version=${version}&layer=${this._layer}&style=${style}&format=${format}&tileMatrixSet=${tileMatrixSet}&tileMatrix=${tileMatrix}&tileRow=${row}&tileCol=${col}`;
    

    return sTileUrl;
  }

}
