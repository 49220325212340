import { MgImageLayer } from "./imageLayer";

export class MgWmsLayer extends MgImageLayer {

  //#region -- CONSTRUCTOR
  constructor(id, options) {
    super(id, options);    
    this._image;
  } 
  //#endregion
  
  //#region -- STATIC METHODS
  /**
   * @param  {number} id
   * @param  {json} options
   */
  static createObject(id, options, taskId) {    
    return new MgWmsLayer(id, options, taskId);    
  } 
  //#endregion
  
  //#region -- OVERRIDES
   /**   
   * @returns Promise(MgLayer)
   * @override
   */
  isLayerReady(canvas) {
    
    return new Promise((resolve, reject) => {      
      
      const getMapUrl = `${this.options.url}?SERVICE=wms&VERSION=${this.options.version}&REQUEST=GetMap&LAYERS=${this.options.layers}
      &STYLES=${this.options.styles}&CRS=${this.options.crs}&width=${canvas.width}&HEIGHT=${canvas.height}&FORMAT=${this.options.format}&BBOX=${this.options.bbox}`;

      fetch(getMapUrl)
      .then(response=>{
        if(!response.ok)
          throw new Error("HTTP Status " + response.status);
        return response.blob()
      })
      .then(wmsBlob => {      
        if(wmsBlob.type == "text/xml"){
          this._readErrorFromBlob(wmsBlob)
          .then(message  => console.log(message))
          .catch(error => this.handleError(error));
        }
        else {
          const urlCreator = window.URL || window.webkitURL;                  
          let imgUrl = urlCreator.createObjectURL(wmsBlob);          
    
          this._image = new Image();
          this._image.src = imgUrl;
          console.log(imgUrl);
    
          this._image.onload = ()=> {            
            resolve(this);
          };        
        }      
      })
      .catch(error => reject(error));
    });
  }

  /**
   * @param  {MgCanvas} canvas
   * @override
   */
  refreshView(canvas) {                  
    canvas.drawImage(this._image, 0, 0);           
  }
  //#endregion
  
  //#region -- METHODS
  _readErrorFromBlob(blob) {   
    return new Promise(function(resolve, reject) {
      let reader = new FileReader();
      reader.onload = () => {        
        let parser = new DOMParser();
        let xml = parser.parseFromString(reader.result, "text/xml");
        let message = xml.getElementsByTagName("ServiceException")[0].innerHTML;        
        return resolve(message);
      };
      reader.onerror = (error) => reject(error);      
      reader.readAsText(blob);
    });    
  }     
  //#endregion
}