import * as Colour from './Colour';
import MapEnums from '../map/enums';
import {Rectangle} from '../geometry/rectangle';
import {RestAPI} from '../api/restapi';
import { map } from 'rxjs/operators';
import { MgCanvas } from '../map/canvas';
import { fakeStop } from '../dom/DomEvent';
export class HoverText {
  constructor(text, fontName, fontSize, fontStyle, expressionColumn) {
    this.Text = text != undefined ? text : '';;
    this.FontName = fontName != undefined ? fontName : 'Arial';
    this.FontSize = fontSize != undefined ? fontSize.replace('pt', 'px') : '12px';
    this.FontStyle = fontStyle != undefined ? fontStyle : 'Normal';
    this.IsExpression = expressionColumn != null;
    this.expressionColumn = expressionColumn;
  }

  toCSS()
  {
    var fontStyle = (this.FontStyle == 'Bold') ? "font-weight:bold;" : "font-weight:normal;";

    if (this.FontStyle == 'Italic')
      fontStyle += "font-style:italic;";
    
    let sFont = `font-family:${this.FontName};font-size:${this.FontSize}px;${fontStyle}`;
    return sFont; 
  }

  toHTML(parent, text) {
    let span = document.createElement('span');
    span.style.margin = '2px';
    span.style.padding = '0px';
    span.style.fontFamily = this.FontName;
    span.style.fontSize = this.FontSize;
    span.style.fontWeight = 'Normal';
    
    if (this.FontStyle.indexOf('Bold') > -1)
      span.style.fontWeight = 'Bold';
    if (this.FontStyle.indexOf('Italic') > -1)
      span.style.fonStyle = 'Italic';
    
    span.innerHTML = text;
    parent.appendChild(span);
  }
}

export class HoverParagraph {
  constructor(textAlign) {
    this.hoverTexts = [];    
    this.columns = [];
    this.textAlign = textAlign != undefined ? textAlign : 'start';
    this.width = 0;
  }

  addHoverText(hoverText, column) {
    this.hoverTexts.push(hoverText);
    this.hoverFormat += hoverText.text;
    if (column)
      this.columns.push(column);
    else  
      this.columns.push(hoverText.Text);
  }

  getTextWidth(feature, context) {
    var formattedLine = '';

    for(let i = 0; i < this.hoverTexts.length; i++) {
      let hoverText = this.hoverTexts[i];
      let column = this.columns[i];
      var text = column;
      if (hoverText.IsExpression)       
        text = feature.dctValues[column];    
        
        formattedLine += text;  
    }
    
    
    if (this.hoverTexts.length > 0)
      context.font = `${this.hoverTexts[0].FontStyle} ${this.hoverTexts[0].FontSize}px ${this.hoverTexts[0].FontName}`;  

    this.width = context.measureText(formattedLine).width;
    return this.width;
  }

  render(feature, canvas, context, x, y) {
        
    for(let i = 0; i < this.hoverTexts.length; i++) {
      let hoverText = this.hoverTexts[i];
      let column = this.columns[i];
      var text = column;
      if (hoverText.IsExpression)       
        text = feature.dctValues[hoverText.expressionColumn];              
      
      context.fillStyle = '#000000';

      if (hoverText.FontName != undefined && hoverText.FontSize != undefined)
      context.font = `${hoverText.FontSize}px ${hoverText.FontName}`;      
      
      let metrics = context.measureText(text); 
      let width = metrics.width;
      
      context.textAlign = this.textAlign; 
      context.fillText(text, x, y);

      if (width > 0)
        x = x + width + 2;
    }
  }

  toHTML(parent, feature) {
        
    let p = document.createElement('p');
    p.style.margin = '0px';
    p.style.padding = '0px';
    parent.appendChild(p);

    for(let i = 0; i < this.hoverTexts.length; i++) {
      let hoverText = this.hoverTexts[i];
      
      let column = this.columns[i];
      var text = column;
      if (hoverText.IsExpression)       
        text = feature.dctValues[hoverText.expressionColumn];              

      hoverText.toHTML(p, text);
    }
  }
}

export class Hover {
  // @region Constructors

  constructor(xml, isSimple) {
    this.pixelRatio = PIXEL_RATIO;
    this.paragraphs = [];
    this.columns = [];
    this.expressionColumns = [];
    this.isSimple = isSimple != undefined ? isSimple : false;
    this.parseHoverXml(xml);    
  }

  get Columns() {
    return this.columns;
  }

  get ExpressionColumns() {
    return this.expressionColumns;
  }
  parseHoverXml(xml) {
    /*
    Example:

    <?xml version="1.0" encoding="UTF-8"?>
    <Body OrderBy="2.Name">
      <Paragraph align="Center">
          <TextRun FontName="Lucida Console" />
          <EvalTextRun Expression="142" FontName="Lucida Console" />
          <TextRun FontName="Lucida Console" />
          <EvalTextRun Expression="143" FontName="Lucida Console" />
      </Paragraph>
    </Body>
    */
    if (xml != null && xml.length > 0) {
      var xmlDoc = null;
      if (window.DOMParser) {
        let parser= new window.DOMParser();
        xmlDoc=parser.parseFromString(xml,"text/xml");
      } 
      else { // Internet Explorer
        xmlDoc=new ActiveXObject("Microsoft.XMLDOM");
        xmlDoc.async=false;
        xmlDoc.loadXML(xml); 
      }

      var tags = xmlDoc.getElementsByTagName('*');      
      var paragraph = null;

      for (var i = 0; i < tags.length; i++) {
        let tag = tags[i];
        
        if (tag.nodeName == 'TextRun' || tag.nodeName == 'EvalTextRun') {
          var column = tag.getAttribute("Expression");
          var expressionColumn = column;
          if (expressionColumn != null)
            expressionColumn = expressionColumn.trim();
          
          let text = tag.nodeName == 'TextRun' ? (tag.childNodes.length > 0 ? tag.childNodes[0].nodeValue : '') : `{${expressionColumn}}`;          
          let hoverText = new HoverText(text, tag.getAttribute("FontName"), tag.getAttribute("FontSize"), tag.getAttribute("FontStyle"), expressionColumn);
                                
          if (paragraph != null)
            paragraph.addHoverText(hoverText, column);          

          if (column != undefined && column.length > 0)
            this.columns.push(column);  
          
          if (expressionColumn != null && column.length > 0)
            this.expressionColumns.push(expressionColumn);  
        }
        else if (tag.nodeName == 'Paragraph') {
          paragraph = new HoverParagraph(tag.getAttribute("align"));
          this.paragraphs.push(paragraph);
        }
        else if (tag.nodeName == 'TableCell' || tag.nodeName == 'Frame') {
          this.isSimple = false;
        }
        
      }
    }    
  }
  
  showTooltip(feature, canvas, context, x, y, map) {
    context.globalCompositeOperation = 'source-over';

    // why are we drawing stuff other than the tooltip here?

    // let hoverItem = map.hoverItem;
    // if (hoverItem != null) {

      /*
        let width = hoverItem.Shape.symbol.HoverSymbol.width;
        let height = hoverItem.Shape.symbol.HoverSymbol.height;
        let sx = x - width;
        let sy = y - height;

        console.log(`sx: ${sx} sy: ${sy}  width:${width} height:${height}`);
        canvas.clearRect(sx, sy, width * 2, height *2 )      
        canvas.context.drawImage(canvas.offScreenCanvas, sx, sy, width*2, height*2, sx, sy, width*2, height*2);
      */
      
    //   hoverItem.Shape.draw(canvas, 1, context);
    // }
		
    // if (feature.Shape != null) {
    //   feature.Shape.drawHover(canvas, 1, context);
    // }

    let toolTipDiv = this.createToolTiDiv(map, x, y+20);

    if (this.isSimple) {
      for(let i = 0; i < this.paragraphs.length; i++) {
        let paragraph = this.paragraphs[i];     
        paragraph.toHTML(toolTipDiv, feature);    
      }

      toolTipDiv.style.visibility = "visible";
    }
    else {
      let image = new Image();
			
			image.addEventListener("load", () => {
        let width = image.naturalWidth ;
        let height = image.naturalHeight ;        

        if (map.canvas.height < (y+height+40)) {
          toolTipDiv.style.top = y - height - 40;
        }

        if (map.canvas.width < (x+(2* width))) {
          toolTipDiv.style.left = x - (2 * width);
        }

        toolTipDiv.style.visibility = "visible";        			
      });
      let layer = feature.featureLayer;
      let taskId = layer.taskId;
      if (taskId){
        let tokens = taskId.toString().split('-');
        if (tokens != null && tokens.length > 1)
          taskId = tokens[0];
      }

      let encodedXml = encodeURIComponent(layer.options.Hover);
      let imageUrl = `${RestAPI.BaseUrl}GetFeatureTooltip?token=${RestAPI.AccessToken}&datasetId=${layer.Dataset}&xmlHover=${encodedXml}&featureId=${feature.Id}`;
      image.src = imageUrl;              
      toolTipDiv.appendChild(image);
    }    

    map.div.insertBefore(toolTipDiv, map.div.firstChild);
  }

  render(feature, canvas, context, x, y, map) {
    context.globalCompositeOperation = 'source-over';
        
    if (feature.Shape != null)
      feature.Shape.drawHover(canvas, 1, context);

    if (this.isSimple) {
      var longestWidth = -1;

      var emptyParagraphs = 0;

      for(let i = 0; i < this.paragraphs.length; i++) {
        let width = this.paragraphs[i].getTextWidth(feature, context);
        longestWidth = Math.max(longestWidth, width);      

        if (width <= 0)
        emptyParagraphs++;
      }
      
      let offset = 4;
      let height = 24 * (this.paragraphs.length - emptyParagraphs) + offset;

      let rect = new Rectangle(x + offset, y + (offset*2), longestWidth + 40, height);      
      canvas.drawRoundRectangle(rect.left, rect.top, rect.width, rect.height, 5, '#000000', '#ffffed', context)
      
      y = rect.top + 16;
      for(let i = 0; i < this.paragraphs.length; i++) {
        let paragraph = this.paragraphs[i];     
        if (paragraph.width > 0) { 
          paragraph.render(feature, canvas, context, rect.left+offset, y);
          y += 24;
        }
      }
    }
    else {
      let image = new Image();
			
			image.addEventListener("load", () => {
        let width = image.naturalWidth ;
        let height = image.naturalHeight ;

        canvas.drawRoundRectangle(x-2, y-2, width+4, height + 4, 5, '#000000', '#ffffed', context)			;  
				canvas.context.drawImage(image, x + 2, y + 2);				
			
      });
      let layer = feature.featureLayer;
      let encodedXml = encodeURIComponent(layer.options.Hover);

      let imageUrl = `${RestAPI.BaseUrl}GetFeatureTooltip?token=${RestAPI.AccessToken}&taskId=${layer.taskId}&datasetId=${layer.Dataset}&xmlHover=${encodedXml}&featureId=${feature.Id}`;
			image.src = imageUrl;
    }    
  }
  
  createToolTiDiv(map, x, y) {

    if (map.toolTipDiv != null) {
      map.toolTipDiv.parentNode.removeChild(map.toolTipDiv);
    }
  
    var toolTipDiv = document.createElement('div');
    toolTipDiv.style.margin = '2px';
    toolTipDiv.style.padding = '2px';
    toolTipDiv.style.backgroundColor = '#ffffed';
    toolTipDiv.style.border = '1px solid grey';
    toolTipDiv.style.borderRadius = '5px';
    toolTipDiv.style.position = 'absolute';
    toolTipDiv.style.left = x + 'px';
    toolTipDiv.style.top = y + 'px';
    toolTipDiv.style.zIndex = 1000;
    toolTipDiv.style.outlineStyle = 'none';

    toolTipDiv.style.webkitUserSelect = 'none'; /* Safari */
    toolTipDiv.style.msUserSelect = 'none'; /* IE 10+ and Edge */
    toolTipDiv.style.userSelect = 'none'; /* Standard syntax */

    toolTipDiv.style.visibility = "hidden";
    toolTipDiv.oncontextmenu = function(e) {e.preventDefault();}

    map.toolTipDiv = toolTipDiv;    
  
    return toolTipDiv;
  }
}

export let PIXEL_RATIO = (function () {
  var ctx = document.createElement("canvas").getContext("2d"),
      dpr = window.devicePixelRatio || 1,
      bsr = ctx.webkitBackingStorePixelRatio ||
            ctx.mozBackingStorePixelRatio ||
            ctx.msBackingStorePixelRatio ||
            ctx.oBackingStorePixelRatio ||
            ctx.backingStorePixelRatio || 1;

  return dpr / bsr;
})();


