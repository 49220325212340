import {Envelope} from '../geometry/Envelope';
import {Point} from '../geometry/Point';
import {LatLng} from '../geometry/LatLng';
import {Rectangle} from '../geometry/rectangle';

export class Viewport
  {
    constructor(client, world, cellsize, scale)
    {
      this._client = client;
      this._world = world;
      this._cellsize = cellsize;
      this._scale = scale;
      this.offScreenCanvas = undefined;

    }

    // @section Getters / Setters
    get Client() { return this._client; }
    get Cellsize() { return this._cellsize; }
    get World() { return this._world; }
    get Scale() { return this._scale; }

    get done (){ return false;  }
    get geometryChanged (){ return true;  }

    dispose(tileSource, now) {
    }
    
    worldToClient(point) {
      return new Point(Math.trunc((point.X - this.World.MinX) / this.Cellsize), Math.trunc((this.World.MaxY - point.Y) / this.Cellsize));
    }

    worldToClient(x, y) {
      return new Point(Math.trunc((x - this.World.MinX) / this.Cellsize), Math.trunc((this.World.MaxY - y) / this.Cellsize));
    }

    worldToClientRect(envelope)
    {
      return new Rectangle(this.Client.MinX + Math.floor((envelope.MinX - this.World.MinX) / this.Cellsize), 
                       this.Client.MinY + Math.floor((this.World.MaxY - envelope.MaxY) / this.Cellsize), 
                       this.Client.MinX + Math.ceil((envelope.MaxX - this.World.MinX) / this.Cellsize), 
                       this.Client.MinY + Math.ceil((this.World.MaxY - envelope.MinY) / this.Cellsize), true);
    }

    pointToWorldCoordinate(point) {      
      //return new Coordinate((point.X * this.Cellsize) + this.World.MinX, this.World.MaxY - ((point.Y - this.Client.Top) * this.Cellsize));      
      return new LatLng(this.World.MaxY - ((point.y - this.Client.MinY) * this.Cellsize), (point.x * this.Cellsize) + this.World.MinX);      
    }

    pointToCoordinate(point) {      
      return new Coordinate(this.World.MaxY - ((point.y - this.Client.MinY) * this.Cellsize), (point.x * this.Cellsize) + this.World.MinX);            
    }
    /*
    Polygon GetClipBox(strokeWidth)
    {
      return new Polygon(new LinearRing(new Coordinate[] { new Coordinate(World.MinX - strokeWidth, World.MinY - strokeWidth), new Coordinate(World.MaxX + strokeWidth, World.MinY - strokeWidth), new Coordinate(World.MaxX + strokeWidth, World.MaxY + strokeWidth), new Coordinate(World.MinX - strokeWidth, World.MaxY + strokeWidth), new Coordinate(World.MinX - strokeWidth, World.MinY - strokeWidth) }));
    }
    */
        
    /*
    RectangleF WorldToClient(Envelope envelope)
    {
      return RectangleF.FromLTRB(Math.Floor((envelope.MinX - World.MinX) / Cellsize), Math.Floor((World.MaxY - envelope.MaxY) / Cellsize), Math.Ceiling((envelope.MaxX - World.MinX) / Cellsize), Math.Ceiling((World.MaxY - envelope.MinY) / Cellsize));
    }
    */
  }