import * as Colour from './Colour';
import MapEnums from '../map/enums';

export class Fill {
  constructor(fillStyle) {
    this.style = fillStyle;
    this.colour = fillStyle != undefined && fillStyle.Colour != undefined ? Colour.fromString(fillStyle.Colour) : 'black';
    this.type = this.getFillType(fillStyle);
    this.hatchStyle = MapEnums.HatchStyles.None;
    this.hatchImg = null;
    this.pattern = null;
    this.hoverPattern = null;

    if (fillStyle && fillStyle.FillType == 'Hatch') {
      this.setHatchStyle(fillStyle.HatchStyle);      
    }    
    else if (fillStyle && fillStyle.FillType == 'Pattern') {
      this.patternId = fillStyle.PatternId;
      this.patternHeight = fillStyle.PatternHeight;
      this.patternSpacing = fillStyle.PatternSpacing;
    }
  }
  
  getFillType(fillStyle) {

    if (fillStyle == undefined)
      return MapEnums.FillTypes.None;

    var sType = fillStyle.Type;

    if (sType == undefined)
      return MapEnums.FillTypes.None;
    else if (sType == 'Solid')
      return MapEnums.FillTypes.Solid;
    else if (sType == 'Hatch')
      return MapEnums.FillTypes.Hatch;
    else if (sType == 'Pattern')
      return MapEnums.FillTypes.Pattern;  
    else if (sType == 'Gradient')
      return MapEnums.FillTypes.Gradient;
    else if (sType == 'LinearGradient')
      return MapEnums.FillTypes.LinearGradient;  
  }

  setHatchStyle(sType) {
    if (sType == undefined) {
      this.hatchStyle = MapEnums.HatchStyles.None;
    }
    else if (sType == 'Horizontal') {
      this.hatchImg = new Image();
      this.hatchImg.src = "data:image/svg+xml;charset=utf8, " + this.sanitisedSVG(Fill.Horizontal);
      this.hatchStyle = MapEnums.HatchStyles.Horizontal;
    }
    else if (sType == 'Vertical') {
      this.hatchImg = new Image();
      this.hatchImg.src = "data:image/svg+xml;charset=utf8, " + this.sanitisedSVG(Fill.Horizontal);
      //this.hatchStyle = MapEnums.HatchStyles.Vertical;
    }
    else if (sType == 'ForwardDiagonal') {
      this.hatchImg = new Image();
      this.hatchImg.src = "data:image/svg+xml;charset=utf8, " + this.sanitisedSVG(Fill.FDiagonal);

      this.hatchStyle = MapEnums.HatchStyles.ForwardDiagonal;  
    }
    else if (sType == 'BackwardDiagonal') {
      this.hatchImg = new Image();
      this.hatchImg.src = "data:image/svg+xml;charset=utf8, " + this.sanitisedSVG(Fill.BDiagonal);

      this.hatchStyle = MapEnums.HatchStyles.BackwardDiagonal;
    }
    else if (sType == 'Cross') {
      this.hatchImg = new Image();
      this.hatchImg.src = "data:image/svg+xml;charset=utf8, " + this.sanitisedSVG(Fill.Cross);

      this.hatchStyle = MapEnums.HatchStyles.Cross;  
    }
    else if (sType == 'DiagonalCross') {
      this.hatchImg = new Image();
      this.hatchImg.src = "data:image/svg+xml;charset=utf8, " + this.sanitisedSVG(Fill.DoubleDiagonal);

      this.hatchStyle = MapEnums.HatchStyles.DiagonalCross;  
    }
  }

  setHoverStyles(hoverColour){
    var hoverStyle = {
      'Colour' : hoverColour,
      'Type' : 'Solid',
    };
    this.hoverFill = new Fill(hoverStyle);

  }

  setSelectedStyles(selectedColour){
    var selectedStyle = {
      'Colour' : selectedColour,
      'Type' : 'Solid',
    };
    this.selectedFill = new Fill(selectedStyle);

  }

  setTrackingStyles(trackingColour){
    var trackingStyle = {
      'Colour': trackingColour,
      'Type': 'Solid',
    };
    this.trackingFill = new Fill(trackingStyle);
  }

  sanitisedSVG(svg) {
    var sanitized = svg.replace(/\</g,'%3C')   //for <
    sanitized = sanitized.replace(/\>/g,'%3E')   //for >
    sanitized = sanitized.replace(/\#/g,'%23')   //for #
    
    return sanitized;
  }
  
  loadHoverPatten(baseUrl, accessToken, debugMode = false, hoverColour){

    let hoverStyle = Object.assign({}, this.style, {Colour: hoverColour});

    let base64 = window.btoa(debugMode ? JSON.stringify(hoverStyle) : JSON.stringify(hoverStyle));
    let imageUrl = `${baseUrl}\GenerateFillPattern?token=${accessToken}&jStyle=${base64}&size=24`;

    if (this.type ==  MapEnums.FillTypes.Hatch || this.type ==  MapEnums.FillTypes.Pattern) {
      this.hoverPattern = new Image();

      let __this = this;

      this.hoverPattern.onload = function () {
        __this.hoverPattern.width = __this.style.PatternHeight;
        __this.hoverPattern.height = __this.style.PatternHeight;
      };

      this.hoverPattern.src = imageUrl;
    }
  }

  loadPattern(baseUrl, accessToken, debugMode = false) {

    let base64 = window.btoa(debugMode ? JSON.stringify(this.style) : JSON.stringify(this.style.objJSON));
    let imageUrl = `${baseUrl}\GenerateFillPattern?token=${accessToken}&jStyle=${base64}&size=24`;

    if (this.type ==  MapEnums.FillTypes.Hatch || this.type ==  MapEnums.FillTypes.Pattern) {
      this.pattern = new Image();

      let __this = this;

      this.pattern.onload = function () {
        __this.pattern.width = __this.style.PatternHeight;
        __this.pattern.height = __this.style.PatternHeight;
      };

      this.pattern.src = imageUrl;
    }
  }

  get IsEmpty() {
    return (type == Types.None || width == 0.0 || opacity == 0 || colour == "none");
  }      

  get Colour() {
    return this.colour;
  }
  
  get HatchImage() {
    return this.hatchImg;
  }

  get FillPattern() {
    return this.pattern;
  }

  get FillType() {
    return this.type;
  }
}

Fill.Circles = "<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><rect width='10' height='10' fill='#FFFFFF' /><circle cx='2.0' cy='2.0' r='2.0' fill='#000000'/></svg>";
Fill.Horizontal = "<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><rect width='10' height='10' fill='#FFFFFF' /><rect x='0' y='0' width='10' height='1' fill='#000000'/></svg>";
Fill.Vertical = "<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><rect width='10' height='10' fill='#FFFFFF' /><rect x='0' y='0' width='1' height='10' fill='#000000'/></svg>";
Fill.Square = "<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><rect width='10' height='10' fill='#FFFFFF' /><rect x='5' y='0' width='1' height='10' fill='#000000'/><rect x='0' y='5' width='10' height='1' fill='#000000'/></svg>";
Fill.FDiagonal = "<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><line x1='0' y1='10' x2='10' y2='0' stroke='#000000' stroke-width='1'/></svg>";
Fill.BDiagonal = "<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><line x1='0' y1='0' x2='10' y2='10' stroke='#000000' stroke-width='1'/></svg>";
Fill.Cross = "<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><line x1='5' y1='1' x2='5' y2='9' stroke='#000000' stroke-width='1'/><line x1='1' y1='5' x2='9' y2='5' stroke='blue' stroke-width='1'/></svg>";
Fill.DoubleDiagonal  = "<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><line x1='0' y1='10' x2='10' y2='0' stroke='#000000' stroke-width='1'/><line x1='0' y1='0' x2='10' y2='10' stroke='#000000' stroke-width='1'/></svg>";


