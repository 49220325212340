import {Events} from '../core/Events';
import { fromEvent, interval } from 'rxjs';
import { debounce } from 'rxjs/operators';

import * as DomEvent from '../dom/DomEvent';
import * as DomUtil from '../dom/DomUtil';

export class ZoomControl extends Events   {
  constructor() {
    super();
  }

  addTo(map) {
    this._map = map;
    var zoomName = 'mgmap-control-container';
    var container = DomUtil.create('div', zoomName, this._map.container);
    
    this._zoomInButton  = this._createButton('+', 'Zoom In', zoomName + '-zoom-in',  container, this._zoomIn);
		this._zoomOutButton = this._createButton('&#x2212;', 'Zoom Out', zoomName + '-zoom-out', container, this._zoomOut);
		
		const zoomInClicks = fromEvent(this._zoomInButton, 'click');
		const zoomOutClicks = fromEvent(this._zoomOutButton, 'click');

		this.onZoomInEvent = zoomInClicks.pipe(debounce(() => interval(200)));
		this.onZoomOutEvent = zoomOutClicks.pipe(debounce(() => interval(200)));
  }

  _zoomIn (e) {
    if (!e)
      e = window.event;

		e.stopPropagation();
		if (this._map._zoom < this._map.maxZoom) {
      this._map.zoomIn();			
		}
	}

	_zoomOut(e) {
		if (!e)
		  e= window.event;
		 
		e.stopPropagation();
		if (this._map._zoom > this._map.minZoom) {
      this._map.zoomOut();			
		}
	}

	_createButton(html, title, className, container, fn) {
		var link = DomUtil.create('a', className, container);
		link.innerHTML = html;
		link.href = '#';
		link.title = title;

		/*
		 * Will force screen readers like VoiceOver to read this as "Zoom in - button"
		 */
		link.setAttribute('role', 'button');
		link.setAttribute('aria-label', title);

		DomEvent.disableClickPropagation(link);
		DomEvent.on(link, 'click', DomEvent.stop);
		//DomEvent.on(link, 'click', fn, this);
				
		return link;
	}
}