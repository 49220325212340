import {TileSource} from './tilesource';
import { RestAPI } from '../api';

export class SecureTileSource extends TileSource
{
  // @section Constructors

  constructor(map, dataset) {
    super(map);
    this.dataset = dataset;
  }

  // @section Properties

  // @section TileSource Methods

  getTileUrl(level, col, row) {
    
    var sTileUrl = RestAPI.BaseUrl + "TileQuery?token=" + RestAPI.AccessToken + "&datasetId=" + this.dataset + "&level=" + level + "&col=" + col + "&row=" + row + "";
    return sTileUrl;
  }

}