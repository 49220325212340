import MapEnums from '../map/enums';
import {Point} from '../geometry/Point';
import {Rectangle} from '../geometry/rectangle';
import {Bounds} from '../geometry/Bounds';

export class LabelPoint {
  constructor() {
    this.pt = null;
    this.angle = 0;
    this.length = 0;
  }
}

export class ClipLine {
  constructor() {
    this.x1 = 0;
    this.x2 = 0;
    this.y1 = 0;
    this.y2 = 0;
  }
}

export class LineObj {
  constructor(size = 0) {
    this.numpoints = 0;
    this.points = new Array(size);

    if (size > 0) {
      for(let i = 0; i < size; i++)
        this.points[i] = new Point();
    }
  }
    
  flush() {
    const line = Array.from(this.points);

    this.numpoints = 0;
    return line;
  }

  reset() {
    this.numpoints = 0;        
  }
}

export class MgMapPrimitive {  
  constructor() {
  }

  // #region Clipping Methods
  /*
    ** Private implementation of the Sutherland-Cohen algorithm. Inspired by
    ** "Getting Graphic: Programming Fundamentals in C and C++" by Mark Finlay
    ** and John Petritis. (pages 179-182)
  */
  ClipLine(clipLine, rect)
  {
    var x, y;
    var slope;
    var check1, check2;

    if (clipLine.x1 < rect.Left && clipLine.x2 < rect.Left)
      return false;
    if (clipLine.x1 > rect.Right && clipLine.x2 > rect.Right)
      return false;

    check1 = this.CLIP_CHECK(rect.Left, clipLine.x1, rect.Right);
    check2 = this.CLIP_CHECK(rect.Left, clipLine.x2, rect.Right);
    if (check1 == MapEnums.ClipStates.ClipLeft || check2 == MapEnums.ClipStates.ClipLeft)
    {
      slope = (clipLine.y2 - clipLine.y1) / (clipLine.x2 - clipLine.x1);
      y = clipLine.y1 + (rect.Left - clipLine.x1) * slope;
      if (check1 == MapEnums.ClipStates.ClipLeft)
      {
        clipLine.x1 = rect.Left;
        clipLine.y1 = y;
      }
      else
      {
        clipLine.x2 = rect.Left;
        clipLine.y2 = y;
      }
    }

    if (check1 == MapEnums.ClipStates.ClipRight || check2 == MapEnums.ClipStates.ClipRight)
    {
      slope = (clipLine.y2 - clipLine.y1) / (clipLine.x2 - clipLine.x1);
      y = clipLine.y1 + (rect.Right - clipLine.x1) * slope;
      if (check1 == MapEnums.ClipStates.ClipRight)
      {
        clipLine.x1 = rect.Right;
        clipLine.y1 = y;
      }
      else
      {
        clipLine.x2 = rect.Right;
        clipLine.y2 = y;
      }
    }

    if (clipLine.y1 < rect.Top && clipLine.y2 < rect.Top)
      return false;
    if (clipLine.y1 > rect.Bottom && clipLine.y2 > rect.Bottom)
      return false;

    check1 = this.CLIP_CHECK(rect.Top, clipLine.y1, rect.Bottom);
    check2 = this.CLIP_CHECK(rect.Top, clipLine.y2, rect.Bottom);
    if (check1 == MapEnums.ClipStates.ClipLeft || check2 == MapEnums.ClipStates.ClipLeft)
    {
      slope = (clipLine.x2 - clipLine.x1) / (clipLine.y2 - clipLine.y1);
      x = clipLine.x1 + (rect.Top - clipLine.y1) * slope;
      if (check1 == MapEnums.ClipStates.ClipLeft)
      {
        clipLine.x1 = x;
        clipLine.y1 = rect.Top;
      }
      else
      {
        clipLine.x2 = x;
        clipLine.y2 = rect.Top;
      }
    }

    if (check1 == MapEnums.ClipStates.ClipRight || check2 == MapEnums.ClipStates.ClipRight)
    {
      slope = (clipLine.x2 - clipLine.x1) / (clipLine.y2 - clipLine.y1);
      x = clipLine.x1 + (rect.Bottom - clipLine.y1) * slope;
      if (check1 == MapEnums.ClipStates.ClipRight)
      {
        clipLine.x1 = x;
        clipLine.y1 = rect.Bottom;
      }
      else
      {
        clipLine.x2 = x;
        clipLine.y2 = rect.Bottom;
      }
    }

    return true;
  }   
  
  //
  // Routine for clipping a polyline, stored in a shapeObj struct, to a
  // rectangle. Uses clipLine() function to create a new shapeObj. 
  //
  ClipLineStringRect(points, rect) {
    var lines = [];

    if (points.length > 0)
    {
      var line = new LineObj(points.length);
      var clipLine = new ClipLine();
      var j;

      clipLine.x1 = points[0].x;
      clipLine.y1 = points[0].y;

      for (j = 1; j < points.length; j++)
      {
        clipLine.x2 = points[j].x;
        clipLine.y2 = points[j].y;

        if (this.ClipLine(clipLine, rect) == true)
        {
          if (line.numpoints == 0)
          { // first segment, add both points 

            line.points[0].x = clipLine.x1;
            line.points[0].y = clipLine.y1;
            line.points[1].x = clipLine.x2;
            line.points[1].y = clipLine.y2;

            line.numpoints = 2;
          }
          else
          { // add just the last point 

            line.points[line.numpoints].x = clipLine.x2;
            line.points[line.numpoints].y = clipLine.y2;

            line.numpoints++;
          }

          if ((clipLine.x2 != points[j].x) || (clipLine.y2 != points[j].y))
          {
            if (line.numpoints > 0)
              Array.prototype.push.apply(lines, line.flush());
          }
        }

        clipLine.x1 = points[j].x;
        clipLine.y1 = points[j].y;
      }

      if (line.numpoints > 0)
        Array.prototype.push.apply(lines, line.flush());
    }

    return lines;
  }    

  //
  // Slightly modified version of the Liang-Barsky polygon clipping algorithm
  //
  ClipPolygonRect(points, rect) {
    if (points.length > 0)
    {
      var pts = [];

      var numpoints = points.length;
      var line = new LineObj(numpoints + 2);
      var deltax, deltay, xin, xout, yin, yout;
      var tinx, tiny, toutx, touty, tin1, tin2, tout;
      var x1, y1, x2, y2;
      var i, j;

      for (i = 0; i < numpoints - 1; i++) {

        x1 = points[i].x;
        y1 = points[i].y;
        x2 = points[i + 1].x;
        y2 = points[i + 1].y;

        deltax = x2 - x1;
        if (deltax == 0)
        { // bump off of the vertical 
          deltax = (x1 > rect.Left ? -MgMapPrimitive.NEARZERO : MgMapPrimitive.NEARZERO);
        }
        deltay = y2 - y1;
        if (deltay == 0)
        { // bump off of the horizontal 
          deltay = (y1 > rect.Top ? -MgMapPrimitive.NEARZERO : MgMapPrimitive.NEARZERO);
        }

        if (deltax > 0)
        { //  points to right 
          xin = rect.Left;
          xout = rect.Right;
        }
        else
        {
          xin = rect.Right;
          xout = rect.Left;
        }
        if (deltay > 0)
        { //  points up 
          yin = rect.Top;
          yout = rect.Bottom;
        }
        else
        {
          yin = rect.Bottom;
          yout = rect.Top;
        }

        tinx = (xin - x1) / deltax;
        tiny = (yin - y1) / deltay;

        if (tinx < tiny)
        { // hits x first 
          tin1 = tinx;
          tin2 = tiny;
        }
        else
        { // hits y first 
          tin1 = tiny;
          tin2 = tinx;
        }

        if (1 >= tin1)
        {
          if (0 < tin1)
          {
            line.points[line.numpoints].x = xin;
            line.points[line.numpoints].y = yin;
            line.numpoints++;
          }
          if (1 >= tin2)
          {
            toutx = (xout - x1) / deltax;
            touty = (yout - y1) / deltay;

            tout = (toutx < touty) ? toutx : touty;

            if (0 < tin2 || 0 < tout)
            {
              line.points.length = line.points.length + 1;

              if (tin2 <= tout)
              {
                if (0 < tin2)
                {
                  if (tinx > tiny)
                  {
                    line.points[line.numpoints].x = xin;
                    line.points[line.numpoints].y = y1 + tinx * deltay;
                    line.numpoints++;
                  }
                  else
                  {
                    line.points[line.numpoints].x = x1 + tiny * deltax;
                    line.points[line.numpoints].y = yin;
                    line.numpoints++;
                  }
                }
                if (1 > tout)
                {
                  if (toutx < touty)
                  {
                    line.points[line.numpoints].x = xout;
                    line.points[line.numpoints].y = y1 + toutx * deltay;
                    line.numpoints++;
                  }
                  else
                  {
                    line.points[line.numpoints].x = x1 + touty * deltax;
                    line.points[line.numpoints].y = yout;
                    line.numpoints++;
                  }
                }
                else
                {
                  line.points[line.numpoints].x = x2;
                  line.points[line.numpoints].y = y2;
                  line.numpoints++;
                }
              }
              else
              {
                if (tinx > tiny)
                {
                  line.points[line.numpoints].x = xin;
                  line.points[line.numpoints].y = yout;
                  line.numpoints++;
                }
                else
                {
                  line.points[line.numpoints].x = xout;
                  line.points[line.numpoints].y = yin;
                  line.numpoints++;
                }
              }
            }
          }
        }
      }

      if (line.numpoints > 0)
      {
        line.points.length = line.points.length + 1;
        line.points[line.numpoints].x = line.points[0].x; // force closure 
        line.points[line.numpoints].y = line.points[0].y;
        line.numpoints++;

        //msAddLine(&tmp, &line);
        for (i = 0; i < line.numpoints; i++)
          pts.push(line.points[i]);

      }
      points = pts;
    }

    return points;
  }
  
  
  /*
    ** Not a generic intersection test, we KNOW the lines aren't parallel or coincident. To be used with the next
    ** buffering code only. See code in mapsearch.c for a boolean test for intersection.
  */
  generateLineIntersection(a, b, c, d)
  {
    var p = new Coordinate();
    var r;
    var denominator, numerator;

    if (b.X == c.X && b.Y == c.Y)
      return b;

    numerator = ((a.Y - c.Y) * (d.X - c.X) - (a.X - c.X) * (d.Y - c.Y));
    denominator = ((b.X - a.X) * (d.Y - c.Y) - (b.Y - a.Y) * (d.X - c.X));

    r = numerator / denominator;

    p.X = MgMapPrimitive.MS_NINT(a.X + r * (b.X - a.X));
    p.Y = MgMapPrimitive.MS_NINT(a.Y + r * (b.Y - a.Y));

    return p;
  }
  // #endregion Clipping

  //region Labelling Methods
  GetPolygonLabelPoint(rcExtents, pts, suggestedAngle, labelPoint) {
    var bContains = false;
    var i, j, k;

    if (suggestedAngle != null)
      labelPoint.angle = suggestedAngle;
    else if (!this.GetPolylineLabelPoint(pts, 0.0, suggestedAngle, labelPoint))
      labelPoint.angle = 0.0;

    labelPoint.pt = this.TryGetPolygonCenterOfGravity(pts);
    bContains = this.PolygonContains(pts, labelPoint.pt);

    if (!bContains)
    {
      /* do it the hard way - scanline */
      var xintersect = new Array(pts.length);
      var skip = (rcExtents.Bottom - rcExtents.Top) / MgMapPrimitive.NUM_SCANLINES;
      var x, y, hi_y, lo_y, slope, len, max_len = 0;
      var point1, point2;
      var nfound;

      for (let k = 1; k <= MgMapPrimitive.NUM_SCANLINES; k++)
      {
        /* sample the shape in the y direction */

        y = rcExtents.Bottom - k * skip;

        /* need to find a y that won't intersect any vertices exactly */
        hi_y = y - 1; /* first initializing lo_y, hi_y to be any 2 pnts on either side of lp.y */
        lo_y = y + 1;

        for (i = 0; i < pts.length; i++)
        {
          if ((lo_y < y) && (hi_y >= y))
            break; /* already initialized */

          if (pts[i].y < y)
            lo_y = pts[i].y;
          if (pts[i].y >= y)
            hi_y = pts[i].y;
        }

        for (i = 0; i < pts.length; i++)
        {
          if ((pts[i].y < y) && ((y - pts[i].y) < (y - lo_y)))
            lo_y = pts[i].y;
          if ((pts[i].y >= y) && ((pts[i].y - y) < (hi_y - y)))
            hi_y = pts[i].y;
        }

        if (lo_y == hi_y)
        {
          break;
        }
        else
          y = (hi_y + lo_y) / 2.0;

        nfound = 0;
        point1 = pts[pts.length - 1];
        for (i = 0; i < pts.length; i++)
        {
          point2 = pts[i];

          if (this.EDGE_CHECK(point1.y, y, point2.y) == MapEnums.ClipStates.ClipMiddle)
          {
            if (point1.y == point2.y)
              continue; /* ignore horizontal edges */
            else
              slope = (point2.x - point1.x) / (point2.y - point1.y);

            x = point1.x + (y - point1.y) * slope;
            xintersect[nfound++] = x;
          } /* End of checking this edge */

          point1 = point2;  /* Go on to next edge */
        }

        /* First, sort the intersections */
        if (nfound > 1)
          xintersect.sort(function(a, b){return a - b});

        /* Great, now find longest span */
        for (i = 0; i < nfound; i += 2)
        {
          len = Math.abs(xintersect[i] - xintersect[i + 1]);
          if (len > max_len)
          {
            max_len = len;
            labelPoint.pt = new Point(((xintersect[i] + xintersect[i + 1]) / 2), y);
          }
        }
      }

      bContains = (max_len > 0);
    }

    return bContains;
  }

  GetPolylineLabelPoint(pts, min_length, suggestedAngle, labelPoint) {    
    var segment_length, max_segment_length = 0;
    var i, segment_index = 0;
    var pt2, pt1 = pts[0];
    var bFound = false;
    var theta;

    for (i = 1; i < pts.length; i++)
    {
      pt2 = pts[i];

      segment_length = Math.sqrt((Math.pow((pt1.x - pt2.x), 2.0) + Math.pow((pt1.y - pt2.y), 2.0)));

      if (segment_length > max_segment_length)
      {
        max_segment_length = segment_length;
        segment_index = i;
      }

      pt1 = pt2;
    }

    if (segment_index == 0)
    {
      // must have a degenerate line, skip it
      labelPoint.pt = null;
      labelPoint.length = 0.0;
      labelPoint.angle = 0.0;
    }
    else if (max_segment_length < min_length)
    {
      // too short to label
      labelPoint.pt = null;
      labelPoint.length = 0.0;
      labelPoint.angle = 0.0;
    }
    else
    {
      /* ok, now we know which line and which segment within that line */
      pt1 = pts[segment_index - 1];
      pt2 = pts[segment_index];

      labelPoint.pt = new Point((pt2.x + pt1.x) / 2, (pt2.y + pt1.y) / 2);
      labelPoint.length = max_segment_length;

      if (suggestedAngle != null)
        labelPoint.angle = suggestedAngle;
      else
      {
        theta = MgMapPrimitive.MS_RAD_TO_DEG * Math.asin(Math.abs(pt2.x - pt1.x) / Math.sqrt((Math.pow((pt2.x - pt1.x), 2.0) + Math.pow((pt2.y - pt1.y), 2.0))));

        if (pt1.x < pt2.x)
        {
          /* i.e. to the left */
          if (pt1.y > pt2.y) /* i.e. below */
          labelPoint.angle = -(90.0 - theta);
          else
          labelPoint.angle = (90.0 - theta);
        }
        else
        {
          if (pt1.y > pt2.y) /* i.e. below */
          labelPoint.angle = (90.0 - theta);
          else
          labelPoint.angle = -(90.0 - theta);
        }
      }

      bFound = true;
    }

    return bFound;
  }

  PolygonContains(pts, pt) {
    var bContains = false;

    if (pts.length < 3)
      bContains = false;
    else if (pts.findIndex(o => o.x == pt.x && o.y == pt.y))
      bContains = true;
    else
    {
      var dfTestX = pt.x;
      var dfTestY = pt.y;
      var x1, x2 = pts[0].x - dfTestX;
      var y1, y2 = pts[0].y - dfTestY;
      var dfIntersection;

      // For every point p in ring,
      // test if ray starting from given point crosses segment (p - 1, p)
      var iNumCrossings = 0;

      for (let iPoint = 1; iPoint < pts.length; iPoint++)
      {
        x1 = pts[iPoint].x - dfTestX;
        y1 = pts[iPoint].y - dfTestY;

        if ((y1 > 0 && y2 <= 0) || (y2 > 0 && y1 <= 0))
        {
          // Check if ray intersects with segment of the ring
          dfIntersection = (x1 * y2 - x2 * y1) / (y2 - y1);
          if (0.0 < dfIntersection)
          {
            // Count intersections
            iNumCrossings++;
          }
        }

        x2 = x1;
        y2 = y1;
      }

      // If iNumCrossings number is even, given point is outside the ring,
      // when the crossings number is odd, the point is inside the ring.
      bContains = ((iNumCrossings % 2) == 1);
    }

    return bContains;
  }

  CalculateExtents(pts) {
    var rcExtents;

    if (pts != null && pts.length > 0)
    {
      var top = pts[0].y;
      var left = pts[0].x;
      var right = left;
      var bottom = top;

      for (let index = 1; index < pts.length; index++)
      {
        let pt = pts[index];

        if (pt.x < left)
          left = pt.x;
        else if (pt.x > right)
          right = pt.x;

        if (pt.y < top)
          top = pt.y;
        else if (pt.y > bottom)
          bottom = pt.y;
      }

      rcExtents = new Rectangle(left, top, right, bottom, true);
    }
    else
      rcExtents = new Rectangle();

    return rcExtents;
  }

  CalculateBounds(pts) {
    var bounds;

    if (pts != null && pts.length > 0)
    {
      var top = pts[0].y;
      var left = pts[0].x;
      var right = left;
      var bottom = top;

      for (let index = 1; index < pts.length; index++)
      {
        let pt = pts[index];

        if (pt.x < left)
          left = pt.x;
        else if (pt.x > right)
          right = pt.x;

        if (pt.y < top)
          top = pt.y;
        else if (pt.y > bottom)
          bottom = pt.y;
      }

      bounds = new Bounds(new Point(left, top), new Point(right, bottom));
    }
    else
      bounds = new Bounds();

    return bounds;
  }
  /*
  ** Computes the center of gravity for a polygon based on it's largest outer ring only.
  */
  TryGetPolygonCenterOfGravity(pts) {
    var ptCentre = null;
    var sx = 0, sy = 0, tsx = 0, tsy = 0, s = 0; /* sums */
    var pt2, pt1 = pts[0];
    var a, area = 0;

    for (let i = 1; i < pts.length; i++) {
      pt2 = pts[i];

      a = pt1.x * pt2.y - pt2.x * pt1.y;
      s += a;
      tsx += (pt1.x + pt2.x) * a;
      tsy += (pt1.y + pt2.y) * a;

      pt1 = pt2;
    }

    area = Math.abs(s / 2);

    sx = s > 0 ? tsx : -tsx;
    sy = s > 0 ? tsy : -tsy;

    ptCentre = new Point((sx / (6 * area)), (sy / (6 * area)));

    return ptCentre;
  }

  TryGetPolygonCentroid(pts) {
    var ptCentre = null;
    var cent_weight_x = 0.0, cent_weight_y = 0.0;
    var len, total_len = 0;
    var pt2, pt1 = pts[0];

    for (let i = 1; i < pts.length; i++) {
      pt2 = pts[i];

      len = Math.sqrt((Math.pow((pt1.x - pt2.x), 2.0) + Math.pow((pt1.y - pt2.y), 2.0)));
      cent_weight_x += len * ((pt1.x + pt2.x) / 2);
      cent_weight_y += len * ((pt1.y + pt2.y) / 2);
      total_len += len;

      pt1 = pt2;
    }

    ptCentre = new Point((cent_weight_x / total_len), (cent_weight_y / total_len));

    return ptCentre;
  }

  
  ROUND(a) {
    return ((a) + 0.5);
  }
  
  MS_NINT(x) {
    return (x >= 0.0 ? (x + .5) : (x - .5));
  }

  MS_MAP2IMAGE_X_IC_DBL(x, minx, icx) {
    return ((x - minx) * icx);
  }

  MS_MAP2IMAGE_Y_IC_DBL(y, maxy, icy) {
    return ((maxy - y) * icy);
  }

  CLIP_CHECK(min, a, max) {
    return a < min ? MapEnums.ClipStates.ClipLeft : a > max ? MapEnums.ClipStates.ClipRight : MapEnums.ClipStates.ClipMiddle;
  }
  
  EDGE_CHECK(x0, x, x1) {
    return x < Math.min(x0, x1) ? MapEnums.ClipStates.ClipLeft : x > Math.max(x0, x1) ? MapEnums.ClipStates.ClipRight : MapEnums.ClipStates.ClipMiddle;
  }

  FindRegion(x, y, rect) {
    var code = 0;

    if (x < rect.Left)
      code |= 8; //left
    else if (x >= rect.Right)
      code |= 4; //right

    if (y<rect.Top)
      code |= 1; //top
    else if (y >= rect.Bottom)
      code |= 2; //bottom

    return code;
  }    
}


MgMapPrimitive.INFINITY = (1.0e+30);
MgMapPrimitive.NEARZERO = (1.0e-30); /* 1/INFINITY */

MgMapPrimitive.MS_SUCCESS = 1;
MgMapPrimitive.MS_FAILED  = -1;

MgMapPrimitive.MS_SHAPE_LINE = 1;
MgMapPrimitive.MS_SHAPE_POLYGON = 2;

MgMapPrimitive.NUM_SCANLINES = 5;

MgMapPrimitive.MS_RAD_TO_DEG = 57.295779513082321;
MgMapPrimitive.MS_DEG_TO_RAD = 0.017453292519943296;

export let MapPrimitive = new MgMapPrimitive();
