import {TileSource} from './tilesource';

export class GenericTileSource extends TileSource
{
  // @section Constructors

  constructor(map, name,  url) {
    super(map);
    this.url = url;
    this.name = name;
  }

  // @section Properties

  // @section TileSource Methods

  getTileUrl(level, col, row) {
    
    var sTileUrl = this.url.replace("{z}", "" + level).replace("{x}", "" + col).replace("{y}", "" + row);
    return sTileUrl;
  }

}