import {MgFeatureLayer} from './featureLayer';

export class MgGeocoderLayer extends MgFeatureLayer {
  
  constructor(id, options, taskId) {
    super(id, options, taskId);    
    this.isGeocoder = true;
  }

  // Factory methods
  static createObject(id, options, taskId) {    
    return new MgGeocoderLayer(id, options, taskId);    
  }

  /**   
   * @returns Promise(MgLayer)
   * @virtual
   */
  isLayerReady(canvas, zoom) {
    let __this = this;
    return new Promise((resolve, reject) => {         
      resolve(__this);       
    });
  }

  /**
   * @param  {MgCanvas} canvas   
   * @virtual
   */
  refreshView(canvas, offscreenCanvas) {
    super.refreshView(canvas, offscreenCanvas);
  }

  /**
   * @param  {Bounds} rcMap
   * @param  {LatLngBounds} newExtents
   * @param  {Number} cellsize
   * @param  {Number} scale
   */
  onExtentsChanged(sourceEvent, rcMap, newExtents, cellsize, scale, canvas) {
    super.onExtentsChanged(sourceEvent, rcMap, newExtents, cellsize, scale, canvas);
  }

  onAdd(map) {
    map.geocoder = this;
  }    
}