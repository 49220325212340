
import {TileSource} from './tilesource';
import {Bounds, Point} from '../geometry/index';
import {Rectangle, fromLTRB, fromRect} from '../geometry/rectangle';
import Axios from "axios";
import {Viewport} from '../drawing/viewport';

export const LoadStates = Object.freeze({
  Initial : Symbol("initial"),
  Loading : Symbol("loading"),
  Loaded : Symbol("loaded"),
  Failed : Symbol("failed")
});

export class Tile {
  constructor(level, col, row, tileSource, viewport) {
    this.initialise();
  
    this.rect = undefined;
    this.canvas = undefined;
    this.viewport = viewport;

    this.tileSource = tileSource;
    this.image = null;
    this.Name = `${level}:${col},${row}`;
    this.Level = level;
    this.Col = col;
    this.Row = row;

    var googleTileSize = tileSource.getGoogleTileSize(level);
    var GoogleMinX = TileSource.GoogleExtents.MinX + (col * googleTileSize);
    var GoogleMaxX = GoogleMinX + googleTileSize;

    var GoogleMaxY = TileSource.GoogleExtents.MaxY - (row * googleTileSize);
    var GoogleMinY = GoogleMaxY - googleTileSize;

    this.GoogleBounds = new Bounds(new Point(GoogleMinX, GoogleMinY), new Point(GoogleMaxX, GoogleMaxY));
    this.LatLongBounds = new Bounds(new Point(0, 0), new Point(0, 0));
    
    this.tileSource.transformExtentsToLatLong(this.GoogleBounds,this.LatLongBounds);
    this.imageUrl = this.tileSource.getTileUrl(this.Level, this.Col, this.Row);
    this.references = 1;
  }
  
    
  render(context, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight, ) {
    
    let srcRect = fromLTRB(sx, sy, sWidth, sHeight);
    let dstRect = fromLTRB(dx, dy, dWidth, dHeight);
    
    if (this.imageState == LoadStates.Loaded) {      
      let prevAlpha = context.globalAlpha;
      if(this.viewport.opacity != null) {      
        context.globalAlpha = this.viewport.opacity;
      }

      context.drawImage(
        this.image, 
        srcRect.left, 
        srcRect.top, 
        srcRect.width,
        srcRect.height,
        dstRect.left, 
        dstRect.top, 
        dstRect.width,
        dstRect.height,
      );              

      //Restore Alpha
      context.globalAlpha = prevAlpha;
    }
  }

  dispose() {
    this.imageState = LoadStates.Initial;
    this.Dereferenced = 0;
    this.references = 0;
    this.image = null;
  }

  initialise() {
    this.Name = '';
    this.Level = 0;
    this.Col = 0;
    this.Row = 0;

    this.Dereferenced = 0;
    this.references = 0;

    this.imageState = LoadStates.Initial;
    this.image = null;    
  }

  // @section Properties
  get Image() {
    return this.image; 
  }

  set Image(value) {
    if (this.imageState == LoadStates.Loading) {
      this.imageState = LoadStates.Loaded;
      this.image = value;
    }
    else {
      value = null;
    }
  }

  get ImageState() {
      return this.imageState; 
  }

  set ImageState(value) { 
    this.imageState = value; 
  }

  get IsReferenced() {
    return (this.references > 0); 
  }

  get GoogleMinX() {
    return this.GoogleBounds.MinX;
  }

  get GoogleMinY() {
    return this.GoogleBounds.MinY;
  }

  get GoogleMaxX() {
    return this.GoogleBounds.MaxX;
  }

  get GoogleMaxY() {
    return this.GoogleBounds.MaxY;
  }

  get LatLongMinX() {
    return this.LatLongBounds.MinX;
  }

  get LatLongMinY() {
    return this.LatLongBounds.MinY;
  }

  get LatLongMaxX() {
    return this.LatLongBounds.MaxX;
  }

  get LatLongMaxY() {
    return this.LatLongBounds.MaxY;
  }

  // @section Methods
  addReference() {
    return ++this.references;
  }

  dereference() {
    return --this.references;
  }

  // @section Object Methods
  compareTo(other) {
    return this.Name.localeCompare(other.Name);
  }

	equals(other) {
    return this.Name.localeCompare(other.Name);
  }

  toString() {
    return this.Name;
  }

  loadImage(src) {
    let __this = this;
    return new Promise((resolve, reject) => {
      if (__this.ImageState == LoadStates.Loaded) {
        __this.viewport.renderTile(__this);
        resolve(__this);
      }
      else {
        __this.image = new Image();
        __this.ImageState = LoadStates.Loading;        
        __this.image.addEventListener("load", () => {
          __this.ImageState = LoadStates.Loaded;
          __this.viewport.renderTile(__this);
          resolve(__this);
        });

        __this.image.addEventListener("error", err => {
          __this.ImageState = LoadStates.Failed;
          resolve(__this);
        });

        // only WMTS has _layer field
        if (this.tileSource._layer != null){


          var credentials = Buffer.from("hmcdonald:Mipela2021", "ascii");

          let options = {
            headers: {
              "Authorization": "Basic " + window.btoa(credentials)
            }
          }

          // gotta add auth headers to it...
          fetch(src, options)
          .then(res => res.blob())
          .then(blob => {
            __this.image.src = URL.createObjectURL(blob);
          })
        } else {
          __this.image.src = src;

        }

      }
    });
  };      
}