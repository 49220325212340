import {Geometry} from './geometry';
import { toPoint } from '../geometry/Point';
import MapEnums from '../map/enums';

export class GeoPolygon extends Geometry {
  constructor(exteriorRing, interiorRings) {
    super([]);
    this.interiorRings = [];
    this.exteriorRing = null;

    if (exteriorRing != undefined)
      this.exteriorRing = exteriorRing;

    if (interiorRings != undefined)
      this.interiorRings = interiorRings;          
  }

  get InteriorRings() { 
    return this.interiorRings; 
  }

  get ExteriorRing() { 
    return this.exteriorRing; 
  }

  get GeometryType()  { 
    return MapEnums.GeometryTypes.POLYGON;
  }

  get Coordinate() { 
    return this.exteriorRing != null ? this.exteriorRing.Coordinate : null; 
  }

  get Coordinates() { 
    return this.exteriorRing != null ? this.exteriorRing.Coordinates : null; 
  }
    
  get NumPoints() { 
    let exteriorPoints = this.exteriorRing.NumPoints();
    var interiorPoints = 0;
    this.interiorRings.forEach(element => {
      interiorPoints += element.NumPoints();
    });
    return exteriorPoints + interiorPoints; 
  }

  get NumGeometries() { 
    return 1; 
  }

  GetGeometryN(n) {
    return this;
  }
  
  get IsEmpty() { this.exteriorRing != null  ? false : true; }

  get Bounds() {
    if (this._bounds == null && this.exteriorRing != null)
      this._bounds = this.calculateBounds(this.exteriorRing.Coordinates);

    return this._bounds;   
  }
  
  set Bounds(value) {
    this._bounds = value;
  }
  
  worldToClient(viewport) {
    this.points = [];

    if (this.exteriorRing) {
      var exteriorPoints = this.exteriorRing.worldToClient(viewport);
      this.points.push(exteriorPoints);      
    }

    for (var i = 0; i < this.interiorRings.length; i++) {
      var interiorPoints = this.interiorRings[i].worldToClient(viewport);      
      this.points.push(interiorPoints);            
    }  
    
    return this.points;
  }

  setWorldToClient(viewport, points)  { 

    if (points != null) {
      if (this.exteriorRing && points.length > 0) {
        this.exteriorRing.points = points[0];
      }

      for (var j = 0; j < this.interiorRings.length; j++) {
        this.interiorRings[j].points = points[j+1];      
      }
      this.points = points;
    }
    else {
      this.exteriorRing.points = [];
      this.points = [];
    }     
  }

  draw(canvas, opacity, stroke, fill, context) {
    // first array of points is the exterior ring
    if (this.exteriorRing) {
      canvas.drawPolygon(this.exteriorRing.points, stroke, fill, opacity, context);  
    }

    for (var i = 0; i < this.interiorRings.length; i++) {
      canvas.drawPolygon(this.interiorRings[i].points, stroke, fill, opacity, context, true);
    }
  
    /*
    if (this.points.length > 0) {
      canvas.drawPolygon(this.points[0], stroke, fill, opacity, context);
    
      if (this.points.length > 1) {
        for (var i = 1; i < this.points.length; i++) {
          // need to fill the holes with transparent color
          canvas.drawPolygon(this.points[i], stroke, fill, opacity, context, true);
        }
      }  
    }
    */
  } 

  hitTest(x, y) {
    var isInside = false;
    var isInsideTheHole = false;
    // test first if point (x,y)  is inside the holes 
    if (this.interiorRings != null) {
      for (var i = 0; i < this.interiorRings.length; i++) {
        let interior = this.interiorRings[i];
        if (interior != null && (isInsideTheHole = interior.hitTest(x, y)) == true)
          break;            
      } 
    }

    // If point is inside the holes
    if (!isInsideTheHole && this.exteriorRing != null) {
      isInside = this.exteriorRing.hitTest(x, y);
    }
            
    return isInside;    
  }
  
  isVisible(viewport) {
    var isVisible = false;

    for (var i = 0; i < this.interiorRings.length; i++) {
      var interiorRing = this.interiorRings[i];      
      if ((isVisible = interiorRing.isVisible(viewport)))
        break;
    }

    if (!isVisible && this.exteriorRing) {
      isVisible = this.exteriorRing.isVisible(viewport);
    }
          
    return isVisible;
  }

  intersects(bounds) {
    var bIntersects = false;

    for (var i = 0; i < this.interiorRings.length; i++) {
      var interiorRing = this.interiorRings[i];      
      if ((bIntersects = interiorRing.intersects(bounds)))
        break;
    }

    if (!bIntersects && this.exteriorRing) {
      bIntersects = this.exteriorRing.intersects(bounds);
    }
          
    return bIntersects;
  }

  centroid() {
    if (this.exteriorRing) {
      return this.exteriorRing.centroid();
    }
    else {
      return toPoint(0,0);
    }
  }
  
  addGeometry(geometries) {
    if (this.exteriorRing != undefined)
      geometries.push(this.exteriorRing);

    if (this.interiorRings != undefined) {
      for (let i =0; i < this.interiorRings.length; i++) {
        geometries.push(this.interiorRings[i]);
      }
    }
  }
}