import {MgShapeFactory} from '../drawing/index';
import {FeatureUtil} from './featureutil';
import {WktReader, Geometry} from '../geometries/index';
import MapEnums from '../map/enums';

export class Feature {
  constructor(featureLayer, columns, values, sWkt, id, featureName, wktColumn, thematicIndex) {
    this.featureLayer = featureLayer;
    this._geometry = undefined;
    this._shape = undefined;
    this._values = [];
    this._featureName = featureName;
    this.id = id;
    this.thematicIndex = thematicIndex;
    this.hovering = false;
    this.selected = false;
    this.tracking = false;
    this.dctValues = {};

    if (values != undefined) {
      for (let i = 0; i < values.length; i++) {
        if (columns[i] == wktColumn) {
          this._values.push({});
        }
        else {
          this._values.push(values[i]);
          this.dctValues[columns[i]] = values[i];                                          
        }
      }
    }
    
    if (sWkt != null && sWkt.length > 0) {
      this._geometry = WktReader.parseWKT(sWkt);
      this._shape = MgShapeFactory.createShape(this);
    }
    else {
      this._shape = null;
    }
  }
  
  get ThematicIndex() {
    return this.thematicIndex;
  }

  get Id() {
    return this.id;
  }

  get Shape() {
    return this._shape;
  }

  get Values() {
    return this._values;
  }

  get Geometry() {
    return this._geometry;
  }

  get GeometryType() {
    var sType = 'none';

    switch(this.Geometry.GeometryType) {
      case MapEnums.GeometryTypes.POINT:
        sType = 'POINT';
        break;
      
      case MapEnums.GeometryTypes.LINESTRING:
        sType = 'LINESTRING';
        break;

      case MapEnums.GeometryTypes.POLYGON:
        sType = 'POLYGON';
        break;
  
      case MapEnums.GeometryTypes.MULTIPOINT:
        sType = 'MULTIPOINT';
        break;
        
      case MapEnums.GeometryTypes.MULTILINESTRING:
        sType = 'MULTILINESTRING';
        break;
  
      case MapEnums.GeometryTypes.MULTIPOLYGON:
        sType = 'MULTIPOLYGON';
        break;
      
      case MapEnums.GeometryTypes.GEOMETRYCOLLECTION:
        sType = 'GEOMETRYCOLLECTION';
        break;
      
    }

    return sType;
  }

  get Coordinates() {
    return this._geometry.Coordinates;
  }

  worldToClient(viewport, force) {
    if (this._shape != undefined) {
      this._shape.worldToClient(viewport, force);
    }
  }

  hitTest(x, y) {
    if (this.Geometry != null && this.Geometry.hitTest(x,y, this._shape)) {
      //console.log(`hitTest(${x}, ${y}) featureid: ${this.id} , type: ${this.Geometry.GeometryType.toString()}, hover: ${this.hovering}`);   
      return true;             
    } 

    return false;
  }  

  isVisible(viewport) {
    if (this.Geometry != null && this.Geometry.isVisible(viewport)) {
      return true;             
    }

    return false;
  }

  intersects(bounds) {
    if (this.Geometry != null && this.Geometry.intersects(bounds)) {
      return true;             
    }

    return false;
  } 

  get Tracking(){
    return this.tracking;
  }

  get Selected() {
    return this.selected;
  }

  get Hover() {
    return this.featureLayer.Hover;
  }

  get Id() {
    return this.id != undefined  ? this.id : '';
  }
  
  set Tracking(value){
    this.tracking = true;
    if (value == true){
      if (!this.featureLayer.isAlreadyTracked(this.id))
        this.featureLayer.tracked.push(this);
    } else {
      this.featureLayer.removeFromTracked(this);
    }
  }
  
  set Selected(value) {
    this.selected = value;
    if (value == true) {
      if(!this.featureLayer.isFeatureAlreadySelected(this.id))
        this.featureLayer.selected.push(this);
    }
    else {
      this.featureLayer.removeItemFromSelection(this);
    }
  }

  get FirstPoint() {
    if (this._shape != null && this._shape.Points != null && this._shape.Points.length > 0)
      return this._shape.Points[0];
    
    return null;
  }

  get FeatureName() {
    if (this.featureLayer.labelStyle != null) {
      return this.featureLayer.labelStyle.getFormattedLabel(this);
    }
    

    return this._featureName;
  }
}