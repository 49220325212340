export default class MapEnums {
}

MapEnums.DatasetTypes = {
  Tile : 0,
  Raster : 1,
  Feature : 2,
  Geometry : 3,
  Geocoder : 4,
}


MapEnums.DatasourceTypes = {
  // imagery types
  OpenStreetMap : 0,
  GoogleStreetMap : 1,
  GenericTileService : 2,
  LegacyMapsTileService : 3,
  NearMap : 4,
  Raster : 5,
  WMTS : 6,
  WMS : 7,

  // feature types
  Spatialite : 8,
  SqlServer : 9,
  PostGIS : 10,
  Oracle : 11,

  EsriFileGeodatabase : 12,

  EsriRestService : 13,
  WFS : 14,

  EsriShapeFile : 15,
  MapinfoTabFile : 16,
  DBF : 17,
  CSV : 18
}

MapEnums.SymbolTypes = {
  SolidShape : 0,
  OutlineShape : 1,
  Character : 2,
  Glyph : 3
}

MapEnums.UnitTypes = {
  Inches : 0,
  Feet : 1,
  Miles : 2,
  Meters : 3,
  Kilometers : 4,
  Degrees : 5,
  Pixels : 6
}
 
MapEnums.SpatialFieldTypes = {
  Text : 0,
  Short : 1,
  Integer : 2,
  Long : 3,
  Float : 4,
  Double : 5,
  Boolean : 6,
  DateTime : 7,
  Date : 8,
  Time : 9,
  Guid : 10,
  Binary : 11,
  Geometry : 12,
  Decimal : 13,

  // These are content types

  ForeignKey : 14,
  Document : 15,
  Picture : 16,
  Options : 17,
  Option : 18,
  Folder : 19,
  File : 20,
  URL : 21,
  MultilineText : 22,
  RichText : 23,
  Icon : 24,
  Email : 25,
  Phone : 26,

  User : 27,
  UserGroup : 28,
  Contact : 29,
  Project : 30,
  Entity : 31,
  LinkedEntity : 32,
  Communication : 33,
  StepResponse : 34,
  AssociatedContact : 35,

  Unknown : 36
}

MapEnums.LabelPositions = {
  AboveLeft : 0,
  AboveCentre : 1,
  AboveRight : 2,

  MiddleLeft : 3,
  MiddleCentre : 4,
  MiddleRight : 5,

  BelowLeft : 6,
  BelowCentre : 7,
  BelowRight : 8,
}

MapEnums.StrokeTypes = {
  Solid : 0,
  DashStyle : 1,
  DashPattern : 2,
  Pattern : 3
}

MapEnums.FillTypes = {
  Solid : 0,
  Hatch : 1,
  Pattern : 2,
  Gradient : 3,
  LinearGradient : 4
}

MapEnums.Shapes = {
  Circle : 0,
  Cross : 1,
  DiagonalCross : 2,
  Diamond : 3,
  Marker : 4,
  Pin : 5,
  Square : 6,
  Star : 7,
  TriangleUp : 8,
  TriangleDown : 9,
}


MapEnums.DashStyles = {
  Dash : 0,
  Dot : 1,
  DashDot : 2,
  DashDotDot : 3,
}

MapEnums.HatchStyles = {
  Horizontal : 0,
  Vertical : 1,

  ForwardDiagonal : 2,
  BackwardDiagonal : 3,

  Cross : 4,
  DiagonalCross : 5,

  Percent05 : 6,
  Percent10 : 7,
  Percent20 : 8,
  Percent25 : 9,
  Percent30 : 10,
  Percent40 : 11,
  Percent50 : 12,
  Percent60 : 13,
  Percent70 : 14,
  Percent75 : 15,
  Percent80 : 16,
  Percent90 : 17,
  LightDownwardDiagonal : 18,
  LightUpwardDiagonal : 19,
  DarkDownwardDiagonal : 20,
  DarkUpwardDiagonal : 21,
  WideDownwardDiagonal : 22,
  WideUpwardDiagonal : 23,
  LightVertical : 24,
  LightHorizontal : 25,
  NarrowVertical : 26,
  NarrowHorizontal : 27,
  DarkVertical : 28,
  DarkHorizontal : 29,
  DashedDownwardDiagonal : 30,
  DashedUpwardDiagonal : 31,
  DashedHorizontal : 32,
  DashedVertical : 33,
  SmallConfetti : 34,
  LargeConfetti : 35,
  ZigZag : 36,
  Wave : 37,
  DiagonalBrick : 38,
  HorizontalBrick : 39,
  Weave : 40,
  Plaid : 41,
  Divot : 42,
  DottedGrid : 43,
  DottedDiamond : 44,
  Shingle : 45,
  Trellis : 46,
  Sphere : 47,
  SmallGrid : 48,
  SmallCheckerBoard : 49,
  LargeCheckerBoard : 50,
  OutlinedDiamond : 51,
  SolidDiamond : 52
}

MapEnums.FillTypes = {
  None : 0,
  Solid : 1,
  Hatch : 2,
  Pattern : 3,
  Gradient : 4,
  LinearGradient : 5    
}

MapEnums.HatchStyles = {
  None : 0,
  Horizontal : 1,
  Vertical : 2,
  ForwardDiagonal : 3,
  BackwardDiagonal : 4,
  Cross : 5,
  DiagonalCross : 6,
}

MapEnums.StrokeTypes = {
  None : 0,
  Solid : 1,
  DashStyle : 2,
  DashPattern : 3,
  Pattern : 4
}

MapEnums.DashTypes = {
  Solid : 0,
  Dash : 1,
  Dot : 2,
  DashDot : 3,
  DashDotDot : 4
}
  
MapEnums.EndCaps = {
  Butt : 0,
  Round : 1,
  Square : 2
}

MapEnums.LineJoins = {
  Bevel : 0,
  Round : 1,
  Miter : 2
}

MapEnums.SymbolTypes = {
  None : -1,
  SolidShape : 0,
  OutlineShape : 1,
  Character : 2,
  Glyph : 0
}

MapEnums.Shapes = {
  None : -1,
  Circle : 0,
  Cross : 1,
  DiagonalCross : 2,
  Diamond : 3,
  Marker : 4,
  Pin : 5,
  Square : 6,
  Star : 7,
  TriangleUp : 8,
  TriangleDown : 9,
}

MapEnums.Placements = {
  Unknown : -1,
  AboveLeft : 0,
  AboveCentre : 1,
  AboveRight : 2,

  MiddleLeft : 3,
  MiddleCentre : 4,
  MiddleRight : 5,

  BelowLeft : 6,
  BelowCentre : 7,
  BelowRight : 8,
}

MapEnums.ClipStates = {
  ClipLeft : 0,
  ClipMiddle : 1,
  OutClipRightineShape : 2
}
 
MapEnums.ImageStates = {
  Initial : 0,
  Loading : 1,
  Loaded : 2,
  Failed : 3,
}

MapEnums.GeometryTypes = {
  NONE : -1,
  POINT : 0,
  LINESTRING : 1,
  LINEARRING : 2,
  POLYGON : 3,
  MULTIPOINT : 4,
  MULTILINESTRING : 5,
  MULTIPOLYGON : 6,
  GEOMETRYCOLLECTION : 7
}

MapEnums.PromptTypes = {
  REPLACE_EXISTING_GEOMETRY : 0,
  MINIMUM_REQUIRED_POINTS_POLYGON : 1,  
  MINIMUM_REQUIRED_POINTS_LINESTRING : 2,  
}

MapEnums.ContextTypes = {
  None : -1,
  ShowAllFeatures : 0,
  ShowOnlyRelatedFeatures : 1,
  ShowOtherFeatures : 2,
  Primary: 3
}

MapEnums.MapOptionTypes = {
  MAPACTIVITY : 0,
  GEOMETRY : 1,
  CONTROL : 2
}

MapEnums.DatasourceTypes = {
  None : -1,
  LinkedEntity : 0,
  LinkedAssociatedContact : 1,
  Communication : 2,
  OutstandingActions : 3,
  ProcedureStatus : 4,
  StepResponse : 5,
  CommunicationStatistics : 6,
  LinkedAssociatedContactStatistics : 7,
  LinkedEntityStatistics : 8,        
  Project : 9,    
  Entity : 10,    
  Contact : 11,
  AssociatedContact : 12,
  ProjectCommunication : 13,
  User : 14,
  UserGroup : 15,
  Document : 16
}

MapEnums.SourceEvents = { 
  AngularShowMap: 0,
  AngularLayerSearch: 1,
  AngularContextLayerSearch: 2, 
  AngularContextRelatedLayerSearch: 3,  
  AngularZoomToFeature: 4,
  AngularZoomToLayer: 5,
  AngularLayerVisibility: 6,
  AngularMapDivSizeChange: 7,
  AngularRestoreHistory: 8,

  //map.js  
  ShowThematics: 9,
  ResizeHeight: 10,
  SetView: 11,
  Zoom: 12,
  ZoomDragging: 13,
  PanDragging: 14,
  ZoomByContextOrder: 15,
  DeleteFeatures: 16
}