import {Events} from '../core/Events';
import * as DomEvent from '../dom/DomEvent';
import * as DomUtil from '../dom/DomUtil';

export class LayerControl extends Events   {
  constructor() {
    super();
  }

  addTo(map, layers) {
    this._map = map; 
		let layerContainerItem = 'mgmap-layer-container-item';
		let layerContainer = 'mgmap-layer-container';
    let container = DomUtil.create('div', layerContainer, this._map.container);
		
		let __this = this;
		if (layers != null) {
			for (var i = 0; i < layers.length; i++) {
				let __i = i;
				let layerName = layers[i].id;
				let itemContainer = DomUtil.create('div', layerContainerItem + `-${i}`, container);
				this._createButton(layerName, layerName, layerContainerItem,  itemContainer, (e) => {
					if (!e)
						e= window.event;

					e.stopPropagation();
					__this._map.showLayer(`${__i}`);
				});
			}
		}
		
  }

  _zoomIn (e) {
    if (!e)
     e= window.event;

    e.stopPropagation();
		if (this._map._zoom < this._map.maxZoom) {
      this._map.zoomIn();			
		}
	}

	_createButton(html, title, className, container, fn) {
		var link = DomUtil.create('a', className, container);
		link.innerHTML = html;
		link.href = '#';
		link.title = title;

		/*
		 * Will force screen readers like VoiceOver to read this as "Zoom in - button"
		 */
		link.setAttribute('role', 'button');
		link.setAttribute('aria-label', title);

		DomEvent.disableClickPropagation(link);
		DomEvent.on(link, 'click', DomEvent.stop);
		DomEvent.on(link, 'click', fn, this);
		//DomEvent.on(link, 'click', this._refocusOnMap, this);

		return link;
	}
}