import {Markup, MarkupPoint} from './markup';
import MapEnums from '../map/enums';
export class PolygonMarkup extends  Markup {
  constructor(map, viewport, id) {
    super(map, viewport, id);
    this.geometryType = MapEnums.GeometryTypes.POLYGON;
  }

  //#region -- VIRTUAL FUNCTIONS
  isStartPoint(point) {
    return true;
  }

  isLastPoint(point) {
    return true;
  }

  // Mouse Events and Handlers
  hitTest(x, y) {
    if (!super.hitTest(x,y) && !this.isEmpty) {
      let clientPoint = new Point(x, y);
      let coordinate = this.editor.viewport.pointToWorldCoordinate(clientPoint);
      let bounds = this.calculateBounds(this.linePoints);
      if (bounds != null && bounds.contains(coordinate)) {
        return true;
      }

      return false;
    }
    
    return true;
  }   

  handleMouseMove(x, y) {
    if (!this.selectedForEditing && this.hitTest(x, y)) {
      this.isHovered = true;
      return true;  
    }

    return false;
  }

  handleMouseClick(x, y) {
    let pt = new Point(x, y);
    let tuple = this.getExistingPoint(pt);
    
    let existingPoint = tuple.point;
    
    if (existingPoint == null) {
      let indexToInsert = this.isPointOnLine(pt);
      if (indexToInsert > -1) {
        this.insertPoint(pt, indexToInsert);
      }
      else
        this.addPoint(pt);        
    }      
    else {
      if (existingPoint.IsStartPoint) {
        this.linePoints.push(new MarkupPoint(existingPoint.ClientPoint, existingPoint.WorldCoordinate, false, 'blue'));
        this.isGeometryComplete = true;
      }
      else {                
        if ((this.linePoints.length <= 3))
          this.onDisplayPrompt(MapEnums.PromptTypes.MINIMUM_REQUIRED_POINTS_POLYGON);         
        else
          this.removePoint(existingPoint.WorldCoordinate);
      }
    }
  }

  handleMouseDoubleClick(x, y) {
    let pt = new Point(x, y);
    let tuple = this.getExistingPoint(pt);
    
    let existingPoint = tuple.point;
  
    if (existingPoint == null) {
      let firstPoint = this.linePoints.length > 0 ? this.linePoints[0] : null;

      this.addPoint(pt);        
       
      if (this.linePoints.length >= 3) {
        this.linePoints.push(new MarkupPoint(firstPoint.ClientPoint, firstPoint.WorldCoordinate, false, 'blue'));
        this.isGeometryComplete = true;
      }
    }      
    else {
      if (existingPoint.IsStartPoint && this.linePoints.length >= 3) {
        this.linePoints.push(new MarkupPoint(existingPoint.ClientPoint, existingPoint.WorldCoordinate, false, 'blue'));
        this.isGeometryComplete = true;
      }      
    }
  }

  render(context) {
    if (!this.selectedForEditing) {
      this.editor.renderPolygon(this.Points, context, this.isHovered, this.selected);
    }
    else {
      var lastPoint = null;
      for (let i = 0; i < this.linePoints.length; i++) {
        let current = this.linePoints[i];

        current.ClientPoint = this.editor.viewport.worldToClient(current.WorldCoordinate.X, current.WorldCoordinate.Y);

        if (current.IsStartPoint) {      
          this.editor.drawPoint(current.ClientPoint, true, context);
        }
        else if (lastPoint != null) {                    
          let stroke = this.editor.contextLayer != null && this.editor.contextLayer.PolygonStyle != null 
            && this.editor.contextLayer.PolygonStyle.Stroke != null ? this.editor.contextLayer.PolygonStyle.Stroke.selectedStroke : null;          
          this.editor.drawLine(current.ClientPoint, lastPoint.ClientPoint, context, stroke);          
          this.editor.drawPoint(current.ClientPoint, false, context);        
        }      

        lastPoint = current;
      }
      
      if (this.insertionPoint) {  
        this.editor.drawPoint(new Point(this.insertionPoint.x, this.insertionPoint.y), false, context); 
        this.insertionPoint = null;
      }
    }
  }

  clear() {

  }

  delete() {
    
  }

  toWKT(addTag) {
    if (this.isGeometryComplete) {
      var sWKT = addTag ? 'POLYGON((' : '((';

      for (let i = 0; i < this.linePoints.length; i++) {
        let coordinate = this.linePoints[i].WorldCoordinate;
        if (i > 0)
          sWKT += ', ';

        sWKT += `${coordinate.X} ${coordinate.Y}`; 
      }

      sWKT += '))';
      return sWKT;
    }

    return "";    
  }  

  //#endregion - VIRTUAL FUNCTIONS 
}