import {MgLayer} from './layer';
import {Util} from '../core/index';
import MapEnums from '../map/enums';
import {Viewport} from '../drawing/viewport';
import {RestAPI} from '../api';
import {Rectangle, fromRect} from '../geometry/rectangle';
import {Envelope} from '../geometry/Envelope';

function FixedQueue(fixedSize) {
  this.data = [];
  this.fixedSize = fixedSize == undefined ? 20 : fixedSize;
}

FixedQueue.prototype.add = function(record) {
  this.trimTail();
  this.data.unshift(record);
}

FixedQueue.prototype.trimTail = function(){

  if (this.data.length <= this.fixedSize){
    // No trimming, return out.
    return;
  }

  // Trim whatever is beyond the fixed size.
  Array.prototype.splice.call(
    this.data,
    this.fixedSize,
    (this.data.length - this.fixedSize)
  );
};

FixedQueue.prototype.remove = function() {
  this.data.pop();
}

FixedQueue.prototype.first = function() {
  return this.data[0];
}

FixedQueue.prototype.last = function() {
  return this.data[this.data.length - 1];
}

FixedQueue.prototype.size = function() {
  return this.data.length;
}

FixedQueue.prototype.isEmpty = function() {
  return this.data.length <= 0;
}

FixedQueue.prototype.removeAll = function() {
  return this.data = [];
}


export class RasterInfo {
  constructor(envelope, rect, center, viewport) {
    this.Envelope = envelope;
    this.Rect = rect;
    this.Center = center;
    this.Viewport = viewport;

    this.Top = rect.top;
    this.Left = rect.left;
    this.Bottom = rect.bottom;
    this.Right = rect.right;

    this.ImageState = MapEnums.ImageStates.Initial;
    this.Image = null
  }

  // @method MinX(): Number
	// Returns the leftmost part of the bounding box.
	get MinX() {
		return this.Envelope.MinX;
	}

	// @method MinX(): Number
	// Returns the rightmost part of the bounding box.
	get MaxX() {
		return this.Envelope.MaxX;
	}

	// @method MinY(): Number
	// Returns the upper right of the bounding box.
	get MinY() {
		return this.Envelope.MinY;
	}

	// @method MaxY(): Number
	// Returns the bottom right of the bounding box.
	get MaxY() {
		return this.Envelope.MaxY;
	}

	get Width() {
		return this.Rect.width;
	}

	get Height() {
		return this.Rect.height;
	}
}

export class MgImageLayer extends MgLayer {
  
  constructor(id, options, taskId) {
    super(id, options, taskId);
    this._image = null;
    this._scale = null;
    this.fixedQueue = new FixedQueue(20);
    this.rasters = null;

    this.previousRaster = null;
    this.raster = null;
  }

  // Factory methods
  static createObject(id, options) {    
    return new MgImageLayer(id, options);    
  }
  
   /**   
   * @returns Promise(MgLayer)
   * @override
   */
  isLayerReady(canvas, cellsize) {
    let viewport = this._viewport;
    let __this = this;
    return new Promise((resolve, reject) => {         
            
      if (__this.raster.ImageState == MapEnums.ImageStates.Loaded) {    
        //__this.renderRaster(canvas.context, __this.raster, viewport);
        resolve(__this);               
      }       
      else if (__this.promise != null) {
        __this.promise.then(function(raster) {
          __this.renderRaster(canvas.context, __this.raster, viewport);
          resolve(__this);
        });
      }           										      
    });    
  }
    
  getRasterInfo(extents, viewport, canvas) {
    
    this.raster = null;
    if (!this.fixedQueue.isEmpty()) {
      for (let i = 0; i < this.fixedQueue.data.length; i++) {
        let raster = this.fixedQueue.data[i];
        if (raster.Envelope.contains(extents)) {
          this.raster = raster;
        } 
      }
    }

    if (this.raster == null)  {
      let halfWidth = viewport.World.Width / 2;
      let halfHeight = viewport.World.Height / 2;

      let envelope = new Envelope(extents.MinX - halfWidth, extents.MaxX + halfWidth, extents.MinY - halfHeight, extents.MaxY + halfHeight);
      let rect = viewport.worldToClientRect(envelope);
      let rasterInfo = new RasterInfo(envelope, rect, envelope.Center, viewport);

      this.fixedQueue.add(rasterInfo);
      this.raster = rasterInfo;

      this.promise = this.loadImage(this.raster, 
        this.raster.MinX, 
        this.raster.MaxX, 
        this.raster.MinY, 
        this.raster.MaxY, 
        this.map.options.Projection, 
        this.raster.Width, 
        this.raster.Height);

      let __this = this;
      this.promise.then(function(raster) {
        __this.promise = null; 
        //__this.renderRaster(canvas.context, __this.raster, viewport);
      });  
    }

    return this.raster;
  }

  renderRaster(context, rasterInfo, viewport) {
    if (rasterInfo.Image != null)
    {
      let imageRect = viewport.worldToClientRect(rasterInfo.Envelope);
      context.drawImage(rasterInfo.Image, 0, 0, rasterInfo.Image.width, rasterInfo.Image.height, imageRect.left, imageRect.top, imageRect.width, imageRect.height);
    }
  }

  onAdd(map) {
		this._levels = {};
		this._tiles = {};
  }
  
  /**
   * @param  {Bounds} rcMap
   * @param  {LatLngBounds} newExtents
   * @param  {Number} cellsize
   * @param  {Number} scale
   */
  onExtentsChanged(sourceEvent, rcMap, extents, cellsize, scale, canvas, xOffset, yOffset) {
    console.log('onExtentsChanged - ImageLayer');
    
    var disposableViewPort = this._viewport;
    this.previousRaster = this.raster;
                                      
    let scaleChanged = this._scale == undefined || this._scale != scale ? true : false;
    this._scale = scale;

    if (this.isVisible(scale)) {
      if (disposableViewPort != null && disposableViewPort.World.equals(extents))
        disposableViewPort = null; // just re-use the current
      else {
        this._viewport = new Viewport(rcMap, extents, cellsize, scale);					        
      }
            
      if (scaleChanged)
       this.fixedQueue.removeAll();

      this.getRasterInfo(extents, this._viewport, canvas);

      if (this.raster != null && this.raster.ImageState == MapEnums.ImageStates.Loaded) {    
        this.renderRaster(canvas.context, this.raster, this._viewport);
      }   
    }
    else {
      this._viewport = null;
    }
    
    return true;
  }
  
  render(canvas, force, context, compositeOperation) {     
    console.log('render - ImageLayer - start ' + this.id);   
    
    if (!canvas.isPanning) {

      context.save();
      if (compositeOperation)
        context.globalCompositeOperation = compositeOperation;
                      
      if (this.raster.Image != null) {
        this.renderRaster(context, this.raster, this._viewport);
      }

      context.restore();
    }
    
    console.log('render - ImageLayer - end ' + this.id);   
  }

  /**
   * @param  {MgCanvas} canvas
   * @override
   */
  refreshView(canvas) {    
    if(this._image)
      canvas.drawImage(this._image, 0, 0);        
  }     

  get defaultOptions() {
	  var _defaultOptions = {
      minZoom: 0,         // Number
      maxZoom: 18,        // Number
      errorImageUrl: '',  // String

      // @option crossOrigin: Boolean|String = false
      // Whether the crossOrigin attribute will be added to the tiles.
      // If a String is provided, all tiles will have their crossOrigin attribute set to the String provided. This is needed if you want to access tile pixel data.
      // Refer to [CORS Settings](https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_settings_attributes) for valid String values.
      crossOrigin: false
    }

		_defaultOptions = Util.mergeOptions(_defaultOptions, this.getWCLayerOptions())
    return _defaultOptions;
	}

  getWCLayerOptions() {
		let parentOptions = super.getWCLayerOptions();

		let layerOptions =  { 
			Opacity: 1.0  // Number = 1.0
    }
    
		layerOptions = Util.mergeOptions(parentOptions, layerOptions);
		return layerOptions;
  }
    
  loadImage(rasterInfo, MinX, MaxX, MinY, MaxY, projection, width, height) {
    let url = RestAPI.BaseUrl + `RasterQuery?token=${RestAPI.AccessToken}&datasetId=${this.options.Dataset}&MinX=${MinX}&MaxX=${MaxX}&MinY=${MinY}&MaxY=${MaxY}&projection=${projection}&width=${width}&height=${height}`;
   
    return new Promise((resolve, reject) => {
      if (rasterInfo.ImageState == MapEnums.ImageStates.Loaded)
        resolve(rasterInfo);
      else {
        rasterInfo.Image = new Image();
        rasterInfo.ImageState = MapEnums.ImageStates.Loading;  

        rasterInfo.Image.addEventListener("load", () => {
          rasterInfo.ImageState = MapEnums.ImageStates.Loaded;
          resolve(rasterInfo);
        });

        rasterInfo.Image.addEventListener("error", err => {
          rasterInfo.ImageState = MapEnums.ImageStates.Failed;
          resolve(rasterInfo);
        });

        rasterInfo.Image.src = url;
      }
    });
  } 
}