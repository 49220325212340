import {centroid}  from './centroid';
import {Geometry} from './geometry';
import MapEnums from '../map/enums';

export class GeoMultiLineString extends Geometry {
  constructor(linestrings) {
    super([]);
    this.linestrings = linestrings != null ? linestrings : []; 
  }

  get Geometries() {
    return this.linestrings;
  }

  get GeometryType()  { 
    return MapEnums.GeometryTypes.MULTILINESTRING;
  }

  get Coordinate() { 
    return  this.linestrings.length > 0 ? this.linestrings[0] : null; 
  }
    
  get NumPoints() { 
    var numPoints = 0;
    this.linestrings.forEach(element => {
      numPoints += element.NumPoints();
    });

    return numPoints;
  }

  get NumGeometries() { 
    return this.linestrings.length; 
  }

  GetGeometryN(n) {
    return n < this.linestrings.length ? this.linestrings[n] : null;
  }
  
  get IsEmpty() {
    var isEmpty = true;

    for (var i = 0; i < this.linestrings.length; i++) {
      isEmpty = this.linestrings[i].IsEmpty; 
      if (!isEmpty)
        break;
    }

    return isEmpty;
  }

  get Bounds() {
    if (this._bounds == null) {
      
      for (var i = 0; i < this.linestrings.length; i++) {
        let geometry = this.linestrings[i];
          
        if (this._bounds ==null)
          this._bounds = this.calculateBounds(geometry.Coordinates);
        else
          this._bounds.extend(this.calculateBounds(geometry.Coordinates));          
      }
      
    }
    return this._bounds;   
  }

  set Bounds(value) {
    this._bounds = value;
  }
  
  worldToClient(viewport) {
    this.points = [];

    for (var i = 0; i < this.linestrings.length; i++) {
      let linestring = this.linestrings[i];        
      this.points.push(linestring.worldToClient(viewport));
    }
        
    return this.points;
    /*
    this.points = [];
    for (var i = 0; i < this.linestrings.length; i++) {
      var linestring = this.linestrings[i];      
      this.points.push(linestring.worldToClient(viewport));            
    }  
    
    return this.points;
    */
  }

  setWorldToClient(viewport, points)  { 
    this.points = points; 
  }

  draw(canvas, opacity, stroke, fill, context) {
    for (var i = 0; i < this.linestrings.length; i++) {
      let linestring = this.linestrings[i];        
      linestring.draw(canvas, opacity, stroke, fill, context);
    }        
  } 

  hitTest(x, y) {
    var isInside = false;
    for (var i = 0; i < this.linestrings.length; i++) {
      if ((isInside = this.linestrings[i].hitTest(x,y)) == true)
        break;
    }

    return isInside;    
  }

  isVisible(viewport) {
    for (var i = 0; i < this.linestrings.length; i++) {
      let linestring = this.linestrings[i];
      if (linestring.isVisible(viewport))
          return true;        
    }
    
    return false;    
  }

  intersects(bounds) {
    for (var i = 0; i < this.linestrings.length; i++) {
      let linestring = this.linestrings[i];
      if (linestring.intersects(bounds))
          return true;        
    }
    
    return false;    
  }

  addGeometry(geometries) {
    for (let i = 0; i < this.linestrings.length; i++) {
      geometries.push(this.linestrings[i]);
    }
  }
}