import {Feature} from '../layer/feature';
import {Geometry} from '../geometries/geometry';

export class MgShape {    

  //#region -- CONSTRUCTORS
  constructor(feature) {    
    this._feature = feature;
    this._geometry = feature.Geometry;

    this.labelText = null;
    this._labelStyle = feature.featureLayer.labelStyle;
    if (this._labelStyle != null)
      this.labelText = this._labelStyle.getFormattedLabel(feature);
      
    this._points = [];
  } 
  //#endregion
  
  //#region -- GETTERS and SETTERS
   
  //#endregion
  
  //#region -- VIRTUALS
  /**
   * @param  {MgCanvas} canvas
   * @param  {string} strokeColor
   * @param  {string} fillColor
   * @virtual
   */
  draw(canvas, opacity, context) {
    throw new Error("Implement draw method");
  }
  
  drawHover(canvas, opacity, context) {
    throw new Error("Implement draw method");
  }

  drawSelected(canvas, opacity, context) {
    throw new Error("Implement draw method");
  }

  render(canvas, opacity, context) {
    if (this._feature != null && this._feature.Selected == true)
      this.drawSelected(canvas, opacity, context);      
    else
      this.draw(canvas, opacity, context);
  }
  /**
   * @param  {Viewport} viewport
   * @virtual
   */
  worldToClient(viewport, force) {
    if (this._points == null || this._points.length <= 0 || force == true)
      this._points = this.Feature.Geometry.worldToClient(viewport); 
  }
  //#endregion

  //#region -- PROPERTIES
  get Feature() {
    return this._feature;
  }

  get Points() {
    return this._points;
  }

  get Geometry() {
    return this._geometry;
  }

  get LabelStyle() {
    return this._labelStyle;
  }

  get LabelText() {
    return this.labelText;
  }
  
  //#endregion

  //#region -- METHODS
  /**
   * @param  {MgShape} x
   * @returns boolean   
   */
  compare(x){    
    if(this === x)
      return true;

    const properties = Object.getOwnPropertyNames(this);    
    
    let bMatch = true;
    for(let p of properties) {
      if(this[p] != x[p]) {
        bMatch = false;
        break;
      }
    }
    return bMatch;
  }   

  hitTest(x, y) {
    return false;
  }

  intersects(bounds) {
    if (this.Geometry)
      return this.Geometry.intersects(bounds);

    return false;
  }
  
  getRectExtents() {
    return null;
  }
  //#endregion    
}