import {centroid}  from './centroid';
import {Geometry} from './geometry';
import { Point } from '../geometry';
import {MapPrimitive} from '../drawing/mapprimitive';
import MapEnums from '../map/enums';

export class GeoLineString extends Geometry {
  constructor(coordinates) {
    super(coordinates);
    this.points = [];
  }

  get GeometryType()  { 
    return MapEnums.GeometryTypes.LINESTRING;
  }

  get Coordinate() { 
    return this._coordinates.length > 0 ? this._coordinates[0] : null; 
  }

    
  get NumPoints() { 
    return this._coordinates.length; 
  }

  get NumGeometries() { 
    return 1; 
  }

  GetGeometryN(n) {
    return this;
  }
  
  get IsEmpty() { this.points.length > 0 ? false : true; }

  get Bounds() {
    if (this._bounds == null)
      this._bounds = this.calculateBounds(this.Coordinates);

    return this._bounds;   
  }

  set Bounds(value) {
    this._bounds = value;
  }
    
  worldToClient(viewport) {
    this.points = [];
    for (var i = 0; i < this._coordinates.length; i++) {
      let pt = this._coordinates[i];      
      this.points.push(viewport.worldToClient(pt.X, pt.Y));            
    }  
    
    this.points = MapPrimitive.ClipLineStringRect(this.points, viewport.Client);
    return this.points;
  }  

  setWorldToClient(viewport, points)  { 
    //this.points = MapPrimitive.ClipLineStringRect(points, viewport.Client); 
    this.points = points;
  }

  draw(canvas, opacity, stroke, fill, context) {
    if (this.points && this.points.length > 0) {
      canvas.drawLine(this.points, stroke, opacity, context);    
    }
  } 

  hitTest(x, y) {
    if (this.points != null) {
      let testPoint = new Point(x,y);
      for (var i = 0; i < this.points.length-1; i++) {
        let start = this.points[i];      
        let end = this.points[i+1];

        if (this.isPointOnSegment(testPoint, start, end))
          return true;
      }
    }  

    return false;
  }

  isPointOnSegment(point, A, B) {
    let d1 = this.distance(point, A);
    let d2 = this.distance(point, B);
    let lineDistance = this.distance(A, B);
    let buffer = 2;  // 2 pixels

    if ( d1+d2 >= lineDistance-buffer &&  d1+d2 <= lineDistance+buffer )
      return true; // point is on the line.

    return false;
  }

  isVisible(viewport) {
    if (this.points.length > 0) {
      for (let i = 0; i < this.points.length; i++) {
        if (viewport.Client.contains(this.points[i]))
          return true;
      }  
    }
    else
      return false;    
  }

  intersects(bounds) {
    if (this.points.length > 0) {
      for (let i = 0; i < this.points.length; i++) {
        if (bounds.contains(this.points[i]))
          return true;
      }  
    }
    else
      return false;    
  }
}