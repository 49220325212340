import {Point, toPoint} from '../geometry/Point';
import {Envelope} from '../geometry/Envelope';
import {Viewport, Symbol, Fill, Stroke, Label} from '../drawing/index';
import MapEnums from '../map/enums';

export class MarkupPoint {
  constructor(clientPoint, worldCoordinate, isStartPoint, colour)
  {
    this.ClientPoint = clientPoint;
    this.WorldCoordinate = worldCoordinate;
    this.IsStartPoint = isStartPoint;
    this.Colour = colour;
  }  
}

export class Markup {
  constructor(map, editor, id = -1) {
    this.map = map;
    this.editor = editor;
    this.canvas = map.canvas;
    this.context = map.canvas.context;
    this.linePoints = [];
    this.isGeometryComplete = false;
    this.selectedForEditing = false;
    this.selected = false;
    this.id = id == -1 || id == undefined ? new Date().getTime() : id;
    this.geometryType = MapEnums.GeometryTypes.NONE;
    this.isHovered = false;
    this.insertionPoint = null;
  }
  
  //#region -- Virtual Functions
  isStartPoint(point) {

  }

  isLastPoint(point) {

  }

  isPointOnLine(point) {
    let indexToInsertAt = -1;
    
    for (var i = 0; i < this.linePoints.length-1; i++) {
      let start = this.linePoints[i].ClientPoint;
      let end = this.linePoints[i+1].ClientPoint;

      if (this.isPointOnSegment(point, start, end)) {
        indexToInsertAt = i+1;
        break;
      }
    }  

    return  indexToInsertAt;
  }

  getExistingPoint(clientPoint) {     
    var foundPoint = null;
    var i = -1;
    for ( i = 0; i < this.linePoints.length; i++) {
      let point =  this.linePoints[i];
    
      var existingPoint = this.editor.viewport.worldToClient(point.WorldCoordinate.X, point.WorldCoordinate.Y);
      let distance = existingPoint.distanceTo(clientPoint); 

      // we use the radius of the linePoint (circle) drawn as a way
      // to detect if the clientPoint in inside that circle.
      // if distance of clientPoint to linePoint is less than or equal to the radius, then the point is inside.
      if ( distance <= (Markup.Radius+4)) {
        foundPoint = point;
        break;      
      }
    }

    //if (foundPoint == null && this.activeMarkup != null) 
    //  return this.activeMarkup.isPointOnLine(clientPoint);
          
    return {"point":foundPoint, "index": i};
  }

  handleMouseClick(x, y) {
  }

  handleMouseMove(x, y) {    
    if (!this.selectedForEditing && this.hitTest(x, y)) {
      // this.isHovered = true;
      return true;  
    }

    return false;
  }

  handleMouseDoubleClick(x, y) {
  }

  render(context) {
    
  }

  clear() {

  }

  delete() {
    
  }

  withinBounds(topLeftPoint, bottomRightPoint) {
    
    if (this.linePoints.length > 0) {
      let markupBounds = this.calculateBounds(this.linePoints);            
      let worldTopLeft = this.editor.viewport.pointToWorldCoordinate(topLeftPoint);
      let worldBottomRight = this.editor.viewport.pointToWorldCoordinate(bottomRightPoint);
      let selectionWorldBounds = new Envelope(worldTopLeft.X, worldBottomRight.X, worldTopLeft.Y, worldBottomRight.Y);					      
      return markupBounds.intersects(selectionWorldBounds);            
    }
  }


  toWKT(addTag) {
    return "";
  }
  //#endregion - Virtual Functions

  //#region - Common Functions 
  get isEmpty() {
    return this.linePoints.length <= 0 ? true : false; 
  }

  get Points() {
    let pts = [];
    for (let i = 0; i < this.linePoints.length; i++) {
      let current = this.linePoints[i];
      pts.push(this.editor.viewport.worldToClient(current.WorldCoordinate.X, current.WorldCoordinate.Y));      
    }

    return pts;
  }


  getLastPoint() {
    if (this.linePoints.length > 0)
      return this.linePoints[this.linePoints.length-1];
    
    return null;
  }

  addPoint(clientPoint) {        
    var worldCoordinate = this.editor.viewport.pointToWorldCoordinate(clientPoint);

    let newPoint = new MarkupPoint(clientPoint, worldCoordinate, this.linePoints.length == 0, 'blue')
    this.linePoints.push(newPoint);

    return newPoint;
    
  }

  insertPoint(clientPoint, indexToInsertAt) {      
    if (indexToInsertAt - 1 >= 0)
    {
      var worldPoint = this.editor.viewport.pointToWorldCoordinate(clientPoint);      
      this.linePoints.splice(indexToInsertAt, 0, new MarkupPoint(clientPoint, worldPoint, false, 'blue'));      
    }      
  }

  addWorldCoordinate(worldCoordinate) {        
    var clientPoint = this.editor.viewport.worldToClient(worldCoordinate.X, worldCoordinate.Y);

    let newPoint = new MarkupPoint(clientPoint, worldCoordinate, this.linePoints.length == 0, 'blue')
    this.linePoints.push(newPoint);

    return newPoint;    
  }

  removePoint(worldCoordinate) {    
    // find original point      
    var index = this.linePoints.findIndex( x => x.WorldCoordinate.equals(worldCoordinate));

    if (index > -1)
      this.linePoints.splice(index, 1);

    // if it was a closed polygon and now it's not anymore then remove the last point (which is the same as the starting point)
    if (this.linePoints.length > 1 && this.linePoints.length < 4 && this.linePoints[this.linePoints.length - 1].WorldCoordinate.equals(this.linePoints[0].WorldCoordinate))
      this.linePoints.splice(this.linePoints.length - 1, 1);
  }
    
  distance(p1, p2) {
    //let a = p1.x - p2.x;
    //let b = p1.y - p2.y;
    //return Math.sqrt( a*a + b*b );
    return Math.hypot(p2.x - p1.x, p2.y - p1.y);
  }

  isPointOnSegment(point, A, B) {
    let d1 = this.distance(point, A);
    let d2 = this.distance(point, B);
    let lineDistance = this.distance(A, B);
    let buffer = 2;  // 2 pixels

    if ( d1+d2 >= lineDistance-buffer &&  d1+d2 <= lineDistance+buffer )
      return true; // point is on the line.

    return false;
  }

  calculateBounds(pts) {
    var rcBounds;
    
    if (pts != null && pts.length > 0) {      
      var top = pts[0].WorldCoordinate.Y;
      var left = pts[0].WorldCoordinate.X;
      var right = left;
      var bottom = top;

      for (let index = 1; index < pts.length; index++) {
        let pt = pts[index];

        if (pt.WorldCoordinate.X < left)
          left = pt.WorldCoordinate.X;
        else if (pt.WorldCoordinate.X > right)
          right = pt.WorldCoordinate.X;

        if (pt.WorldCoordinate.Y < top)
          top = pt.WorldCoordinate.Y;
        else if (pt.WorldCoordinate.Y > bottom)
          bottom = pt.WorldCoordinate.Y;
      }
     
      rcBounds = new Envelope( left, right, top, bottom);      
    }
    else
      rcBounds = new Envelope();

    return rcBounds;
  }

  onDisplayPrompt(promptType) {     

    if (this.map.cbDisplayPrompt) {
      let message = {
        prompt: promptType,              
      }
      
      this.map.cbDisplayPrompt(message);
    }
  }

  // Mouse Events and Handlers
  hitTest(x, y) {
    let testPoint = new Point(x,y);
    let tuple = this.getExistingPoint(testPoint);

    if (tuple.point == null) {
      for (var i = 0; i < this.linePoints.length-1; i++) {
        let start = this.linePoints[i].ClientPoint;      
        let end = this.linePoints[i+1].ClientPoint;

        if (this.isPointOnSegment(testPoint, start, end))
          return true;
      }
    }  
    else {
      return true;
    }

    return false;
  }   
    
  //#endregion
}

Markup.Radius = 4;
Markup.RadiusOfEarthInMetres = 6378137;