import {MgShape} from './mgshape';

export class MgMultiPoint extends MgShape {
    
  //#region -- CONSTRUCTORS
  
  /**
   * @param  {MgPoint[]} points
   */
  constructor(feature) {    
    super(feature);

    /*
    this._geoPoints = [];

    let geometry = feature.Geometry;
    for (let i = 0; i < feature.Geometry.NumGeometries; i++) {
      let geoPoint = geometry.GetGeometryN(i);
      if (geoPoint != null && !geoPoint.IsEmpty)
        this._geoPoints.push(geoPoint);
    }
    */
    this.symbol = null;
    
    if (feature.ThematicIndex > -1) {
      this.symbol = feature.featureLayer.Thematics[feature.ThematicIndex].PointStyle;
    }
    else if (feature.featureLayer.pointStyle != null) {
      this.symbol = feature.featureLayer.pointStyle;      
    }
  }
  //#endregion
  
  //#region -- STATIC METHODS  
  /**
   * @param  {MgPoint[]} points
   */
  static createObject(points) {    
    return new MgMultiPoint(points);    
  } 
  //#endregion  
    
  //#region -- OVERRIDES
  /**
   * @param  {MgCanvas} canvas
   * @param  {string} strokeColor
   * @param  {string} fillColor
   * @override
   */
  draw(canvas, opacity, context) {    
    if (this.symbol != null) {
      for(let p of this._points) {
        this.drawPoint(p, canvas, opacity, this.symbol.NormalSymbol, context);      
      }    
    }
  }    
  
  drawHover(canvas, opacity, context) {    
    context.globalCompositeOperation = 'source-over';
    
    if (this.symbol != null) {
      for(let p of this._points) {
        this.drawPoint(p, canvas, opacity, this.symbol.HoverSymbol, context);      
      }    
    }    
  }

  drawSelected(canvas, opacity, context) {    
    if (this.symbol != null) {
      for(let p of this._points) {
        this.drawPoint(p, canvas, opacity, this.symbol.SelectedSymbol, context);      
      }    
    }    
  }

  drawPoint(pt, canvas, opacity, symbol, context) {    
    
    if (this._points.length > 0) {
      
      let offsetX = symbol.width / 2;
      let offsetY = symbol.height / 2;
      canvas.drawSymbol(symbol, pt.x - offsetX, pt.y - offsetY, context);
      this.drawText(new Point(pt.x - offsetX, pt.y - offsetY), canvas, offsetX, offsetY, context);
    }
  }

  drawText(pt, canvas, offsetX, offsetY, context) {
    if (this.LabelStyle != null && this.LabelStyle.isVisible(this.Feature.featureLayer.map.scale)) {

      var sLabel = this.labelText;
      var labelRotation = this.LabelStyle.Rotation != null && this.LabelStyle.Rotation.length > 0 ? parseInt(this.LabelStyle.Rotation) : undefined;
      
      let newX = pt.x;
      let newY = pt.y;
      let textAlign = 'start';

      switch (this.LabelStyle.Placement)
      {
        case MapEnums.Placements.AboveLeft:
          newX = pt.x - this.symbol.Width/2;
          newY = pt.y - this.symbol.Height/2;
          textAlign = 'right';
          break;
        case MapEnums.Placements.AboveCentre:
          newY = pt.y - this.symbol.Height/2;
          textAlign = 'left';
          break;
        case MapEnums.Placements.AboveRight:
          newX = pt.x + this.symbol.Width /2;
          newY = pt.y - this.symbol.Height/2;
          textAlign = 'left';

          break;
        case MapEnums.Placements.MiddleLeft:
          newX = pt.x - this.symbol.Width/2;
          newY = pt.y + offsetY;
          textAlign = 'right';
          break;
        case MapEnums.Placements.MiddleCentre:
          textAlign = 'center';
          break;
        case MapEnums.Placements.MiddleRight:
          newX = pt.x + this.symbol.Width;
          newY = pt.y + this.symbol.Height/2;
          textAlign = 'left';
          break;

        case MapEnums.Placements.BelowLeft:
          newX = pt.x - this.symbol.Width/2;
          newY = pt.y + this.symbol.Height;
          textAlign = 'right';
          break;

        case MapEnums.Placements.BelowCentre:
          newY = pt.y + this.symbol.Height + this.LabelStyle.FontSize;
          textAlign = 'center';
          break;  

        case MapEnums.Placements.BelowRight:
          newX = pt.x + this.symbol.Width/2;
          newY = pt.y + this.symbol.Height;
          textAlign = 'left';
          break;
      }      

      if (sLabel != null) {
        canvas.drawText(sLabel, Math.trunc(newX), Math.trunc(newY)+2, labelRotation, this.LabelStyle, textAlign, context); 
      }
    }
  }
  //#endregion
}