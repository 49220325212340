import {centroid}  from './centroid';
import {Geometry} from './geometry';
import MapEnums from '../map/enums';

export class GeoMultiPolygon extends Geometry {
  constructor(polygons) {
    super([]);
    this.polygons = polygons != null ? polygons : []; 
  }

  get Geometries() {
    return this.polygons;
  }

  get GeometryType()  { 
    return MapEnums.GeometryTypes.MULTIPOLYGON;
  }

  get Coordinate() { 
    return  this.polygons.length > 0 ? this.polygons[0] : null; 
  }
    
  get NumPoints() { 
    var numPoints = 0;
    this.polygons.forEach(element => {
      numPoints += element.NumPoints();
    });

    return numPoints;
  }

  get NumGeometries() { 
    return this.polygons.length; 
  }

  GetGeometryN(n) {
    return n < this.polygons.length ? this.polygons[n] : null;
  }
  
  get IsEmpty() {
    var isEmpty = true;

    for (var i = 0; i < this.polygons.length; i++) {
      isEmpty = this.polygons[i].IsEmpty; 
      if (!isEmpty)
        break;
    }

    return isEmpty;
  }

  get Bounds() {
    if (this._bounds == null) {
      
      for (var i = 0; i < this.polygons.length; i++) {
        let geometry = this.polygons[i];
        if (geometry.exteriorRing == null)
          continue;
          
        if (this._bounds ==null)
          this._bounds = this.calculateBounds(geometry.exteriorRing.Coordinates);
        else
          this._bounds.extend(this.calculateBounds(geometry.exteriorRing.Coordinates));          
      }
      
    }
    return this._bounds;   
  }

  set Bounds(value) {
    this._bounds = value;
  }
  
  worldToClient(viewport) {
    this.points = [];

    for (var i = 0; i < this.polygons.length; i++) {
      let polygon = this.polygons[i];        
      this.points.push(polygon.worldToClient(viewport));
    }
        
    return this.points;
    /*
    this.points = [];

    for (var i = 0; i < this.polygons.length; i++) {
      let polygon = this.polygons[i];
      let polyPoints = [];

      if (polygon.exteriorRing) {
        polyPoints.push(polygon.exteriorRing.worldToClient(viewport));
      }
  
      for (var j = 0; j < polygon.interiorRings.length; j++) {
        var interiorRing = polygon.interiorRings[j];      
        polyPoints.push(interiorRing.worldToClient(viewport));            
      }  

      this.points.push(polyPoints);
    }
        
    return this.points;
    */
  }

  setWorldToClient(viewport, points)  { 
    
    for (var i = 0; i < this.polygons.length; i++) {
      let polygon = this.polygons[i];
      
      if (polygon.exteriorRing) {
        polygon.exteriorRing.points =  points[i] != null ? points[i][0] : [];
      }
  
      for (var j = 0; j < polygon.interiorRings.length; j++) {
        polygon.interiorRings[j].points = points[i][j+1];      
      }  
    }

    this.points = points; 
  }

  draw(canvas, opacity, stroke, fill, context) {
    for (var i = 0; i < this.polygons.length; i++) {
      let polygon = this.polygons[i];        
      polygon.draw(canvas, opacity, stroke, fill, context);
    }
        
  }

  hitTest(x, y) {
    var isInside = false;
    for (var i = 0; i < this.polygons.length; i++) {
      if ((isInside = this.polygons[i].hitTest(x,y)) == true)
        break;
    }

    return isInside;    
  }

  isVisible(viewport) {
    for (var i = 0; i < this.polygons.length; i++) {
      let polygon = this.polygons[i];
      if (polygon.isVisible(viewport))
          return true;        
    }
    
    return false;    
  }

  intersects(bounds) {
    for (var i = 0; i < this.polygons.length; i++) {
      let polygon = this.polygons[i];
      if (polygon.intersects(bounds))
          return true;        
    }
    
    return false;    
  }

  addGeometry(geometries) {
    for (let i = 0; i < this.polygons.length; i++) {
      geometries.push(this.polygons[i]);
    }
  }
}