import {centroid}  from './centroid';
import {Geometry} from './geometry';
import { GeoPolygon } from './geopolygon';
import MapEnums from '../map/enums';

export class GeometryCollection extends Geometry {
  constructor(geometries) {
    super([]);
    this.geometries = geometries != null ? geometries : []; 
  }

  get GeometryType()  { 
    return MapEnums.GeometryTypes.GEOMETRYCOLLECTION;
  }

  get Coordinate() { 
    return  this.geometries.length > 0 ? this.geometries[0] : null; 
  }
    
  get NumPoints() { 
    var numPoints = 0;
    this.geometries.forEach(element => {
      numPoints += element.NumPoints();
    });

    return numPoints;
  }

  get NumGeometries() { 
    return this.geometries.length; 
  }

  get Geometries() {
    return this.geometries;
  }

  GetGeometryN(n) {
    return n < this.geometries.length ? this.geometries[n] : null;
  }
  
  get IsEmpty() {
    var isEmpty = true;

    for (var i = 0; i < this.geometries.length; i++) {
      isEmpty = this.geometries[i].IsEmpty; 
      if (!isEmpty)
        break;
    }

    return isEmpty;
  }

  worldToClient(viewport) {
    this.points = [];

    for (var i = 0; i < this.geometries.length; i++) {
      let geometry = this.geometries[i];        
      this.points.push(geometry.worldToClient(viewport));
    }
        
    return this.points;
  }

  setWorldToClient(viewport, points)  { 
    this.points = points; 
  }
  
  get Bounds() {
    if (this._bounds == null) {
      
      for (var i = 0; i < this.geometries.length; i++) {
        let geometry = this.geometries[i];
          
        if (this._bounds ==null)
          this._bounds = geometry.Bounds;
        else
          this._bounds.extend(geometry.Bounds);          
      }
      
    }
    return this._bounds;   
  }

  set Bounds(value) {
    this._bounds = value;
  }
  
  draw(canvas, opacity, stroke, fill, context) {
    for (var i = 0; i < this.geometries.length; i++) {
      let geometry = this.geometries[i];        
      geometry.draw(canvas, opacity, stroke, fill, context);
    }
        
  }

  hitTest(x, y) {
    for (var i = 0; i < this.geometries.length; i++) {
      let geometry = this.geometries[i];
      if (geometry.hitTest(x,y)) {
        return true;        
      }
    }

    return false;    
  }

  isVisible(viewport) {
    for (var i = 0; i < this.geometries.length; i++) {
      let geometry = this.geometries[i];
      if (geometry.isVisible(viewport))
          return true;        
    }
    
    return false;    
  }

  intersects(bounds) {
    for (var i = 0; i < this.geometries.length; i++) {
      let geometry = this.geometries[i];
      if (geometry.intersects(bounds))
          return true;        
    }
    
    return false;    
  }

  addGeometry(geometries) {
    for (let i = 0; i < this.geometries.length; i++) {
      this.geometries[i].addGeometry(geometries)
    }
  }
}