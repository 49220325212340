import {Centroid} from './centroid';
import {Coordinate} from './coordinate';
import {Envelope, Point, Bounds} from '../geometry/index';

export const DimensionTypes = 
Object.freeze({
  Xyz : Symbol("Xyz"),
  Xym : Symbol("Xym"),
  Xyzm : Symbol("Xyzm")
});

export class Geometry
{
  constructor(coordinates) {
    this._coordinates = coordinates != undefined ? coordinates : [];
    this._bounds = null;
  }
 
  /// <summary>
  /// Converts geometry coordinates to client coordinates
  /// </summary>
  worldToClient(viewport)  { 
    throw new Error("Implement GeometryType method"); 
  }

  setWorldToClient(viewport, points)  { 
    throw new Error("Implement GeometryType method"); 
  }
  
  /// <summary>
  /// Gets the geometry type
  /// Virtual method, MUST be implemented by deriving class
  /// </summary>
  get GeometryType()  { 
    throw new Error("Implement GeometryType method");
  }

  /// <summary>  
  /// Returns a vertex of this <c>Geometry</c>
  /// (usually, but not necessarily, the first one).
  /// </summary>
  get Coordinate() { 
    return null; 
  }

  /// <summary>
  /// Returns an array containing the values of all the vertices for 
  /// this geometry.
  /// </summary>
  /// <remarks>
  get Coordinates() { 
    return this._coordinates; 
  }
  
  /// <summary>  
  /// Returns the count of this <c>Geometry</c>s vertices. The <c>Geometry</c>
  /// s contained by composite <c>Geometry</c>s must be
  /// Geometry's; that is, they must implement <c>NumPoints</c>.
  /// </summary>
  /// <returns>The number of vertices in this <c>Geometry</c>.</returns>
  get NumPoints() { 
    return 0; 
  }

  /// <summary>
  /// Returns the number of Geometryes in a GeometryCollection,
  /// or 1, if the geometry is not a collection.
  /// </summary>
  get NumGeometries() { 
    return 1; 
  }

  /// <summary>
  /// Returns an element Geometry from a GeometryCollection,
  /// or <code>this</code>, if the geometry is not a collection.
  /// </summary>
  /// <param name="n">The index of the geometry element.</param>
  /// <returns>The n'th geometry contained in this geometry.</returns>
  GetGeometryN(n) {
    return this;
  }
    
  get Bounds() {
    throw new Error("Implement Geometry:Bounds method");
  }

  hitTest(x, y, shape) {
    return false;
  }

  distance(p1, p2) {  
    return Math.hypot(p2.x - p1.x, p2.y - p1.y);
  }

  draw(canvas, opacity, stroke, fill, context) {    
  }

  isVisible(viewport) {
    return true;
  }

  intersects(bounds) {
    return false;
  }
 

  calculateBounds(pts) {
    var rcBounds;
    
    if (pts != null && pts.length > 0) {
      /*
      if (pts.length >=2 )
        rcBounds = new Bounds(pts[0], pts[1]);
      else   
      rcBounds = new Bounds(pts[0], pts[0]);

      for (let index = 2; index < pts.length; index++) {
        let pt = pts[index];
        rcBounds.extend(pt);
      }
      */
      var top = pts[0].Y;
      var left = pts[0].X;
      var right = left;
      var bottom = top;

      for (let index = 1; index < pts.length; index++) {
        let pt = pts[index];

        if (pt.X < left)
          left = pt.X;
        else if (pt.X > right)
          right = pt.X;

        if (pt.Y < top)
          top = pt.Y;
        else if (pt.Y > bottom)
          bottom = pt.Y;
      }
     
      rcBounds = new Envelope( left, right, top, bottom);      
    }
    else
      rcBounds = new Envelope();

    return rcBounds;
  }

  addGeometry(geometries) {
    geometries.push(this);
  }
}
