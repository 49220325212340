export class FeatureUtil {

  static getPosByColumnName(columns, column) {
    return columns.findIndex(x => x == column);
  }

  static getStringByColumnName(values, columns, column) {
    let pos = this.getPosByColumnName(columns, column);
    if (pos != -1) {
      return values[pos];
    }

    return undefined;
  }

  static getIntByColumnName(values, columns, column) {
    let pos = this.getPosByColumnName(columns, column);
    if (pos != -1) {
      return parseInt(values[pos]);
    }

    return NaN;
  }

  static getNumericByColumnName(values, columns, column) {
    let pos = this.getPosByColumnName(columns, column);
    if (pos != -1) {
      return parseNumber(values[pos]);
    }

    return NaN;
  }

  static getBooleanByColumnName(values, columns, column) {
    let pos = this.getPosByColumnName(columns, column);
    if (pos != -1) {
      let value = values[pos].toLowerCase();
      return (value == 'true' || value == 't' || value == '1');
    }
    
    return undefined;
  }
  
  static getStringByPos(values, pos) {
    if (pos != -1) {
      return values[pos];
    }

    return undefined;
  }

  static getIntByPos(values, pos) {
    if (pos != -1) {
      return parseInt(values[pos]);
    }

    return NaN;
  }

  static getNumericByPos(values, pos) {
    if (pos != -1) {
      return parseNumber(values[pos]);
    }

    return NaN;
  }

  static getBooleanByPos(values, pos) {
    if (pos != -1) {
      let value = values[pos].toLowerCase();
      return (value == 'true' || value == 't' || value == '1');
    }
    
    return undefined;
  }

}