import {Util} from '../core/index';
import {Bounds} from '../geometry/Bounds';

import {LatLngBounds, toLatLngBounds as latLngBounds} from '../geometry/LatLngBounds';
import { MgImageLayer } from './imageLayer';
import { RestAPI } from '../api';
import {Viewport} from '../drawing/viewport';
import MapEnums from '../map/enums';
import {TileViewport} from '../drawing/tileviewport';

import {OpenStreetMapTileSource} from './OpenStreetMapTileSource';
import {EsriTileSource} from './EsriTileSource';
import {GoogleTileSource} from './GoogleTileSource';
import {GenericTileSource} from './GenericTileSource';
import {TileSource} from './tilesource';
import { Envelope } from '../geometry';

export class MgReprojectedTileLayer extends MgImageLayer {
    
  constructor(id, options, taskId) {
    super(id, options, taskId);
  }

  // Factory methods
  /**
   * @param  {number} id
   * @param  {json} options
   */
  static createObject(id, options, taskId) {    
    return new MgReprojectedTileLayer(id,  options, taskId);    
  }
		
	/**   
   * @returns Promise(MgLayer)
   * @virtual
   */
  isLayerReady(canvas) {

    return new Promise(function(resolve, reject) {     

      if(this._transformPromise != null && this._tranformPromiseParams != null) {
        this._transformPromise.then(response => {
          
          this._transformPromise = null;

          if(!response.ok)
            throw new Error("HTTP Status " + response.status);
          return response.json();
        })
        .then(sTranformedExtents => {      
          const jTranformedExtents = JSON.parse(sTranformedExtents);
          const envelope = new Envelope(jTranformedExtents.MinX, jTranformedExtents.MaxX, jTranformedExtents.MinY, jTranformedExtents.MaxY);
          this._viewport = new TileViewport(this._tranformPromiseParams.rcMap, envelope, (envelope.MaxX - envelope.MinX) / this._tranformPromiseParams.rcMap.Width, this._tranformPromiseParams.scale, this._tileSource, this._tranformPromiseParams.canvas, this._options.Opacity);
          this.scale = this._tranformPromiseParams.scale;
          
          this.getTiles(this._tranformPromiseParams.extents, this._viewport, this._tranformPromiseParams.canvas, this._tranformPromiseParams.cellsize);   
          this._tranformPromiseParams = null;
          
          this.fetchReprojectedTiles(resolve, reject);                                                    
        })
        .catch(error => reject(error));
      }
      //When extents hasn't changed, don't fetch tiles from server
      else if(this.map.extents == this.currentExtents && this._image != null) {
        resolve(this);
      }
      else if(this._tileUrls != null && this._tileUrls.length > 0) { //getTiles was previously called already
        this.fetchReprojectedTiles(resolve, reject);
      }           
      else {
        reject("Tile Urls unknown");
      }
 		}.bind(this));		
  }

  fetchReprojectedTiles(resolve, reject) {

    this.currentExtents = this.map.extents;

    if (this._tileUrls != null && this._tileUrls.length > 0) {      						    
      const parameters = {
        projection: this._map.options.Projection,
        datasetId: this._options.Dataset,
        tileUrls: JSON.stringify(this._tileUrls),
        columns: this._tileColumnCount,
        rows: this._tileRowCount,
        extents: JSON.stringify({minX: this.map.extents.MinX, minY: this.map.extents.MinY, maxX: this.map.extents.MaxX, maxY: this.map.extents.MaxY}),
        tileSize: this._tileSource.ImageSize,
        upperLeft: JSON.stringify(this._upperLeft.LatLongBounds),
        lowerRight: JSON.stringify(this._lowerRight.LatLongBounds),
        viewportClient: JSON.stringify({height: this._viewport.Client.Height, width: this._viewport.Client.Width})
      }

      const imageUrl = `${RestAPI.BaseUrl}ReprojectedTileSource?token=${RestAPI.AccessToken}&parameters=${this.toStringBase64(parameters)}`;      

      fetch(imageUrl)
      .then(response => {
        if(!response.ok)
          throw new Error("HTTP Status " + response.status);
        return response.blob()
      })
      .then(imageBlob => {      
        if(imageBlob.type == "text/xml"){
          this._readErrorFromBlob(imageBlob)
          .then(message  => console.log(message))
          .catch(error => this.handleError(error));
        }
        else {
          const urlCreator = window.URL || window.webkitURL;                  
          let imgUrl = urlCreator.createObjectURL(imageBlob);          
    
          this._image = new Image();
          this._image.src = imgUrl;
          console.log(imgUrl);
    
          this._image.onload = ()=> {            
            resolve(this);
          };        
        }      
      })
      .catch(error => reject(error));
    }
  }
 

  toStringBase64(paramsJson)  {
    return window.btoa(JSON.stringify(paramsJson));
  }

	
  /**
   * @param  {Bounds} rcMap
   * @param  {LatLngBounds} newExtents
   * @param  {Number} cellsize
   * @param  {Number} scale
   */
  onExtentsChanged(sourceEvent, rcMap, extents, cellsize, scale, canvas) {		
    var disposableViewPort = this._viewport;
    this._tileUrls = null; //Clear when extents changed

		if (this.isVisible(scale)) {
      if (disposableViewPort != null && disposableViewPort.World.equals(extents)) {
				disposableViewPort = null; // just re-use the current
			}
      else {
        const parameters = {
          sourceProjection: this._map.options.Projection,
          targetProjection: "EPSG:4326",
          envelope: JSON.stringify({MinX: extents.MinX, MaxX: extents.MaxX, MinY: extents.MinY, MaxY: extents.MaxY})
        }

        const transformViewportUrl = `${RestAPI.BaseUrl}TransformEnvelope?token=${RestAPI.AccessToken}&parameters=${this.toStringBase64(parameters)}`;      
        
        this._transformPromise = fetch(transformViewportUrl);
        this._tranformPromiseParams = {
          rcMap: rcMap,
          canvas: canvas,
          scale: scale,
          cellsize: cellsize,
          extents: extents
        }
      }
    }
    else {
			this._viewport = null;
      this._transformPromise = null;
      this._tranformPromiseParams = null;
    }
			    
		if (disposableViewPort != null)
		{
			disposableViewPort.dispose(this._tileSource, Date.now());
			this._tileSource.cleanup();
		}

    return true;    
	}

	getTiles() {         
    this._tileUrls = [];
    this._tileColumnCount = 0;
    this._tileRowCount = 0;

		if (this._viewport != null && this._viewport.tiles.length > 0) {      			      
      
      this._tileColumnCount = this._viewport.tiles.length;

			for (let iCol = this._viewport.tiles.length - 1; iCol >= 0; iCol--) {
				
        const tiles = this._viewport.tiles[iCol];        
        if(iCol == 0)
          this._tileRowCount = tiles.length;
        
        let columns = [];

        for(let iRow = tiles.length -1; iRow >= 0; iRow--) {
          const tile = tiles[iRow];
          
          if(tile != null)
            columns.unshift(tile.imageUrl);
        }       

        this._tileUrls.unshift(columns); 
				      
        this._upperLeft = this._viewport.tiles[0][0];
        this._lowerRight = this._viewport.tiles[this._tileColumnCount-1][this._tileRowCount-1];    
			}  			
		}   
	} 
	

	getWCLayerOptions() {
		let parentOptions = super.getWCLayerOptions();

		let layerOptions =  { 
			Opacity: 1.0, 		// Number = 1.0
			Datasource: {
				Type: null, 		// MapEnums.DatasourceTypes
				Url: null, 			// String
				Key: null 			// String
			}
		}
		layerOptions = Util.mergeOptions(parentOptions, layerOptions);
		return layerOptions;
	}
	
	/**
	 * @param  {} map
	 */
	onAdd(map) {
		super.onAdd(map);

		 if (this._options.Datasource.Type == 'OpenStreetMap')
			this._tileSource = new OpenStreetMapTileSource(map);
		else if (this._options.Datasource.Type == 'EsriRestService')	
			this._tileSource = new EsriTileSource(map, this._options.Name, this._options.Datasource.Url);
		else if (this._options.Datasource.Type == 'GoogleStreetMap')	
			this._tileSource = new GoogleTileSource(map, this._options.Name);	
     else if (this.options.Datasource.Type == 'GenericTileService')
       this._tileSource = new GenericTileSource(map, this.options.Name, this.options.Datasource.Url);
		else
		  this._tileSource = new TileSource(map);
		
	}  

   /**
   * @param  {MgCanvas} canvas
   * @override
   */
    refreshView(canvas) {    
      if(this._image)
        canvas.drawImage(this._image, 0, 0);        
    } 
    
     render(canvas, force, context, compositeOperation) {     
      console.log('render - reprojectedTileLayer - start ' + this.id);   
      
      if (!canvas.isPanning) {
  
        context.save();
        if (compositeOperation)
          context.globalCompositeOperation = compositeOperation;
                        
        if (this._image != null) {
          let halfWidth = this._viewport.World.Width / 2;
          let halfHeight = this._viewport.World.Height / 2;
    
          let envelope = new Envelope(this.map.extents.MinX - halfWidth, this.map.extents.MaxX + halfWidth, this.map.extents.MinY - halfHeight, this.map.extents.MaxY + halfHeight);
          
          let imageRect = this._viewport.worldToClientRect(envelope);
          //context.drawImage(this._image, 0, 0, this._image.width, this._image.height, imageRect.left, imageRect.top, imageRect.width, imageRect.height);
          context.drawImage(this._image, 0, 0, this._image.width, this._image.height);
    
        }
  
        context.restore();
      }
      
      console.log('render - reprojectedTileLayer - end ' + this.id);   
    }
  
}