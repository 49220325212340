import {LatLng, toLatLng} from './LatLng';
import {toLatLngBounds, LatLngBounds} from './LatLngBounds'
import { Coordinate } from '../geometries';
/*
* var corner1 = L.latLng(40.712, -74.227),
 * corner2 = L.latLng(40.774, -74.125),
 * bounds = L.latLngBounds(corner1, corner2);
 * Caution: if the area crosses the antimeridian (often confused with the International Date Line), you must specify corners _outside_ the [-180, 180] degrees longitude range.
 *
 * Note that `LatLngBounds` does not inherit from Leafet's `Class` object,
 * which means new classes can't inherit from it, and new methods
 * can't be added to it with the `include` function.
 */

export class Envelope {
  constructor(x1, x2, y1, y2) {
		if (x1 < x2) {
			this._minX = x1;
			this._maxX = x2;
		}
		else {
			this._minX = x2;
			this._maxX = x1;
		}

		if (y1 < y2) {
			this._minY = y1;
			this._maxY = y2;
		}
		else {
			this._minY = y2;
			this._maxY = y1;
		}

    const sw = new LatLng(this._maxY, this._minX);
    const ne = new LatLng(this._minY, this._maxX);

    if (!sw) { return; }

    var latlngs = ne ? [sw, ne] : sw;

    for (var i = 0, len = latlngs.length; i < len; i++) {
      this.extend(latlngs[i]);
    }
  }

	// @method extend(latlng: LatLng): this
	// Extend the bounds to contain the given point

	// @alternative
	// @method extend(otherBounds: LatLngBounds): this
	// Extend the bounds to contain the given bounds
	extend(obj) {
		var sw = this._southWest,
		    ne = this._northEast,
		    sw2, ne2;

		if (obj instanceof Coordinate) {
			sw2 = new LatLng(obj.Y, obj.X);
			ne2 = sw2;
		}
		if (obj instanceof LatLng) {
			sw2 = obj;
			ne2 = obj;
		} 
		else if (obj instanceof LatLngBounds) {
			sw2 = obj._southWest;
			ne2 = obj._northEast;

			if (!sw2 || !ne2) { return this; }
		} 
		else if (obj instanceof Envelope) {
			sw2 = obj._southWest;
			ne2 = obj._northEast;

			if (!sw2 || !ne2) { return this; }	
		} 
		else {
			return obj ? this.extend(toLatLng(obj) || toLatLngBounds(obj)) : this;
		}

		if (!sw && !ne) {
			this._southWest = new LatLng(sw2.lat, sw2.lng);
			this._northEast = new LatLng(ne2.lat, ne2.lng);
		} 
		else {
			sw.lat = Math.min(sw2.lat, sw.lat);
			sw.lng = Math.min(sw2.lng, sw.lng);
			ne.lat = Math.max(ne2.lat, ne.lat);
			ne.lng = Math.max(ne2.lng, ne.lng);
		}

		return this;
	}

	// @method pad(bufferRatio: Number): LatLngBounds
	// Returns bounds created by extending or retracting the current bounds by a given ratio in each direction.
	// For example, a ratio of 0.5 extends the bounds by 50% in each direction.
	// Negative values will retract the bounds.
	pad(bufferRatio) {
		var sw = this._southWest,
		    ne = this._northEast,
		    heightBuffer = Math.abs(sw.lat - ne.lat) * bufferRatio,
		    widthBuffer = Math.abs(sw.lng - ne.lng) * bufferRatio;

		return new LatLngBounds(
		        new LatLng(sw.lat - heightBuffer, sw.lng - widthBuffer),
		        new LatLng(ne.lat + heightBuffer, ne.lng + widthBuffer));
	}

	// @method getCenter(): LatLng
	// Returns the center point of the bounds.
	get Center() {
		return new LatLng(
		        (this._southWest.lat + this._northEast.lat) / 2,
		        (this._southWest.lng + this._northEast.lng) / 2);
	}

	// @method SouthWest: LatLng
	// Returns the south-west point of the bounds.
	get SouthWest() {
		return this._southWest;
	}

	// @method NorthEast: LatLng
	// Returns the north-east point of the bounds.
	get NorthEast() {
		return this._northEast;
	}

	// @method getNorthWest(): LatLng
	// Returns the north-west point of the bounds.
	get NorthWest() {
		return new LatLng(this.North, this.West);
	}

	// @method getSouthEast(): LatLng
	// Returns the south-east point of the bounds.
	get SouthEast() {
		return new LatLng(this.South, this.East);
	}

	// @method getWest(): Number
	// Returns the west longitude of the bounds
	get West() {
		return this._southWest.lng;
	}

	// @method getSouth(): Number
	// Returns the south latitude of the bounds
	get South() {
		return this._southWest.lat;
	}

	// @method getEast(): Number
	// Returns the east longitude of the bounds
	get East() {
		return this._northEast.lng;
	}

	// @method getNorth(): Number
	// Returns the north latitude of the bounds
	get North() {
		return this._northEast.lat;
	}

	// @method contains(otherBounds: LatLngBounds): Boolean
	// Returns `true` if the rectangle contains the given one.

	// @alternative
	// @method contains (latlng: LatLng): Boolean
	// Returns `true` if the rectangle contains the given point.
	contains(obj) { // (LatLngBounds) or (LatLng) -> Boolean
		if (typeof obj[0] === 'number' || obj instanceof LatLng || 'lat' in obj) {
			obj = toLatLng(obj);
		} 
		else if (!(obj instanceof Envelope)) {
			obj = toLatLngBounds(obj);
		}

		var sw = this._southWest,
		    ne = this._northEast,
		    sw2, ne2;

		if (obj instanceof LatLngBounds) {
			sw2 = obj.SouthWest;
			ne2 = obj.NorthEast;
		}
		else if (obj instanceof Envelope) {
			sw2 = obj._southWest;
			ne2 = obj._northEast;
		} 
		else {
			sw2 = ne2 = obj;
		}

		return (sw2.lat >= sw.lat) && (ne2.lat <= ne.lat) &&
		       (sw2.lng >= sw.lng) && (ne2.lng <= ne.lng);
	}

	// @method intersects(otherBounds: LatLngBounds): Boolean
	// Returns `true` if the rectangle intersects the given bounds. Two bounds intersect if they have at least one point in common.
	intersects(bounds) {
		//bounds = toLatLngBounds(bounds);

		var sw = this._southWest,
		    ne = this._northEast,
		    sw2 = bounds.SouthWest,
		    ne2 = bounds.NorthEast,

		    latIntersects = (ne2.lat >= sw.lat) && (sw2.lat <= ne.lat),
		    lngIntersects = (ne2.lng >= sw.lng) && (sw2.lng <= ne.lng);

		return latIntersects && lngIntersects;
	}

	// @method overlaps(otherBounds: Bounds): Boolean
	// Returns `true` if the rectangle overlaps the given bounds. Two bounds overlap if their intersection is an area.
	overlaps(bounds) {
		bounds = toLatLngBounds(bounds);

		var sw = this._southWest,
		    ne = this._northEast,
		    sw2 = bounds.SouthWest,
		    ne2 = bounds.NorthEast,

		    latOverlaps = (ne2.lat > sw.lat) && (sw2.lat < ne.lat),
		    lngOverlaps = (ne2.lng > sw.lng) && (sw2.lng < ne.lng);

		return latOverlaps && lngOverlaps;
	}

	// @method toBBoxString(): String
	// Returns a string with bounding box coordinates in a 'southwest_lng,southwest_lat,northeast_lng,northeast_lat' format. Useful for sending requests to web services that return geo data.
	toBBoxString() {
		return [this.West, this.South, this.East, this.North].join(',');
	}

	// @method equals(otherBounds: LatLngBounds, maxMargin?: Number): Boolean
	// Returns `true` if the rectangle is equivalent (within a small margin of error) to the given bounds. The margin of error can be overridden by setting `maxMargin` to a small number.
	equals(bounds, maxMargin) {
		if (!bounds) { return false; }

		if (!(bounds instanceof Envelope))
		  bounds = toLatLngBounds(bounds);

		return this._southWest.equals(bounds.SouthWest, maxMargin) &&
		       this._northEast.equals(bounds.NorthEast, maxMargin);
	}

	// @method isValid(): Boolean
	// Returns `true` if the bounds are properly initialized.
	get isValid() {
		return !!(this._southWest && this._northEast);
	}

	// @method MinX(): Number
	// Returns the leftmost part of the bounding box.
	get MinX() {
		return this._minX;
	}

	set MinX(value) {
		this._minX = value;
	}
	// @method MinX(): Number
	// Returns the rightmost part of the bounding box.
	get MaxX() {
		return this._maxX;
	}

	set MaxX(value) {
		this._maxX = value;
	}
	// @method MinY(): Number
	// Returns the upper right of the bounding box.
	get MinY() {
		return this._minY;
	}

	set MinY(value) {
		this._minY = value;
	}

	// @method MaxY(): Number
	// Returns the bottom right of the bounding box.
	get MaxY() {
		return this._maxY;
	}

	set MaxY(value) {
		this._maxY = value;
	}

	get Width() {
		return this._maxX - this._minX;
	}

	get Height() {
		return this._maxY - this._minY;
	}
}