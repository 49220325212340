import {TileSource} from './tilesource';

export class OSMInternationalMapTileSource extends TileSource
{
  // @section Constructors

  constructor(map) {
    super(map);
  }

  // @section Properties

  // @section TileSource Methods

  getTileUrl(level, col, row) {
    let domains = ['a','b','c'];
    let i = (col + row) % domains.length;

    var sTileUrl = `https://maps.wikimedia.org/osm-intl/${level}/${col}/${row}.png`
    return sTileUrl;
  }

}