
import {Tile, LoadStates} from './tile';
import {Envelope} from '../geometry/Envelope';
import {Bounds, Point} from '../geometry/index';
import { Util } from '../core';

const MaxCachedTiles = 100;
      
export class TileSource{
  // @section Constructors  
  constructor(map){
    this.map = map;
    this.pendingTiles = [];
    this.dctTiles = new Object();
    this.unused = [];
    this.cReferences = 0;
    this.longitudeResolutions = new Array(this.NumberOfResolutions);
    this.latitudeResolutions = new Array(this.NumberOfResolutions);
    this.googleResolutions = new Array(this.NumberOfResolutions);

    for (let index = 0, pixels = this.ImageSize; index < this.NumberOfResolutions; index++)
    {
      this.longitudeResolutions[index] = TileSource.LatLongExtents.Width / pixels;
      this.latitudeResolutions[index] = TileSource.LatLongExtents.Height / pixels;
      this.googleResolutions[index] = TileSource.GoogleExtents.Width / pixels;
      pixels *= 2;
    }
  }

  addReference() {
    this.cReferences++;
  }

  removeReference() {
    this.cReferences--;

    if (this.cReferences == 0)
    this.dispose();
  }

  dispose() {
    this.pendingTiles.Clear();

    //foreach (KeyValuePair<string, Tile> kvp in dctTiles)
    //  kvp.Value.Dispose();

    this.dctTiles.Clear();
  }

  
  // sections Constants

  // @section Events

  // @section Static Properties
  /*
  static OpenStreetMapsTileSource OpenStreetMapsInstance
  {
    get
    {
      if (openStreetMapsInstance == null)
      {
        openStreetMapsInstance = new OpenStreetMapsTileSource();
        openStreetMapsInstance.AddReference();
      }

      return openStreetMapsInstance;
    }
  }

  public static GoogleStreetMapsTileSource GoogleStreetMapsInstance
  {
    get
    {
      if (googleStreetMapsInstance == null)
      {
        googleStreetMapsInstance = new GoogleStreetMapsTileSource();
        googleStreetMapsInstance.AddReference();
      }

      return googleStreetMapsInstance;
    }
  }
  */
  
  // @section Properties

  get ImageExtension() {
    return ".png";
  }

  get ImageSize() { 
    return 256;
  }

  get NumberOfResolutions() {
    return 20;
  }

  // @section Methods
  getNearestLevel(resolution) {
    var result = 0;

    //smaller than smallest
    if (this.longitudeResolutions[this.longitudeResolutions.length - 1] > resolution)
      result = this.longitudeResolutions.length - 1;
    //bigger than biggest
    else if (this.longitudeResolutions[0] < resolution)
      result = 0;
    else
    {
      var resultDistance = Number.MAX_VALUE;

      for (let i = 0; i < this.longitudeResolutions.length; i++)
      {
        var distance = Math.abs(this.longitudeResolutions[i] - resolution);
        if (distance < resultDistance)
        {
          result = i;
          resultDistance = distance;
        }
      }
    }

    return result;
  }

  getLatLongResolution(level) {
    return this.longitudeResolutions[level];
  }

  getGoogleResolution(level) {
    return this.googleResolutions[level];
  }

  getLongitudeTileSize(level){
    return this.longitudeResolutions[level] * this.ImageSize;
  }

  getLatitudeTileSize(level) {
    return this.latitudeResolutions[level] * this.ImageSize;
  }

  getGoogleTileSize(level) {
    return this.googleResolutions[level] * this.ImageSize;
  }

  getTile(level, col, row, viewport) {
    var sKey = `${level}:${col},${row}`;
    var tile = this.dctTiles[sKey];
    var nPos = 0;

    if (tile == undefined) {
      tile = new Tile(level, col, row, this, viewport);
      this.dctTiles[sKey] = tile;
    }
    else if (tile.addReference() == 1) {
      // clear the dereferenced time
      tile.Dereferenced = 0;
      this.unused = this.unused.filter(item => item != tile);      
    }      


    if (tile.ImageState == LoadStates.Initial)
      tile.ImageState = LoadStates.Loading;
    else if (tile.ImageState == LoadStates.Loaded) {
      viewport.renderTile(tile);
    }

    return tile;
  }   

  dereferenceTile(tile, now) {
    var nPos = -1;

    if (tile.dereference() == 0)
    {
      // give the tile an dereferenced time
      tile.Dereferenced = now;

      // insert the tile into the unused list
      if ((nPos = Util.findIndex(this.unused, tile)) < 0)
        this.unused.push(tile);
        //this.unused.Insert(~nPos, tile);
    }
  }

  cleanup() {
    // if our total tile count is greater than our cache limit, then we should look at freeing some
    if (this.dctTiles.keys > this.MaxCachedTiles && this.unused.length > 0)
    {
      // sort by dereferenced value
      this.unused.sort((x, y) => y.Dereferenced > x.Dereferenced);

      while (this.dctTiles.keys.length > this.MaxCachedTiles && unused.length > 0) {
        var tile = this.unused[this.unused.length - 1];

        tile.dispose();
        delete this.dctTiles[tile.Name];
        this.unused = this.unused.slice(this.unused.length - 1);
      }

      // sort again by name
      unused.Sort();
    }
  }
  
  transformExtentsFromLatLong(latLongMinX, latLongMinY, latLongMaxX, latLongMaxY, boundsObj) {
    var longitude1 = this.normalise(latLongMinX, TileSource.LatLongExtents.MinX, TileSource.LatLongExtents.MaxX);
    var latitude1 = this.normalise(latLongMinY, TileSource.LatLongExtents.MinY, TileSource.LatLongExtents.MaxY);

    boundsObj.minX = longitude1 * 20037508.34 / 180.0;
    boundsObj.minY = Math.log(Math.tan((90.0 + latitude1) * Math.PI / 360.0)) / (Math.PI / 180.0);
    boundsObj.minY = boundsObj.minY * 20037508.34 / 180.0;

    var longitude2 = this.normalise(latLongMaxX, TileSource.LatLongExtents.MinX, TileSource.LatLongExtents.MaxX);
    var latitude2 = this.normalise(latLongMaxY, TileSource.LatLongExtents.MinY, TileSource.LatLongExtents.MaxY);

    boundsObj.maxX = longitude2 * 20037508.34 / 180.0;
    boundsObj.maxY = Math.log(Math.tan((90.0 + latitude2) * Math.PI / 360.0)) / (Math.PI / 180.0);
    boundsObj.maxY = boundsObj.maxY * 20037508.34 / 180.0;
  }

  transformExtentsToLatLong(googleBounds, latlongBounds) {
    latlongBounds.MinX = (googleBounds.MinX / 20037508.34) * 180.0;
    latlongBounds.MinY = (googleBounds.MinY / 20037508.34) * 180.0;
    latlongBounds.MinY = 180.0 / Math.PI * (2.0 * Math.atan(Math.exp(latlongBounds.MinY * Math.PI / 180.0)) - Math.PI / 2.0);
    
    latlongBounds.MaxX = (googleBounds.MaxX / 20037508.34) * 180.0;
    latlongBounds.MaxY = (googleBounds.MaxY / 20037508.34) * 180.0;
    latlongBounds.MaxY = 180.0 / Math.PI * (2.0 * Math.atan(Math.exp(latlongBounds.MaxY * Math.PI / 180.0)) - Math.PI / 2.0);
  }

  _getSubdomain(tilePoint) {
		var index = Math.abs(tilePoint.x + tilePoint.y) % this.options.subdomains.length;
		return this.options.subdomains[index];
  }
  
  // @section Tile Loading Methods
  loadTile(level, col, row) {

  }

  getTileUrl(level, col, row) {
  }

  createTile(level, col, row) {
    var sKey = `${level}:${col},${row}`;
    var tile = this.dctTiles[sKey];
    var nPos = 0;

    if (tile == undefined) {
      tile = new Tile(level, col, row, this);
      this.dctTiles[sKey] = tile;
    }
    else if (tile.AddReference() == 1)
    {
      // clear the dereferenced time
      tile.Dereferenced = 0;
      this.unused = this.unused.filter(item => item != tile);      
    }      

    return tile;
  }
  
  // @section Helper Methods
  transformPointFromLatLong(longitude, latitude, x, y) {
    longitude = this.normalise(longitude, TileSource.LatLongExtents.MinX, TileSource.LatLongExtents.MaxX);
    latitude = this.normalise(latitude, TileSource.LatLongExtents.MinY, TileSource.LatLongExtents.MaxY);

    x = longitude * 20037508.34 / 180;
    y = Math.log(Math.tan((90 + latitude) * Math.PI / 360)) / (Math.PI / 180);
    y = y * 20037508.34 / 180;
  }

  transformPointToLatLong(x, y, longitude, latitude) {
    longitude = (x / 20037508.34) * 180;
    latitude = (y / 20037508.34) * 180;
    latitude = 180 / Math.PI * (2 * Math.atan(Math.exp(latitude * Math.PI / 180)) - Math.PI / 2);
  }

  render(canvas) {
    
  }

  normalise(value, min, max) {
    if (value < min) {
      var range = max - min;
      do {
        value += range;
      } while (value < min);
    }
    else if (value > max)
    {
      var range = max - min;

      do {
        value -= range;
      } while (value > max);
    }

    return value;
  }
}

 // @section Static Fields
 TileSource.LatLongExtents = new Envelope(-180.0, 180.0,-90.0, 90.0); 
 TileSource.GoogleExtents = new Envelope(-20037508.34, 20037508.34, -20037508.34, 20037508.34);
 
