import {MgShape} from './mgshape';
import { MgPoint } from './mgpoint';
import { Stroke} from '../drawing/Stroke';
import {MapPrimitive, LabelPoint} from './mapprimitive';
import {Point} from '../geometry/index';

export class MgLine extends MgShape {
    

  //#region -- CONSTRUCTORS
  /**
   * @param  {number[[]]} points
   */
  constructor(feature) {    
    super(feature);

    this.stroke = null;
    
    if (feature.ThematicIndex > -1) {
      this.stroke = feature.featureLayer.Thematics[feature.ThematicIndex].LineStyle;
    }
    else if (feature.featureLayer.LineStyle != null) {
      this.stroke = feature.featureLayer.LineStyle;      
    }    
  } 
  //#endregion
  
  //#region -- STATIC METHODS
  /**
   * @param  {number[[]]} points
   */
  static createObject(points) {    
    return new MgLine(points);    
  } 
  //#endregion

  
  //#region -- OVERRIDES
  /**
   * @param  {MgCanvas} canvas
   * @param  {string} strokeColor
   * @param  {string} fillColor
   * @override
   */  
  draw(canvas, opacity, context) {
    if (this.stroke != null) {
      this.drawLine(canvas, opacity, this.stroke, context);
    }    
  }

  drawHover(canvas, opacity, context) {
    context.globalCompositeOperation = 'source-over';
    
    if (this.stroke != null) {
      this.drawLine(canvas, opacity, this.stroke.hoverStroke, context);
    }    
  }

  drawSelected(canvas, opacity, context) {
    context.globalCompositeOperation = 'source-over';
    if (this.stroke != null) {
      this.drawLine(canvas, opacity, this.stroke.selectedStroke, context);
    }    
  }

  drawLine(canvas, opacity, stroke, context) {
    canvas.drawLine(this._points, stroke, opacity, context);        
    this.drawText(this._points, canvas, context);          
  }
  
  getRectExtents() {
    return MapPrimitive.CalculateExtents(this._points);    
  }
  
  drawText(pts, canvas, context) {
    const DEG_TO_RAD = 0.017453292519943296;
    if (this.LabelStyle != null  && this.LabelStyle.isVisible(this.Feature.featureLayer.map.scale)) {
      if (true) { //this.labelPoint == null || canvas.xOffset == null || canvas.yOffset == null ) {        
        this.labelPoint = new LabelPoint();
        let rcExtents =  MapPrimitive.CalculateExtents(pts);
        var sLabel = this.labelText;
        var labelRotation = this.LabelStyle.Rotation != null && this.LabelStyle.Rotation.length > 0 ? parseInt(this.LabelStyle.Rotation) : undefined;
        
        var pt = null;
        if (pts == null)
          sLabel = null;
        else if (MapPrimitive.GetPolylineLabelPoint(pts, 0, labelRotation, this.labelPoint)) {
          labelRotation = this.labelPoint.angle;
          pt = this.labelPoint.pt;
        }
        else
          sLabel = null;
        
        if (sLabel != null && sLabel.length > 0 && pt != null) {
          let textWidth = canvas.getTextWidth(sLabel);
          let halfLabelHeight = this.LabelStyle.FontSize / 2 + 2;
          let halfLabelWidth = textWidth / 2;

          let angleInRadians = DEG_TO_RAD * labelRotation;
          let cos = Math.cos(angleInRadians);
          let sin = Math.sin(angleInRadians);

          var tangent = {
            "Width": (cos * halfLabelWidth), 
            "Height": (sin * halfLabelWidth)
          }
          var perpendicular = {
            "Width": sin * halfLabelHeight,
            "Height": cos * halfLabelHeight
          }

          let ptUpperLeft = new Point(pt.x - tangent.Width + perpendicular.Width, pt.y - perpendicular.Height - tangent.Height);
          let ptLowerLeft = new Point(pt.x - tangent.Width - perpendicular.Width, pt.y + perpendicular.Height - tangent.Height);

          let ptUpperRight = new Point(pt.x + tangent.Width + perpendicular.Width, pt.y - perpendicular.Height + tangent.Height);
          let ptLowerRight = new Point(pt.x + tangent.Width - perpendicular.Width, pt.y + perpendicular.Height + tangent.Height);

          // basic test. Note: We should be doing line intersection like mapserv
          if (this.isMouseOver(rcExtents, pts, ptUpperLeft) && this.isMouseOver(rcExtents, pts, ptUpperRight) && 
              this.isMouseOver(rcExtents, pts, ptLowerRight) && this.isMouseOver(rcExtents, pts, ptLowerLeft)) {
            canvas.drawText(sLabel, Math.trunc(pt.x), Math.trunc(pt.y), labelRotation, this.LabelStyle, 'center', context)             
          }
          else {
            this.labelPoint = null;            
          }
        }        
      }
    }
  }

  isMouseOver(rcExtents, points, ptMouse) {
    var bFound = false;

    if (rcExtents.pointInRect(ptMouse.x, ptMouse.y)) {
      bFound = MapPrimitive.PolygonContains(points, ptMouse);      
    }

    return bFound;
  }
  //#endregion  
  
}