import {Viewport} from './viewport';
import {Util}  from '../core/index';
import {TileSource} from '../layer/tilesource';
import {Tile, LoadStates} from '../layer/tile';
import {MapEnums} from '../map/enums';
import {MgCanvas} from '../map/canvas';

export class TileViewport extends Viewport
{
  // @section Constructors

  constructor(view,  world, Cellsize, scale, tileSource, canvas, opacity){
    super(view, world, Cellsize, scale)
 
    this.initialise();
    this.canvas = canvas;
    this.tileSource = tileSource;
    this.level = tileSource.getNearestLevel(Cellsize);
    this.googleTileSize = tileSource.getGoogleTileSize(this.level);
    this.offScreenCanvas = undefined;
    this.offScreenContext = undefined;
    this.opacity = opacity != null ? opacity : 1.0;

    this.createOffscreenCanvas(canvas);

    this.maxColumns = 1;
    for (let index = 1; index <= this.level; index++)
      this.maxColumns = this.maxColumns << 1;

    var boundsObj = {
      minX : 0,
      minY : 0,
      maxX : 0,
      maxY : 0
    };

    this.normalisedMinX = this.tileSource.normalise(world.MinX, TileSource.LatLongExtents.MinX, TileSource.LatLongExtents.MaxX);
    this.normalisedMaxY = this.tileSource.normalise(world.MaxY, TileSource.LatLongExtents.MinY, TileSource.LatLongExtents.MaxY);
    tileSource.transformExtentsFromLatLong(world.MinX, world.MinY, world.MaxX, world.MaxY, boundsObj);

    this.firstCol = Math.floor((boundsObj.minX - TileSource.GoogleExtents.MinX) / this.googleTileSize);
    this.firstRow = Math.floor((TileSource.GoogleExtents.MaxY - boundsObj.maxY) / this.googleTileSize);
    this.lastCol = Math.floor((boundsObj.maxX - TileSource.GoogleExtents.MinX) / this.googleTileSize);
    this.lastRow = Math.floor((TileSource.GoogleExtents.MaxY - boundsObj.minY) / this.googleTileSize);

    this.cCols = (this.lastCol >= this.firstCol) ? (this.lastCol - this.firstCol + 1) : (this.maxColumns - this.firstCol + this.lastCol + 1);
    this.cRows = (this.lastRow >= this.firstRow) ? (this.lastRow - this.firstRow + 1) : (this.maxColumns - this.firstRow + this.lastRow + 1);
    this.tiles = [this.cCols];
    
    var col = this.firstCol;    
    var i = 0;
    for (var nCol = 0; nCol < this.cCols; nCol++) {
      this.tiles[nCol] = [this.cRows];
      var row = this.firstRow;

      for (var nRow = 0; nRow < this.cRows; nRow++) {
        this.tiles[nCol][nRow] = this.tileSource.getTile(this.level, col, row, this);
        i++;
        // need to do wrapping
        if (++row == this.maxColumns)
          row = 0;
      }

      // need to do wrapping
      if (++col == this.maxColumns)
        col = 0;
    }
  }

  // @section  Fields

  initialise() {
    
    this.level = -1;
    this.firstCol = -1;
    this.firstRow = -1;
    this.lastCol = -1;
    this.lastRow = -1;
    this.maxColumns = -1;
    this.normalisedMinX = 0.0
    this.normalisedMaxY = 0.0;

    this.tiles = [];
  }

  dispose(tileSource, now) {
    for (let nCol = 0; nCol < this.cCols; nCol++) {
      for (let nRow = 0; nRow < this.cRows; nRow++) {
        tileSource.dereferenceTile(this.tiles[nCol][nRow], now);
      }
    }
  }

  // @section Methods

  isLoaded() {
    var dims = Util.getArrayDimensions(this.tiles);
    var cCols = dims[0];
    var cRows = dims[1];
    bIsLoaded = true;

    for (var nCol = 0; bIsLoaded && nCol < cCols; nCol++) {
      for (var nRow = 0; bIsLoaded && nRow < cRows; nRow++)
        bIsLoaded = tiles[nCol][nRow].Image != null;
    }

    return bIsLoaded;
  }

  /* TODO
  RenderFull(TileSource tileSource, out Geometries.RectObj srcExtents) {
    int x, nCol, cCols = tiles.GetLength(0);
    int y, nRow, cRows = tiles.GetLength(1);
    Tile upperLeft = tiles[0, 0], lowerRight = tiles[cCols - 1, cRows - 1];
    Bitmap bitmap = new Bitmap(cCols * tileSource.ImageSize, cRows * tileSource.ImageSize, tilePixelFormat);

    using (Graphics g = Graphics.FromImage(bitmap))
    {
      for (x = 0, nCol = 0; nCol < cCols; nCol++)
      {
        for (y = 0, nRow = 0; nRow < cRows; nRow++)
        {
          Image image = tiles[nCol, nRow].Image;

          if (image != null)
            g.DrawImage(image, new Rectangle(x, y, tileSource.ImageSize, tileSource.ImageSize), 0, 0, tileSource.ImageSize, tileSource.ImageSize, GraphicsUnit.Pixel);

          y += tileSource.ImageSize;
        }

        x += tileSource.ImageSize;
      }
    }

    srcExtents = new Geometries.RectObj(upperLeft.LatLongMinX, lowerRight.LatLongMinY, lowerRight.LatLongMaxX, upperLeft.LatLongMaxY);

    return bitmap;
  }

  public Bitmap RenderScaled(PixelFormat tilePixelFormat, TileSource tileSource, int opacity, out Geometries.RectObj srcExtents)
  {
    Bitmap bitmap = new Bitmap(Client.Width, Client.Height, tilePixelFormat);

    using (Graphics g = Graphics.FromImage(bitmap))
      Render(g, tileSource, opacity);

    srcExtents = new Geometries.RectObj(World.MinX, World.MinY, World.MaxX, World.MaxY);

    return bitmap;
  }
  */

  createOffscreenCanvas(canvas) {
    if (!this.offScreenCanvas) {
      this.offScreenCanvas = MgCanvas.createHiDPICanvas(canvas.canvas.width, canvas.canvas.height, 1, true);
      this.offScreenContext = this.offScreenCanvas.getContext('2d');
    }
    else {
      //this.offScreenContext.clearRect(0, 0, this.offScreenCanvas.width, this.offScreenCanvas.height);
    }
  }

  render(canvas, force, context, compositeOperation) {          
    if (force) {
      var xWrap = 0.0;
      
      for (var nCol = 0, col = this.firstCol; nCol < this.cCols; nCol++) {
        for (var nRow = 0, row = this.firstRow; nRow < this.cRows; nRow++) {
          var tile = this.tiles[nCol][nRow];
          
          if (tile.image == null || tile.ImageState != LoadStates.Loaded)
            continue;

          var image = tile.image;
          var dstTop = Math.trunc((this.normalisedMaxY - tile.LatLongBounds.MaxY) / this.Cellsize)  + this.Client.MinY;
          var dstLeft = Math.trunc((xWrap + tile.LatLongBounds.MinX - this.normalisedMinX) / this.Cellsize)  + this.Client.MinX;
          var dstRight = Math.trunc((xWrap + tile.LatLongBounds.MaxX - this.normalisedMinX) / this.Cellsize)  + this.Client.MinX;
          var dstBottom = Math.trunc((this.normalisedMaxY - tile.LatLongBounds.MinY) / this.Cellsize) + this.Client.MinY;

          var heightRatio = image.height / (dstBottom - dstTop);
          var widthRatio = image.width / (dstRight - dstLeft);

          var rcClient = this.Client;

          var srcTop = 0;
          var srcLeft = 0;
          var srcRight = image.width;
          var srcBottom = image.height;
          
          if (dstLeft < rcClient.MinX)
          {
            srcLeft += (rcClient.MinX - dstLeft) * widthRatio;
            dstLeft = rcClient.MinX;
          }

          if (dstTop < rcClient.MinY)
          {
            srcTop += (rcClient.MinY - dstTop) * heightRatio;
            dstTop = rcClient.MinY;
          }

          if (dstRight > rcClient.MaxX)
          {
            srcRight -= (dstRight - rcClient.MaxX) * widthRatio;
            dstRight = rcClient.MaxX;
          }

          if (dstBottom > rcClient.MaxY)
          {
            srcBottom -= (dstBottom - rcClient.MaxY) * heightRatio;
            dstBottom = rcClient.MaxY;
          }
          
          if (compositeOperation)
            this.offScreenContext.globalCompositeOperation = compositeOperation;

          tile.render(this.offScreenContext, srcLeft, srcTop, srcRight, srcBottom, dstLeft, dstTop, dstRight, dstBottom);            
        }
        
        if (++col == this.maxColumns)
        {
          xWrap += TileSource.LatLongExtents.Width;
          col = 0;
        }
      }  
                          
    }
    
    
    if (compositeOperation)
      context.globalCompositeOperation = compositeOperation;

    if (this.offScreenCanvas.width > 0 && this.offScreenCanvas.height > 0)
      this.canvas.drawImage(this.offScreenCanvas, 0, 0, context, this.opacity);                 
  }
  
  renderTile(tile) {
    var xWrap = 0.0;
    if (tile.Row == this.maxColumns)
      xWrap += TileSource.LatLongExtents.Width;
    
    var image = tile.image;
    var dstTop = Math.trunc((this.normalisedMaxY - tile.LatLongBounds.MaxY) / this.Cellsize)  + this.Client.MinY;
    var dstLeft = Math.trunc((xWrap + tile.LatLongBounds.MinX - this.normalisedMinX) / this.Cellsize)  + this.Client.MinX;
    var dstRight = Math.trunc((xWrap + tile.LatLongBounds.MaxX - this.normalisedMinX) / this.Cellsize)  + this.Client.MinX;
    var dstBottom = Math.trunc((this.normalisedMaxY - tile.LatLongBounds.MinY) / this.Cellsize) + this.Client.MinY;

    var heightRatio = image.height / (dstBottom - dstTop);
    var widthRatio = image.width / (dstRight - dstLeft);

    var rcClient = this.Client;

    var srcTop = 0;
    var srcLeft = 0;
    var srcRight = image.width;
    var srcBottom = image.height;
    
    if (dstLeft < rcClient.MinX)
    {
      srcLeft += (rcClient.MinX - dstLeft) * widthRatio;
      dstLeft = rcClient.MinX;
    }

    if (dstTop < rcClient.MinY)
    {
      srcTop += (rcClient.MinY - dstTop) * heightRatio;
      dstTop = rcClient.MinY;
    }

    if (dstRight > rcClient.MaxX)
    {
      srcRight -= (dstRight - rcClient.MaxX) * widthRatio;
      dstRight = rcClient.MaxX;
    }

    if (dstBottom > rcClient.MaxY)
    {
      srcBottom -= (dstBottom - rcClient.MaxY) * heightRatio;
      dstBottom = rcClient.MaxY;
    }
    
    this.offScreenContext.globalCompositeOperation = 'destination-over';
    tile.render(this.offScreenContext, srcLeft, srcTop, srcRight, srcBottom, dstLeft, dstTop, dstRight, dstBottom);         
    this.canvas.context.globalCompositeOperation = 'destination-over';    
    tile.render(this.canvas.context, srcLeft, srcTop, srcRight, srcBottom, dstLeft, dstTop, dstRight, dstBottom);     
  }

  isReferenced(tile) {
    var bIsReferenced = false;

    if (tile.Level != this.level)
      bIsReferenced = false;
    else if (!this.IsInRange(tile.Col, this.firstCol, this.lastCol))
      bIsReferenced = false;
    else if (!this.IsInRange(tile.Row, this.firstRow, this.lastRow))
      bIsReferenced = false;
    else
      bIsReferenced = true;

    return bIsReferenced;
  }

  isInRange(tile, firstTile, lastTile) {
    var bIsInRange = false;

    if (lastTile >= firstTile)
      bIsInRange = (tile >= firstTile && tile <= lastTile);
    else
      bIsInRange = ((tile >= firstTile && tile <= this.maxColumns) || (tile >= 0 && tile <= lastTile));

    return bIsInRange;
  }

  renderCanvas(canvas) {
    
    let canRender = this.tiles == null || this.tiles.length <= 0 ? false : true;
    for (let i = 0; i < this.tiles.length; i++) {
      for (let j = 0; j < this.tiles[i].length; j++) {
        let tile = this.tiles[i][j];
        if (tile.ImageState == LoadStates.Initial || tile.ImageState == LoadStates.Loading) {
          canRender = false;
          break;
        }
      }
    }

    if (canRender && canvas) {
      this.tileSource.map.canvas.offScreenContext.globalCompositeOperation = 'destination-over';
      this.tileSource.map.canvas.drawImage(canvas, 0, 0, this.tileSource.map.canvas.offScreenContext);  
      this.tileSource.map.canvas.refreshView();
    }        
  }  

}