import {Markup} from './markup';
import MapEnums from '../map/enums';

export class LineMarkup extends  Markup {
  constructor(map, viewport, id) {
    super(map, viewport, id);
    this.geometryType = MapEnums.GeometryTypes.LINESTRING;
  }

  //#region -- VIRTUAL FUNCTIONS
  isStartPoint(point) {
    return true;
  }

  isLastPoint(point) {
    return true;
  }

  handleMouseClick(x, y) {
    let pt = new Point(x, y);
    let tuple = this.getExistingPoint(pt);
    
    let existingPoint = tuple.point;
    
    if (existingPoint == null) {
      let indexToInsert = this.isPointOnLine(pt);
      if (indexToInsert > -1) {
        this.insertPoint(pt, indexToInsert);
      }
      else
        this.addPoint(pt);        
    }      
    else {
      if (this.linePoints.length <= 2)
        this.onDisplayPrompt(MapEnums.PromptTypes.MINIMUM_REQUIRED_POINTS_LINESTRING);        
      else 
        this.removePoint(existingPoint.WorldCoordinate);            
    }
  }

  handleMouseDoubleClick(x, y) {
    let pt = new Point(x, y);
    let tuple = this.getExistingPoint(pt);
    
    let existingPoint = tuple.point;
    
    if (existingPoint == null) {
      let indexToInsert = this.isPointOnLine(pt);
      if (indexToInsert > -1) {
        this.insertPoint(pt, indexToInsert);
      }
      else
        this.addPoint(pt);        
    }      
    
    if (this.linePoints.length >= 1)
      this.isGeometryComplete = true;
  }

  render(context) {
    if (!this.selectedForEditing) {
      this.editor.renderLine(this.Points, context, this.isHovered, this.selected);
    }
    else {
      var lastPoint = null;

      for (let i = 0; i < this.linePoints.length; i++) {
        let current = this.linePoints[i];

        current.ClientPoint = this.editor.viewport.worldToClient(current.WorldCoordinate.X, current.WorldCoordinate.Y);

        if (current.IsStartPoint) {      
          this.editor.drawPoint(current.ClientPoint, false, context);
        }
        else if (lastPoint != null) {                    
          let stroke = this.editor.contextLayer != null && this.editor.contextLayer.LineStyle != null ? this.editor.contextLayer.LineStyle.selectedStroke : null;          
          this.editor.drawLine(current.ClientPoint, lastPoint.ClientPoint, context, stroke);          
          this.editor.drawPoint(current.ClientPoint, false, context);        
        }      

        lastPoint = current;
      }

      if (this.insertionPoint) {  
        this.editor.drawPoint(new Point(this.insertionPoint.x, this.insertionPoint.y), false, context); 
        this.insertionPoint = null;
      }
    }
  }

  clear() {

  }

  delete() {
    
  }

  toWKT(addTag) {
    if (this.isGeometryComplete) {
      var sWKT = addTag ? 'LINESTRING(' : '(';

      for (let i = 0; i < this.linePoints.length; i++) {
        let coordinate = this.linePoints[i].WorldCoordinate;
        if (i > 0)
          sWKT += ', ';

        sWKT += `${coordinate.X} ${coordinate.Y}`; 
      }

      sWKT += ')';
      return sWKT;
    }

    return "";    
  }

  //#endregion - VIRTUAL FUNCTIONS 
}