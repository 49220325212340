import {MgMap} from './map/index';
import {MgFeatureLayer, MgFeatureLayer2, MgImageLayer, MgTileLayer, MgWmsLayer, MgGeocoderLayer, MgReprojectedTileLayer} from './layer/index';
import * as DomUtil from './dom/DomUtil';
import {Point, toPoint} from './geometry/Point';
import {Envelope} from './geometry/Envelope';
import {RestAPI} from './api';

export function createMap (id, options, baseUrl, accessToken) {
  let generatedMap = dctMaps.get(id);
  if(!generatedMap){  
     generatedMap = MgMap.createObject(id, options, baseUrl, accessToken);
     dctMaps.set(id, generatedMap);
  }  
  return generatedMap;
}

export function deleteMap(id) {
  dctMaps.delete(id);
}

export function createTileLayer(id, options, taskId, mapProjection){
  if(!mapProjection || mapProjection == "EPSG:4326" || mapProjection == "EPSG:4283" || mapProjection == "EPSG:3857")
    return MgTileLayer.createObject(id, options, taskId);
  else 
    return MgReprojectedTileLayer.createObject(id, options, taskId);
}

export function createImageLayer(id, options, taskId){
  return MgImageLayer.createObject(id, options, taskId);
}

export function createFeatureLayer(id, options, taskId){
  return MgFeatureLayer.createObject(id, options, taskId);
}

export function createGeocoderLayer(id, options, taskId){
  return MgGeocoderLayer.createObject(id, options, taskId);
}

export function createWmsLayer(id, options, taskId){
  return MgWmsLayer.createObject(id, options, taskId);
}

export function createEnvelope(x1, x2, y1, y2){
  return new Envelope(x1, x2, y1, y2);
}

RestAPI.BaseUrl = "http://localhost:8380/ConnectWebService/REST/";
RestAPI.AccessToken = "e0f3923c-570b-4f55-a3e9-26dba007002f";
    
window.RestAPI =  RestAPI;
window.DomUtil = DomUtil;
window.Point = Point;
window.toPoint = toPoint;

let dctMaps = new Map();