/// <summary>
/// A lightweight class used to store coordinates on the 2-dimensional Cartesian plane.
/// <para>
/// It is distinct from <see cref="IPoint"/>, which is a subclass of <see cref="IGeometry"/>.
/// Unlike objects of type <see cref="IPoint"/> (which contain additional
/// information such as an envelope, a precision model, and spatial reference
/// system information), a <other>Coordinate</other> only contains ordinate values
/// and propertied.
/// </para>
/// <para>
/// <other>Coordinate</other>s are two-dimensional points, with an additional Z-ordinate.    
/// If an Z-ordinate value is not specified or not defined,
/// constructed coordinates have a Z-ordinate of <code>NaN</code>
/// (which is also the value of <see cref="NullOrdinate"/>).
/// </para>
/// </summary>
/// <remarks>
/// Apart from the basic accessor functions, NTS supports
/// only specific operations involving the Z-ordinate.
/// </remarks>
export class Coordinate {
  ///<summary>
  /// The value used to indicate a null or missing ordinate value.
  /// In particular, used for the value of ordinates for dimensions
  /// greater than the defined dimension of a coordinate.
  ///</summary>
  
  /// <summary>
  /// Constructs a <other>Coordinate</other> at (x,y,z).
  /// </summary>
  /// <param name="x">X value.</param>
  /// <param name="y">Y value.</param>
  /// <param name="z">Z value.</param>
  constructor(x, y, z)
  {
    this.X = x != undefined ? x : Coordinate.NullOrdinate;
    this.Y = y != undefined ? y : Coordinate.NullOrdinate;
    this.Z = z != undefined ? z : Coordinate.NullOrdinate;
  }

  clone(c) {
    this.X = c.X;
    this.Y = c.Y;
    this.Z = c.Z;    
  } 

  get CoordinateValue() {
      return this; 
  }

  /// <summary>
  /// Returns whether the planar projections of the two <other>Coordinate</other>s are equal.
  ///</summary>
  /// <param name="other"><other>Coordinate</other> with which to do the 2D comparison.</param>
  /// <returns>
  /// <other>true</other> if the x- and y-coordinates are equal;
  /// the Z coordinates do not have to be equal.
  /// </returns>
  Equals2D(other) {
    return this.X == other.X && this.Y == other.Y;
  }

  /// <summary>
  /// Tests if another coordinate has the same value for X and Y, within a tolerance.
  /// </summary>
  /// <param name="c">A <see cref="Coordinate"/>.</param>
  /// <param name="tolerance">The tolerance value.</param>
  /// <returns><c>true</c> if the X and Y ordinates are within the given tolerance.</returns>
  /// <remarks>The Z ordinate is ignored.</remarks>
  Equals2DWithTolerance(c, tolerance) {
    if (!this.EqualsWithTolerance(this.X, c.X, tolerance))
        return false;
    if (!this.EqualsWithTolerance(this.Y, c.Y, tolerance))
        return false;
    return true;
  }

  EqualsWithTolerance(x1, x2, tolerance)
  {
      return Math.abs(x1 - x2) <= tolerance;
  }

  /// <summary>
  /// Returns <other>true</other> if <other>other</other> has the same values for the x and y ordinates.
  /// Since Coordinates are 2.5D, this routine ignores the z value when making the comparison.
  /// </summary>
  /// <param name="other"><other>Coordinate</other> with which to do the comparison.</param>
  /// <returns><other>true</other> if <other>other</other> is a <other>Coordinate</other> with the same values for the x and y ordinates.</returns>
  Equals(other) {
    if (other == undefined)
        return false;

    if (other instanceof Coordinate)
      return this.Equals2D(other);
    else
      return false;  
  }
    
  /// <summary>
  /// Compares this object with the specified object for order.
  /// Since Coordinates are 2.5D, this routine ignores the z value when making the comparison.
  /// Returns
  ///   -1  : this.x lowerthan other.x || ((this.x == other.x) AND (this.y lowerthan other.y))
  ///    0  : this.x == other.x AND this.y = other.y
  ///    1  : this.x greaterthan other.x || ((this.x == other.x) AND (this.y greaterthan other.y))
  /// </summary>
  /// <param name="o"><other>Coordinate</other> with which this <other>Coordinate</other> is being compared.</param>
  /// <returns>
  /// A negative integer, zero, or a positive integer as this <other>Coordinate</other>
  ///         is less than, equal to, or greater than the specified <other>Coordinate</other>.
  /// </returns>
  CompareTo(other)
  {
    if (other instanceof Coordinate) {
      if (this.X < other.X)
        return -1;
      if (this.X > other.X)
          return 1;
      if (this.Y < other.Y)
          return -1;
      return this.Y > other.Y ? 1 : 0;
    }

    return false;
  }
  
  /// <summary>
  /// Returns <c>true</c> if <paramref name="other"/> 
  /// has the same values for X, Y and Z.
  /// </summary>
  /// <param name="other">A <see cref="Coordinate"/> with which to do the 3D comparison.</param>
  /// <returns>
  /// <c>true</c> if <paramref name="other"/> is a <see cref="Coordinate"/> 
  /// with the same values for X, Y and Z.
  /// </returns>
  Equals3D(other) {
    return (this.X == other.X) && (this.Y == other.Y) && ((this.Z == other.Z) || (isNaN(this.Z) && isNaN(other.Z)));
  }

  /// <summary>
  /// Tests if another coordinate has the same value for Z, within a tolerance.
  /// </summary>
  /// <param name="c">A <see cref="Coordinate"/>.</param>
  /// <param name="tolerance">The tolerance value.</param>
  /// <returns><c>true</c> if the Z ordinates are within the given tolerance.</returns>
  EqualInZ(c, tolerance) {
    return this.EqualsWithTolerance(this.Z, c.Z, tolerance);
  }

  /// <summary>
  /// Returns a <other>string</other> of the form <I>(x,y,z)</I> .
  /// </summary>
  /// <returns><other>string</other> of the form <I>(x,y,z)</I></returns>
  ToString() {
    return `(${this.X}, ${this.Y}, ${this.Z})`;
      //return "(" + X.ToString("R", NumberFormatInfo.InvariantInfo) + ", " +
      //              Y.ToString("R", NumberFormatInfo.InvariantInfo) + ", " +
      //              Z.ToString("R", NumberFormatInfo.InvariantInfo) + ")";
  }

  /// <summary>
  /// Create a new object as copy of this instance.
  /// </summary>
  /// <returns></returns>
  Copy() {
    return new Coordinate(this.X, this.Y, this.Z);
  }

  /// <summary>
  /// Computes the 2-dimensional Euclidean distance to another location.
  /// </summary>
  /// <param name="c">A <see cref="Coordinate"/> with which to do the distance comparison.</param>
  /// <returns>the 2-dimensional Euclidean distance between the locations.</returns>
  /// <remarks>The Z-ordinate is ignored.</remarks>
  Distance(c) {
    var dx = this.X - c.X;
    var dy = this.Y - c.Y;
    return Math.sqrt(dx * dx + dy * dy);
  }

  /// <summary>
  /// Computes the 3-dimensional Euclidean distance to another location.
  /// </summary>
  /// <param name="c">A <see cref="Coordinate"/> with which to do the distance comparison.</param>
  /// <returns>the 3-dimensional Euclidean distance between the locations.</returns>
  Distance3D(c) {
    var dx = this.X - c.X;
    var dy = this.Y - c.Y;
    var dz = this.Z - c.Z;
    return Math.sqrt(dx * dx + dy * dy + dz * dz);
  }

  /// <summary>
  /// Gets a hashcode for this coordinate.
  /// </summary>
  /// <returns>A hashcode for this coordinate.</returns>
  GetHashCode() {
    var result = 17;
    // ReSharper disable NonReadonlyFieldInGetHashCode
    result = 37 * result + GetHashCode(X);
    result = 37 * result + GetHashCode(Y);
    // ReSharper restore NonReadonlyFieldInGetHashCode
    return result;
  }

  /// <summary>
  /// Computes a hash code for a double value, using the algorithm from
  /// Joshua Bloch's book <i>Effective Java"</i>
  /// </summary>
  /// <param name="value">A hashcode for the double value</param>
  static GetHashCode(value)
  {
    // GetHashCode
    // This was implemented as follows, but that's actually equivalent:
    /*
    var f = BitConverter.DoubleToInt64Bits(value);
    return (int)(f ^ (f >> 32));
    */
    var f = (value >>> 0);
    return (f ^ (f >> 32)).toString(2)
  }

  dec2bin(dec){
    return (dec >>> 0).toString(2);
  }   
}

Coordinate.NullOrdinate = NaN;
