import {MgShape} from './mgshape';

export class MgEllipse extends MgShape {
    
  //#region -- CONSTRUCTORS
  constructor() {
    super();
  } 
  //#endregion

  //#region -- STATIC METHODS
  static createObject() {    
    return new MgEllipse();    
  } 
  //#endregion
    
  //#region -- OVERRIDES   
  /**
   * @param  {MgCanvas} canvas
   * @param  {string} strokeColor
   * @param  {string} fillColor
   * @override
   */
  draw(canvas, strokeColor, fillColor) {
  }
  //#endregion
}