require("babel-polyfill");

import { MgCanvas } from "./canvas";

import { RestAPI } from '../api';
import { Browser } from '../core';
import { Events } from '../core/Events';
import { Util } from '../core/index';

import { Bounds, toBounds } from '../geometry/Bounds';
import { EPSG3857 } from '../geometry/crs/CRS.EPSG3857';
import { Envelope } from '../geometry/Envelope';
import { LatLng, toLatLng } from '../geometry/LatLng';
import { LatLngBounds, toLatLngBounds } from '../geometry/LatLngBounds';
import { Point, toPoint } from '../geometry/Point';
import { Rectangle } from '../geometry/rectangle';

import * as DomEvent from '../dom/DomEvent';
import * as DomUtil from '../dom/DomUtil';
import { Measurement, MgFeatureLayer } from '../layer/index';

import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { Label, Viewport } from '../drawing/index';
import { GeometryEditor } from '../layer/geometryeditor';
import MapEnums from './enums';

/*
const Units = Object.freeze({
	MS_INCHES : Symbol(0),
	MS_FEET : Symbol(1),
	MS_MILES : Symbol(2),
	MS_METERS : Symbol(3),
	MS_KILOMETERS : Symbol(4),
	MS_DD : Symbol(5),
	MS_PIXELS : Symbol(6)
});
*/
const DragStates  = Object.freeze({
	None : Symbol("None"),
	PanStart : Symbol("PanStart"),
	PanDragging : Symbol("PanDragging"),
	ZoomStart : Symbol("ZoomStart"),
	ZoomDragging : Symbol("ZoomDragging"),
	SelectStart : Symbol("SelectStart"),
	SelectDragging : Symbol("SelectDragging"),
	MeasurePanStart : Symbol("MeasurePanStart"),
	MeasurePanDragging : Symbol("MeasurePanDragging"),
	GeometryEditPanStart : Symbol("GeometryEditPanStart"),
	GeometryEditPanDragging : Symbol("GeometryEditPanDragging"),
});

const MIN_DRAG_WIDTH = 10;
const MIN_DRAG_HEIGHT = 10;

const MOUSE_BUTTON_NONE = 0;
const MOUSE_BUTTON_LEFT = 1;
const MOUSE_BUTTON_WHEEL = 2;
const MOUSE_BUTTON_RIGHT = 3;
export class MgMap extends Events {
	
	// constructor
  /**
   * @param  {} id
   * @param  {} options
   * @param  {} div
   */
  constructor(id, options, div, baseUrl, accessToken) {
    super();
		this.isHovering = false;
		this.options = Util.setOptions(this, this.defaultOptions);
		this.options = Util.setOptions(this, options);
		this.zoomTimer = null;
		this.panTimer = null;
		this._zoom = Util.scaleToZoomLevel(this.scale);
        this._id = id;
		this._div = div;
		this.mouseDown = false;
		this.doMouseUpBound = null;
		this.doMouseMoveBound = null;
		this.loadFeatures = true;
		this.homeCenter = null;
		this.homeScale = null;
		this.trackingMode = this.options.TrackingOnByDefault;
		RestAPI.BaseUrl = baseUrl;
		RestAPI.AccessToken = accessToken;
		  
		this._canvas = new MgCanvas(this.createCanvas(div), div, this);
		this.initialise();
    
		if ( this.options.Extents!= null) {
			let extents = this.options.Extents;
			this.extents = new LatLngBounds(toLatLng(extents.MinY, extents.MinX), toLatLng(extents.MaxY, extents.MaxX));
		
			let xScale = (extents.MaxX - extents.MinX) / this.rcMap.Width * MgMap.DPI * this.getInchesPerUnit(0);
			let yScale = (extents.MaxY - extents.MinY) / this.rcMap.Height * MgMap.DPI * this.getInchesPerUnit(0);

			this.options.center = [(extents.MinY + extents.MaxY) / 2.0, (extents.MinX + extents.MaxX) / 2.0];
			this.options.scale = Math.max(xScale, yScale);
      
    }
    else if (this.options.LocationAndScale != null) {
      this.options.center = [this.options.LocationAndScale.Y, this.options.LocationAndScale.X];
      this.options.scale = this.options.LocationAndScale.Scale;
		}

		this.homeCenter = this.options.center;
		this.homeScale = this.options.scale;

		this.scale = this.options.scale;
		this.units = this.options.Units;
		this._initContainer(id);
		this._initLayout();

		this.hoverItem = null;
		this.mouseX = undefined;
		this.mouseY = undefined;			
		this.promises = [];		
		this.selectedFeatures = new Object();
		this.trackingFeatures = [];
		
		this._initEvents();
		
		this.enableTooltip();
		this.mouseClicks = 0;
		this.timerid = -1;

		this.measureDistanceMode = false;
		this.geometryEditMode = false;
		this.measurement = null;
		this.geometryEditor = null;
		this.geocoder = null;

		var doContextMenu = () => this.handleContextmenu(event); 
		this._canvas.canvas.addEventListener('contextmenu', doContextMenu.bind(this), false);  											
		
		this._ongoingRefreshView = false;
		this._pendingZoomByContextOrder = false;
		this._initialExtentsDone = false;

		// this.isLoadingExtents$ = new BehaviorSubject(false).pipe();

		this.anyLoadingExtents$ = new BehaviorSubject([]).pipe();
	
		 
	}

  // Factory methods
  /**
   * @param  {string} id
   * @param  {json} options
   * @returns MgMap
   */
  static createObject(id, options, baseUrl, accessToken) {
    if(id){
      const mapDiv = document.getElementById(id);
      return new MgMap(id, options, mapDiv, baseUrl, accessToken);
    }      
  }

	initialise() {
		this._dctLayers = new Map();    

		this.cellsize = 0;
		this.cellsize = 0.0;
		this.units = MgMap.MS_DD;
		this.rcMap = new Bounds( new Point(0,0), new Point(this.div.clientWidth, this.div.clientHeight));
		this.scale = this.options.scale;
		this.extents = null;
		// mouse dragging fields
    	this.ptDragStart = null;
    	this.ptDragCurrent = null;
		this.ptSelectionStart = null;
		this.ptSelectionEnd = null;
		this.dragState = DragStates.None;
		this.Capture = false;
		
    this._handlers = [];
		this._layers = [];
		this._zoomBoundLayers = {};
		this._sizeChanged = true;
		this.repaintLayers = [];
	}

	resize(sourceEvent, triggerCallback = true) {
		
		//Map is not ready yet. Ignore call
		if(sourceEvent == MapEnums.SourceEvents.AngularMapDivSizeChange && !this._initialExtentsDone)
			return;

		this.canvas.clear();
		let newWidth = this.div.clientWidth;
		let newHeight = this.div.clientHeight;

		this.rcMap = new Bounds( new Point(0,0), new Point(this.div.clientWidth, this.div.clientHeight));

		this.canvas.resize(newWidth, newHeight);
		for (let layer of this._layers) 
			layer.resize(newWidth, newHeight);
			
		this.calculateOffset();	
		this.calculateExtents(sourceEvent, null, null, triggerCallback);
		this.refreshView(false);
	}

	resizeHeight(newHeight) {
		this.canvas.clear();
		let newWidth = this.div.clientWidth;
		this.div.clientHeight = newHeight;

		this.rcMap = new Bounds( new Point(0,0), new Point(this.div.clientWidth, this.div.clientHeight));

		this.canvas.resize(newWidth, newHeight);
		for (let layer of this._layers) 
			layer.resize(newWidth, newHeight);
			
		this.calculateOffset();	
		this.calculateExtents(MapEnums.SourceEvents.ResizeHeight);
		this.refreshView(true);
	}

	// @section default properties
  get defaultOptions() {
    let _defaultOptions = {
      // @section Map State Options
      // @option crs: CRS = L.CRS.EPSG3857
      // The [Coordinate Reference System](#crs) to use. Don't change this if you're not
      // sure what it means.
      crs: EPSG3857,

      // @option center: LatLng = undefined
      // Initial geographic center of the map
      center: undefined,

      // @option zoom: Number = undefined
      // Initial map zoom level
      zoom: 12,

      // @option minZoom: Number = *
      // Minimum zoom level of the map.
      // If not specified and at least one `GridLayer` or `TileLayer` is in the map,
      // the lowest of their `minZoom` options will be used instead.
      minZoom: 4,

      // @option maxZoom: Number = *
      // Maximum zoom level of the map.
      // If not specified and at least one `GridLayer` or `TileLayer` is in the map,
      // the highest of their `maxZoom` options will be used instead.
      maxZoom: 22,

      // @option maxBounds: LatLngBounds = null
      // When this option is set, the map restricts the view to the given
      // geographical bounds, bouncing the user back if the user tries to pan
      // outside the view. To set the restriction dynamically, use
      // [`setMaxBounds`](#map-setmaxbounds) method.
      maxBounds: undefined,
    }
		_defaultOptions = Util.mergeOptions(_defaultOptions, this.getWCMapOptions())
    return _defaultOptions;
	}

	getWCMapOptions() {
		let mapOptions =  { 
			Projection: null, 				// String
			Units: null, 							// MapEnums.UnitTypes
			Extents: {
				MinX: null, 						// Number
				MaxX: null, 						// Number
				MinY: null, 						// Number
				MaxX: null, 						// Number
			},
			LocationAndScale : {
				X: null, 								// Number
				Y: null, 								// Number
				Scale: 50000, 						// Number
			}
    }
		return mapOptions;
	}

	// @section Properties  
  /** @returns MgCanvas
   */
  get canvas() {
    return this._canvas;
  }
	
	get container() {
    return this._container;
	}
	
  /** @returns div
   */
  get div() {
    return this._div;
  }

  // @method zoom(): Number
  // Returns the current zoom level of the map view
  get zoom() {
    return this.options.zoom;
	}
	
  /**
   * @param  {} value
   */
  set zoom(value) {
    this._zoom = value;
  }

  // @method center: Number
  // Returns the current zoom level of the map view
  get center() {
		if(!this.options.center && this.options.LocationAndScale)
			this._center = toLatLng(this.options.LocationAndScale.Y, this.options.LocationAndScale.X);
		else if (this._center == undefined)
			this._center = toLatLng(this.options.center[0], this.options.center[1]);
			
		return this._center;
  }

  set center(value) {
    this._center = value;
  }

	// @method scale: Number
  // Returns the current scale of the map view
  get scale() {
    return this._scale;
	}
	
	// @method scale: Number
  // Sets the current scale of the map view
  set scale(value) {
    this._scale = value;
	}	

	// @method selectedMarkupId: []
  	// Returns the currently selected markup ids
	get selectedMarkupIds() {
		let ids = [];
		if (this.geometryEditor != null) {
			if (this.geometryEditor.markupsWithinSelectionRectangle != null && this.geometryEditor.markupsWithinSelectionRectangle.length > 0)
				ids = this.geometryEditor.markupsWithinSelectionRectangle.map(x => x.id);
			if (this.geometryEditor.selectedMarkup != null)
				ids.push(this.geometryEditor.selectedMarkup.id);			
			if (this.geometryEditor.activeMarkup != null && this.geometryEditor.activeMarkup.linePoints.length > 0)
				ids.push(this.geometryEditor.activeMarkup.id);			
		}
		
		return ids;
	}

	// @method activeMarkupId: Number
  	// Returns the id of the active markup
	get activeMarkupId() {
		return this.geometryEditor && this.geometryEditor.activeMarkup ? this.geometryEditor.activeMarkup.id : null;
	}	

	// @section Map initialization methods
	
	_initEvents() {	
		this.shiftKeyDown = false;
		this.ctrlKeyDown = false;

		let __this = this;
		fromEvent(document, 'keydown').subscribe(e => {
		  if (!e.repeat && e.key == 'Shift') {
				console.log(`shiftKeyDown ${e.key}`);	
				__this.shiftKeyDown = true;
			}
			else if (!e.repeat && e.key == 'Control') {
				__this.ctrlKeyDown = true;
			}
		});
		
		fromEvent(document, 'keyup').subscribe(e => {
		  if (e.key == 'Shift') {
			  console.log(`shiftKeyUp ${e.key}`);	
				__this.shiftKeyDown = false;
			}
			else if (e.key == 'Control') {
			  console.log(`ctrlKeyUp ${e.key}`);	
				__this.ctrlKeyDown = false;
			}
		});

		// Subscription RxJS
		this.featureHoveredEvent = new Subject();
		this.featureSingleClickedEvent = new Subject();
		this.featureDoubleClickedEvent = new Subject();
		this.featureSelectedEvent = new Subject();
		
		/*
		// this will limit the emission of mouse move event to 60fps		
		// and limit calling the hitTest function to 60fps as well
		let _mouseMoveEvent = new Subject();
		this.mouseMoveEvent = _mouseMoveEvent.pipe(debounce(() => interval(1000/60)));
		this.mouseMoveEvent.subscribe(e => {
			__this.mouseMoveCallback(e);
		});

		
		// this will limit the emission of mousewheel event to 1 every 200ms		
		let _mouseWheelEvent = new Subject();
		this.mouseWheelEvent = _mouseWheelEvent.pipe(debounce(() => interval(1000/2)));
		this.mouseWheelEvent.subscribe(e => {
			__this.mouseWheelCallback(e);
		});
    */
	}
	/**
	 * @param  {} id
	 */
	_initContainer(id) {
		var container = this._container = DomUtil.get(id);

		if (!container) {
			throw new Error('Map container not found.');
		} else if (container._leaflet_id) {
			throw new Error('Map container is already initialized.');
		}

		this._canvasId = Util.stamp(container);
	
 	}
	/**
	 */
	_initLayout() {
		var container = this._container;

		//this._fadeAnimated = this.options.fadeAnimation && Browser.any3d;
    /*
		DomUtil.addClass(container, 'leaflet-container' +
			(Browser.touch ? ' leaflet-touch' : '') +
			(Browser.retina ? ' leaflet-retina' : '') +
			(Browser.ielt9 ? ' leaflet-oldie' : '') +
			(Browser.safari ? ' leaflet-safari' : '') +
			(this._fadeAnimated ? ' leaflet-fade-anim' : ''));
    */
		var position = DomUtil.getStyle(container, 'position');

		if (position !== 'absolute' && position !== 'relative' && position !== 'fixed') {
			container.style.position = 'relative';
		}

		/*
		this.zoomControl = new ZoomControl();	
		this.zoomControl.addTo(this);  
	
		this.layerControl = new LayerControl();

		let _this = this;
		this.zoomControl.onZoomInEvent.subscribe(e => {
			console.log(e);
			e.stopPropagation();
			_this.zoomIn();
		});

		this.zoomControl.onZoomOutEvent.subscribe(e => {
			console.log(e);
			e.stopPropagation();
			_this.zoomOut();
		});
    */
		// Assign the listener callback to a variable
		
		 var doMouseUp = () => this.onMouseUp(event); 
		var doMouseMove = () => this.onMouseMove(event);

		this.doMouseMoveBound = doMouseMove.bind(this);
		this.doMouseUpBound = doMouseUp.bind(this);

		var doMouseDown = () => this.onMouseDown(event); 
		var doMouseWheel = () => this.onMouseWheel(event);
		var doMouseLeave = () => this.onMouseLeave(event);
		var doMouseOver = () => this.enableDocumentLevelMouseMove(event) 
		var doDblClick = () => this.onMouseDoubleClick(event); 

		// document.addEventListener('mouseup', doMouseUp.bind(this), true);
		// this._container.addEventListener('mouseup', doMouseUp.bind(this), true);
		this._container.addEventListener('mouseover', doMouseOver.bind(this), true);
		this._container.addEventListener('mousedown', doMouseDown.bind(this), true);
		this._container.addEventListener('mousewheel', doMouseWheel.bind(this), true);
		this._container.addEventListener('mouseleave', doMouseLeave.bind(this), true);
		this._container.addEventListener('ondblclick', doDblClick.bind(this), true);
		
		this.initTooltipContainer();	

		// calculate offset for mouse events
	  this.calculateOffset();		
	}

	enableDocumentLevelMouseMove(e){


		if (!this.isHovering){
			// has yet to hover over container div, but now we have
			this.isHovering = true;
			document.addEventListener('mousemove', this.doMouseMoveBound, true);	
			document.addEventListener('mouseup', this.doMouseUpBound, true);	

		}

	}
	
	calculateOffset() {
		const getOffset = (element, horizontal = false) => {
			if(!element) return 0;
			return getOffset(element.offsetParent, horizontal) + (horizontal ? element.offsetLeft : element.offsetTop);
		};
		
		this.offsetX = getOffset(this._div, true);
    	this.offsetY = getOffset(this._div) ;

	}

	getScrollParent(node) {
		if (node == null) {
		  return null;
		}
	  
		if (node.scrollHeight > node.clientHeight) {
		  return node;
		} else {
		  return this.getScrollParent(node.parentNode);
		}
	  }


  // @section  Methods
  /**
   * @param  {} div
   * @returns canvas DOM Element
   */
  createCanvas(div) {
		let canvas = MgCanvas.createHiDPICanvas(div.clientWidth, div.clientHeight, 1);
    canvas.id     = "mapcontrol-" + div.id;
    canvas.style.zIndex   = 8;
    canvas.style.position = "absolute";   
    div.appendChild(canvas);

    this._div = div;
    return document.getElementById(canvas.id);    
  }
	
	// addlayer
  /**
   * @param  {MgLayer} layer
   */
  addLayer(layer) {
		console.log("add Layer " + layer.id +  " To " + this._id);
		this._layers.push(layer);
		this._dctLayers.set(layer.id, layer);
		
		if (layer.options.ContextLayer == true) 
			this._contextLayer = layer;
			
		//sort according to position; least number = bottom. greatest number = top
		this._layers.sort((a, b) => {
			if(a.options.position < b.options.position) return -1;
			else if(a.options.position > b.options.position) return 1;
			else return 0;
		});
	}
	
	initialiseLayers() {
		//this.layerControl.addTo(this, this._layers);
	}
  /**
   */
  refreshView(fullRefresh, hitItem, hitItemLayer) {			
		this.removeTooltip();
		this._ongoingRefreshView = true;

		

		if (this.shiftKeyDown && this.dragState == DragStates.SelectDragging) {
			// do nothing do not refresh the view
			console.log('refreshView: do nothing, user is select dragging');			
			this.canvas.drawCanvasSelection(this.ptSelectionStart, this.ptSelectionEnd); 
		}
		else if (this.ctrlKeyDown && this.dragState == DragStates.SelectDragging) {			
			this.canvas.drawCanvasSelection(this.ptDragStart, this.ptDragCurrent);			
		}
		else if (this.canvas.isPanning) {
			console.log('map - refreshView - Panning');
			this.canvas.refreshView();
			
			if (this.measurement != null)
				this.measurement.render();
				
			if (this.geometryEditor != null && this.geometryEditor.contextLayer.isVisible(this.scale))
			  this.geometryEditor.render();
		}
		else {						
		
			let __canvas = this.canvas;
			if (this.promises != null)
			  this.promises.length = 0;



			if (this.promises.length <= 0) {
				// __canvas.clearAll();
					for(let i = this._layers.length-1; i >= 0; i--) {		
						let layer = this._layers[i];
						if (layer.isVisible(this.scale)) {

							var promise = layer.isLayerReady(this.canvas, this.cellsize);
							promise.then(function(layer) {
								// layer.render(__canvas, true, __canvas.offScreenContext, 'destination-over');	
							});		
							this.promises.push(promise);			

						
						} else {
							layer.clearItems();
						}
					}
			
				

				 Promise.all(this.promises).then(function(layers) {


					__canvas.clearAll();
					let reversedLayers = layers.reverse();
					reversedLayers.forEach( layer => {

						if (layer != null && layer.isVisible(this.scale)) {
							layer.render(__canvas, fullRefresh, __canvas.context, 'source-over');
							layer.render(__canvas, fullRefresh, __canvas.offScreenContext, 'source-over');
							if (hitItemLayer && hitItem && layer == hitItemLayer){
								hitItem.Shape.drawHover(__canvas, 1, __canvas.context);
							}								
						}
					});

					if (this.measurement != null) {
						this.measurement.render();
					}
					
					if (this.geometryEditor != null && this.geometryEditor.contextLayer.isVisible(this.scale)) { 
						this.geometryEditor.render();
						if (this.geometryEditor.selectedMarkup == null) {
							this.geometryEditor.render(__canvas.offScreenContext);
						}
					}

					this.drawTracked();

					this.drawSelection();

					this.promises = [];
					//this._ongoingRefreshView = false;
					//if(this._pendingZoomByContextOrder) {
					//	this.zoomByContextOrder();
					//}



				}.bind(this));
			}
			else {				
				this.invalidate();
				
			}						
		}
	}  


	changeLoadingStatus(loadingStatus){

		
		
		// if (this.isLoadingExtents$.value && !force) // already loading, don't do anything
		// 	return;
		let newLoadingExtents = this.anyLoadingExtents$.value;

		const index = newLoadingExtents.findIndex((obj) => obj.id === loadingStatus.id);

		if (index === -1) { 

			if (loadingStatus.loading == true)
				newLoadingExtents.push(loadingStatus);
				
		} else {
			// newLoadingExtents[index] = loadingStatus;
			newLoadingExtents.splice(index, 1);
		}

	
		this.anyLoadingExtents$.next(newLoadingExtents);

	}
	

	// need this?
	redraw(sourceEvent, layer, clearCanvas = true) {		
		if (layer != null) { 
			if (layer.isVisible(this.scale)) {
				let __this = this;
				let __canvas = this.canvas;

				layer.onExtentsChanged(sourceEvent, this.rcMap, this.extents, this.cellsize, this.scale, this.canvas);
				layer.isLayerReady(this.canvas, this.cellsize).then(function(layer) {
       			   layer.render(__canvas, true, __canvas.context);
				});				
			}
			else {
				layer.clearItems();				
			}
		}

		if (clearCanvas)
			this.canvas.clearAll();
			
		this._layers.forEach( layer => {
			if (layer != null && layer.isVisible(this.scale)) {
				layer.render(this.canvas, true, this.canvas.context);
			}
		});			
	}

	markItemsAsTracked(items, layerName){

		if (!this.trackingMode) return;

		console.log(items);
		let layer = this._layers.find(l => l._id == layerName);

		if (layer == null) return;

		for(let i = 0; i < items.length; i++) {
			let feature = layer._visibleItems.find(vi => vi.id == items[i].FeatureId);
			if (feature){
				feature.Tracking = true;
				this.trackingFeatures.push(feature);
			}
		}
	}
	
	clearTracked(){
		if (this.trackingFeatures.length > 0) {
			for (var i = 0; i < this.trackingFeatures.length; i++) {
			  this.trackingFeatures[i].Tracking = false;
			}
		  }
	  
		  this.trackingFeatures = [];	
	}


	drawTracked() {		
		for(let i = 0; i < this._layers.length; i++) {
			let layer = this._layers[i];			
			if (layer.isVisible(this.scale)) {
			  if (layer instanceof MgFeatureLayer) {
					layer.renderTracked(this.canvas, this.canvas.context, 'source-over');
				}					
			}			
		}		
	}

	drawSelection() {		
		for(let i = 0; i < this._layers.length; i++) {
			let layer = this._layers[i];			
			if (layer.isVisible(this.scale)) {
			  if (layer instanceof MgFeatureLayer) {
					layer.renderSelection(this.canvas, this.canvas.context, 'source-over');
				}					
			}			
		}		
	}
  /**
   * @param  {} promises
   */
  async checkLayerReady(promises) {
    for(const promise of promises) {
			await promise.then(layer => 
			{
				if(layer)
				  layer.render(this.canvas);   
			});
    }
  }

  handleClick(event) {
		console.log(`handleClick: ${event.type}`);    
		event.preventDefault();
  }

  // @method getBounds(): LatLngBounds
  // Returns the geographical bounds visible in the current map view
  get bounds() {
		var bounds = this.getPixelBounds(),
		    sw = this.unproject(bounds.getBottomLeft()),
		    ne = this.unproject(bounds.getTopRight());

		return new LatLngBounds(sw, ne);
	}

  // @method getMinZoom(): Number
  // Returns the minimum zoom level of the map (if set in the `minZoom` option of the map or of any layers), or `0` by default.
  get minZoom() {
	  return this.options.minZoom === undefined ? this._layersMinZoom || 0 : this.options.minZoom;
  }

  set minZoom(value) {
    this._minZoom = value;
	}
	
	get fadeAnimation() {
	  return this.options.fadeAnimation === undefined ? this._fadeAnimation : 0;
  }

  set fadeAnimation(value) {
    this._fadeAnimation = value;
	}
	
	// @method getMaxZoom(): Number
	// Returns the maximum zoom level of the map (if set in the `maxZoom` option of the map or of any layers).
	get maxZoom() {
		return this.options.maxZoom === undefined ?
			(this._layersMaxZoom === undefined ? Infinity : this._layersMaxZoom) :
			this.options.maxZoom;
  }

  set maxZoom(value) {
    this._maxZoom = value;
  }

	// @method getSize(): Point
	// Returns the current size of the map container (in pixels).
	getSize() {
		if (!this._size || this._sizeChanged) {
			this._size = new Point(
				this._canvas.width || 0,
				this._canvas.height || 0);

			this._sizeChanged = false;
		}
		return this._size.clone();
	}

	// @method getPixelBounds(): Bounds
	// Returns the bounds of the current map view in projected pixel
	// coordinates (sometimes useful in layer and overlay implementations).
	getPixelBounds(center, zoom) {
		var topLeftPoint = this._getTopLeftPoint(center, zoom);
		return new Bounds(topLeftPoint, topLeftPoint.add(this.getSize()));
	}

	// TODO: Check semantics - isn't the pixel origin the 0,0 coord relative to
	// the map pane? "left point of the map layer" can be confusing, specially
	// since there can be negative offsets.
	// @method getPixelOrigin(): Point
	// Returns the projected pixel coordinates of the top left point of
	// the map layer (useful in custom layer and overlay implementations).
	getPixelOrigin() {
		this._checkIfLoaded();
		return this._pixelOrigin;
	}

	// @method getPixelWorldBounds(zoom?: Number): Bounds
	// Returns the world's bounds in pixel coordinates for zoom level `zoom`.
	// If `zoom` is omitted, the map's current zoom level is used.
	getPixelWorldBounds(zoom) {
		return this.options.crs.getProjectedBounds(zoom === undefined ? this.zoom : zoom);
	}

	// @section Other Methods

	// @method getPane(pane: String|HTMLElement): HTMLElement
	// Returns a [map pane](#map-pane), given its name or its HTML element (its identity).
	getPane(pane) {
		return typeof pane === 'string' ? this._panes[pane] : pane;
	}

	// @method getPanes(): Object
	// Returns a plain object containing the names of all [panes](#map-pane) as keys and
	// the panes as values.
	getPanes() {
		return this._panes;
	}

	// @method getContainer: HTMLElement
	// Returns the HTML element that contains the map.
	getContainer() {
		return this._canvas;
	}


	// @section Conversion Methods

	// @method getZoomScale(toZoom: Number, fromZoom: Number): Number
	// Returns the scale factor to be applied to a map transition from zoom level
	// `fromZoom` to `toZoom`. Used internally to help with zoom animations.
	getZoomScale(toZoom, fromZoom) {
		// TODO replace with universal implementation after refactoring projections
		var crs = this.options.crs;
		fromZoom = fromZoom === undefined ? this.zoom : fromZoom;
		return crs.scale(toZoom) / crs.scale(fromZoom);
	}

	// @method getScaleZoom(scale: Number, fromZoom: Number): Number
	// Returns the zoom level that the map would end up at, if it is at `fromZoom`
	// level and everything is scaled by a factor of `scale`. Inverse of
	// [`getZoomScale`](#map-getZoomScale).
	getScaleZoom(scale, fromZoom) {
		var crs = this.options.crs;
		fromZoom = fromZoom === undefined ? this._zoom : fromZoom;
		var zoom = crs.zoom(scale * crs.scale(fromZoom));
		return isNaN(zoom) ? Infinity : zoom;
	}

	// @method project(latlng: LatLng, zoom: Number): Point
	// Projects a geographical coordinate `LatLng` according to the projection
	// of the map's CRS, then scales it according to `zoom` and the CRS's
	// `Transformation`. The result is pixel coordinate relative to
	// the CRS origin.
	project(latlng, zoom) {
		zoom = zoom === undefined ? this._zoom : zoom;
		return this.options.crs.latLngToPoint(toLatLng(latlng), zoom);
	}

	// @method unproject(point: Point, zoom: Number): LatLng
	// Inverse of [`project`](#map-project).
	unproject(point, zoom) {
		zoom = zoom === undefined ? this._zoom : zoom;
		return this.options.crs.pointToLatLng(toPoint(point), zoom);
	}

	// @method layerPointToLatLng(point: Point): LatLng
	// Given a pixel coordinate relative to the [origin pixel](#map-getpixelorigin),
	// returns the corresponding geographical coordinate (for the current zoom level).
	layerPointToLatLng(point) {
		var projectedPoint = toPoint(point).add(this.getPixelOrigin());
		return this.unproject(projectedPoint);
	}

	// @method latLngToLayerPoint(latlng: LatLng): Point
	// Given a geographical coordinate, returns the corresponding pixel coordinate
	// relative to the [origin pixel](#map-getpixelorigin).
	latLngToLayerPoint(latlng) {
		var projectedPoint = this.project(toLatLng(latlng))._round();
		return projectedPoint._subtract(this.getPixelOrigin());
	}

	// @method wrapLatLng(latlng: LatLng): LatLng
	// Returns a `LatLng` where `lat` and `lng` has been wrapped according to the
	// map's CRS's `wrapLat` and `wrapLng` properties, if they are outside the
	// CRS's bounds.
	// By default this means longitude is wrapped around the dateline so its
	// value is between -180 and +180 degrees.
	wrapLatLng(latlng) {
		return this.options.crs.wrapLatLng(toLatLng(latlng));
	}

	// @method wrapLatLngBounds(bounds: LatLngBounds): LatLngBounds
	// Returns a `LatLngBounds` with the same size as the given one, ensuring that
	// its center is within the CRS's bounds.
	// By default this means the center longitude is wrapped around the dateline so its
	// value is between -180 and +180 degrees, and the majority of the bounds
	// overlaps the CRS's bounds.
	wrapLatLngBounds(latlng) {
		return this.options.crs.wrapLatLngBounds(toLatLngBounds(latlng));
	}

	// @method distance(latlng1: LatLng, latlng2: LatLng): Number
	// Returns the distance between two geographical coordinates according to
	// the map's CRS. By default this measures distance in meters.
	distance(latlng1, latlng2) {
		return this.options.crs.distance(toLatLng(latlng1), toLatLng(latlng2));
	}

	// @method containerPointToLayerPoint(point: Point): Point
	// Given a pixel coordinate relative to the map container, returns the corresponding
	// pixel coordinate relative to the [origin pixel](#map-getpixelorigin).
	containerPointToLayerPoint(point) { // (Point)
		return toPoint(point).subtract(this._getMapPanePos());
	}

	// @method layerPointToContainerPoint(point: Point): Point
	// Given a pixel coordinate relative to the [origin pixel](#map-getpixelorigin),
	// returns the corresponding pixel coordinate relative to the map container.
	layerPointToContainerPoint(point) { // (Point)
		return toPoint(point).add(this._getMapPanePos());
	}

	// @method containerPointToLatLng(point: Point): LatLng
	// Given a pixel coordinate relative to the map container, returns
	// the corresponding geographical coordinate (for the current zoom level).
	containerPointToLatLng(point) {
		var layerPoint = this.containerPointToLayerPoint(toPoint(point));
		return this.layerPointToLatLng(layerPoint);
	}

	// @method latLngToContainerPoint(latlng: LatLng): Point
	// Given a geographical coordinate, returns the corresponding pixel coordinate
	// relative to the map container.
	latLngToContainerPoint(latlng) {
		return this.layerPointToContainerPoint(this.latLngToLayerPoint(toLatLng(latlng)));
	}

	// @method mouseEventToContainerPoint(ev: MouseEvent): Point
	// Given a MouseEvent object, returns the pixel coordinate relative to the
	// map container where the event took place.
	mouseEventToContainerPoint(e) {
		return DomEvent.getMousePosition(e, this._container);
	}

	// @method mouseEventToLayerPoint(ev: MouseEvent): Point
	// Given a MouseEvent object, returns the pixel coordinate relative to
	// the [origin pixel](#map-getpixelorigin) where the event took place.
	mouseEventToLayerPoint(e) {
		return this.containerPointToLayerPoint(this.mouseEventToContainerPoint(e));
	}

	// @method mouseEventToLatLng(ev: MouseEvent): LatLng
	// Given a MouseEvent object, returns geographical coordinate where the
	// event took place.
	mouseEventToLatLng(e) { // (MouseEvent)
		return this.layerPointToLatLng(this.mouseEventToLayerPoint(e));
	}

	// @method fitBounds(bounds: LatLngBounds, options?: fitBounds options): this
	// Sets a map view that contains the given geographical bounds with the
	// maximum zoom level possible.
	fitBounds(bounds, options) {

		bounds = toLatLngBounds(bounds);

		if (!bounds.isValid()) {
			throw new Error('Bounds are not valid.');
		}

		var target = this._getBoundsCenterZoom(bounds, options);
		return this.setView(target.center, target.zoom, options);
	}

	// @method fitWorld(options?: fitBounds options): this
	// Sets a map view that mostly contains the whole world with the maximum
	// zoom level possible.
	fitWorld(options) {
		return this.fitBounds([[-90, -180], [90, 180]], options);
	}

	// @method panTo(latlng: LatLng, options?: Pan options): this
	// Pans the map to a given center.
	panTo(center, options) { // (LatLng)
		return this.setView(center, this._zoom, {pan: options});
	}
	

	// @method flyTo(latlng: LatLng, zoom?: Number, options?: Zoom/pan options): this
	// Sets the view of the map (geographical center and zoom) performing a smooth
	// pan-zoom animation.
	flyTo(targetCenter, targetZoom, options) {

		options = options || {};
		if (options.animate === false || !Browser.any3d) {
			return this.setView(targetCenter, targetZoom, options);
		}

		this._stop();

		var from = this.project(this.getCenter()),
		    to = this.project(targetCenter),
		    size = this.getSize(),
		    startZoom = this._zoom;

		targetCenter = toLatLng(targetCenter);
		targetZoom = targetZoom === undefined ? startZoom : targetZoom;

		var w0 = Math.max(size.x, size.y),
		    w1 = w0 * this.getZoomScale(startZoom, targetZoom),
		    u1 = (to.distanceTo(from)) || 1,
		    rho = 1.42,
		    rho2 = rho * rho;

		function r(i) {
			var s1 = i ? -1 : 1,
			    s2 = i ? w1 : w0,
			    t1 = w1 * w1 - w0 * w0 + s1 * rho2 * rho2 * u1 * u1,
			    b1 = 2 * s2 * rho2 * u1,
			    b = t1 / b1,
			    sq = Math.sqrt(b * b + 1) - b;

			    // workaround for floating point precision bug when sq = 0, log = -Infinite,
			    // thus triggering an infinite loop in flyTo
			    var log = sq < 0.000000001 ? -18 : Math.log(sq);

			return log;
		}

		function sinh(n) { return (Math.exp(n) - Math.exp(-n)) / 2; }
		function cosh(n) { return (Math.exp(n) + Math.exp(-n)) / 2; }
		function tanh(n) { return sinh(n) / cosh(n); }

		var r0 = r(0);

		function w(s) { return w0 * (cosh(r0) / cosh(r0 + rho * s)); }
		function u(s) { return w0 * (cosh(r0) * tanh(r0 + rho * s) - sinh(r0)) / rho2; }

		function easeOut(t) { return 1 - Math.pow(1 - t, 1.5); }

		var start = Date.now(),
		    S = (r(1) - r0) / rho,
		    duration = options.duration ? 1000 * options.duration : 1000 * S * 0.8;

		function frame() {
			var t = (Date.now() - start) / duration,
			    s = easeOut(t) * S;

			if (t <= 1) {
				this._flyToFrame = Util.requestAnimFrame(frame, this);

				this._move(
					this.unproject(from.add(to.subtract(from).multiplyBy(u(s) / u1)), startZoom),
					this.getScaleZoom(w0 / w(s), startZoom),
					{flyTo: true});

			} else {
				this
					._move(targetCenter, targetZoom)
					._moveEnd(true);
			}
		}

		this._moveStart(true, options.noMoveStart);

		frame.call(this);
		return this;
	}

	// @method flyToBounds(bounds: LatLngBounds, options?: fitBounds options): this
	// Sets the view of the map with a smooth animation like [`flyTo`](#map-flyto),
	// but takes a bounds parameter like [`fitBounds`](#map-fitbounds).
	flyToBounds(bounds, options) {
		var target = this._getBoundsCenterZoom(bounds, options);
		return this.flyTo(target.center, target.zoom, options);
	}

	// @method setMaxBounds(bounds: Bounds): this
	// Restricts the map view to the given bounds (see the [maxBounds](#map-maxbounds) option).
	setMaxBounds(bounds) {
		bounds = toLatLngBounds(bounds);

		if (!bounds.isValid()) {
			this.options.maxBounds = null;
			return this.off('moveend', this._panInsideMaxBounds);
		} else if (this.options.maxBounds) {
			this.off('moveend', this._panInsideMaxBounds);
		}

		this.options.maxBounds = bounds;

		if (this._loaded) {
			this._panInsideMaxBounds();
		}

		return this.on('moveend', this._panInsideMaxBounds);
	}

	// @method setMinZoom(zoom: Number): this
	// Sets the lower limit for the available zoom levels (see the [minZoom](#map-minzoom) option).
	setMinZoom(zoom) {
		var oldZoom = this.options.minZoom;
		this.options.minZoom = zoom;

		if (this._loaded && oldZoom !== zoom) {
			this.fire('zoomlevelschange');

			if (this.getZoom() < this.options.minZoom) {
				return this.setZoom(zoom);
			}
		}

		return this;
	}

	// @method setMaxZoom(zoom: Number): this
	// Sets the upper limit for the available zoom levels (see the [maxZoom](#map-maxzoom) option).
	setMaxZoom(zoom) {
		var oldZoom = this.options.maxZoom;
		this.options.maxZoom = zoom;

		if (this._loaded && oldZoom !== zoom) {
			this.fire('zoomlevelschange');

			if (this.getZoom() > this.options.maxZoom) {
				return this.setZoom(zoom);
			}
		}

		return this;
	}

	// @method panInsideBounds(bounds: LatLngBounds, options?: Pan options): this
	// Pans the map to the closest view that would lie inside the given bounds (if it's not already), controlling the animation using the options specific, if any.
	panInsideBounds(bounds, options) {
		this._enforcingBounds = true;
		var center = this.getCenter(),
		    newCenter = this._limitCenter(center, this._zoom, toLatLngBounds(bounds));

		if (!center.equals(newCenter)) {
			this.panTo(newCenter, options);
		}

		this._enforcingBounds = false;
		return this;
	}

	// @method panInside(latlng: LatLng, options?: options): this
	// Pans the map the minimum amount to make the `latlng` visible. Use
	// `padding`, `paddingTopLeft` and `paddingTopRight` options to fit
	// the display to more restricted bounds, like [`fitBounds`](#map-fitbounds).
	// If `latlng` is already within the (optionally padded) display bounds,
	// the map will not be panned.
	panInside(latlng, options) {
		options = options || {};

		var paddingTL = toPoint(options.paddingTopLeft || options.padding || [0, 0]),
		    paddingBR = toPoint(options.paddingBottomRight || options.padding || [0, 0]),
		    center = this.getCenter(),
		    pixelCenter = this.project(center),
		    pixelPoint = this.project(latlng),
		    pixelBounds = this.getPixelBounds(),
		    halfPixelBounds = pixelBounds.getSize().divideBy(2),
		    paddedBounds = toBounds([pixelBounds.min.add(paddingTL), pixelBounds.max.subtract(paddingBR)]);

		if (!paddedBounds.contains(pixelPoint)) {
			this._enforcingBounds = true;
			var diff = pixelCenter.subtract(pixelPoint),
			    newCenter = toPoint(pixelPoint.x + diff.x, pixelPoint.y + diff.y);

			if (pixelPoint.x < paddedBounds.min.x || pixelPoint.x > paddedBounds.max.x) {
				newCenter.x = pixelCenter.x - diff.x;
				if (diff.x > 0) {
					newCenter.x += halfPixelBounds.x - paddingTL.x;
				} else {
					newCenter.x -= halfPixelBounds.x - paddingBR.x;
				}
			}
			if (pixelPoint.y < paddedBounds.min.y || pixelPoint.y > paddedBounds.max.y) {
				newCenter.y = pixelCenter.y - diff.y;
				if (diff.y > 0) {
					newCenter.y += halfPixelBounds.y - paddingTL.y;
				} else {
					newCenter.y -= halfPixelBounds.y - paddingBR.y;
				}
			}
			this.panTo(this.unproject(newCenter), options);
			this._enforcingBounds = false;
		}
		return this;
	}	

	// @section Methods for modifying map state
	// @method stop(): this
	// Stops the currently running `panTo` or `flyTo` animation, if any.
	stop() {
		this.setZoom(this._limitZoom(this._zoom));
		if (!this.options.zoomSnap) {
			this.fire('viewreset');
		}
		return this._stop();
	}

	// @section Geolocation methods
	// @method locate(options?: Locate options): this
	// Tries to locate the user using the Geolocation API, firing a [`locationfound`](#map-locationfound)
	// event with location data on success or a [`locationerror`](#map-locationerror) event on failure,
	// and optionally sets the map view to the user's location with respect to
	// detection accuracy (or to the world view if geolocation failed).
	// Note that, if your page doesn't use HTTPS, this method will fail in
	// modern browsers ([Chrome 50 and newer](https://sites.google.com/a/chromium.org/dev/Home/chromium-security/deprecating-powerful-features-on-insecure-origins))
	// See `Locate options` for more details.
	locate(options) {

		options = this._locateOptions = Util.extend({
			timeout: 10000,
			watch: false
			// setView: false
			// maxZoom: <Number>
			// maximumAge: 0
			// enableHighAccuracy: false
		}, options);

		if (!('geolocation' in navigator)) {
			this._handleGeolocationError({
				code: 0,
				message: 'Geolocation not supported.'
			});
			return this;
		}

		var onResponse = Util.bind(this._handleGeolocationResponse, this),
		    onError = Util.bind(this._handleGeolocationError, this);

		if (options.watch) {
			this._locationWatchId =
			        navigator.geolocation.watchPosition(onResponse, onError, options);
		} else {
			navigator.geolocation.getCurrentPosition(onResponse, onError, options);
		}
		return this;
	}

	// @method stopLocate(): this
	// Stops watching location previously initiated by `map.locate({watch: true})`
	// and aborts resetting the map view if map.locate was called with
	// `{setView: true}`.
	stopLocate() {
		if (navigator.geolocation && navigator.geolocation.clearWatch) {
			navigator.geolocation.clearWatch(this._locationWatchId);
		}
		if (this._locateOptions) {
			this._locateOptions.setView = false;
		}
		return this;
	}
  	
	_findEventTargets(e, type) {
		var targets = [],
		    target,
		    isHover = type === 'mouseout' || type === 'mouseover',
		    src = e.target || e.srcElement,
		    dragging = false;

		while (src) {
			target = this._targets[Util.stamp(src)];
			if (target && (type === 'click' || type === 'preclick') && !e._simulated && this._draggableMoved(target)) {
				// Prevent firing click after you just dragged an object.
				dragging = true;
				break;
			}
			if (target && target.listens(type, true)) {
				if (isHover && !DomEvent.isExternalTarget(src, e)) { break; }
				targets.push(target);
				if (isHover) { break; }
			}
			if (src === this._canvas) { break; }
			src = src.parentNode;
		}
		if (!targets.length && !dragging && !isHover && DomEvent.isExternalTarget(src, e)) {
			targets = [this];
		}
		return targets;
	}

	
  _fireDOMEvent(e, type, targets) {

		if (e.type === 'click') {
			// Fire a synthetic 'preclick' event which propagates up (mainly for closing popups).
			// @event preclick: MouseEvent
			// Fired before mouse click on the map (sometimes useful when you
			// want something to happen on click before any existing click
			// handlers start running).
			var synth = Util.extend({}, e);
			synth.type = 'preclick';
			this._fireDOMEvent(synth, synth.type, targets);
		}

		if (e._stopped) { return; }

		// Find the layer the event is propagating from and its parents.
		targets = (targets || []).concat(this._findEventTargets(e, type));

		if (!targets.length) { return; }

		var target = targets[0];
		if (type === 'contextmenu' && target.listens(type, true)) {
			DomEvent.preventDefault(e);
		}

		var data = {
			originalEvent: e
		};

		if (e.type !== 'keypress' && e.type !== 'keydown' && e.type !== 'keyup') {
			var isMarker = target.getLatLng && (!target._radius || target._radius <= 10);
			data.containerPoint = isMarker ?
				this.latLngToContainerPoint(target.getLatLng()) : this.mouseEventToContainerPoint(e);
			data.layerPoint = this.containerPointToLayerPoint(data.containerPoint);
			data.latlng = isMarker ? target.getLatLng() : this.layerPointToLatLng(data.layerPoint);
		}

    const _mouseEvents = ['click', 'dblclick', 'mouseover', 'mouseout', 'contextmenu'];

		for (var i = 0; i < targets.length; i++) {
			targets[i].fire(type, data, true);
			if (data.originalEvent._stopped ||
				(targets[i].options.bubblingMouseEvents === false && Util.indexOf(_mouseEvents, type) !== -1)) { return; }
		}
	}

	_draggableMoved(obj) {
		obj = obj.dragging && obj.dragging.enabled() ? obj : this;
		return (obj.dragging && obj.dragging.moved()) || (this.boxZoom && this.boxZoom.moved());
	}

	// @section Other Methods

	// @method whenReady(fn: Function, context?: Object): this
	// Runs the given function `fn` when the map gets initialized with
	// a view (center and zoom) and at least one layer, or immediately
	// if it's already initialized, optionally passing a function context.
	whenReady(callback, context) {
		if (this._loaded) {
			callback.call(context || this, {target: this});
		} else {
			this.on('load', callback, context);
		}
		return this;
	}


	// private methods for getting map state

	_getMapPanePos() {
		return DomUtil.getPosition(this._canvas) || new Point(0, 0);
	}

	_moved() {
		var pos = this._getMapPanePos();
		return pos && !pos.equals([0, 0]);
	}

	_getTopLeftPoint(center, zoom) {
		var pixelOrigin = center && zoom !== undefined ?
			this._getNewPixelOrigin(center, zoom) :
			this.getPixelOrigin();
		return pixelOrigin.subtract(this._getMapPanePos());
	}

	_getNewPixelOrigin(center, zoom) {
		var viewHalf = this.getSize()._divideBy(2);
		return this.project(center, zoom)._subtract(viewHalf)._add(this._getMapPanePos())._round();
	}

	_latLngToNewLayerPoint(latlng, zoom, center) {
		var topLeft = this._getNewPixelOrigin(center, zoom);
		return this.project(latlng, zoom)._subtract(topLeft);
	}

	_latLngBoundsToNewLayerBounds(latLngBounds, zoom, center) {
		var topLeft = this._getNewPixelOrigin(center, zoom);
		return toBounds([
			this.project(latLngBounds.getSouthWest(), zoom)._subtract(topLeft),
			this.project(latLngBounds.getNorthWest(), zoom)._subtract(topLeft),
			this.project(latLngBounds.getSouthEast(), zoom)._subtract(topLeft),
			this.project(latLngBounds.getNorthEast(), zoom)._subtract(topLeft)
		]);
	}

	// @section Methods for modifying map state
	// @method setView(center: LatLng, scale: Number): this
	// Sets the view of the map (geographical center and scale) 
	setView(center, scale, mapOptionType) {
		
		if(this.pendingSetExtentsParam != null) {		
			this.setInitialExtents(MapEnums.SourceEvents.SetView, this.pendingSetExtentsParam, true);
			return;			
		}

		//zoom = zoom === undefined ? this._zoom : this._limitZoom(zoom);
		this.center = center;
		this.mapOptionType = mapOptionType;		
		var findNewExtents = false;

		// MapActivity with Context (search) but no query fields - means auto show results without clicking "Search" button
		// We need to get new extents according to feature geometry bounds
		if(this.mapOptionType == MapEnums.MapOptionTypes.MAPACTIVITY && this.options.Context && !this.options.HasQueryFields && this._contextLayer) {
			findNewExtents = true;
		}

		if(findNewExtents) {							
			this._contextLayer.searchFeatures("", -1, false, 0, 0).then(function(params) {
				// this.convertBoundsToScale(params.bounds);				
				// this.updateExtents(this.scale / MgMap.DPI / this.getInchesPerUnit(0), true);    				
				this.calculateOffset();
				this.calculateExtents(MapEnums.SourceEvents.SetView);
				this.refreshView(false);
			}.bind(this));
		}
		else {
			this._zoom = Util.scaleToZoomLevel(scale);
			this.center = this._limitCenter(toLatLng(this.center), this._zoom, this.options.maxBounds);
			this.scale = scale; //Util.zoomLevelToScale(this._zoom);
			// this.scale = Util.zoomLevelToScale(this._zoom);
			// this.updateExtents(MapEnums.SourceEvents.SetView, this.scale / MgMap.DPI / this.getInchesPerUnit(0));    
			this.calculateOffset();
			this.calculateExtents(MapEnums.SourceEvents.SetView);
			this.refreshView(false);
		}

		this._initialExtentsDone = true;
				
	}

	setInitialExtents(sourceEvent, extents, skipRefreshView = false, extentsPercent = null) {
				
		if(this.pendingSetExtentsParam != null) {		
			extents = this.pendingSetExtentsParam.extents;
			this.pendingSetExtentsParam = null;
		}

		let xScale = (extents.MaxX - extents.MinX) / this.rcMap.Width * MgMap.DPI * this.getInchesPerUnit(0);
		let yScale = (extents.MaxY - extents.MinY) / this.rcMap.Height * MgMap.DPI * this.getInchesPerUnit(0);

		if (extentsPercent && extentsPercent != "100"){
			let zoomedInScale = Math.max(xScale, yScale);

			let overscan = +extentsPercent / 100.0;
			 xScale = zoomedInScale * extents.Width * overscan / extents.Width;
			 yScale = zoomedInScale * extents.Height * overscan / extents.Height;
		}


		this.center = toLatLng((extents.MinY + extents.MaxY) / 2.0, (extents.MinX + extents.MaxX) / 2.0);
		this.scale = Math.max(xScale, yScale);
		this.homeCenter = Object.assign({},this.center, {X: this.center.X, Y: this.center.Y});
		
		// getting weird behaviour from this, must deep copy
		// this.homeScale =  Object.assign({},this.scale);

		this.homeScale = JSON.parse(JSON.stringify(this.scale));


		this.calculateExtents(sourceEvent);	
		// this.updateExtents(sourceEvent, this.scale / MgMap.DPI / this.getInchesPerUnit(0));

		if (!skipRefreshView){
			this.refreshView(true);	
		}
	
		this._initialExtentsDone = true;		
	}

	convertBoundsToScale(extents) {
		if (extents != null) {
		
			var MinX = extents.SouthWest.lng;
			var MaxY = extents.SouthWest.lat;
			var MaxX = extents.NorthEast.lng;
			var MinY = extents.NorthEast.lat;

			this.center = toLatLng((extents.MinY + extents.MaxY) / 2.0, (extents.MinX + extents.MaxX) / 2.0);

			let xScale = Math.abs((MaxX - MinX) / this.rcMap.Width * MgMap.DPI * this.getInchesPerUnit(0));
			let yScale = Math.abs((MaxY - MinY) / this.rcMap.Height * MgMap.DPI * this.getInchesPerUnit(0));

			this.scale = Math.max(xScale, yScale);
		}
			
	}

	// @method setZoom(zoom: Number, options?: Zoom/pan options): this
	// Sets the zoom of the map.
	setZoom(zoom, options) {
		if (!this._loaded) {
			this._zoom = zoom;
			return this;
		}
		this.setView(this.center, zoom, {zoom: options});
		this.refreshView();
	}

	set onZoomCallback(callback) {
		this.cbZoom = callback;
	}

	pointToWorldCoordinate(World, Client, Cellsize, point) {      
		return new LatLng(World.MaxY - ((point.y - Client.MinY) * Cellsize), (point.x * Cellsize) + World.MinX);      
	}

	worldToClient(World, Cellsize, lat, lng) {
		return new Point(Math.trunc((lng - World.MinX) / Cellsize), Math.trunc((World.MaxY - lat) / Cellsize));
	}

	zoomIn(e) {
		//if (this.promises != null && this.promises.length > 0)
		//  return;
		let w = this.canvas.width;
		let h = this.canvas.height;

		let newW = w * 0.5;
		let newH = h * 0.5;

		let ptStart = new Point(newW, newH);
		let ptEnd = new Point(w - newW, h - newH);
		
		let rcMap = new Bounds( ptStart, ptEnd);
   
		//let latLng1 = toLatLng(this.extents.MaxY - (ptStart.y) * this.cellsize, (ptStart.x * this.cellsize) + this.extents.MinX);      
		//let latLng2 = toLatLng(this.extents.MaxY - (ptEnd.y) * this.cellsize, (ptEnd.x * this.cellsize) + this.extents.MinX);
		//let extents = new LatLngBounds(latLng1, latLng2);

		this._zoom = Util.scaleToZoomLevel(this._scale / 1.5);	
		if (this._zoom < this.maxZoom) {
			this._scale /= 1.5;

			//this.canvas.drawCroppedCanvas(ptStart.x, ptStart.y, ptEnd.x - ptStart.x, ptEnd.y - ptEnd.y, 0.5, rcMap.height, rcMap.width);
			this.canvas.clearAll();
			// if (e) 

			if (e && !this.loadFeatures){
				this.calculateExtents(MapEnums.SourceEvents.Zoom, (e.offsetX - newW), newH - e.offsetY);
			} else{
				this._scale *= 1.5;
				this.calculateExtents(MapEnums.SourceEvents.Zoom);
			} 

			this.calculateExtents(MapEnums.SourceEvents.PanDragging);				
			this.refreshView(false);
		}		

		if (this.cbZoom) this.cbZoom(this.scale);

	}

	ZoomALittle(e) {

		this.zoomOut(e);
	}

	zoomOut(e) {
		//if (this.promises != null && this.promises.length > 0)
		//	return;
			
		let w = this.canvas.width;
		let h = this.canvas.height;

		let newW = w * 0.5;
		let newH = h * 0.5;

		let ptStart = new Point(-1 * newW, -1 * newH);
		let ptEnd = new Point(w + newW, h + newH);

		let rcMap = new Bounds( ptStart, ptEnd);		
		this._zoom = Util.scaleToZoomLevel(this._scale * 1.5);
		
		console.error("ZoomZoom (e.offsetX", e.offsetX)
		console.error("ZoomZoom (e.offsetY", e.offsetY)


		if (this._zoom > this.minZoom) {
			this._scale *= 1.5;

			//this.canvas.drawCroppedCanvas(ptStart.x, ptStart.y, 0, 0, 1.5, rcMap.height, rcMap.width);
			this.canvas.clearAll();
			if (e && !this.loadFeatures){
				this.calculateExtents(MapEnums.SourceEvents.Zoom, (e.offsetX - newW), newH - e.offsetY);
			} else{
				this._scale /= 1.5;
				this.calculateExtents(MapEnums.SourceEvents.Zoom);
			} 

			this.calculateExtents(MapEnums.SourceEvents.PanDragging);				
			this.refreshView(false);
		}	

		if (this.cbZoom)
		  this.cbZoom(this.scale);	


	}

	showLayer(layer) {
		var index =  parseInt(layer);
		if (index != NaN) {
			let layer = this._layers[index];

		  layer.visible = !layer.visible;
			layer.cancelRequest();
			
			if (layer.visible == false)
			  layer.clearItems();

			this.refreshView(true);			
		}
	}  
	
	
	_stop() {
		Util.cancelAnimFrame(this._flyToFrame);
		if (this._panAnim) {
			this._panAnim.stop();
		}
		return this;
	}

	_panInsideMaxBounds() {
		if (!this._enforcingBounds) {
			this.panInsideBounds(this.options.maxBounds);
		}
	}

	_checkIfLoaded() {
		if (!this._loaded) {
			throw new Error('Set map center and zoom first.');
		}
  }
    	
	// @section Private fuctions Layers
	// layer point of the current center
	_getCenterLayerPoint() {
		return this.containerPointToLayerPoint(this.getSize()._divideBy(2));
	}

	// offset of the specified place to the current center in pixels
	_getCenterOffset(latlng) {
		return this.latLngToLayerPoint(latlng).subtract(this._getCenterLayerPoint());
	}

	// adjust center for view to get inside bounds
	_limitCenter(center, zoom, bounds) {

		if (!bounds) { return center; }

		var centerPoint = this.project(center, zoom),
		    viewHalf = this.getSize().divideBy(2),
		    viewBounds = new Bounds(centerPoint.subtract(viewHalf), centerPoint.add(viewHalf)),
		    offset = this._getBoundsOffset(viewBounds, bounds, zoom);

		// If offset is less than a pixel, ignore.
		// This prevents unstable projections from getting into
		// an infinite loop of tiny offsets.
		if (offset.round().equals([0, 0])) {
			return center;
		}

		return this.unproject(centerPoint.add(offset), zoom);
	}

	// adjust offset for view to get inside bounds
	/**
	 * @param  {} offset
	 * @param  {} bounds
	 */
	_limitOffset(offset, bounds) {
		if (!bounds) { return offset; }

		var viewBounds = this.getPixelBounds(),
		    newBounds = new Bounds(viewBounds.min.add(offset), viewBounds.max.add(offset));

		return offset.add(this._getBoundsOffset(newBounds, bounds));
	}

	// returns offset needed for pxBounds to get inside maxBounds at a specified zoom
	/**
	 * @param  {} pxBounds
	 * @param  {} maxBounds
	 * @param  {} zoom
	 */
	_getBoundsOffset(pxBounds, maxBounds, zoom) {
		var projectedMaxBounds = toBounds(
		        this.project(maxBounds.getNorthEast(), zoom),
		        this.project(maxBounds.getSouthWest(), zoom)
		    ),
		    minOffset = projectedMaxBounds.min.subtract(pxBounds.min),
		    maxOffset = projectedMaxBounds.max.subtract(pxBounds.max),

		    dx = this._rebound(minOffset.x, -maxOffset.x),
		    dy = this._rebound(minOffset.y, -maxOffset.y);

		return new Point(dx, dy);
	}
	
	/**
	 * @param  {} left
	 * @param  {} right
	 */
	_rebound(left, right) {
		return left + right > 0 ?
			Math.round(left - right) / 2 :
			Math.max(0, Math.ceil(left)) - Math.max(0, Math.floor(right));
  }
  
	/**
	 * @param  {} zoom
	 */
	_limitZoom(zoom) {
		var min = this.minZoom,
		    max = this.maxZoom,
		    snap = Browser.any3d ? this.options.zoomSnap : 1;
		if (snap) {
			zoom = Math.round(zoom / snap) * snap;
		}
		return Math.max(min, Math.min(max, zoom));
	}

	// @section Map Control Extents
	updateExtents(sourceEvent, newCellsize, excludeContextLayer = false, triggerCallback = true ) {
		var halfClientWidth = (this.rcMap.Width-1) / 2.0;
		var  halfClientHeight = (this.rcMap.Height-1) / 2.0;
		//const onefourthW = halfClientWidth / 2;
		//const onefourthH = halfClientHeight / 2;

		//halfClientWidth += onefourthW;
		//halfClientHeight += onefourthH;

		var newExtents = new Envelope(this.center.X - (newCellsize * halfClientWidth), 
																	this.center.X + (newCellsize * halfClientWidth), 
																	this.center.Y - (newCellsize * halfClientHeight), 
																	this.center.Y + (newCellsize * halfClientHeight));

		if (this.extents == null || !newExtents.equals(this.extents))
		{
			this.extents = newExtents;
			this.cellsize = newCellsize;

			let boundingClientRect = this._div.getBoundingClientRect();
			this.boundingRectOffsetX = boundingClientRect.left;
		  this.boundingRectOffsetY = boundingClientRect.top;        		
		}

		if (triggerCallback && this.cbUpdateExtents) {
			this.cbUpdateExtents(this.extents);
			 
		}

		// not sure how this is possible
		else if (this.cellsize != newCellsize)
		{
			this.cellsize = newCellsize;
		}

		if (this.measurement)
			this.measurement.updateExtents();

		if (this.geometryEditor)
			this.geometryEditor.updateExtents();	
			
		//this.canvas.clearOffscreen();
		//this.invalidate();
		for (var index = 0; index < this._layers.length; index++) {
			let layer = this._layers[index];
			if (layer.isVisible(this.scale)) {
				if(excludeContextLayer && layer._options.ContextLayer ) {
					//do nothing Layer was already retrieved in setView
				}
				else
					layer.onExtentsChanged(sourceEvent, this.rcMap, newExtents, this.cellsize, this.scale, this.canvas);
			}
				
		}

		if (this.cbZoom)
		  this.cbZoom(this.scale);			
	}

	get pendingSetExtentsParam() { return this._pendingSetExtentsParam; }
	set pendingSetExtentsParam(value) { this._pendingSetExtentsParam = value; }

	//Restore extents
	setExtents(sourceEvent, newExtents, triggerCallback = false) {

		if(!this._initialExtentsDone) {
			this.pendingSetExtentsParam = {extents:  newExtents, triggerCallback: triggerCallback};
			return;
		}	
		
		// Clear pending because it will now be processed
		if(this.pendingSetExtentsParam) 
			this.pendingSetExtentsParam = null;

		this.extents = new LatLngBounds(toLatLng(newExtents.MinY, newExtents.MinX), toLatLng(newExtents.MaxY, newExtents.MaxX));
		
		let xScale = (newExtents.MaxX - newExtents.MinX) / this.rcMap.Width * MgMap.DPI * this.getInchesPerUnit(0);
		let yScale = (newExtents.MaxY - newExtents.MinY) / this.rcMap.Height * MgMap.DPI * this.getInchesPerUnit(0);
		
		this._center = toLatLng((newExtents.MinY + newExtents.MaxY) / 2.0, (newExtents.MinX + newExtents.MaxX) / 2.0)
		this.scale = Math.max(xScale, yScale);
		
		this.resize(sourceEvent, triggerCallback);		

		if (triggerCallback && this.cbUpdateExtents) {   
			this.cbUpdateExtents(this.extents);
			 
		}
	}

	_getTiledPixelBounds(center) {
		var map = this,
		    mapZoom = map.zoom,
		    scale = map.getZoomScale(mapZoom, this._tileZoom),
		    pixelCenter = map.project(center, this._tileZoom).floor(),
		    halfSize = map.getSize().divideBy(scale * 2);

		return new Bounds(pixelCenter.subtract(halfSize), pixelCenter.add(halfSize));
	}

	getInchesPerUnit(center_lat) {
		var lat_adj = 1.0, ipu = 1.0;

		switch (this.units)
		{
			case MgMap.MS_METERS:
			case MgMap.MS_KILOMETERS:
			case MgMap.MS_MILES:
			case MgMap.MS_INCHES:
			case MgMap.MS_FEET:
				ipu = MgMap.InchesPerUnit[this.units];
				break;
			case MgMap.MS_DD:
				ipu = MgMap.InchesPerUnit[this.units] * lat_adj;
				break;
			default:
				break;
		}

		return ipu;
	}	

	// @section Mouse Events and Handlers
  calculateExtents(sourceEvent, xMouseOffset, yMouseOffset, triggerCallback = true) {
		let newCellsize = (this.scale / MgMap.DPI) / this.getInchesPerUnit(0);

		xMouseOffset = xMouseOffset === undefined ? 0.0 : xMouseOffset;
		yMouseOffset = yMouseOffset === undefined ? 0.0 : yMouseOffset;
		
		// when using the scroll wheel, we try to point under the mouse at the same location
		this.center.X += ((xMouseOffset * this.cellsize) - (xMouseOffset * newCellsize));
		this.center.Y += ((yMouseOffset * this.cellsize) - (yMouseOffset * newCellsize));

		this.updateExtents(sourceEvent, newCellsize, false, triggerCallback);
	}

	zoomHome(sourceEvent){
		this.center.X = this.homeCenter.X;
		this.center.Y = this.homeCenter.Y;
		this.scale = this.homeScale;
		this.calculateExtents(sourceEvent);
			this.refreshView(false);
	}
	
	/* 
		Sets dragstate depending on keydown (ctrl or shift)
		Runs prior to onMouseUp
	*/

	onMouseDown(e) {
		this.mouseX = undefined;
		this.mouseY = undefined;

		this.mouseDown = true;

		if (e.which == MOUSE_BUTTON_LEFT && this.extents != null) {
			this.ptDragStart = new Point(e.clientX, e.clientY);

			if (e.ctrlKey)
			  this.dragState = DragStates.ZoomStart;
			else if (e.shiftKey)
			  this.dragState = DragStates.SelectStart;
			else
			  this.dragState = DragStates.PanStart;

			this.canvas.pan.start.x = e.clientX;
	    	this.canvas.pan.start.y = e.clientY;

			if (this.measureDistanceMode) {				
				if (this.measurement == null){
					this.measurement = new Measurement(this);
					this.measurement.setDistanceType(this.options.MeasureTool);

				}
					
				if (this.measurement.handleMouseDown(e.clientX - this.offsetX, e.clientY - this.offsetY, this._canvas.canvas.style.cursor === 'crosshair'))
				  this.dragState = DragStates.MeasurePanStart;
			}
			
			if (this.geometryEditor != null) {									
				if (this.geometryEditor.handleMouseDown(e.clientX - this.offsetX, e.clientY - this.offsetY, this._canvas.canvas.style.cursor === 'crosshair')) 
				  this.dragState = DragStates.GeometryEditPanStart;
			}

			if (this.dragState == DragStates.SelectStart) {
				this.removeTooltip();
		  }
				
			this.Capture = true;
		}
	}
		
	onMouseMove(e) {

		if (this.dragState != DragStates.None) {
			if (!this.isLeftButtonPressed(e))
			  this.dragState = DragStates.None;

			if (this.dragState == DragStates.PanStart) {
				if (Math.abs(e.clientX - this.ptDragStart.x) > MIN_DRAG_WIDTH || Math.abs(e.clientY - this.ptDragStart.y) > MIN_DRAG_HEIGHT) {
					this.dragState = DragStates.PanDragging;
				}
			}
			else if (this.dragState == DragStates.MeasurePanStart) {
				if (Math.abs(e.clientX - this.ptDragStart.x) > MIN_DRAG_WIDTH || Math.abs(e.clientY - this.ptDragStart.y) > MIN_DRAG_HEIGHT) {
					this.dragState = DragStates.MeasurePanDragging;
				}
			}
			else if (this.dragState == DragStates.GeometryEditPanStart) {
				if (Math.abs(e.clientX - this.ptDragStart.x) > MIN_DRAG_WIDTH || Math.abs(e.clientY - this.ptDragStart.y) > MIN_DRAG_HEIGHT) {
					this.dragState = DragStates.GeometryEditPanDragging;
				}
			}
			else if (this.dragState == DragStates.ZoomStart) {
				if (Math.abs(e.clientX - this.ptDragStart.x) > MIN_DRAG_WIDTH || Math.abs(e.clientY - this.ptDragStart.y) > MIN_DRAG_HEIGHT) {
					this.dragState = DragStates.ZoomDragging;
					this.ptDragCurrent = this.ptDragStart;
				}
			}
			else if (this.dragState == DragStates.SelectStart) {
				if (Math.abs(e.clientX - this.ptDragStart.x) > MIN_DRAG_WIDTH || Math.abs(e.clientY - this.ptDragStart.y) > MIN_DRAG_HEIGHT) {
					this.dragState = DragStates.SelectDragging;
					this.ptDragCurrent = this.ptDragStart;
				}
			}
			
			if (this.dragState == DragStates.MeasurePanDragging) {
				if (this.measurement)
					this.measurement.handleMouseDragging(e.clientX - this.offsetX, e.clientY - this.offsetY);					
			}
			else if (this.dragState == DragStates.GeometryEditPanDragging) {
				if (this.geometryEditor)
				  this.geometryEditor.handleMouseDragging(e.clientX - this.offsetX, e.clientY - this.offsetY);	
			}
			else if (this.dragState == DragStates.PanDragging) {									
				// offset the centre point
				this._center.X += (this.ptDragStart.x - e.clientX) * this.cellsize;
				this._center.Y += (e.y - this.ptDragStart.y) * this.cellsize;
				this.ptDragStart = new Point(e.clientX, e.clientY);

				var offsetX  = e.clientX - this.canvas.pan.start.x;
				var offsetY  = e.clientY - this.canvas.pan.start.y;
				this.canvas.pan.offset.x = this.canvas.global.offset.x + offsetX;
				this.canvas.pan.offset.y = this.canvas.global.offset.y + offsetY;

				this.canvas.isPanning = true;
				this.invalidate(offsetX, offsetY);				
			}
			else if (this.dragState == DragStates.ZoomDragging || this.dragState == DragStates.SelectDragging) {				
				this.ptSelectionStart = new Point(this.ptDragStart.x - this.offsetX, this.ptDragStart.y - this.offsetY);
				this.ptSelectionEnd = new Point(e.clientX - this.offsetX, e.clientY - this.offsetY);				
			}			
		}
		else if (this.extents != null) {		
			e.preventDefault();
	    	e.stopPropagation();
	
			let mouseX = e.clientX - this.offsetX;
			let mouseY = e.clientY - this.offsetY;

			if (this.mouseX == undefined || this.mouseY == undefined ||
				  (Math.abs(mouseX - this.mouseX) > 2 && 
			    Math.abs(mouseY - this.mouseY) > 2)) {
			  this.mouseX = mouseX;
			  this.mouseY = mouseY;

				//if (this.promises == null || this.promises.length == 0)
				this.mouseMoveCallback(e);
				    
			}
			
		}
  }

  /*
  		Called right after onMouseDown, which sets the dragState
  		if (this.dragState == DragStates.SelectStart) the shift key is being held
  */

	onMouseUp(e) {		
		console.log('map - onMouseUp(e)');
        this.removeOrAdd = "";
		this.canvas.isPanning = false;		

		if (this.dragState != DragStates.None) {
			if (this.dragState == DragStates.MeasurePanStart || 
				  this.dragState == DragStates.GeometryEditPanStart ||
				  this.dragState == DragStates.PanStart) { 
				// this is a click
				// check if zoom in or zoom out button is clicked
				if (e.toElement.title.indexOf('Zoom') > -1 || e.toElement.innerHTML.length >= 4)
					return;
				
				this.mouseClicks++;
				let __this =  this;
				if (this.mouseClicks == 1) {
					setTimeout(function(){
						if(__this.mouseClicks == 1) {
							__this.onMouseSingleClick(e);
						} else {
							__this.onMouseDoubleClick(e);
						}
						__this.mouseClicks = 0;
						
						// if in draw mode then ensure all selected markup is cleared (we may have selected markup if did Shift drag to draw a selection rectangle) 
						let inDrawMode = __this._canvas.canvas.style.cursor === 'crosshair';						
						if (inDrawMode && __this.geometryEditor != null && __this.geometryEditor.markupsWithinSelectionRectangle != null && __this.geometryEditor.markupsWithinSelectionRectangle.length > 0)
							__this.geometryEditor.clearSelectedMarkup();
					}, 300);
				}							
			}			
			else if (this.dragState == DragStates.GeometryEditPanDragging) {
				if (this.geometryEditor != null)
				  this.geometryEditor.handleMouseUp(e.clientX - this.offsetX, e.clientY - this.offsetY);
			}

			else if (this.dragState == DragStates.SelectStart || this.dragState == DragStates.ZoomStart){

				// user is holding shift or ctrl key, but not dragging only clicking, so select whatever feature or markup that is under there
				
				let x = e.clientX - this.offsetX;
				let y = e.clientY - this.offsetY;		
				var hitItem = null;
				let hitLayers = [];
				// this layer retrieval for loop happens many times throughout the code
				// detection should start from the topmost layer to the bottom
				for(let i = this._layers.length-1; i >= 0; i--) {
					let layer = this._layers[i];
					if (layer instanceof MgFeatureLayer && layer.isVisible(this.scale) && !layer.isDownloading) {
						if ((hitItem = layer.getHitItem(x, y)) != null) {
							hitLayers.push(layer);
							break;
						}										
					}
				}
				if (hitItem){

					// if it's ctrl clicking + it's already selected, we have to unselect it

					if (this.dragState == DragStates.ZoomStart && this.selectedFeatures.hasOwnProperty(hitItem.id)){
						this.selectedFeatures[hitItem.id] = null;
						
						for (let i = hitLayers.length-1; i>=0; i--){
							hitLayers[i].removeItemFromSelection(hitItem);
						}
						 
						this.removeOrAdd = "Remove";

					} else {
						this.selectedFeatures[hitItem.id] = hitItem;
						this.selectedFeatures[hitItem.id].Selected = true;

						 
						this.removeOrAdd = "Add";

						this.featureSelectedEvent.next(hitItem);
						if (this.cbSelectFeatures) this.cbSelectFeatures(hitItem);
					}
				} else {
					for (let i = hitLayers.length-1; i>=0; i--){
						hitLayers[i].clearSelection();
 
					}
					this.removeOrAdd = "Clear";
				}

				if (this.geometryEditor != null) {
					let selectedMarkups = this.geometryEditor.addToSelectedMarkup(x, y);
					if (selectedMarkups.length == 0){
						this.geometryEditor.clearSelectedMarkup();
					}
				}

				this.invalidate();
				this.drawTracked(null);
				this.drawSelection(null);

			}
			else if (this.dragState == DragStates.SelectDragging) { 
				
				// ensure the previously selected geometries are de-selected
				// why are we checking if markupsWithinSelectRectangle has something in it? Shouldn't we clear it if a new rectangle is being drawn?
				// if (this.geometryEditor != null && this.geometryEditor.markupsWithinSelectionRectangle != null && this.geometryEditor.markupsWithinSelectionRectangle.length > 0) 
				
				if (this.geometryEditor != null) 
					this.geometryEditor.clearSelectedMarkup();

				this.canvas.drawCanvasSelection(this.ptSelectionStart, this.ptSelectionEnd);						

				let bounds = toBounds(this.ptSelectionStart, this.ptSelectionEnd); 
				let selected = [];
        		var layerSelection = null;

				// this layer retrieval for loop happens many times throughout the code

        		for(let i = this._layers.length-1; i >= 0; i--) {
					let layer = this._layers[i];
					if (layer instanceof MgFeatureLayer && layer.isVisible(this.scale)) {
						layer.clearSelection();
						if ((layerSelection = layer.getIntersections(bounds)) != null) {
							Array.prototype.push.apply(selected, layerSelection);
						}										
					}
				}

				if (this.geometryEditor != null) 
					this.geometryEditor.selectMarkupsWithinBounds(this.ptSelectionStart, this.ptSelectionEnd);				

				this.invalidate();
				this.drawTracked();
				this.drawSelection(null);

				if (selected != null && selected.length > 0) {
					for (let i = 0; i < selected.length; i++)
						this.selectedFeatures[selected[i].id] = selected[i];
					
					this.removeOrAdd = "Add";  
					this.featureSelectedEvent.next(selected);
					if (this.cbSelectFeatures)
					  this.cbSelectFeatures(selected);
				}			

				// let the selection box remain visible for a second before clearing otherwise it clears before even seeing it
				setTimeout(() => {
					this.ptSelectionStart = null;
					this.ptSelectionEnd = null;					
					this.refreshView(false);
				}, 1000);			
			}
			else if (this.dragState == DragStates.ZoomDragging) {
				let ptStart = new Point(this.ptDragStart.x - this.offsetX, this.ptDragStart.y - this.offsetY);
				let ptEnd = new Point(e.clientX - this.offsetX, e.clientY - this.offsetY);
				
				let latLng1 = toLatLng(this.extents.MaxY - (ptStart.y - this.rcMap.MinY) * this.cellsize, (ptStart.x * this.cellsize) + this.extents.MinX);      
				let latLng2 = toLatLng(this.extents.MaxY - (ptEnd.y - this.rcMap.MinY) * this.cellsize, (ptEnd.x * this.cellsize) + this.extents.MinX);

				let extents = new LatLngBounds(latLng1, latLng2);
			
				let xScale = (extents.MaxX - extents.MinX) / this.rcMap.Width * MgMap.DPI * this.getInchesPerUnit(0);
				let yScale = (extents.MaxY - extents.MinY) / this.rcMap.Height * MgMap.DPI * this.getInchesPerUnit(0);

				this.center = toLatLng((extents.MinY + extents.MaxY) / 2.0, (extents.MinX + extents.MaxX) / 2.0);
				this.scale = Math.max(xScale, yScale);
				this.calculateExtents(MapEnums.SourceEvents.ZoomDragging);	

				this.canvas.drawCroppedCanvas(ptStart.x, ptStart.y, ptEnd.x - ptStart.x, ptEnd.y - ptEnd.y, 1.0);
				//this.updateExtents(this.scale / MgMap.DPI / this.getInchesPerUnit(0));  
				setTimeout(() => {
					this.ptSelectionStart = null;
					this.ptSelectionEnd = null;					
					this.refreshView(true);
				}, 1000);		  
			}
      else if (this.dragState == DragStates.PanDragging) {
				this.dragState = DragStates.None
				this.canvas.X = this.canvas.pan.start.x;
				this.canvas.Y = this.canvas.pan.start.y;
				this.canvas.pan.start.x = null;
				this.canvas.pan.start.y = null;
				this.canvas.global.offset.x = this.canvas.pan.offset.x;
				this.canvas.global.offset.y = this.canvas.pan.offset.y;

				this.Capture = false;

				// this.onPan();
				this.calculateExtents(MapEnums.SourceEvents.PanDragging);				
				this.refreshView(false);


			}
		}

		this.dragState = DragStates.None;

		this.mouseDown = false;

		// mouseleave has ran
		if (!this.isHovering){
			document.removeEventListener('mousemove', this.doMouseMoveBound, true);				
			document.removeEventListener('mouseup', this.doMouseUpBound, true);		
		}

	 

		if (this.cbMouseUp)
		{
		    if (this.removeOrAdd == "Add")
		       {this.cbMouseUp(hitItem.id, "Add");}
			else if (this.removeOrAdd == "Remove")
			 {this.cbMouseUp(hitItem.id, "Remove");}
			else if (this.removeOrAdd == "Clear")
			 {this.cbMouseUp(null, "Clear");}
			else if (hitItem)
			{
			 if (hitItem.id != undefined)
		       {
				if (hitItem.id != null && hitItem.id != "") 
			       {
					this.cbMouseUp(hitItem.id, "Add");
			       }
		       }
			} 
		}
	}

	onPan(){

		if(this.panTimer !== null) {
			clearTimeout(this.panTimer);        
		}
		this.panTimer = setTimeout(() => {
			// load features
			this.loadFeatures = true;
			this.calculateExtents(MapEnums.SourceEvents.PanDragging);				
			this.refreshView(false);
			this.loadFeatures = false;

			 

		}, 1000);

		this.loadFeatures = false;
		this.calculateExtents(MapEnums.SourceEvents.PanDragging);				
		this.refreshView(false);
	}
	
	onMouseWheel(e) {	
		
	e.preventDefault();
	e.stopPropagation();
	
		if(this.zoomTimer !== null) {
			clearTimeout(this.zoomTimer);        
		}
		this.zoomTimer = setTimeout(() => {
			// load features
			this.loadFeatures = true;
			console.log('SCROLL WHEEL TIMEOUT TRUE')
			this.mouseWheelCallback(e);	
			// this.loadFeatures = false;

		}, 1000);

		this.loadFeatures = false;

		this.mouseWheelCallback(e);	
	}

	onMouseLeave(e) {
		this.mouseX = undefined;
		this.mouseY = undefined;
		this.isHovering = false;

		// has left the container div, so check if mouseDown 
		if (!this.mouseDown){
			document.removeEventListener('mousemove', this.doMouseMoveBound, true);		
			document.removeEventListener('mouseup', this.doMouseUpBound, true);		
		}


	}

	onMouseSingleClick(e) {
		e.preventDefault();
		e.stopPropagation();
		this.calculateOffset();
		let x = e.clientX - this.offsetX;
		let y = e.clientY - this.offsetY;

		var hasScrollParent = this.getScrollParent(this._div);

		if (hasScrollParent) {
			x += hasScrollParent.scrollLeft;
			y += hasScrollParent.scrollTop;
		}


		if (this.geometryEditor != null && this.geometryEditor.overwritePromptDisplayed){
			this.geometryEditor.deleteMarkup(-1);
			this.geometryEditor.overwritePromptDisplayed = false;		
		}
		
		if (this.measureDistanceMode) {
			if (this.measurement == null){
				this.measurement = new Measurement(this);
				this.measurement.setDistanceType(this.options.MeasureTool);

			}
				
			this.measurement.handleMouseClick(x,y);	
			return;
		}

		if (this.geometryEditor != null && this.geometryEditor.handleMouseClick(x,y, this._canvas.canvas.style.cursor === 'crosshair')) {
			return;
		}
		
		var hitItem = null;
		var rectExtents = null;
		
		// detection should start from the topmost layer to the bottom
		for(let i = this._layers.length-1; i >= 0; i--) {
			let layer = this._layers[i];
			if (layer instanceof MgFeatureLayer && layer.isVisible(this.scale) && !layer.isDownloading) {
				if ((hitItem = layer.getHitItem(x, y)) != null) {
					break;
				}										
			}
		}
	
		if (hitItem != null) {
			let item = {"x": x, "y": y, "feature": hitItem };
			console.log(`onMouseSingleClick:  ${item}`);

			rectExtents = hitItem.Shape.getRectExtents();

			let items = [];
			if (!e.ctrlKey) {
				this.clearSelection()

				items.push(hitItem);
				this.selectedFeatures[hitItem.id] = hitItem;
				hitItem.featureLayer.clearSelection();
			}
			else {
				if (this.selectedFeatures.length <= 0) {
					hitItem.featureLayer.clearSelection();
					items.push(hitItem);
					this.selectedFeatures[hitItem.id] = hitItem;
				}
				else {
					if (!this.selectedFeatures.hasOwnProperty(hitItem.id)) {
						items.push(hitItem);
						for (var key in this.selectedFeatures) {
							items.push(this.selectedFeatures[key])											
						}

						this.selectedFeatures[hitItem.id] = hitItem;
					}
					else {
						// remove the item from the dictionary, this is unselecting
						delete this.selectedFeatures[hitItem.id];
						hitItem.Selected = false;
						
						for (var key in this.selectedFeatures) {
							items.push(this.selectedFeatures[key]);											
						}
					}					
				}
			}

			for (let i = 0; i < items.length; i++) {
				if (!items[i].Selected)
				  items[i].Selected = true;
			}
			// only used to print console logs
		 

			this.featureSelectedEvent.next(items);
			if (this.cbSelectFeatures)
			  this.cbSelectFeatures(items);

		}	
		else {
			for(let i = this._layers.length-1; i >= 0; i--) {
				let layer = this._layers[i];
				if (layer instanceof MgFeatureLayer) {
				  layer.clearSelection();											
				}
			}
			this.selectedFeatures = new Object();

			 
			this.featureSelectedEvent.next([]);

			if (this.cbSelectFeatures)
			  this.cbSelectFeatures([]);
		}
		this.invalidate();
		this.drawTracked();
		this.drawSelection();  
		
        
	}

	onMouseDoubleClick(e) {
		e.preventDefault();
		e.stopPropagation();
				
		if (e.toElement.title.indexOf('Zoom') > -1)
				return;
			
		let x = e.clientX - this.offsetX;
		let y = e.clientY - this.offsetY;
		
		if (this.geometryEditMode) {
			this.geometryEditor.handleMouseDoubleClick(x,y);	
			return;
		}
				
		var hitItem = null;

		// detection should start from the topmost layer to the bottom
		for(let i = this._layers.length-1; i >= 0; i--) {
			let layer = this._layers[i];
			if (layer instanceof MgFeatureLayer && layer.isVisible(this.scale) && !layer.isDownloading) {
				if ((hitItem = layer.getHitItem(x, y)) != null) {
					break;
				}										
			}
		}
	
		if (hitItem != null) {
			let item = {"x": x, "y": y, "feature": hitItem };
			console.log(`onMouseDoubleClick ${item}`);
			//this.featureDoubleClickedEvent.next(item);			
			document.removeEventListener('mousemove', this.doMouseMoveBound, true);				
			document.removeEventListener('mouseup', this.doMouseUpBound, true);	
			this.isHovering = false;
	
			if (this.cbDoubleClick)
				this.cbDoubleClick(hitItem);
		}					
	}

	// Drawing Functions
	mouseWheelCallback(e) {	
		e.preventDefault();
		console.log('onMouseWheel', e);
		var lines = 0;

		if (e instanceof WheelEvent) 			
			lines = e.deltaY / 200;

		var factor;

		if (lines < 0)
		{
			// if control is down we do a fine small zoom
			// zoom in is negative lines
			
			if 	(e.ctrlKey)
				factor = lines * this._scale;
			else
				factor = 1;

			// this._scale *= lines;
			this.zoomIn(e);
			// this.zoomIn();
		}
		else
		{
			if (e.ctrlKey)
				factor = lines *  this._scale;
			else
				factor = 1;

			// this._scale *= lines;
			this.zoomOut(e);			
		}


		// cancel any ongoing http requests 
		/*
		this._layers.forEach( layer => {
			layer.cancelRequest();
		});

		this.showToolTipContainer(false);
		this.calculateExtents();
		this.refreshView(true);		
		*/
	}

	mouseMoveCallback(e) {
		if (this.dragState == DragStates.None && this.mouseX >= 0 && this.mouseY >= 0) {		
			this.timerid = -1;	
			let previousItem = this.hoverItem;				
			// if (this.hoverItem != null) {
			// 	this.hoverItem.hovering = false;
			// }

			var hitItem = null;			
			var needsRedraw = false;
			var hitItems = [];
			var layers = [];
			var hitItemLayer = null;

			// detection should start from the topmost layer to the bottom
			//for(let i = 0; i < this._layers.length; i++) {
			for(let i = this._layers.length-1; i >= 0; i--) {
				let layer = this._layers[i];
				if (layer instanceof MgFeatureLayer && layer.isVisible(this.scale) && layer.canHover) {
					layers.push(layer);
					if ((hitItem = layer.getHitItem(this.mouseX, this.mouseY)) != null) {	
						hitItems.push(hitItem);
						hitItemLayer = layer;
						if (previousItem && hitItem.id == previousItem.id ) {
							needsRedraw = false;
						}
						else {
							needsRedraw = true;
						}
						break;
					}										
				}
			}

			// if (hitItem == null){
			// 	this.hoverItem = null;
			// 	previousItem.hovering = false;
			// }
																		
			if (this.measurement != null) {
				needsRedraw = true;
				this.measurement.insertionPoint = null;
				this._canvas.canvas.style.cursor = 'crosshair';	

				if (this.measurement.hitTest(this.mouseX, this.mouseY)) 
					this.measurement.insertionPoint = new Point(this.mouseX, this.mouseY, null);		
					
			}						

			if (this.geometryEditor != null && this.geometryEditor.contextLayer.isVisible(this.scale)) {
				this.geometryEditor.handleMouseMove(this.mouseX, this.mouseY);							
			} 				 			  	
			
			if (needsRedraw) {				
				//this.invalidate();


				// if (this.measurement != null){
				// 	this.measurement.render();

				// }
				
				if (this.geometryEditor != null && this.geometryEditor.contextLayer.isVisible(this.scale))
					this.geometryEditor.render();	
				
				

				// when you hover over a new item...
				if (hitItem  && hitItem != this.hoverItem) {
					this.canvas.context.globalCompositeOperation = 'source-over';
					
				
					// if (this.hoverItem != null && this.hoverItem.Shape != null)
					// 	this.hoverItem.Shape.draw(this.canvas, 1, this.canvas.context);
					

					
					if (hitItem.Shape != null){
						// this.clearOffscreenCanvas();
						this.refreshView(false, hitItem, hitItemLayer);
						// render the hovered item in its proper order
						// this is because the hover item currently is painted on top of all layers regardless of layer order
							// for(let i = 0; i < this._layers.length; i++) {
							// 	if (this._layers[i].isVisible(this.scale)){
							// 		if (this._layers[i] != hitItemLayer){
							// 			this._layers[i].render(this.canvas, true, this.canvas.context, 'source-over');
							// 		} else {
							// 			this._layers[i].render(this.canvas, true, this.canvas.context, 'source-over');
							// 			hitItem.Shape.drawHover(this.canvas, 1, this.canvas.context);
							// 		}
							// 	}
							// }
					}
					
					
					if (hitItem.Hover != null)
						hitItem.Hover.showTooltip(hitItem, this.canvas, this.canvas.context, this.mouseX, this.mouseY, this);		
						
					this.hoverItem = hitItem;

				}
				this.drawTracked();
				this.drawSelection();	
			}
			else if (previousItem != null) {
				if (!previousItem.hitTest(this.mouseX, this.mouseY)) {
					this.removeTooltip();								

					
					if (this.hoverItem != null && this.hoverItem.Shape != null){
						// this.hoverItem.Shape.drawClear(this.canvas, 1, this.canvas.context);
						this.refreshView(false, this.hoverItem, hitItemLayer);
						// this.hoverItem.Shape.draw(this.canvas, 1, this.canvas.context);

					}
				
					this.hoverItem = null;
					
					// why we do this?
					// if (previousItem.Shape instanceof MgPoint && previousItem.Shape.symbol != null) {
						// this.invalidate();

						// if (this.measurement != null)
						// 	this.measurement.render();
						
						// if (this.geometryEditor != null && this.geometryEditor.contextLayer.isVisible(this.scale))
						// 	this.geometryEditor.render();	
						
						// this.drawSelection();

						//let width = previousItem.Shape.symbol.HoverSymbol.width;
						//let height = previousItem.Shape.symbol.HoverSymbol.height;
						//let sx = firstPoint.x - width;
						//let sy = firstPoint.y - height;

						//console.log(`sx: ${sx} sy: ${sy}  width:${width} height:${height}`);
						//this.canvas.context.drawImage(this.canvas.offScreenCanvas, sx, sy, width*2, height*2, sx, sy, width*2, height*2);
					// }			
				}
			}				
			
			if (this.ptSelectionStart && this.ptSelectionEnd)
				this.canvas.drawCanvasSelection(this.ptSelectionStart, this.ptSelectionEnd); 
		}
	}

	invalidate(xOffset, yOffset){
		this.canvas.refreshView(xOffset, yOffset);
		/*
		if (this.measurement != null)
			this.measurement.render();

		if (this.geometryEditor != null) {
			this.geometryEditor.render();
			this.geometryEditor.render(this.canvas.offScreenContext);	
		}
		*/		
	}


	clearOffscreenCanvas() {
		let offScreenCanvas = this.canvas.offScreenCanvas;
		let context = offScreenCanvas.getContext("2d");
		context.save();

		context.setTransform(1, 0, 0, 1, 0, 0);
		context.clearRect(0, 0, offScreenCanvas.width, offScreenCanvas.height);
 
		context.restore(); 
	}

	isLeftButtonPressed(evt) {
    evt = evt || window.event;
    if ("buttons" in evt) {
        return evt.buttons == 1;
    }
    var button = evt.which || evt.button;
    return button == 1;
	}
	
	initTooltipContainer() {
		
		this.showDivStyle = document.createElement('style');
		this.showDivStyle.type = 'text/css';
		this.showDivStyle.innerHTML = '.showDiv { z-index: 1000; min-width: 100px; border: 1px solid grey; position: relative; background-color: ghostwhite; padding: 4px; display: inline-block; }';
		
		this.hideDivStyle = document.createElement('style');
		this.hideDivStyle.type = 'text/css';
		this.hideDivStyle.innerHTML = '.hideDiv { display: none; }';

		document.head.appendChild(this.showDivStyle);
		document.head.appendChild(this.hideDivStyle);

		this.toolTip = DomUtil.create('div', '', this._container);		
		this.toolTip.classList.add('hideDiv');
		
	}
	
	drawToolTipBox(x,y, toolTip) {
    let ctx = this.canvas.context;
    let label = new Label({ FontName: 'Arial', Colour: 'black', FontSize : 10, Position : 0 });
    const offset = 8;
    const panelHeight = 24;

		const textWidth = Math.trunc(this.canvas.getTextWidth(toolTip)) + 40;

		let rect = new Rectangle(x + offset, y + offset, textWidth, panelHeight);      
		this.canvas.drawRoundRectangle(rect.left, rect.top, rect.width, rect.height, 5, '#000000', '#ffffed', ctx)
		this.canvas.drawText(toolTip, rect.left + offset, rect.top + (offset * 2), undefined, label, 'start', ctx); 
    
		console.log(`drawToolTipBox :${toolTip}`);
	} 
	
	enableTooltip() {
		let _this = this;
		this.featureHoveredEvent.subscribe( result => {
			console.log(result);
			
			let feature = result.feature;
			if (feature != null && typeof feature.id === 'string' ) {
				let x = _this.mouseX > _this._canvas.width - _this.toolTip.clientWidth ? _this._canvas.width - _this.toolTip.clientWidth : _this.mouseX;
				let y = _this.mouseY > _this._canvas.height - _this.toolTip.clientHeight ? _this._canvas.height - _this.toolTip.clientHeight : _this.mouseY;

				//_this.drawToolTipBox(x,y, feature.id);				
			}
			
		});		
	}

	// Context Menu Related Items
	_createMenuItem(html, className, container, fn) {
		var menu = DomUtil.create('li', className, container);
		menu.innerHTML = html;
			
		DomEvent.disableClickPropagation(menu);
		DomEvent.on(menu, 'click', DomEvent.stop);
		if (fn != undefined)
		  DomEvent.on(menu, 'click', fn, this);
		
		menu.style = this.liStyle;
		menu.style.paddingTop = '2px';	
		menu.style.paddingBottom = '2px';	
		return menu;
  }
  
  // this still used? 
  createContextMenu(container) {
		var doEnableMeasure = () => this.onEnableMeasurement(event); 
    var doDisableMeasure = () => this.onDisableMeasurement(event); 
    var doMoreInformation = () => this.onMoreInformation(event);
		
		var doInitGeometry = () => this.onInitGeometry(event);

		var doDrawPoint = () => this.onDrawPointSelected(event);
		var doDrawLine = () => this.onDrawLineSelected(event);
		var doDrawPolygon = () => this.onDrawPolygonSelected(event);
		var doDeleteMarkup = () => this.onDeleteMarkup(event);
		var doDeleteAllMarkups = () => this.onDeleteAllMarkups(event);
		var doSearchFeatures = () => this.onSearchFeatures(event);
		var doZoomToFeatures = () => this.onZoomToFeatures(event);
		

		if (container == undefined) {
			this.contextMenuContainer = DomUtil.create('div', '', this._div);
			this.contextMenuContainer.style.display = 'none';
			this.contextMenuContainer.style.width = '170px';
			this.contextMenuContainer.style.height = '60';
			this.contextMenuContainer.style.textAlign = 'left';
		
			this.liStyle = this.createStyle('li:hover {background-color: #8fcadd;');
			
		}

		DomUtil.empty(this.contextMenuContainer);

		this.contextMenu = DomUtil.create('ul', '', this.contextMenuContainer);
		this.contextMenu.style.listStyle = 'none';
		this.contextMenu.style.backgroundColor = 'white';
		this.contextMenu.style.position = 'absolute';
		this.contextMenu.style.width = '170px';
  	this.contextMenu.style.height = '160px';
		
		this.contextMenu.style.padding = '0px'; 
		this.contextMenu.style.margin = '0px';

		this._createMenuItem('More Information', '', this.contextMenu, doMoreInformation.bind(this));									
		let hr = DomUtil.create('hr', '', this.contextMenu); // menu separator
		//hr.style.paddingTop = '2px'; 
		//hr.style.paddingBottom = '2px'; 

		if (this.measureDistanceMode)
			this._createMenuItem('Clear Measurement', '', this.contextMenu, doDisableMeasure.bind(this));	
		else 
			this._createMenuItem('Measure Distance', '', this.contextMenu, doEnableMeasure.bind(this));		

		if (this.homeCenter && this.homeScale)
			this._createMenuItem('Home', '', this.contextMenu, doZoomToFeatures.bind(this));			


		this._createMenuItem('Search Features', '', this.contextMenu, doSearchFeatures.bind(this));
		this._createMenuItem('Zoom To Features', '', this.contextMenu, doZoomToFeatures.bind(this));			
		
		this._createMenuItem('Init Geometry', '', this.contextMenu, doInitGeometry.bind(this));
		this._createMenuItem('Draw Point', '', this.contextMenu, doDrawPoint.bind(this));
		this._createMenuItem('Draw Line', '', this.contextMenu, doDrawLine.bind(this));
		this._createMenuItem('Draw Polygon', '', this.contextMenu, doDrawPolygon.bind(this));
		this._createMenuItem('Delete', '', this.contextMenu, doDeleteMarkup.bind(this));			
		this._createMenuItem('Delete All', '', this.contextMenu, doDeleteAllMarkups.bind(this));
		
	}
	
	set contextMenuCallback(callback) {
		this.cbContextMenu = callback;
	}

	set geometryEditCallback(callback) {
		this.cbGeometryEdit = callback;
	}

	set displayPromptCallback(callback) {
		this.cbDisplayPrompt = callback;
	}

	set selectFeaturesCallback(callback) {
		this.cbSelectFeatures = callback;
	}

	set doubleClickCallback(callback) {
		this.cbDoubleClick = callback;
	}

	set mouseUpCallback(callback) {
		this.cbMouseUp = callback;
	}

	set updateExtentsCallback(callback) {  
    this.cbUpdateExtents = callback;
	}

	handleContextmenu(e) {
		e.preventDefault();
		e.stopPropagation();
		this.mouseX = e.clientX - this.offsetX;
		this.mouseY = e.clientY - this.offsetY;

		let markups = [];
		let allMarkups = [];
		let allFeatures = {};
		if (this.geometryEditor != null){
			markups = this.geometryEditor.getHitItems(this.mouseX, this.mouseY);
			allMarkups = this.geometryEditor.markups;
		}

		if (this.cbContextMenu) {		
		
			let features = [];				
			for(let i = this._layers.length-1; i >= 0; i--) {
				let layer = this._layers[i];
				
				if (layer instanceof MgFeatureLayer && layer.isVisible(this.scale) && layer.canHover) {
					// this is for testing
					if (layer._id == "Inspection Areas"){
						allFeatures = {visibleItems: layer._visibleItems, features: layer._features, markups: allMarkups};
					}
					let hitItems = layer.getHitItemsFeatureInfo(this.mouseX, this.mouseY);
					features = features.concat(hitItems);															
				}
			}

			if(this.selectedFeatures != null && Object.keys(this.selectedFeatures).length > 0) {
				let selectedFeatures = features.filter(x => x.Selected == true);
				if(selectedFeatures != null && selectedFeatures.length > 0)
					features = selectedFeatures;
			}

			// if we're right clicking the measurebox

			let overMeasureBox = false;

			if (this.measurement != null && this.measurement.measureBoxHitTest(this.mouseX, this.mouseY))
				overMeasureBox = true;
				
							
			let message = {
				event: e,
				features: features,
				markups: markups,
				allInspAreaFeatures: allFeatures,
				overMeasureBox: overMeasureBox
			}
			
			this.cbContextMenu(message);
		}
		else  {		
			this.createContextMenu(this.contextMenuContainer);
			
			let style = this.contextMenuContainer.style;
			this.contextMenuContainer.style.position = 'absolute';
			this.contextMenuContainer.style.display = '';
			this.contextMenuContainer.style.borderStyle = 'Solid';
			this.contextMenuContainer.style.borderWidth = '2px';
			this.contextMenuContainer.style.borderColor = 'grey';

			this.contextMenuContainer.style.zIndex = 1001;
			let x = e.clientX - this.offsetX;
			let y = e.clientY - this.offsetY;
		
			DomUtil.setPosition(this.contextMenuContainer, toPoint(x, y));											
		}
	}

	hideContextMenu(cursor) {
		this._canvas.canvas.style.cursor = cursor;
		if (this.contextMenuContainer)
		  this.contextMenuContainer.style.display = 'none';
	}

	onSearchFeatures(e) {
		this.hideContextMenu('default');
		this.searchFeatures(""); 
	}

	onZoomToFeatures(e) {
		this.hideContextMenu('default');
		if (this._contextLayer != null) {
			if (new Date().getMilliseconds() % 2 == 1)
			  this._contextLayer.zoomToFeature(-1, ["711", "8388647"]);
			else
			  this._contextLayer.zoomToFeature(-1, ["711", "8388647"]);
		}
	}

	onEnableMeasurement(e) {
		e.stopPropagation();
		this.hideContextMenu('crosshair');
		this.measureDistanceMode = true;		
 	}

	onDisableMeasurement(e) {
		e.stopPropagation();
		this.hideContextMenu('default');
		this.measureDistanceMode = false;		
		
		this.measurement.linePoints.length = 0;					
		this.measurement = null;
		
	

		this.refreshView();
	}
	
	enableMeasurement(enable) {
		if (enable) {
			this.hideContextMenu('crosshair');
			this.measureDistanceMode = true;
		}
		else  {
			this.hideContextMenu('default');
		  	this.measureDistanceMode = false;
		  
		  	if (this.measurement && this.measurement.linePoints) 
				this.measurement.linePoints.length = 0;
			this.measurement = null;
			
		}
	}

	onInitGeometry(e) {
		e.preventDefault();
		e.stopPropagation();

		//let sWkt = `GEOMETRYCOLLECTION(POINT (150.38217656775913 -23.524670906572588), POINT (150.44569124614299 -23.532925521813812), POINT (150.50209778362466 -23.546224624146895), POINT (150.50301496309592 -23.556542893198422), POLYGON((150.37827855500635 -23.545078149807836, 150.39066047786818 -23.547371098485954, 150.3874503497188 -23.56021161108341, 150.3702532346329 -23.567319751985572, 150.35328541441484 -23.55860654700873, 150.35924708097795 -23.548058983089387, 150.37827855500635 -23.545078149807836)), POLYGON((150.4129020800459 -23.542326611394095, 150.41978092608025 -23.548517572825013, 150.4124434903103 -23.56296314949715, 150.40074945205188 -23.56021161108341, 150.39776861877033 -23.541409431922848, 150.40533534940812 -23.535906355095364, 150.4129020800459 -23.542326611394095)), LINESTRING(150.4365194514305 -23.546683213882517, 150.44087605391894 -23.554020649652493, 150.4518822075739 -23.56456821357183, 150.46403483556793 -23.543931675468777, 150.46380554070012 -23.563651034100587), LINESTRING(150.4229910542296 -23.562045970025903, 150.43376791301677 -23.565026803307457, 150.42413752856868 -23.57098846987056))`;
		let sWkt = `POLYGON((150.37827855500635 -23.545078149807836, 150.39066047786818 -23.547371098485954, 150.3874503497188 -23.56021161108341, 150.3702532346329 -23.567319751985572, 150.35328541441484 -23.55860654700873, 150.35924708097795 -23.548058983089387, 150.37827855500635 -23.545078149807836))`;		
		//let sWkt = `POINT (150.50209778362466 -23.546224624146895)`;		
		this.initialiseGeometryEditor(MapEnums.GeometryTypes.POLYGON, sWkt);
	}

	geometryEditorInitialise(wkt){
		if (this.geometryEditor) this.geometryEditor.initialise(wkt);
	}


	initialiseGeometryEditor(geometryType, sWKT, bounds) {		
		if (this.geometryEditor == null) {
			this.hideContextMenu('default');
      		this.geometryEditor = new GeometryEditor(this, geometryType, sWKT, bounds);
			this.geometryEditMode = false;
			this.geometryType == MapEnums.GeometryTypes.NONE;			
		}		
	}

	setGeometryEditorType(geometryType) {
    this.geometryType = geometryType;

		if (geometryType == MapEnums.GeometryTypes.NONE) {
			this.hideContextMenu('default');			
			this.geometryEditMode = false;
			if (this.geometryEditor) this.geometryEditor.endEditMode();
		} else {

			if (this.geometryEditor && this.geometryEditor.markups.length > 0 && 
				this.geometryEditor.geometryType != MapEnums.GeometryTypes.GEOMETRYCOLLECTION &&
				this.geometryEditor.geometryType != MapEnums.GeometryTypes.MULTIPOLYGON){
				this.geometryEditor.displayOverwritePrompt();
			}
				this.hideContextMenu('crosshair');
				this.geometryEditMode = true;
				this.geometryEditor.addMarkup(geometryType);
			}
	} 

	

	onDrawPointSelected(e) {
		e.preventDefault();
		e.stopPropagation();

    	if (this.geometryEditor != null) {
			this.hideContextMenu('crosshair');
			this.setGeometryEditorType(this.geometryEditor.markupType == MapEnums.GeometryTypes.POINT ? MapEnums.GeometryTypes.NONE : MapEnums.GeometryTypes.POINT);
		}		
	}

	onDrawLineSelected(e) {
		e.preventDefault();
		e.stopPropagation();

		if (this.geometryEditor != null) {
			this.hideContextMenu('crosshair');
			this.setGeometryEditorType(this.geometryEditor.markupType == MapEnums.GeometryTypes.LINESTRING ? MapEnums.GeometryTypes.NONE : MapEnums.GeometryTypes.LINESTRING);
		}
	}

	onDrawPolygonSelected(e) {
		e.preventDefault();
		e.stopPropagation();
		
		if (this.geometryEditor != null) {
			this.hideContextMenu('crosshair');
			this.setGeometryEditorType(this.geometryEditor.markupType == MapEnums.GeometryTypes.POLYGON ? MapEnums.GeometryTypes.NONE : MapEnums.GeometryTypes.POLYGON);
		}
	}
	
	onDeleteMarkup(e) {
    e.preventDefault();
		e.stopPropagation();

		if (this.geometryEditor != null && this.geometryEditor.hitItems != null) {
			for (let i = 0; i  < this.geometryEditor.hitItems.length; i++) {
				let hitItem = this.geometryEditor.hitItems[i];
				this.geometryEditor.deleteMarkup(hitItem.id);
			}
		
			this.hideContextMenu(this.geometryEditor.geometryType == MapEnums.GeometryTypes.NONE ? 'default' : 'crosshair');
		}

		this.invalidate();		
	}

	onDeleteAllMarkups(e) {
    e.preventDefault();
		e.stopPropagation();

		if (this.geometryEditor != null) {
			this.geometryEditor.deleteMarkup(-1); // -1 for all						
			this.hideContextMenu(this.geometryEditor.geometryType == MapEnums.GeometryTypes.NONE ? 'default' : 'crosshair');
		}

		this.invalidate();		
	}

	deleteAllMarkup() {
		if (this.geometryEditor != null) {
			this.geometryEditor.deleteMarkup(-1); // -1 for all
		}
	}

	hasHomeExtents (){
		return (this.homeCenter && this.homeScale);
	}
	
	deleteSelectedMarkups(markupIds) {
    	if (this.geometryEditor != null && markupIds != null && markupIds.length > 0){ 
			this.geometryEditor.deleteSelectedMarkups(markupIds);	
		}
	}
	enableGeometryEditing(enable, type) {
		if (enable) {
			this._canvas.canvas.style.cursor = 'crosshair';
		  this.measureDistanceMode = true;
		}
		else  {
			this._canvas.canvas.style.cursor = 'pointer';
		  this.measureDistanceMode = false;
		  
			this.measurement.linePoints.length = 0;
			this.measurement = null;
			this.refreshView();
		}
	}
	
	clearSelectedMarkup() {		
		if (this.geometryEditor != null) {
			this.geometryEditor.clearSelectedMarkup();
			this.refreshView(false);  
		}
	}

	isActiveMarkupValid() {
		if (this.geometryEditor != null)
			return this.geometryEditor.isActiveMarkupValid();
	}

	saveInCompleteMarkup(activeMarkupId) {
		if (this.geometryEditor != null)
			this.geometryEditor.saveInCompleteMarkup(activeMarkupId);
	}

	onMoreInformation(e) {
		e.stopPropagation();
		this.measureDistanceMode = false;
		this.contextMenuContainer.style.display = 'none';
	}

	removeTooltip() {
		if (this.toolTipDiv != null) {
			if (this.toolTipDiv.parentNode != null) this.toolTipDiv.parentNode.removeChild(this.toolTipDiv);
			this.toolTipDiv = null;
		}

	}

	createStyle(css) {
		var head = document.head || document.getElementsByTagName('head')[0];
		var style = document.createElement('style');

		head.appendChild(style);

		style.type = 'text/css';
		if (style.styleSheet){
			// This is required for IE8 and below.
			style.styleSheet.cssText = css;
		} else {
			style.appendChild(document.createTextNode(css));
		}
		
		return style;		
	}

	getLayerByDataset(dataset) {
		for(let i = 0; i < this._layers.length; i++) {
			let layer = this._layers[i];
			if (layer instanceof MgFeatureLayer && layer.Dataset == dataset) {
				return layer;														
			}
		}

		return null;
	}

	getContextLayer() {
		if (this.options.Context != null) {
			let contextLayers = [];
			for(let i = 0; i < this._layers.length; i++) {
				let layer = this._layers[i];
				if (layer instanceof MgFeatureLayer && layer.Dataset == this.options.Context.Dataset) {
					contextLayers.push(layer);														
				}
			}

			if (contextLayers.length == 1) {
        return contextLayers[0];
			}
			else {
				for(let contextLayer of contextLayers) {
					if (contextLayer.options.ContextualType == "ShowOnlyRelatedFeatures")
					  return contextLayer;
				}

				return contextLayers[0];
			}
		}

		return null;
	}

	zoomByContextOrder() {
		if(!this._ongoingRefreshView) {
			
			this._pendingZoomByContextOrder = false;
			
			let contextLayers = [];
			for(let i = 0; i < this._layers.length; i++) {
				let layer = this._layers[i];
				if (layer instanceof MgFeatureLayer && layer.options.ContextualType == "ShowOnlyRelatedFeatures" && layer.options.ContextOrder >= 0) {
					contextLayers.push(layer);														
				}
			}
	
			contextLayers.sort(function(x, y) {return x.options.ContextOrder - y.options.ContextOrder});
			for(let i = 0; i < contextLayers.length; i++) {
				let contextLayer = contextLayers[i];
	
				if(contextLayer && contextLayer.currentBounds != undefined) {
					this.zoomToGeometryBounds(MapEnums.SourceEvents.ZoomByContextOrder, contextLayer.currentBounds, contextLayer);
					break;
				}
			}
		}
		else {
			this._pendingZoomByContextOrder = true;
		}
	}

	zoomToGeometryBounds(sourceEvent, bounds, contextLayer, skipRefreshView, center = null) {		
		if (bounds != null && bounds.MinX != -1 && bounds.MinY != -1) {
			this.hoverItem = null;
			// this.removeTooltip();

			// for(let i = 0; i < this._layers.length; i++) {				
				//if(this._layers[i]._id != contextLayer._id)
				// this._layers[i].createOffscreenCanvas(this.canvas);
			// }
		

			var MinX = bounds.SouthWest.lng;
			var MaxY = bounds.SouthWest.lat;
			var MaxX = bounds.NorthEast.lng;
			var MinY = bounds.NorthEast.lat;


      		var kScaleToZoomIn = -1;
			if ( Math.abs(MinX - MaxX) == 0 &&  Math.abs(MinY - MaxY) == 0) {
				kScaleToZoomIn = 2000;
				let cellsize =  (kScaleToZoomIn / MgMap.DPI) / this.getInchesPerUnit(0);			
				MinX -= cellsize;
				MinY -= cellsize;
				MaxX += cellsize;
				MaxY += cellsize;				
			}

			let extents = this.extents != null ? this.extents : bounds
			let viewport = new Viewport(this.rcMap, extents, this.cellsize, this.scale);     

			let ptStart = viewport.worldToClient(MinX, MinY);
			let ptEnd = viewport.worldToClient(MaxX, MaxY);
			
			let width = Math.abs(ptEnd.x - ptStart.x);
			let height = Math.abs(ptEnd.y - ptStart.y);
			let xBuffer = width > 100 ? width * 0.8 : 100;
			let yBuffer = height > 100 ? height * 0.8 : 100;

			ptStart.x -= xBuffer;
			ptStart.y -= yBuffer;
			ptEnd.x += xBuffer;
			ptEnd.y += yBuffer;

			let latLng1 = toLatLng(extents.MaxY - (ptStart.y - this.rcMap.MinY) * this.cellsize, (ptStart.x * this.cellsize) + extents.MinX);      
			let latLng2 = toLatLng(extents.MaxY - (ptEnd.y - this.rcMap.MinY) * this.cellsize, (ptEnd.x * this.cellsize) + extents.MinX);

			extents = new LatLngBounds(latLng1, latLng2);
			let xScale = 0;
			let yScale =  0; 
			// if (center){
			// 	this.center =  toLatLng(center.lat, center.lon);
			// } else {
				this.center = toLatLng((extents.MinY + extents.MaxY) / 2.0, (extents.MinX + extents.MaxX) / 2.0);
			// }

			xScale = Math.abs((MaxX - MinX) / this.rcMap.Width * MgMap.DPI * this.getInchesPerUnit(0));
			yScale = Math.abs((MaxY - MinY) / this.rcMap.Height * MgMap.DPI * this.getInchesPerUnit(0));
			

			this.scale = Math.max(xScale, yScale);
				
      //if (contextLayer != null)
			//  contextLayer.scale = this.scale;
			
			//this.resize();
			this.calculateOffset();
			this.calculateExtents(sourceEvent);

			//skip refresh view when an address search is done.
			//address search will not trigger zoomtogeometrybounds as we don't want the
			//map focus to change. if there is a pending currentbounds that needs to be zoomed into,
			//we just update the variables, but not the view. view will be updated by zoomtoFeatures,
			//which calls this zoomtogeometrybounds function again.
			if(!skipRefreshView) {
					this.refreshView(true);
			}
		}
		else {
			this.calculateOffset();
			this.calculateExtents(sourceEvent);

			if(!skipRefreshView) {
				this.refreshView(true);
			}
		}			  
	}

	zoomToGeometry(bounds, featureLayer) {		
		if (bounds != null) {
			this.hoverItem = null;
			// this.removeTooltip();
			
			let scale = +featureLayer.options.ZoomToScale;
			if (scale == null)
				scale = 2000;
				
			this.canvas.clearAll();
			this.setView(bounds.Center, scale);
		}			  
	}

	searchFeatures(userFilter, layer) {
		if (layer == null) {
			this._contextLayer.searchFeatures(userFilter);
		}
		else  {
			layer.searchFeatures(userFilter);
		}

	}

	searchGeocoder(userFilter) {
		if (this.geocoder != null) {			
			this.geocoder.searchFeatures(userFilter, -1, false, 0, 100);
		}

	}

	setSelection(items) {		
		if(items) {
			this.clearSelection();
			for(let i = 0; i < items.length; i++) {
				let item = items[i];
				if(item != null)
				if (!this.selectedFeatures.hasOwnProperty(item.id)) {
					item.Selected = true;
					this.selectedFeatures[item.id] = item;
					item.featureLayer.render(this.canvas, true, this.canvas.context, 'source-over');
				}
			}
		}
	}

	clearSelection() {
		for (var key in this.selectedFeatures) {
			let item = this.selectedFeatures[key];
			if (item != undefined) {
				item.Selected = false;
				item.featureLayer.render(this.canvas, true, this.canvas.context, 'source-over');
			}	
			
			delete this.selectedFeatures[key];
		}

		this.selectedFeatures = new Object();
	}

	getMapDpi(){
		return MgMap.DPI;
	}


}

// @section Static Fields
MgMap.MS_INCHES = 0;
MgMap.MS_FEET = 1;
MgMap.MS_MILES = 2;
MgMap.MS_METERS = 3;
MgMap.MS_KILOMETERS = 4;
MgMap.MS_DD = 5;
MgMap.MS_PIXELS = 6;

MgMap.InchesPerUnit = [1, 12, 63360.0, 39.3701, 39370.1, 4374754 ];
MgMap.DPI = 72;
MgMap.DomUtil = DomUtil;

