import {centroid}  from './centroid';
import {Geometry} from './geometry';
import { Point } from '../geometry';
import MapEnums from '../map/enums';

export class GeoPoint extends Geometry {
  constructor(coordinate) {
    super([coordinate]);
    this.point =  coordinate; 
    this.points = [];
  }

  get GeometryType()  { 
    return MapEnums.GeometryTypes.POINT;
  }

  get Coordinate() { 
    return this.point; 
  }

    
  get NumPoints() { 
    return this.point != null ? 1 : 0; 
  }

  get NumGeometries() { 
    return 1; 
  }

  GetGeometryN(n) {
    return this;
  }
  
  get IsEmpty() { this.point != null ? false : true; }

  get Bounds() {
    if (this._bounds == null)
      this._bounds = this.calculateBounds([this.point]);

    return this._bounds;   
  }

  set Bounds(value) {
    this._bounds = value;
  }
  
  worldToClient(viewport) {
    this.points = [];
    this.points.push(viewport.worldToClient(this.point.X, this.point.Y));            
    
    return this.points;
  }

  setWorldToClient(viewport, points)  { 
    this.points = points; 
  }
  
  draw(canvas, opacity, symbol, fill, context) {    
    
    if (this.points.length > 0) {
      let pt = this.points[0];

      let offsetX = symbol.NormalSymbol.width / 2;
      let offsetY = symbol.NormalSymbol.height / 2;
      canvas.drawSymbol(symbol.NormalSymbol, pt.x - offsetX, pt.y - offsetY, context);
      //this.drawText(new Point(pt.x - offsetX, pt.y - offsetY), canvas, offsetX, offsetY, context);
    }    
  }

  drawHover(canvas, opacity, symbol, fill, context) {
    context.globalCompositeOperation = 'source-over';

    if (this.points.length > 0) {
      let pt = this.points[0];

      let offsetX = symbol.NormalSymbol.width / 2;
      let offsetY = symbol.NormalSymbol.height / 2;
      canvas.drawSymbol(symbol.HoverSymbol, pt.x - symbol.HoverSymbol.width / 2, pt.y -  symbol.HoverSymbol.height / 2, context);
      //this.drawText(new Point(pt.x - offsetX, pt.y - offsetY), canvas, offsetX, offsetY, context);
    }
  }    

  drawSelected(canvas, opacity, symbol, fill, context) {
    context.globalCompositeOperation = 'source-over';

    if (this.points.length > 0) {
      let pt = this.points[0];

      let offsetX = symbol.NormalSymbol.width / 2;
      let offsetY = symbol.NormalSymbol.height / 2;
      canvas.drawSymbol(symbol.SelectedSymbol, pt.x -  symbol.SelectedSymbol.width / 2, pt.y -  symbol.SelectedSymbol.height / 2, context);
      //this.drawText(new Point(pt.x - offsetX, pt.y - offsetY), canvas, offsetX, offsetY, context);
    }
  }

  hitTest(x, y, shape) {
    if (shape != null) {
      return shape.hitTest(x, y);
    }
    else {
      if (this.points.length > 0) {
        var a = this.points[0].x - x;
        var b = this.points[0].y - y;
        var c = Math.sqrt( a*a + b*b );

        return  c <= 16 ? true : false;
      }
      else
        return false;
    }
  }

  isVisible(viewport) {
    if (this.points.length > 0) {
      return viewport.Client.contains(this.points[0]);      
    }
    else
      return false;    
  }

  intersects(bounds) {
    if (this.points.length > 0) {
      return bounds.contains( this.points[0]);      
    }
    else
      return false;   
  }
}