import {centroid}  from './centroid';
import {Geometry, DimensionTypes} from './geometry';
import { GeoLineString } from './geolinestring';
import {MapPrimitive} from '../drawing/mapprimitive';
import MapEnums from '../map/enums';

export class GeoLinearRing extends Geometry {
  constructor(coordinates) {
    super(coordinates);
    this.points = [];
  }

  get GeometryType()  { 
    return MapEnums.GeometryTypes.LINEARRING;
  }

  get Coordinate() { 
    return this._coordinates.length > 0 ? this._coordinates[0] : null; 
  }

    
  get NumPoints() { 
    return this._coordinates.length; 
  }

  get NumGeometries() { 
    return 1; 
  }

  GetGeometryN(n) {
    return this;
  }
  
  get IsEmpty() { this.points.length > 0 ? false : true; }

  get Bounds() {
    if (this._bounds == null)
      this._bounds = this.calculateBounds(this.Coordinates);

    return this._bounds;   
  }
  
  set Bounds(value) {
    this._bounds = value;
  }
    
  worldToClient(viewport) {
    this.points = [];
    for (var i = 0; i < this._coordinates.length; i++) {
      var pt = this._coordinates[i];      
      this.points.push(viewport.worldToClient(pt.X, pt.Y));            
    }  
    
    this.points = MapPrimitive.ClipLineStringRect(this.points, viewport.Client);
    return this.points;
  }  
  
  setWorldToClient(viewport, points)  { 
    //this.points = MapPrimitive.ClipLineStringRect(points, viewport.Client); 
    this.points = points; 
  }

  draw(canvas, opacity, stroke, fill, context) {
    canvas.drawLine(this.points, stroke, opacity, context);    
  }

  //https://rosettacode.org/wiki/Ray-casting_algorithm    
  /**
   * @return {boolean} true if (lng, lat) is in bounds
   */
  hitTest(x, y) {
    
    var count = 0;
    if (this.points != null) {
      for (var b = 0; b < this.points.length; b++) {
          var vertex1 = this.points[b];
          var vertex2 = this.points[(b + 1) % this.points.length];
          if (this.west(vertex1, vertex2, x, y))
              ++count;
      }
    }
    return count % 2 == 0 ? false : true;         
  }

  //https://rosettacode.org/wiki/Ray-casting_algorithm    
  /**
   * @return {boolean} true if (x,y) is west of the line segment connecting A and B
   */
  west(A, B, x, y) {
    if (A.y <= B.y) {
      if (y <= A.y || y > B.y || x >= A.x && x >= B.x) {
        return false;
      } 
      else if (x < A.x && x < B.x) {
        return true;
      } 
      else {
        return (y - A.y) / (x - A.x) > (B.y - A.y) / (B.x - A.x);
      }
    } 
    else {
      return this.west(B, A, x, y);
    }
  }

  isVisible(viewport) {
    if (this.points.length > 0) {
      for (let i = 0; i < this.points.length; i++) {
        if (viewport.Client.contains(this.points[i]))
          return true;
      }  
    }
    else
      return false;    
  }

  intersects(bounds) {
    if (this.points != null && this.points.length > 0) {
      for (let i = 0; i < this.points.length; i++) {
        if (bounds.contains(this.points[i]))
          return true;
      }  
    }
    else
      return false;    
  }
}