import { MgLayer, MgGeocoderLayer } from './layer';
import { Viewport } from '../drawing/viewport';
import { RestAPI } from '../api';
import { Feature } from './feature';
import Axios from "axios";
import MapEnums from '../map/enums';
import { Util } from '../core/index';
import { Stroke, Fill, Symbol, Label, Hover } from '../drawing/index';
import { Bounds, toBoundsFromRect, LatLng, QuadTree } from '../geometry/index';
import { Rectangle, fromRect } from '../geometry/rectangle';
import { Envelope } from '../geometry/Envelope';
import { cancelable, CancelablePromise } from 'cancelable-promise';
import { WktReader } from '../geometries/index';

export class MgFeatureLayer extends MgLayer {

  constructor(id, options, taskId) {
    super(id, options, taskId);
    this._image;
    this.quadTree = null;
    this.quadTrees = [];
    this._viewport = null;
    this.coordinates = [];
    this._context = null;
    this._features = [];
    this._visibleItems = [];
    this.promise = undefined;
    this.searchFeaturesPromise = undefined;
    this.scale = undefined;
    this.selected = [];
    this.tracked = [];
    this.thematics = [];
    this.customStyles = [];
    this.hasThematics = false;
    this.hasStyle = false;
    this.hoverTemplate = null;
    this.currentBounds = null;
    this.isGeocoder = false;
    this.cellsize = 0;
    this.worker = null;
    if (this.options.MaxScale != null)
      this.maxScale = this.options.MaxScale;
    else if (this.options.objJSON != null && this.options.objJSON.MaxScale != null)
      this.maxScale = this.options.MaxScale;
    else
      this.maxScale = Number.POSITIVE_INFINITY;

    this.backgroundLayer = this.options.BackgroundLayer == true ? true : false;

    this.count = 0;

    this.createDefaultStyles(this.options.Symbology, this.options.Label);
    this.createCustomStyles(this.options.Thematics);
    this.processSearchColumns();
    this.checkContextualType();

    this.loadedExtents = [];

  }

  // Factory methods
  static createObject(id, options, taskId) {
    return new MgFeatureLayer(id, options, taskId);
  }

  showLayer(sourceEvent, show) {
    this.visible = show;
    if (this.map != null) {
      if (show == true) {
        if (this.options.ContextLayer == false && this.isGeocoder == false) {
          this.clearItems();
          this._viewport = null;
          this.currentExtents = null;
        }
        else {
          this.currentExtents = null;
        }

        this.onExtentsChanged(sourceEvent, this.map.rcMap, this.map.extents, this.map.cellsize, this.map.scale, this.map.canvas);
      }

      this.map.refreshView();
    }
  }

  showThematics(index, show) {
    if (index == -1) {
      for (var i = 0; i < this.thematics.length; i++) {
        this.thematics[i] = show;
      }

      this.visible = show;
    }
    else if (index < this.thematics.length) {
      this.thematics[index] = show;

      this.visible = false;
      // check if any thematic is enabled
      for (let i = 0; i < this.thematics.length; i++) {
        if (this.thematics[i] == true) {
          this.visible = true;
          break;
        }
      }

    }

    if (this.map != null) {
      if (this.options.ContextLayer == false && this.isGeocoder == false) {
        this.clearItems();
        this.quadTree = null;
        this._viewport = null;
        this.currentExtents = null;
      }
      else {
        this.currentExtents = null;
      }

      this.onExtentsChanged(MapEnums.SourceEvents.ShowThematics, this.map.rcMap, this.map.extents, this.map.cellsize, this.map.scale, this.map.canvas);
      this.map.refreshView();
    }

  }

  isVisible(scale) {

    return (this.options.MaxScale >= scale || this.options.MaxScale == null) && (this.options.MinScale <= scale || this.options.MinScale == null) ? this.visible && this.hasStyle : false;

    // return this.maxScale >= scale ? this.visible && this.hasStyle : false;  
  }

  clearItems() {
    this._visibleItems.length = 0;
    if (this.options.ContextLayer == false &&
      this.isGeocoder == false &&
      (!this.options.ContextualType || this.options.ContextualType != "ShowOnlyRelatedFeatures"))
      this._features.length = 0;
    this.promise = undefined;
  }

  isLayerReadyDebounce(canvas, cellsize) {


    this.isDebouncing.next(true);

    const debounced = Util.debouncePromise(this.isLayerReadyToDebounce, 1000);

    return debounced(canvas, cellsize, this);

  }

  /*   
   * @returns Promise(MgLayer)
   * @override
   * 
  */
  isLayerReady(canvas, cellsize) {


    let ctx = this.offScreenContext;
    return new Promise(function (resolve, reject) {

      if (this.workerPromise != null) {
        this.isDebouncing.next(false);

        this.workerPromise.then(function (layer) {
          resolve(this);
        }.bind(this));
      }
      else if (this.callFeatureQuery == true) {
        let viewport = this._viewport;

        this.guid = this.uuidv4();

        
        this.callRestApiFeatureQuery().then(function (response) {
          this.map.changeLoadingStatus({id: this._id, loading: false});

          if (response.status == 200 && response.status < 400) {
            let data = response.data;
            let newFeatures = [];
            let geometries = [];

            let quadTree = new QuadTree(viewport.World);
            this.quadTree = quadTree;


            if (data.Results != null) {
              for (var i = 0; i < data.Results.length; i++) {

                let values = data.Results[i];
                let thematicIndex = data.Thematics.length > 0 ? data.Thematics[i] : -1;
                if ((this.hasThematics == true && thematicIndex > -1) || this.hasThematics == false) {
                  let feature = new Feature(this, this.columns, values, data.Geometries[i], data.Ids[i], data.FeatureNames[i], this.geometryFieldId, thematicIndex);
                  newFeatures.push(feature);

                  var envelope = null;
                  if (data.Bounds[i] != null)
                    envelope = new Envelope(data.Bounds[i][0], data.Bounds[i][1], data.Bounds[i][2], data.Bounds[i][3]);

                  if (feature.Shape != null && envelope != null) {
                    feature.Shape.Geometry.Bounds = envelope;
                    //geometries.push(feature.Shape.Geometry);
                    quadTree.insertFeature(feature, feature.Shape.Geometry.Bounds, 0);
                  }
                }
              }
              this.currentBounds = new Envelope(data.MinX, data.MaxX, data.MinY, data.MaxY);

            }
                        
            this._features = newFeatures;

            if (window.Worker) {
              var visibleFeatures = [];
              this.quadTree.getIntersectingFeatures(visibleFeatures, viewport.World);

              for (let feature of visibleFeatures) {
                if (feature.Shape != null) {
                  geometries.push(feature.Shape.Geometry);
                }
              }

              this.worker = new Worker(WORKER_DIR);
              this.map.changeLoadingStatus({id: 'WORKER'+this._id, loading: true});


              this.worker.onmessage = function (e) {


                
                this._visibleItems = visibleFeatures;

                for (let i = 0; i < visibleFeatures.length; i++) {
                  let feature = visibleFeatures[i];
                  if (feature.Shape != null && e.data.Points[i] != null) {
                    feature.Shape.Geometry.setWorldToClient(viewport, e.data.Points[i]);
                    feature.Shape._points = e.data.Points[i];

                    // when enabled, this bit of code renders the shape in it's hovered state

                    // if (this.map.hoverItem){
                    //   if (this.map.hoverItem.id){
                    //     // to confirm that the hoverItem and the feature are the same item, we have to match both id and 
                    //     // _featureName. This is due to some features having no id
                    //     if (this.map.hoverItem.id == feature.id && this.map.hoverItem._featureName == feature._featureName){
                    //       feature.hovering = true;
                    //     } 

                    //   } else if (this.map.hoverItem._featureName == feature._featureName){
                    //     // no id, for instance QLD LGA
                    //     feature.hovering = true;
                    //   }
                    // }


                    //If Map Activity has Search query fields (Show Results button has to be clicked), featureQuery is called twice.
                    //Once in setView and another after zoomToGeometryBounds.
                    //We prevent layer from being drawn in advance
                    if (!this.map.options.HasQueryFields) {

                      // feature.Shape.render(canvas, 1, ctx);
                      // feature.Shape.render(canvas, 1, canvas.context);
                    }
                  }
                }

                this.map.changeLoadingStatus({id: 'WORKER'+this._id, loading: false});

                this.worker.terminate();

                resolve(this);
                this.cancelable = undefined;
                this.source = undefined;
                this.promise = undefined;

                this.getVisibleFeatures(viewport.World, canvas, this._map._scale, visibleFeatures);


              }.bind(this);



              const payload = {
                World: this._viewport.World,
                Client: this._viewport.Client,
                CheckVisibility: false,
                CellSize: cellsize,
                Geometries: geometries,
              };
            

              this.worker.postMessage(payload);
              this.isDebouncing.next(false);

              this.geometries = geometries;

            }
            else {

              this._features = newFeatures;
              canvas.context.globalCompositeOperation = 'source-over';
              this.getVisibleFeatures(viewport.World, canvas, this.scale);

              
              this.isDebouncing.next(false);
              resolve(this);
            }
          }
        }.bind(this));
      }
      else if (this.searchFeaturesPromise != null) {

        this.searchFeaturesPromise.then(function (response) {
          this.searchFeaturesPromise = undefined;
          this.isDebouncing.next(false);

          resolve(this);
        }.bind(this));
      }
      else {
        // console.log(`isLayerReady - featureLayer - end  ${this.id}`); 
        if (this.offScreenCanvas != null && this.offScreenCanvas.width > 0)
          canvas.drawImage(this.offScreenCanvas, 0, 0, canvas.context);

        this.isDebouncing.next(false);


        resolve(this);
      }

    }.bind(this));
  }

  uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }
  
  render(canvas, force, context, compositeOperation) {

    let ctx = this.offScreenContext;

    if (compositeOperation)
      context.globalCompositeOperation = compositeOperation;

      if (force) {
        for (var i = 0; i < this._visibleItems.length; i++) {
          var feature = this._visibleItems[i];
          if (feature.Shape && !feature.Selected && !feature.Tracking && !feature.hovering) {
            feature.worldToClient(this._viewport, true);
            feature.Shape.draw(canvas, 1, ctx);
          } else if (feature.Shape && !feature.Selected && !feature.Tracking && feature.hovering) {
            feature.worldToClient(this._viewport, true);
            feature.Shape.drawHover(canvas, 1, ctx);
          }
        }
  
      }

    if (this.offScreenCanvas != null && this.offScreenCanvas.width > 0)
      canvas.drawImage(this.offScreenCanvas, 0, 0, context);

    if (canvas.context == context) {
      if (this.selected.length > 0) {
        for (var i = 0; i < this.selected.length; i++) {
          this.selected[i].worldToClient(this._viewport, true);
          this.selected[i].Shape.drawSelected(canvas, 1, canvas.context);
        }
      }
      if (this.tracked.length > 0) {
        for (var i = 0; i < this.tracked.length; i++) {
          this.tracked[i].worldToClient(this._viewport, true);
          this.tracked[i].Shape.drawSelected(canvas, 1, canvas.context);
        }
      }
    }


  }

  renderTracked(canvas, context, compositeOperation) {
    if (compositeOperation)
      context.globalCompositeOperation = compositeOperation;

    if (this.tracked.length > 0) {
      for (var i = 0; i < this.tracked.length; i++) {
        this.tracked[i].Shape.drawTracked(canvas, 1, context);
      }
    }
  }

  renderSelection(canvas, context, compositeOperation) {
    if (compositeOperation)
      context.globalCompositeOperation = compositeOperation;

    if (this.selected.length > 0) {
      for (var i = 0; i < this.selected.length; i++) {
        this.selected[i].Shape.drawSelected(canvas, 1, context);
      }
    }
  }

  /**
   * @param  {Bounds} rcMap
   * @param  {LatLngBounds} newExtents
   * @param  {Number} cellsize
   * @param  {Number} scale
   */
  onExtentsChanged(sourceEvent, rcMap, extents, cellsize, scale, canvas) {

    this.createOffscreenCanvas(canvas);
    var disposableViewPort = this._viewport;
    if (this.isVisible(scale)) {
      // if (disposableViewPort != null && disposableViewPort.World.equals(extents)) {
      //   disposableViewPort = null; // just re-use the current
      //   this.getVisibleFeatures(extents, canvas, scale);
      // }
      // else {
        this._viewport = new Viewport(rcMap, extents, cellsize, scale);
        let isNewScale = this.scale == undefined || this.scale != scale;

        if (sourceEvent == MapEnums.SourceEvents.AngularZoomToFeature){
          this.scale = scale;
          this.getFeatures(sourceEvent, extents, this._viewport, canvas, cellsize);
          return true;
        }

        if ((this.currentExtents == null || isNewScale) && this.map.loadFeatures) {
        // if (this.currentExtents == null) {
          this.scale = scale;
          this.getFeatures(sourceEvent, extents, this._viewport, canvas, cellsize);
        }
        else {
          if (this.currentExtents && this.currentExtents.contains(extents)) {
            // if (this.isExtentsLoaded(extents)) {
            this.cancelable = undefined;
            this.source = undefined;
            // this.promise = undefined;
            this.getVisibleFeatures(extents, canvas, scale);
          }
          else {
            this.getFeatures(sourceEvent, extents, this._viewport, canvas, cellsize);
          }
        // }
      }
    }
    else
      this._viewport = null;

      return true;
  }

  isExtentsLoaded(extents){
    var isLoaded = false;
    this.loadedExtents.forEach(le => {
      if (le.contains(extents))
        isLoaded = true;
    })

    return isLoaded;
  }

  getFeatures(sourceEvent, extents, viewport, canvas, cellsize) {
    let halfWidth = viewport.World.Width / 2;
    let halfHeight = viewport.World.Height / 2;

    //this.currentExtents = extents;
    this.currentExtents = new Envelope(extents.MinX - halfWidth, extents.MaxX + halfWidth, extents.MinY - halfHeight, extents.MaxY + halfHeight);

    if (this.source != null) {
      // this.source.cancel('new request coming thru');
    }

    //Is Map Activity with Search fields. We wait for the 'Show Results' button to be clicked. Thus map handled differently
    if (this.map.options.HasQueryFields) {
      if (this.options.ContextLayer) {
        if (sourceEvent != MapEnums.SourceEvents.AngularShowMap && 
          sourceEvent != MapEnums.SourceEvents.AngularContextLayerSearch &&
          sourceEvent != MapEnums.SourceEvents.AngularLayerVisibility) {
          //Update _visibleItems
          this.getVisibleFeatures(extents, canvas, this.scale)
        }
        else {
          //Do nothing. map.component calls the searchFeatures.
          // No it doesnt
          this.setRestApiFeatureQueryParams(cellsize);
        }
      }
      else if (this.isGeocoder == true) {
        this.getVisibleFeatures(extents, canvas, this.scale)
      }
      else if (!this.options.ContextualType || this.options.ContextualType != "ShowOnlyRelatedFeatures") {
        this.setRestApiFeatureQueryParams(cellsize);
      }
      else { //ShowOnlyRelatedFeatures --> data retrieved by searchFeatures which is called from angular
        // no, it isnt 
        // this.getVisibleFeatures(extents, canvas, this.scale);
        this.setRestApiFeatureQueryParams(cellsize);
      }
    }
    // When Map Activity has map that shows on load, or when is Geometry Map or Map Control
    else {
      if (this.options.ContextLayer == true) {
        if (!this.isGeocoder && this.quadTree == null && this.map.mapOptionType != MapEnums.MapOptionTypes.GEOMETRY) {
          // this.searchFeaturesPromise = this.searchFeatures(sourceEvent, "", -1, false, 0, 0);
          this.searchFeaturesPromise = this.searchFeatures(sourceEvent, "", -1, false, 0, 0);
        } else {
          this.getVisibleFeatures(extents, canvas, this.scale)
        }
      }
      else { //ShowOnlyRelated and ShowOtherFeatures will call FeatureQuery here. 
        //For ShowOnlyRelated, we passed from angular the Context connect object's value in the options.ContextFilter and options.UserFilter and set this.options.isIdentitySearch to true
        //For ShowOtherFeatures, we passed from angular the filter in options.UserFilter and set this.options.isIdentitySearch to true.                    
        this.setRestApiFeatureQueryParams(cellsize);

      }
    }
  }

  setRestApiFeatureQueryParams(cellsize) {
    this.cellsize = cellsize;
    this.callFeatureQuery = true;
  }

  callRestApiFeatureQuery() {
    // Clear visibleItems. If we don't, while layer is still fetching new data, hovering over previously visible item
    // will highlight the shape in the map    
    
    // this.map.changeLoadingStatus({id: this._id, loading: true});

    this._visibleItems = [];

    this.callFeatureQuery = false;

    this.source = Axios.CancelToken.source();

    let customParams = {
      cellSize: this.cellsize * MgFeatureLayer.kGeometryReducer,
      id: this._id
    }

    let params = Util.mergeOptions(this.defaultFeatureQueryParams, customParams);

    this.map.changeLoadingStatus({id: this._id, loading: true});


    return RestAPI.featureQuery(params, this.source);

  }

  getVisibleFeatures(extents, canvas, scale, overwriteFeatures) {

    this.promise = null;

    if (this.quadTree != null && this.isVisible(scale)) {
      var visibleFeatures = [];
      this.quadTree.getIntersectingFeatures(visibleFeatures, extents);

      if (this.hasThematics) {
        // this._visibleItems = [];

        for (let feature of visibleFeatures) {
          if (feature.thematicIndex >= 0 && this.thematics[feature.thematicIndex] == true) {
            if (feature.Shape) {
              feature.worldToClient(this._viewport, true)
              feature.Shape.draw(canvas, 1, canvas.context);
              feature.Shape.draw(canvas, 1, this.offScreenContext);

            }

            this._visibleItems = visibleFeatures;
          }
        }
      }
      else {
        for (let feature of visibleFeatures) {
          if (feature.Shape) {
            feature.worldToClient(this._viewport, true)
            feature.Shape.draw(canvas, 1, canvas.context);
            feature.Shape.draw(canvas, 1, this.offScreenContext);
          }
        }

        this._visibleItems = visibleFeatures;
      }
    }
  }

  deleteFeature(feature) {

    this._features = this._features.filter(f => f.id != feature.id);
    this._visibleItems = this._visibleItems.filter(f => f.id != feature.id);

  }

  drawPointAtLatLon(coordinates) {

    // coordinates: {lat: 42, lon: 2}

    this.currentBounds = new Envelope(coordinates.lat, coordinates.lat,
      coordinates.lon, coordinates.lon);

    this.currentExtents = this.currentBounds;
    this.quadTree = new QuadTree(this.currentBounds);

    let isContextualType = this.options.ContextualType && this.options.ContextualType == "ShowOnlyRelatedFeatures" && !this.options.ContextLayer;

    if (this._viewport == null)
      this._viewport = new Viewport(this.map.rcMap, this.currentExtents, this.map.cellsize, this.map.scale);

    // create point geometry

    let geometry = `POINT (${coordinates.lat} ${coordinates.lon})`;

    let id = Math.floor(Math.random() * (9999999 - 999999 + 1) + 999999);

    let feature = new Feature(this, this.columns, null, geometry, id, `${coordinates.lat}, ${coordinates.lon}`, this.geometryFieldId, -1);
    this._features.push(feature);

    var envelope = null;

    if (feature.Shape != null && this.currentBounds != null) {
      feature.Shape.Geometry.Bounds = envelope;
      this.quadTree.insertFeature(feature, feature.Shape.Geometry.Bounds, 0);
      this._visibleItems.push(feature);

      if (isContextualType) {
        feature.worldToClient(this._viewport, true)
        feature.Shape.draw(this.map.canvas, 1, this.map.canvas.context);
        feature.Shape.draw(this.map.canvas, 1, this.offScreenContext);
      }

    }
    if (this.pendingSelectionIds) {
      this.setSelection(this.pendingSelectionIds);
    }

    return this.currentBounds;

  }

  searchContextLayerFeatures(sourceEvent, userFilter, taskId, zoomToBounds = false, offset = 0, limit = 0) {
    if (this.cancelable != null) {
      this.cancelable.cancel();
    }

    this._features = [];
    this._visibleItems = [];

    let extents = new Envelope(-180.0, 180.0, -90.0, 90.0);
    /*if(sourceEvent === MapEnums.SourceEvents.AngularShowMap && this.map.options.Extents != null) {
      extents = new Envelope(this.map.options.Extents.MinX, this.map.options.Extents.MaxX, this.map.options.Extents.MinY, this.map.options.Extents.MaxY);
    }*/

    this.quadTree = new QuadTree(extents);

    this.source = Axios.CancelToken.source();

    let customParams = {
      extents: extents,
      extentsProjection: "EPSG:4326",
      sUserFilter: this.options.UserFilter != null && this.options.UserFilter != "" ? this.options.UserFilter : userFilter,
      offset: offset,
      limit: limit,
      contextType: this.contextType != MapEnums.ContextTypes.None ? this.contextType : 0,
      taskId: taskId != undefined ? taskId : -1,
      id: this._id
    }
    let params = Util.mergeOptions(this.defaultFeatureQueryParams, customParams);

    if (params.sUserFilter == '0') params.sUserFilter = "";

    let promise = RestAPI.featureQuery(params);

    this.cancelable = cancelable(promise);
    var innerPromise = new Promise(function (resolve, reject) {
      promise.then(function (response) {
        if (response.status == 200 && response.status < 400) {
          let data = response.data;
          this.currentBounds = new Envelope(data.MinX, data.MaxX, data.MinY, data.MaxY);
          //this.currentExtents = new Envelope(this.currentBounds.MinX - halfWidth, this.currentBounds.MaxX + halfWidth, this.currentBounds.MinY - halfHeight, this.currentBounds.MaxY + halfHeight);
          this.currentExtents = this.currentBounds;
          this.quadTree = new QuadTree(this.currentBounds);

          let isContextualType = this.options.ContextualType && this.options.ContextualType == "ShowOnlyRelatedFeatures" && !this.options.ContextLayer;

          if (data.Results != null) {
            if (data.Results.length == 0) {
              //__this.map.refreshView(true);
              this.map.resize();
            }

            if (this._viewport == null)
              this._viewport = new Viewport(this.map.rcMap, this.currentExtents, this.map.cellsize, this.map.scale);

            for (var i = 0; i < data.Results.length; i++) {
              let values = data.Results[i];
              let thematicIndex = data.Thematics.length > 0 ? data.Thematics[i] : -1;
              if ((this.hasThematics == true && thematicIndex > -1) || this.hasThematics == false) {


                let feature = new Feature(this, this.columns, values, data.Geometries[i], data.Ids[i], data.FeatureNames[i], this.geometryFieldId, thematicIndex);
                this._features.push(feature);

                var envelope = null;
                if (data.Bounds[i] != null)
                  envelope = new Envelope(data.Bounds[i][0], data.Bounds[i][1], data.Bounds[i][2], data.Bounds[i][3]);

                if (feature.Shape != null && envelope != null) {
                  feature.Shape.Geometry.Bounds = envelope;
                  this.quadTree.insertFeature(feature, feature.Shape.Geometry.Bounds, 0);
                  this._visibleItems.push(feature);

                  feature.worldToClient(this._viewport, true)
                  feature.Shape.draw(this.map.canvas, 1, this.map.canvas.context);
                  feature.Shape.draw(this.map.canvas, 1, this.offScreenContext);
                }

                if (this.map.geometryEditor) {

                  for (var k = 0; k < data.Geometries.length; k++) {
                    let geometry = WktReader.parseWKT(data.Geometries[k]);
                    if (geometry != null) {
                      var geometries = [];
                      var id = 100;
                      geometry.addGeometry(geometries);

                      for (let i = 0; i < geometries.length; i++) {
                        this.map.geometryEditor.addGeometry(geometries[i], id);
                        id += 1;
                      }
                    }
                  }
                }
              }
            }

            if (this.map.geometryEditor) {
              this.map.geometryEditor.onGeometryChanged();
            }

            // Happens when Map history is restored. In angular, map.component (map Item) and map.content.component (map activity) 
            // will process restoration in parallel. map.content.component will trigger searchFeatures which will clear this._features,
            // which setSelection (called by map.component) uses to find feature of interest.
            if (this.pendingSelectionIds) {
              this.setSelection(this.pendingSelectionIds);
            }

            if (zoomToBounds != false && this._features.length > 0) {
              this.map.zoomToGeometryBounds(sourceEvent, this.currentBounds, this);
            }
            resolve({ renderOrderIndex: this.options.RenderOrderIndex, bounds: this.currentBounds });
          }
        }
      }.bind(this));

    }.bind(this));

    return innerPromise;
  }

  searchCoordinates(sourceEvent, coordinates, taskId, zoomToBounds = false) {
    if (this.cancelable != null) {
      this.cancelable.cancel();
    }



    let extents = new Envelope(-180.0, 180.0, -90.0, 90.0);
    /*if(sourceEvent === MapEnums.SourceEvents.AngularShowMap && this.map.options.Extents != null) {
      extents = new Envelope(this.map.options.Extents.MinX, this.map.options.Extents.MaxX, this.map.options.Extents.MinY, this.map.options.Extents.MaxY);
    }*/

    this.quadTree = new QuadTree(extents);

    this.source = Axios.CancelToken.source();

    let customParams = {
      extents: extents,
      extentsProjection: "EPSG:4326",
      taskId: taskId != undefined ? taskId : -1,
      id: this._id,
      coordinates: `${coordinates.lat}, ${coordinates.lon}`
    }



    let params = Util.mergeOptions(this.defaultFeatureQueryParams, customParams);
    let promise = RestAPI.coordinatesQuery(params);

    this.cancelable = cancelable(promise);
    var innerPromise = new Promise(function (resolve, reject) {
      promise.then(function (response) {
        if (response.status == 200 && response.status < 400) {
          let data = response.data;

          this.currentBounds = new Envelope(data.MinX, data.MaxX, data.MinY, data.MaxY);

          this.currentExtents = this.currentBounds;
          this.quadTree = new QuadTree(this.currentBounds);

          let isContextualType = this.options.ContextualType && this.options.ContextualType == "ShowOnlyRelatedFeatures" && !this.options.ContextLayer;
          var bounds = null;

          if (data.Results != null) {
            if (data.Results.length == 0) {
              //__this.map.refreshView(true);
              this.map.resize();
            }

            if (this._viewport == null)
              this._viewport = new Viewport(this.map.rcMap, this.currentExtents, this.map.cellsize, this.map.scale);

            for (var i = 0; i < data.Results.length; i++) {
              let values = data.Results[i];
              let thematicIndex = data.Thematics.length > 0 ? data.Thematics[i] : -1;
              if ((this.hasThematics == true && thematicIndex > -1) || this.hasThematics == false) {
                let feature = new Feature(this, this.columns, values, data.Geometries[i], data.Ids[i], data.FeatureNames[i], this.geometryFieldId, thematicIndex);
                this._features.push(feature);

                var envelope = null;
                if (data.Bounds[i] != null)
                  envelope = new Envelope(data.Bounds[i][0], data.Bounds[i][1], data.Bounds[i][2], data.Bounds[i][3]);

                if (feature.Shape != null && envelope != null) {
                  feature.Shape.Geometry.Bounds = envelope;
                  this.quadTree.insertFeature(feature, feature.Shape.Geometry.Bounds, 0);
                  this._visibleItems.push(feature);

                  if (isContextualType) {
                    feature.worldToClient(this._viewport, true)
                    feature.Shape.draw(this.map.canvas, 1, this.map.canvas.context);
                    feature.Shape.draw(this.map.canvas, 1, this.offScreenContext);
                  }
                }
                if (bounds == null)
                  bounds = new Envelope(feature.Shape.Geometry.Bounds.MinX, feature.Shape.Geometry.Bounds.MaxX, feature.Shape.Geometry.Bounds.MinY, feature.Shape.Geometry.Bounds.MaxY);
                else
                  bounds.extend(feature.Shape.Geometry.Bounds);
              }
            }



            // Happens when Map history is restored. In angular, map.component (map Item) and map.content.component (map activity) 
            // will process restoration in parallel. map.content.component will trigger searchFeatures which will clear this._features,
            // which setSelection (called by map.component) uses to find feature of interest.
            if (this.pendingSelectionIds) {
              this.setSelection(this.pendingSelectionIds);
            }

            if (zoomToBounds != false && this._features.length > 0) {
              // this.map.zoomToGeometryBounds(sourceEvent, bounds, this, false, coordinates);      
              this.zoomToFeature(sourceEvent, [this._id], false);
            }
            resolve({ renderOrderIndex: this.options.RenderOrderIndex, bounds: bounds });
          }
        }
      }.bind(this));

    }.bind(this));

    return innerPromise;
  }

  searchFeatures(sourceEvent, userFilter, taskId, zoomToBounds = false, offset = 0, limit = 0) {
    if (this.cancelable != null) {
      this.cancelable.cancel();
    }

    this._features = [];
    this._visibleItems = [];

    let extents = new Envelope(-180.0, 180.0, -90.0, 90.0);
    /*if(sourceEvent === MapEnums.SourceEvents.AngularShowMap && this.map.options.Extents != null) {
      extents = new Envelope(this.map.options.Extents.MinX, this.map.options.Extents.MaxX, this.map.options.Extents.MinY, this.map.options.Extents.MaxY);
    }*/

    this.quadTree = new QuadTree(extents);

    this.source = Axios.CancelToken.source();

    let customParams = {
      extents: extents,
      extentsProjection: "EPSG:4326",
      sUserFilter: this.options.UserFilter != null && this.options.UserFilter != "" ? this.options.UserFilter : userFilter,
      offset: offset,
      limit: limit,
      contextType: this.contextType != MapEnums.ContextTypes.None ? this.contextType : 0,
      taskId: taskId != undefined ? taskId : -1,
      id: this._id
    }
    let params = Util.mergeOptions(this.defaultFeatureQueryParams, customParams);
    let promise = RestAPI.featureQuery(params);

    this.cancelable = cancelable(promise);
    var innerPromise = new Promise(function (resolve, reject) {
      promise.then(function (response) {
        if (response.status == 200 && response.status < 400) {
          let data = response.data;

          this.currentBounds = new Envelope(data.MinX, data.MaxX, data.MinY, data.MaxY);

          let halfWidth = this.currentBounds.Width / 2;
          let halfHeight = this.currentBounds.Height / 2;
          //this.currentExtents = new Envelope(this.currentBounds.MinX - halfWidth, this.currentBounds.MaxX + halfWidth, this.currentBounds.MinY - halfHeight, this.currentBounds.MaxY + halfHeight);
          this.currentExtents = this.currentBounds;
          this.quadTree = new QuadTree(this.currentBounds);

          let isContextualType = this.options.ContextualType && this.options.ContextualType == "ShowOnlyRelatedFeatures" && !this.options.ContextLayer;

          if (data.Results != null) {
            if (data.Results.length == 0) {
              //__this.map.refreshView(true);
              this.map.resize();
            }

            if (this._viewport == null)
              this._viewport = new Viewport(this.map.rcMap, this.currentExtents, this.map.cellsize, this.map.scale);

            for (var i = 0; i < data.Results.length; i++) {
              let values = data.Results[i];
              let thematicIndex = data.Thematics.length > 0 ? data.Thematics[i] : -1;
              if ((this.hasThematics == true && thematicIndex > -1) || this.hasThematics == false) {
                let feature = new Feature(this, this.columns, values, data.Geometries[i], data.Ids[i], data.FeatureNames[i], this.geometryFieldId, thematicIndex);
                this._features.push(feature);

                var envelope = null;
                if (data.Bounds[i] != null)
                  envelope = new Envelope(data.Bounds[i][0], data.Bounds[i][1], data.Bounds[i][2], data.Bounds[i][3]);

                if (feature.Shape != null && envelope != null) {
                  feature.Shape.Geometry.Bounds = envelope;
                  this.quadTree.insertFeature(feature, feature.Shape.Geometry.Bounds, 0);
                  this._visibleItems.push(feature);

                  if (isContextualType) {
                    feature.worldToClient(this._viewport, true)
                    feature.Shape.draw(this.map.canvas, 1, this.map.canvas.context);
                    feature.Shape.draw(this.map.canvas, 1, this.offScreenContext);
                  }
                }
              }
            }

            // Happens when Map history is restored. In angular, map.component (map Item) and map.content.component (map activity) 
            // will process restoration in parallel. map.content.component will trigger searchFeatures which will clear this._features,
            // which setSelection (called by map.component) uses to find feature of interest.
            if (this.pendingSelectionIds) {
              this.setSelection(this.pendingSelectionIds);
            }

            if (zoomToBounds != false && this._features.length > 0) {
              this.map.zoomToGeometryBounds(sourceEvent, this.currentBounds, this);
            }
            resolve({ renderOrderIndex: this.options.RenderOrderIndex, bounds: this.currentBounds });
          }
        }
      }.bind(this));

    }.bind(this));

    return innerPromise;
  }

  syncSearchResults(results) {
    this._features = [];
    this._visibleItems = [];

    let columns = ["0", "1", "2"];

    for (var i = 0; i < results.length; i++) {
      let values = ["", results[i].ItemId, results[i].Name];
      let feature = new Feature(this, columns, values, results[i].WKT, results[i].ItemId, results[i].Name, "0", -1);
      this._features.push(feature);

      var envelope = new Envelope(results[i].MinX, results[i].MaxX, results[i].MinY, results[i].MaxY);

      if (feature.Shape != null && envelope != null) {
        feature.Shape.Geometry.Bounds = envelope;
        this._visibleItems.push(feature);
      }
    }
  }

  searchAndZoomToFeatures(sourceEvent, userFilter) {
    if (this.cancelable != null) {
      this.cancelable.cancel();
    }

    let extents = new Envelope(-180.0, 180.0, -90.0, 90.0);
    this.source = Axios.CancelToken.source();

    let customParams = {
      extents: extents,
      extentsProjection: "EPSG:4326",
      sUserFilter: userFilter,
      contextType: 0,
      isIdentitySearch: true
    }

    let params = Util.mergeOptions(this.defaultFeatureQueryParams, customParams);
    params.taskId = -1;
    let promise = RestAPI.featureQuery(params);

    this.cancelable = cancelable(promise);

    promise.then(function (response) {
      if (response.status == 200 && response.status < 400) {
        let data = response.data;

        this.currentBounds = new Envelope(data.MinX, data.MaxX, data.MinY, data.MaxY);

        let halfWidth = this.currentBounds.Width / 2;
        let halfHeight = this.currentBounds.Height / 2;
        this.currentExtents = new Envelope(this.currentBounds.MinX - halfWidth, this.currentBounds.MaxX + halfWidth, this.currentBounds.MinY - halfHeight, this.currentBounds.MaxY + halfHeight);

        this.quadTree = new QuadTree(this.currentBounds);

        if (data.Results != null) {
          var bounds = null;
          for (var i = 0; i < data.Results.length; i++) {
            let values = data.Results[i];
            let thematicIndex = data.Thematics.length > 0 ? data.Thematics[i] : -1;
            if ((this.hasThematics == true && thematicIndex > -1) || this.hasThematics == false) {
              let feature = new Feature(this, this.columns, values, data.Geometries[i], data.Ids[i], data.FeatureNames[i], this.geometryFieldId, thematicIndex);
              this._features.push(feature);

              var envelope = null;
              if (data.Bounds[i] != null)
                envelope = new Envelope(data.Bounds[i][0], data.Bounds[i][1], data.Bounds[i][2], data.Bounds[i][3]);

              if (feature.Shape != null && envelope != null) {
                feature.Selected = true;
                feature.Shape.Geometry.Bounds = envelope;
                this.quadTree.insertFeature(feature, feature.Shape.Geometry.Bounds, 0);
                this._visibleItems.push(feature);

                if (bounds == null)
                  bounds = new Envelope(feature.Shape.Geometry.Bounds.MinX, feature.Shape.Geometry.Bounds.MaxX, feature.Shape.Geometry.Bounds.MinY, feature.Shape.Geometry.Bounds.MaxY);
                else
                  bounds.extend(feature.Shape.Geometry.Bounds);
              }
            }
          }


          if (bounds != null) {
            this.map.zoomToGeometryBounds(sourceEvent, bounds, this);
          }
        }
      }
    }.bind(this));
  }

  zoomToLayer(sourceEvent) {
    this.visible = true;
    if (this.hasThematics) {
      for (var i = 0; i < this.thematics.length; i++) {
        this.thematics[i] = true;
      }

      if (this.options.ContextLayer == false && this.isGeocoder == false) {
        this.clearItems();
        this.quadTree = null;
        this._viewport = null;
        this.currentExtents = null;
      }
    }
    else {
      if (this.options.ContextLayer == false && this.isGeocoder == false) {
        this.clearItems();
        this._viewport = null;
        this.currentExtents = null;
      }
    }

    this.searchFeaturesPromise = this.searchFeatures(sourceEvent, "", -1, true, 0, 0);
  }

  zoomToFeature(sourceEvent, ids, isInitial) {
    this.clearSelection();
    this.visible = true;
    if (this.hasThematics) {
      for (var i = 0; i < this.thematics.length; i++) {
        this.thematics[i] = true;
      }
    }

    if (ids != null && ids.length > 0) {
      var bounds = null;
      var idsFound = 0;

      var mapSelectedItems = [];

      for (let i = 0; i < ids.length; i++) {
        let sId = ids[i].toString();
        var matches = this._features.filter(x => x.id == sId || x.Id == sId).map(x => x);

        if (matches == null && matches.length > 0)
          matches = this._features.filter(x => x.FeatureName.toString() == sId);

        if (matches != null && matches.length > 0)
          idsFound += 1

        for (let feature of matches) {
          if (feature.Shape != null && feature.Shape.Geometry.Bounds != null) {
            feature.worldToClient(this._viewport, true);
            feature.Selected = true;
            mapSelectedItems.push(feature);

            if (bounds == null)
              bounds = new Envelope(feature.Shape.Geometry.Bounds.MinX, feature.Shape.Geometry.Bounds.MaxX, feature.Shape.Geometry.Bounds.MinY, feature.Shape.Geometry.Bounds.MaxY);
            else
              bounds.extend(feature.Shape.Geometry.Bounds);
          }
        }
      }

      if (bounds != null && idsFound == ids.length) {
        // if this is the initial zoomToFeature, we need to re-initialise the extents and bounds
        // so that if zoomToGeometryBounds will work properly
        if (isInitial != undefined && isInitial == true) {
          this.map.setInitialExtents(sourceEvent, bounds, true);
        }

        this.createOffscreenCanvas(this.map.canvas);
        if ((this.options.GeometryType == "MultiPoint" || this.options.GeometryType == "Point") && ids.length == 1)
          this.map.zoomToGeometry(bounds, this);
        else
          this.map.zoomToGeometryBounds(sourceEvent, bounds, this);

        //Set Map Selection
        if (mapSelectedItems != null)
          this._map.setSelection(mapSelectedItems);
      }
      else {
        // If feature is not visible in the map, make an API call to search for it
        // and zoom to that feature
        var sFeatureIds = ids.join("|");
        this.searchAndZoomToFeatures(sourceEvent, sFeatureIds)
      }
    }
    else {
      if (this.currentBounds != null) {
        this.createOffscreenCanvas(this.map.canvas);
        if ((this.options.GeometryType == "MultiPoint" || this.options.GeometryType == "Point") && ids.length == 1)
          this.map.zoomToGeometry(bounds, this);
        else
          this.map.zoomToGeometryBounds(sourceEvent, bounds, this);
      }

      //Clear map selection
      this._map.setSelection([]);
    }
  }

  set pendingSelectionIds(ids) { this._pendingSelectionIds = ids; }
  get pendingSelectionIds() { return this._pendingSelectionIds; }

  setSelection(ids) {
    if (this._features == null || this._features.length == 0) {
      this.pendingSelectionIds = ids;
      return;
    }

    // Clear pending Ids
    if (this.pendingSelectionIds && this.pendingSelectionIds.length > 0)
      this.pendingSelectionIds = null;

    this.clearSelection();

    if (ids != null && ids.length > 0) {
      var idsFound = 0;

      for (let i = 0; i < ids.length; i++) {
        let sId = ids[i].toString();
        var matches = this._features.filter(x => x.id == sId || x.Id == sId).map(x => x);

        if (matches == null && matches.length > 0)
          matches = this._features.filter(x => x.FeatureName.toString() == sId);

        if (matches != null && matches.length > 0)
          idsFound += 1

        for (let feature of matches) {
          if (feature.Shape != null && feature.Shape.Geometry.Bounds != null) {
            feature.Selected = true;
          }
        }
      }

    }
  }

  getFeatureById(id) {
    for (var i = 0; i < this._features.length; i++) {
      let feature = this._features[i];
      if (feature.Id == id) {
        return feature;
      }
    }
    return null;
  }

  getHitItem(x, y) {
    for (var i = 0; i < this._visibleItems.length; i++) {
      let feature = this._visibleItems[i];
      if (feature.hitTest(x, y)) {
        // feature.hovering = true;
        return feature;
      }
    }

    return null;
  }

  getHitItems(x, y) {
    let items = [];
    var dctIds = {};
    for (var i = 0; i < this._features.length; i++) {
      let feature = this._features[i];
      if (feature.hitTest(x, y)) {
        if (!dctIds.hasOwnProperty(feature.id)) {
          items.push(feature);
          dctIds[feature.id] = feature;
        }
      }
    }

    return items;
  }

  getHitItemsFeatureInfo(x, y) {
    let featureInfos = [];
    var dctIds = {};
    let hasSelectedItems = false;

    for (var i = 0; i < this._visibleItems.length; i++) {
      //I think we only look at visibleItems for hits
      //for (var i = 0; i < this._features.length; i++) {
      //let feature = this._features[i];
      let feature = this._visibleItems[i];
      if (feature.hitTest(x, y)) {
        if (!feature.Selected) {
          if (!dctIds.hasOwnProperty(feature.id)) {
            let featureInfo = {
              Dataset: this.options.Dataset,
              LayerId: this.options.RenderOrderIndex,
              FeatureId: feature.Id,
              FeatureName: feature.FeatureName,
              Selected: false
            }

            featureInfos.push(featureInfo);
            dctIds[feature.id] = feature;
          }
        }
        else {
          hasSelectedItems = true;
          break;
        }
      }
    }

    if (hasSelectedItems) {
      featureInfos = [];
      dctIds = {};

      for (var i = 0; i < this.selected.length; i++) {
        let feature = this.selected[i];
        if (!dctIds.hasOwnProperty(feature.id)) {
          let featureInfo = {
            Dataset: this.options.Dataset,
            LayerId: this.options.RenderOrderIndex,
            FeatureId: feature.Id,
            FeatureName: feature.FeatureName,
            Selected: true
          }

          featureInfos.push(featureInfo);
          dctIds[feature.id] = feature;
        }
      }
    }

    return featureInfos;
  }

  hitTest(x, y) {
    for (var i = 0; i < this._features.length; i++) {
      let feature = this._features[i];
      if (feature.hitTest(x, y)) {
        break;
      }
    }
  }

  get canHover() {
    return !this.backgroundLayer;
  }

  get canShowTooltip() {
    return this.hover != null && !this.backgroundLayer;
  }

  get canClick() {
    if (this.options != null && this.options.canClick != null)
      return this.options.canClick;

    return true;
  }

  getIntersections(bounds) {
    this.clearSelection();


    for (var i = 0; i < this._features.length; i++) {
      let feature = this._features[i];
      if (feature.intersects(bounds)) {
        feature.Selected = true;
      }
    }

    return this.selected;
  }

  clearSelection() {
    if (this.selected.length > 0) {
      for (var i = 0; i < this.selected.length; i++) {
        this.selected[i].Selected = false;
      }
    }

    this.selected = [];
  }


  removeFromTracked(feature){
    for (let i = 0; i < this.tracked.length; i++) {
      if (this.tracked[i].id === feature.id) {
        this.tracked.splice(i, 1);
        break;
      }
    }
  }
  

  removeItemFromSelection(feature) {
    for (let i = 0; i < this.selected.length; i++) {
      if (this.selected[i].id === feature.id) {
        this.selected.splice(i, 1);
        break;
      }
    }
  }

  get defaultOptions() {
    var _defaultOptions = {
      minZoom: 0,     // Number
      maxZoom: 18,    // Number
    }

    _defaultOptions = Util.mergeOptions(_defaultOptions, this.getWCLayerOptions())
    return _defaultOptions;
  }

  getWCLayerOptions() {
    let parentOptions = super.getWCLayerOptions();

    let layerOptions = {
      Datasource: {
        Type: null,                   // MapEnums.DatasourceTypes
        Url: null,                    // String
        Key: null                     // String
      },
      GeometryType: null,             // MapEnums.GeometryTypes,
      BackgroundLayer: null,          // Boolean
      HoverLayout: null,              // String
      InfoLayout: null,               // String
      Symbology: {
        Point: {
          Type: null,                 // MapEnums.SymbolTypes
          Height: null,               // Number

          /* Type == OutlineShape | SolidShape */
          Shape: null,                // MapEnums.Shapes
          Colour: null,               // String

          /* Type == Glyph */
          SymbolId: null,             // Number -- this might be changed into GlyphUrl
        },

        Line: {
          Type: null,                // MapEnums.StrokeTypes 

          /* Type == Solid */
          Width: null,                // Number
          Colour: null,               // String,

          /* Type == DashStyle */
          DashStyle: null,            // MapEnums.DashStyles

          /* Type == DashPattern */
          DashPattern: null,          // String

          /* Type == Pattern */
          PatternId: null,            // Number
          PatternHeight: null,        // Number
        },
        Polygon: {
          Stroke: {                   //Same as LineStyle
            Type: null,              // MapEnums.StrokeTypes 

            /* Type == Solid */
            Width: null,              // Number
            Colour: null,             // String,

            /* Type == DashStyle */
            DashStyle: null,          // MapEnums.DashStyles

            /* Type == DashPattern */
            DashPattern: null,        // String

            /* Type == Pattern */
            PatternId: null,          // Number
            PatternHeight: null,      // Number
          },
          Fill: {
            Type: null,               // MapEnums.FillTypes

            /* Type == Solid */
            Colour: null,             // String

            /* Type == Hatch */
            BackgroundColour: null,   // String
            HatchStyle: null,         // MapEnums.HatchStyles

            /* Type == Pattern */
            PatternId: null,          // Number
            PatternHeight: null,      // Number
            PatternSpacing: null,     // Number
          }
        }
      }
    }

    layerOptions = Util.mergeOptions(parentOptions, layerOptions);
    return layerOptions;
  }

  processSearchColumns() {
    this.columns = [];
    if (this.options.Label != null) {
      let label = this.options.Label.Label != null ? JSON.parse(this.options.Label.Label) : null;
      if (label != null) {
        if (label.Type == 'Field' || label.Type == 'SpatialField')
          this.columns.push(String(label.Id));
        else if (label.Type == 'Concatenate') {
          if (label.Comparisons != null) {
            for (let i = 0; i < label.Comparisons.length; i++) {
              let comparison = label.Comparisons[i];

              if (comparison.Id != null) {
                this.columns.push(String(comparison.Id));
              }
            }
          }
          else {
            if (label.Rhs.Id != null)
              this.columns.push(String(label.Rhs.Id));
            if (label.Lhs.Id != null)
              this.columns.push(String(label.Lhs.Id));
          }
        }
      }
    }

    this.geometryFieldId = this.options.GeometryFieldId;
    this.columns.push(String(this.geometryFieldId));

    // Create Hover object and insert columns
    var otherColumns = [];
    if (this.options.Hover != null) {
      this.hover = new Hover(this.options.Hover, this.options.DatasetType == "Feature");
      otherColumns = this.hover.ExpressionColumns;
    }

    if (this.labelStyle != null) {

      otherColumns.concat(this.labelStyle.columns);
    }

    if (this.hover != null && this.options.IdentityField) {
      let jIdField = JSON.parse(this.options.IdentityField);
      otherColumns.push(jIdField.Id.toString());
      this.IdentityField = jIdField.Id.toString();
    }

    // Add other columns to the search columns
    for (let i = 0; i < otherColumns.length; i++) {
      let column = otherColumns[i];
      let index = this.columns.indexOf(column);
      if (index == -1)
        this.columns.push(column.toString());
    }

    this.addThematicFieldsToColumns();
    this.columns.sort(function (a, b) {
      return a.length - b.length || // sort by length, if equal then
        a.toString().localeCompare(b);    // sort by dictionary order
    });
  }

  addThematicFieldsToColumns() {
    if (this.options.Thematics != null) {
      let fields = this.options.Fields;
      let sCustomField = 'DbCustomField,'
      for (let i = 0; i < this.options.Thematics.length; i++) {
        let filter = this.options.Thematics[i].Filter;

        if (filter)
          var pos = filter.indexOf(sCustomField);
        else
          return;

        while (pos > -1) {
          let pos2 = filter.indexOf(',', pos + sCustomField.length + 1);
          let customFieldId = filter.substring(sCustomField.length + pos, pos2);
          let index = this.columns.indexOf(customFieldId);
          if (index == -1)
            this.columns.push(customFieldId);

          pos = filter.indexOf(sCustomField, pos2);
        }
      }
    }
  }

  createDefaultStyles(symbology, label) {
    this.pointStyle = null;
    this.lineStyle = null;
    this.polygonStrokeStyle = null;
    this.polygonFillStyle = null;
    this.labelStyle = null;

    if (label != null) {
      this.labelStyle = new Label(label);
    }

    if (symbology.Point != null && symbology.Point.Type != undefined) {
      this.pointStyle = new Symbol(symbology.Point);
      this.pointStyle.loadSymbols(RestAPI.BaseUrl, RestAPI.AccessToken, this.options, RestAPI.debugMode);
    }

    if (symbology.Line != null && symbology.Line.Type != undefined) {
      this.lineStyle = new Stroke(symbology.Line);

      let hoverColour = this.options.HoverColour != null ? this.options.HoverColour : '#007fff';
      let hoverOutlineWidth = this.options.HoverOutlineWidth != null ? parseFloat(this.options.HoverOutlineWidth) / 100 : 2;

      let selectedColour = this.options.SelectedColour != null ? this.options.SelectedColour : '#007fff';
      let selectedOutlineWidth = this.options.SelectedOutlineWidth != null ? parseFloat(this.options.SelectedOutlineWidth) / 100 : 2;

      this.lineStyle.setHoverAndSelectedStyles(symbology.Line, hoverColour, hoverOutlineWidth, selectedColour, selectedOutlineWidth);

      this.lineStyle.loadPattern(RestAPI.BaseUrl, RestAPI.AccessToken, RestAPI.debugMode);
    }

    if (symbology.Polygon != null) {
      if (symbology.Polygon.Stroke != null && symbology.Polygon.Stroke.Type != undefined) {
        this.polygonStrokeStyle = new Stroke(symbology.Polygon.Stroke);

        let hoverColour = this.options.HoverColour != null ? this.options.HoverColour : '#007fff';
        let hoverOutlineWidth = this.options.HoverOutlineWidth != null ? parseFloat(this.options.HoverOutlineWidth) / 100 : 2;

        let selectedColour = this.options.SelectedColour != null ? this.options.SelectedColour : '#007fff';
        let selectedOutlineWidth = this.options.SelectedOutlineWidth != null ? parseFloat(this.options.SelectedOutlineWidth) / 100 : 2;

        let trackingColour = this.options.TrackingColour != null ? this.options.TrackingColour : '#007fff';
        let trackingOutlineWidth = this.options.TrackingOutlineWidth != null ? parseFloat(this.options.TrackingOutlineWidth) / 100 : 2;

        this.polygonStrokeStyle.setTrackingStyle(symbology.Polygon.Stroke, trackingColour, trackingOutlineWidth);
        this.polygonStrokeStyle.setHoverAndSelectedStyles(symbology.Polygon.Stroke, hoverColour, hoverOutlineWidth, selectedColour, selectedOutlineWidth);
        this.polygonStrokeStyle.loadPattern(RestAPI.BaseUrl, RestAPI.AccessToken, RestAPI.debugMode);
      }

      if (symbology.Polygon.Fill != null && symbology.Polygon.Fill.Type != undefined) {
        this.polygonFillStyle = new Fill(symbology.Polygon.Fill);
        let hoverColour = this.options.HoverColour != null ? this.options.HoverColour : '#007fff';
        this.polygonFillStyle.loadHoverPatten(RestAPI.BaseUrl, RestAPI.AccessToken, RestAPI.debugMode, hoverColour);
        this.polygonFillStyle.loadPattern(RestAPI.BaseUrl, RestAPI.AccessToken, RestAPI.debugMode);
      }

      if (this.polygonFillStyle == null)
        this.polygonFillStyle = new Fill(symbology.Polygon.Fill);

      if (this.options.TrackingFillColour)
        this.polygonFillStyle.setTrackingStyles(this.options.TrackingFillColour);

      if (this.options.HoverFillColour)
        this.polygonFillStyle.setHoverStyles(this.options.HoverFillColour);

      if (this.options.SelectedFillColour)
        this.polygonFillStyle.setSelectedStyles(this.options.SelectedFillColour);

      this.polygonStyle = {
        "Fill": this.polygonFillStyle,
        "Stroke": this.polygonStrokeStyle,
      }

    }

    this.hasStyle = this.pointStyle != null || this.lineStyle != null || this.polygonFillStyle != null || this.polygonStrokeStyle != null;
  }

  createCustomStyles(thematics) {
    if (thematics != null) {
      for (let i = 0; i < thematics.length; i++) {
        let symbology = thematics[i].Symbology;
        let pointStyle = null;
        let lineStyle = null;
        let polygonStyle = null;

        if (symbology.Point != null && symbology.Point.Type != undefined) {
          pointStyle = new Symbol(symbology.Point);
          pointStyle.loadSymbols(RestAPI.BaseUrl, RestAPI.AccessToken, this.options, RestAPI.debugMode);
        }

        if (symbology.Line != null && symbology.Line.Type != undefined) {
          lineStyle = new Stroke(symbology.Line);

          let hoverColour = this.options.HoverColour != null ? this.options.HoverColour : '#007fff';
          let hoverOutlineWidth = this.options.HoverOutlineWidth != null ? parseFloat(this.options.HoverOutlineWidth) / 100 : 2;

          let selectedColour = this.options.SelectedColour != null ? this.options.SelectedColour : '#007fff';
          let selectedOutlineWidth = this.options.SelectedOutlineWidth != null ? parseFloat(this.options.SelectedOutlineWidth) / 100 : 2;

          lineStyle.setHoverAndSelectedStyles(symbology.Line, hoverColour, hoverOutlineWidth, selectedColour, selectedOutlineWidth);
          lineStyle.loadPattern(RestAPI.BaseUrl, RestAPI.AccessToken, RestAPI.debugMode);
        }

        if (symbology.Polygon != null) {
          let polygonStrokeStyle = null;
          let polygonFillStyle = null;
          if (symbology.Polygon.Stroke != null && symbology.Polygon.Stroke.Type != undefined) {
            polygonStrokeStyle = new Stroke(symbology.Polygon.Stroke);

            let hoverColour = this.options.HoverColour != null ? this.options.HoverColour : '#007fff';
            let hoverOutlineWidth = this.options.HoverOutlineWidth != null ? parseFloat(this.options.HoverOutlineWidth) / 100 : 2;

            let selectedColour = this.options.SelectedColour != null ? this.options.SelectedColour : '#007fff';
            let selectedOutlineWidth = this.options.SelectedOutlineWidth != null ? parseFloat(this.options.SelectedOutlineWidth) / 100 : 2;
            
            let trackingColour = this.options.TrackingColour != null ? this.options.TrackingColour : '#007fff';
            let trackingOutlineWidth = this.options.TrackingOutlineWidth != null ? parseFloat(this.options.TrackingOutlineWidth) / 100 : 2;
  
            polygonStrokeStyle.setTrackingStyle(symbology.Polygon.Stroke, trackingColour, trackingOutlineWidth);         
            polygonStrokeStyle.setHoverAndSelectedStyles(symbology.Polygon.Stroke, hoverColour, hoverOutlineWidth, selectedColour, selectedOutlineWidth);
            polygonStrokeStyle.loadPattern(RestAPI.BaseUrl, RestAPI.AccessToken, RestAPI.debugMode);
          }

          if (symbology.Polygon.Fill != null && symbology.Polygon.Fill.Type != undefined) {
            polygonFillStyle = new Fill(symbology.Polygon.Fill);

            let hoverColour = this.options.HoverColour != null ? this.options.HoverColour : '#007fff';

            polygonFillStyle.loadHoverPatten(RestAPI.BaseUrl, RestAPI.AccessToken, RestAPI.debugMode, hoverColour);
            polygonFillStyle.loadPattern(RestAPI.BaseUrl, RestAPI.AccessToken, RestAPI.debugMode);
          }

          if (polygonFillStyle == null)
            polygonFillStyle = new Fill(symbology.Polygon.Fill);

          if (this.options.TrackingFillColour)
            this.polygonFillStyle.setTrackingStyles(this.options.TrackingFillColour);
    
          if (this.options.HoverFillColour)
            this.polygonFillStyle.setHoverStyles(this.options.HoverFillColour);
    
          if (this.options.SelectedFillColour)
            this.polygonFillStyle.setSelectedStyles(this.options.SelectedFillColour);
    
          polygonStyle = {
            "Fill": polygonFillStyle,
            "Stroke": polygonStrokeStyle,
          }
        }

        let customStyle = {
          "PointStyle": pointStyle,
          "LineStyle": lineStyle,
          "PolygonStyle": polygonStyle,
        }

        this.thematics.push(this.visible);
        this.customStyles.push(customStyle);
      }

      this.hasStyle = this.thematics.length > 0;
      this.hasThematics = this.thematics.length > 0;
    }
  }

  checkContextualType() {
    /*
    ShowAllFeatures : 0,
    ShowOnlyRelatedFeatures : 1,
    ShowOtherFeatures : 2,
    Primary: 3
    */
    this.contextType = MapEnums.ContextTypes.None;
    if (this.options.ContextualType == "ShowAllFeatures")
      this.contextType = MapEnums.ContextTypes.ShowAllFeatures;
    else if (this.options.ContextualType == "ShowOnlyRelatedFeatures")
      this.contextType = MapEnums.ContextTypes.ShowOnlyRelatedFeatures;
    else if (this.options.ContextualType == "ShowOtherFeatures")
      this.contextType = MapEnums.ContextTypes.ShowOtherFeatures;
    else if (this.options.ContextualType == "Primary")
      this.contextType = MapEnums.ContextTypes.Primary;
  }
  get PointStyle() {
    return this.pointStyle;
  }

  get LineStyle() {
    return this.lineStyle;
  }

  get PolygonStyle() {
    return this.polygonStyle;
  }

  get Thematics() {
    return this.customStyles;
  }

  get Hover() {
    return this.hover;
  }

  get EnabledThematics() {
    let thematics = [];
    for (let i = 0; i < this.thematics.length; i++) {
      if (this.thematics[i])
        thematics.push(i);
    }

    return thematics;
  }

  get Columns() {
    return this.columns;
  }

  get FlatColumns() {
    return this.columns.join(',');
  }

  get Viewport() {
    return this._viewport;
  }

  
  isAlreadyTracked(id){
    return this.tracked.findIndex(x => x.id == id) >= 0;
  }

  isFeatureAlreadySelected(id) {
    return this.selected.findIndex(x => x.id == id) >= 0;
  }

  get defaultFeatureQueryParams() {
    let params = {
      datasetId: this.options.Dataset,
      extents: this.currentExtents,
      extentsProjection: this.map.options.Projection,
      mapProjection: this.map.options.Projection,
      columns: this.columns,
      thematics: this.EnabledThematics,
      geometryField: this.geometryFieldId,
      sortField: this.columns[0],
      sContextFilter: this.contextType == MapEnums.ContextTypes.ShowOnlyRelatedFeatures || this.contextType == MapEnums.ContextTypes.ShowOtherFeatures ? this.options.ContextFilter : "",
      sLayerFilter: this.options.Filter,
      sUserFilter: this.options.UserFilter != null ? this.options.UserFilter : null,
      offset: 0,
      limit: 0,
      cellSize: 0,
      contextType: this.contextType, //this.contextType != MapEnums.ContextTypes.None ? this.contextType : 0,
      taskId: -1,
      isIdentitySearch: this.options.isIdentitySearch != null ? this.options.isIdentitySearch : false,
      contextFilterDatasource: this.options.ContextFilterDatasource,
      source: this.source,
    }
    return params;
  }

}

MgFeatureLayer.kGeometryReducer = 0.4;