import {TileSource} from './tilesource';

export class GoogleTileSource extends TileSource
{
  // @section Constructors

  constructor(map) {
    super(map);
  }

  // @section Properties

  // @section TileSource Methods

  getTileUrl(level, col, row) {    
    let seclen = ((col * 3) + row) % 8;
    let serverNum = (col + 2 * row) % 4;
    let sec1 = (row >= 10000 && row < 100000) ? "&s=" : '';
    let sec2 = "Galileo".substring(0, seclen);

    //String.Format("http://{0}{1}.google.com/{2}/{10}={3}&hl={4}&x={5}{6}&y={7}&z={8}&s={9}", "mt", serverNum, "vt", "m@130", "English", col, sec1, row, level, sec2, "lyrs");
    let sTileUrl = `https://mt${serverNum}.google.com/vt/lyrs=m@130&hl=en&x=${col}${sec1}&y=${row}&z=${level}&s=${sec2}`;

    //var sTileUrl = `https://${domains[i]}.tile.openstreetmap.org/${level}/${col}/${row}.png`
    //var sTileUrl = `https://mt0.google.com/vt/lyrs=s&hl=en&x=${col}&y=${row}&z=${level}&s=Ga`;
    return sTileUrl;
  }

}