import * as Colour from './Colour';
import MapEnums from '../map/enums';
import { RestAPI } from '../api';

export class Symbol {
  /*
   "Point":
      {
        "Type":"SolidShape",
        "Height":16.0,
        "Shape":"Circle",
        "Colour":"Black"
      }
  */

  constructor(pointStyle) {   
    this.style = pointStyle; 
    this.height = pointStyle.Height;
    this.shape = this.getShapeType(pointStyle.Shape);
    this.colour = pointStyle.Colour;
    this.type = this.getSymbolType(pointStyle.Type);
    this.symbolId = pointStyle.SymbolId;

    this.stroke = null;
    this.fill = null;
    this.pattern = null;

    // symbolType
    // 0 - Normal
    // 1 - Hover Symbol
    // 2 - Selection Symbol

    /*
    if (this.shape == MapEnums.Shapes.Marker || this.shape == MapEnums.Shapes.Pin) {
      let svgSymbol = this.shape == MapEnums.Shapes.Marker ? Symbol.Marker : Symbol.Pin;
      svgSymbol = svgSymbol.replace("{{colour}}", this.colour);
      svgSymbol = svgSymbol.replace("{{width}}", pointStyle.Height);
      svgSymbol = svgSymbol.replace("{{height}}", pointStyle.Height);

      this.pattern = new Image();
      let img = this.pattern;
      
      this.pattern.onload = function () {
        img.width = pointStyle.Height;
        img.height = pointStyle.Height;
      };
      this.pattern.src = "data:image/svg+xml;charset=utf8, " + this.sanitisedSVG(svgSymbol);
    }
    */
  }

  getSymbolType(sType) {
    if (sType == undefined)
      return MapEnums.SymbolTypes.None;
    else if (sType == 'SolidShape')
      return MapEnums.SymbolTypes.SolidShape;
    else if (sType == 'OutlineShape')
      return MapEnums.SymbolTypes.OutlineShape;
    else if (sType == 'Character')
      return MapEnums.SymbolTypes.Character;  
    else if (sType == 'Glyph')
      return MapEnums.SymbolTypes.Glyph;
  }

  getShapeType(sType) {    
    if (sType == 'Circle')
        return MapEnums.Shapes.Circle;
    else if (sType == 'Cross')
      return MapEnums.Shapes.Cross;
    else if (sType == 'DiagonalCross')
      return MapEnums.Shapes.DiagonalCross;
    else if (sType == 'Diamond')
      return MapEnums.Shapes.Diamond;  
    else if (sType == 'Marker')
      return MapEnums.Shapes.Marker;
    else if (sType == 'Pin')
      return MapEnums.Shapes.Pin;
    else if (sType == 'Square')
      return MapEnums.Shapes.Square;
      else if (sType == 'Star')
      return MapEnums.Shapes.Star;
    else if (sType == 'TriangleUp')
      return MapEnums.Shapes.TriangleUp;  
    else if (sType == 'TriangleDown')
      return MapEnums.Shapes.TriangleDown;
    else 
      return  MapEnums.Shapes.None;
  }
  // @region Properties  
  get IsEmpty()
  {
    return (this.pattern == undefined || this.stroke == undefined || this.size == undefined || this.size <= 0);
  }
  
  get Colour() {
    return this.colour;
  }
  
  get Height() {
    return this.height;
  }

  get Width() {
    return this.height;
  }

  get DashPattern() {
    return this.dashPattern;
  }

  get Pattern() {
    return this.pattern;
  }

  get ShapeType() {
    return this.shape;
  }

  get SymbolType() {
    return this.type;
  }

  get NormalSymbol() {
    return this.normalSymbol;
  }

  get HoverSymbol() {
    return this.hoverSymbol;
  }

  get SelectedSymbol() {
    return this.selectedSymbol;
  }

  sanitisedSVG(svg) {
    var sanitized = svg.replace(/\</g,'%3C')   //for <
    sanitized = sanitized.replace(/\>/g,'%3E')   //for >
    sanitized = sanitized.replace(/\#/g,'%23')   //for #
    
    return sanitized;
  }

  loadSymbols(baseUrl, accessToken, options, debugMode) {
    let base64 = window.btoa(debugMode ? JSON.stringify(this.style) : JSON.stringify(this.style.objJSON));
    let imageUrl = `${baseUrl}\GenerateSymbol?token=${accessToken}&geometryType=1&jStyle=${base64}&height=${this.height}`;
    
    this.normalSymbol = new Image();
    this.normalSymbol.src = imageUrl;    
    this.hoverSymbol = new Image();
    this.hoverSymbol.src = imageUrl + `&size=${encodeURI(options.HoverOutlineWidth)}&colour=${encodeURIComponent(options.HoverColour)}`;
    this.selectedSymbol = new Image();
    this.selectedSymbol.src = imageUrl + `&size=${encodeURI(options.SelectedOutlineWidth)}&colour=${encodeURIComponent(options.SelectedColour)}`;    
  }
}

Symbol.Pin = `<svg xmlns="http://www.w3.org/2000/svg" width="{{width}}" height="{{height}}" viewBox="0 0 24 24"><path style="fill:{{colour}}" d="M12 0c-3.313 0-6 2.686-6 6 0 2.972 2.164 5.433 5 5.91v8.09l2-2v-6.09c2.836-.477 5-2.938 5-5.91 0-3.314-2.687-6-6-6zm-2.66 4.841c-.53-.467-.516-1.372.034-2.023.548-.65 1.422-.799 1.952-.333s.515 1.372-.033 2.021c-.549.652-1.423.801-1.953.335zm6.16 18.159c0 .552-1.566 1-3.5 1s-3.5-.448-3.5-1 1.566-1 3.5-1 3.5.448 3.5 1z"/></svg>`;
Symbol.Marker = `<svg xmlns="http://www.w3.org/2000/svg"  width="{{width}}" height="{{height}}" viewBox="0 0 24 24"><path style="fill:{{colour}}" d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>`;
