import { MgEvent} from '../map/event';
import { Util } from '../core/index';
import {Browser} from '../core/index';
import { MapEnums } from '../map/enums';
import {BehaviorSubject} from 'rxjs';

export class MgLayer {    

  //#region -- CONSTRUCTOR    
  constructor(id, options, taskId) {
    this._id = id;
    this._map = null;
    this.taskId = taskId; 
    this.options = Util.setOptions(this, this.defaultOptions);
    this.options = Util.setOptions(this, options);
    this.visible = this.options.Visible;
    this.isDebouncing = new BehaviorSubject(false);

  }
  //#endregion
  
  //#region -- GETTERS and SETTERS  
  get id() { 
    return this._id; 
  }
  get options() { 
    return this._options; 
  }

  get map() { 
    return this._map; 
  }

  set options(value) {
    this._options = value;
  }
  //#endregion

  //#region -- METHODS
  addTo(map) {
    this._map = map;
    if(map) {
      map.addLayer(this);
      if (map.options.Context != null && this.options.ContextFilter == null) 
        this.options.ContextFilter = map.options.Context.Filter;
      this.onAdd(map);
    }
    return this;  
  }    
  //#endregion
  
  /**   
   * @returns Promise(MgLayer)
   * @virtual
   */
  isLayerReady(canvas, zoom) {
    throw new Error("Implement isLayerReady method");
  }

  /**
   * @param  {MgCanvas} canvas   
   * @virtual
   */
  refreshView(canvas, offscreenCanvas) {
    throw new Error("Implement refreshView method");
  }

  /**
   * @param  {Bounds} rcMap
   * @param  {LatLngBounds} newExtents
   * @param  {Number} cellsize
   * @param  {Number} scale
   */
  onExtentsChanged(sourceEvent, rcMap, newExtents, cellsize, scale) {

  }
  // @section
	// @aka MGLayer options
	get defaultOptions() {
    let _defaultOptions = {
      opacity: 1,     // Number = 1.0,
      zIndex: 1,      // Number,
      bounds: null,   // LatLngBounds
      minZoom: 0,     // Number
      maxZoom: 22,    // Number
    }
    _defaultOptions = this.mergeOptions(_defaultOptions, this.getWCLayerOptions());
    return _defaultOptions;
  }

  get DatasetType() {
    return this.options.DatasetType;
  }

  get canHover() {
    return false;
  }

  get Dataset() {
    return this.options.Dataset;
  }
  
  getWCLayerOptions() {
    // FROM API
    const layerOptions =  {
      Name: null,             // String
      Position: null,         // Number
      Dataset: null,          // Number
      DatasetType: null,      // MapEnums.DatasetTypes
      Projection: null,       // String
      Visible: null,          // Boolean
      Folder: [],             // String[]
      MinScale: null,         // Number
      MaxScale: null,         // Number    
      RenderOrderIndex: null  // Number
    }
    return layerOptions;
  }

  onAdd(map) {
  }

  isVisible(scale) {
      return (this.options.MaxScale >= scale || this.options.MaxScale == null) && (this.options.MinScale <= scale || this.options.MinScale == null) ? this.visible : false;  
  }

  showThematics(index, show) {
  }

  showLayer(sourceEvent, show) {
    this.visible = show;
    if (this.map != null) {
      if (show == true) {
        this._viewport = null;
        this.currentExtents = null;
        this.onExtentsChanged(sourceEvent, this.map.rcMap, this.map.extents, this.map.cellsize, this.map.scale, this.map.canvas);
      }

      this.map.refreshView();
    }
  }
  
  render(canvas, force, context, compositeOperation) {
  }

  renderSelection(canvas, context, compositeOperation) {    
  }

  clearItems() {
  }
  
  cancelRequest() {

  }

  getHitItem(x, y) {
    return null;
  }

  hitTest(x, y) {
    return false;
  }

  getIntersections(bounds) {
    return null;
  }
  
  createOffscreenCanvas(canvas, width , height, clearRect) {
    if (this.offScreenCanvas == null || this.offScreenContext == null) {
      this.offScreenCanvas = document.createElement("canvas", {alpha: true});
      let w = width != null ? width : canvas.canvas.width;
      let h = height != null ? height : canvas.canvas.height;

      this.offScreenCanvas.width = w;
      this.offScreenCanvas.height = h;
      this.offScreenCanvas.style.width = w  + "px";
      this.offScreenCanvas.style.height = h  + "px";

      // prevent anti-aliasing
      this.offScreenCanvas.imageSmoothingEnabled = true;
      this.offScreenContext = this.offScreenCanvas.getContext('2d');
      this.offScreenContext.globalCompositeOperation = 'source-over';
    }
    else if (clearRect == true || clearRect == undefined) {
      this.offScreenContext.clearRect(0, 0, this.offScreenCanvas.width, this.offScreenCanvas.height);
      this.offScreenContext.width = this.offScreenContext.width;
    }
  }

  resize(newWidth, newHeight) {
    if (this.offScreenCanvas != undefined) {
      this.offScreenCanvas.width = newWidth;
      this.offScreenCanvas.height = newHeight;
      this.offScreenCanvas.style.width = newWidth  + "px";
      this.offScreenCanvas.style.height = newHeight  + "px";
    }
  }
} 

