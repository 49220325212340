import {MgPoint, MgLine, MgPolygon, MgMultiPoint, MgMultiLine, MgMultiPolygon, MgGeometryCollection} from './index';
import MapEnums from '../map/enums';

export class MgShapeFactory {
  static createShape(feature) {
    var shape = undefined;
    if (feature == undefined || feature.Geometry == undefined)
      return null;

    let geoType = feature.Geometry.GeometryType;

    switch(geoType) {
      case MapEnums.GeometryTypes.POINT:
        shape = new MgPoint(feature);
        break;
      
      case MapEnums.GeometryTypes.LINESTRING:
        shape = new MgLine(feature);
        break;

      case MapEnums.GeometryTypes.POLYGON:
        shape = new MgPolygon(feature);
        break;
  
      case MapEnums.GeometryTypes.MULTIPOINT:
        shape = new MgMultiPoint(feature);
        break;
        
      case MapEnums.GeometryTypes.MULTILINESTRING:
        shape = new MgMultiLine(feature);
        break;
  
      case MapEnums.GeometryTypes.MULTIPOLYGON:
        shape = new MgMultiPolygon(feature);
        break;
      
      case MapEnums.GeometryTypes.GEOMETRYCOLLECTION:
        shape = new MgGeometryCollection(feature);
        break;
      
    }

    return shape;
  }
}