import {MgLayer} from './layer';
import {Util} from '../core/index';

import {Point} from '../geometry/Point';
import {Bounds} from '../geometry/Bounds';
import * as DomUtil from '../dom/DomUtil';

import {LatLngBounds, toLatLngBounds as latLngBounds} from '../geometry/LatLngBounds';
import {Envelope} from '../geometry/Envelope';
import {TileViewport} from '../drawing/tileviewport';
import {OpenStreetMapTileSource} from './OpenStreetMapTileSource';
import {EsriTileSource} from './EsriTileSource';
import {GoogleTileSource} from './GoogleTileSource';
import {WMTSTileSource} from './WMTSTileSource';
import {GenericTileSource} from './GenericTileSource';
import {SecureTileSource} from './SecureTileSource';
import {TileSource} from './tilesource';
import {LoadStates} from '../layer/tile';
import { MapEnums } from '../map/enums';
import {cancelable, CancelablePromise} from 'cancelable-promise';

export class MgTileLayer extends MgLayer {
    
  constructor(id, options, taskId) {
    super(id, options, taskId);
		this._tiles = {};
		this._levels = {};
		this._viewport = null;

		let sType = "";
		this.promise = undefined;
  }

  // Factory methods
  /**
   * @param  {number} id
   * @param  {json} options
   */
  static createObject(id, options) {    
    return new MgTileLayer(id,  options);    
  }

  isLayerReadyDebounce(canvas){
	const debounced = Util.debouncePromise(this.isLayerReady, 1000);

	return debounced(canvas, this);
  }
		
	/**   
   * @returns Promise(MgLayer)
   * @virtual
   */
  isLayerReady(canvas) {
		let __this = this;

		return new Promise((resolve, reject) => {     
		  if (__this.promiseAll != null) {		
				__this.promiseAll.then(tiles => {    
					tiles.forEach( tile => {
						__this._viewport.renderTile(tile);
					});
					__this.promiseAll = undefined; 
					resolve(__this);
				})
				.catch(error => {
					__this.promiseAll = undefined;
					console.log(error);
					reject(__this);
				});
			}
			else {
				__this.promiseAll = undefined;				
				resolve(__this);
			}
		});		
		
  }

	spiralize(tiles) {
		var aSpiral = [];
    // No need to have the number of rows and columns passed as arguments:
    // We can get that information from the array:
    var rows = tiles.length;
    var cols = tiles[0].length;
		// Set "current" cell to be outside array: it moves into it in first inner loop
		var step = 0;
    var row = 0;
		var col = -1;
		var tile = null;
    var direction = 1; // Can be 1 for forward and -1 for backward
    while (rows > 0 && cols > 0) {
			// Print cells along one row
			for (step = 0; step < cols; step++) {
				col += direction;
		    aSpiral.push(tiles[row][col]);
				
			}
			// As we have printed a row, we have fewer rows left to print from:
			rows--;
			// Print cells along one column
			for (step = 0; step < rows; step++) {
				row += direction;
				aSpiral.push(tiles[row][col]);		
			}
			// As we have printed a column, we have fewer columns left to print from:
			cols--;
			// Now flip the direction between forward and backward
			direction = -direction;
		}
		
		return aSpiral;
  }

  /**
   * @param  {Bounds} rcMap
   * @param  {LatLngBounds} newExtents
   * @param  {Number} cellsize
   * @param  {Number} scale
   */
  onExtentsChanged(sourceEvent, rcMap, extents, cellsize, scale, canvas) {
		console.log('onExtentsChanged - TileLayer');
		var disposableViewPort = this._viewport;

		if (this.isVisible(scale)) {
			// if (disposableViewPort != null && disposableViewPort.World.equals(extents)) {
			// 	disposableViewPort = null; // just re-use the current
			// } else {		
				this._viewport = new TileViewport(rcMap, extents, cellsize, scale, this._tileSource, canvas, this.options.Opacity);	        				
				this.scale = scale;
				this.getTiles(extents, this._viewport, canvas, cellsize);                                                       
			// }
    	} else
			this._viewport = null;
			    
		// if (disposableViewPort != null)
		// {
		// 	disposableViewPort.dispose(this._tileSource, Date.now());
		// 	this._tileSource.cleanup();
		// }

    	return true;
	}
	
	worldToClientRect(envelope, World, Client, Cellsize)
	{
		return new Rectangle(Client.MinX + Math.floor((envelope.MinX - World.MinX) / Cellsize), 
											Client.MinY + Math.floor((World.MaxY - envelope.MaxY) / Cellsize), 
											Client.MinX + Math.ceil((envelope.MaxX - World.MinX) / Cellsize), 
											Client.MinY + Math.ceil((World.MaxY - envelope.MinY) / Cellsize), true);
	}

	getTiles(extents, viewport, canvas, cellsize) {       
		if (this.cancelable != null) {
				this.cancelable.cancel();
				this.cancelable = null;

		}

		let tilePromises = [];

		if (this._viewport != null && this._viewport.tiles.length > 0) {
			let tiles = this.spiralize(this._viewport.tiles);
			for (let i = tiles.length - 1; i >= 0; i--) {
				let tile = tiles[i];
				if (tile.image == null && tile.imageUrl != null && tile.imageUrl.length > 0) {                
					tilePromises.push(tile.loadImage(tile.imageUrl));
				}				
			}

			this.promiseAll = Promise.all(tilePromises);

			// this.cancelable = cancelable(this.promiseAll);
		}                
	} 
	
  refreshView(canvas) {
		console.log('refreshView - TileLayer');
		if ( this._viewport != undefined) {
			this._viewport.render(canvas, this._tileSource,1.0);
		}
		
		//canvas.refreshView();
  }       

  // @section
  // @aka TileLayer options
  get defaultOptions() {
	  var _defaultOptions = {
		  tileSize: 256, 		// Number
      minZoom: 0, 			// Number
      maxZoom: 18, 			// Number
      errorTileUrl: '', // String

      // @option crossOrigin: Boolean|String = false
      // Whether the crossOrigin attribute will be added to the tiles.
      // If a String is provided, all tiles will have their crossOrigin attribute set to the String provided. This is needed if you want to access tile pixel data.
      // Refer to [CORS Settings](https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_settings_attributes) for valid String values.
      crossOrigin: false
    }

		_defaultOptions = Util.mergeOptions(_defaultOptions, this.getWCLayerOptions())
    return _defaultOptions;
	}

	getWCLayerOptions() {
		let parentOptions = super.getWCLayerOptions();

		let layerOptions =  { 
			Opacity: 1.0, 		// Number = 1.0
			Datasource: {
				Type: null, 		// MapEnums.DatasourceTypes
				Url: null, 			// String
				Key: null 			// String
			}
		}
		layerOptions = Util.mergeOptions(parentOptions, layerOptions);
		return layerOptions;
	}
	
	/**
	 * @param  {} map
	 */
	onAdd(map) {
		this._levels = {};
		this._tiles = {};

		if (this.options.Datasource.Type == 'OpenStreetMap')
			this._tileSource = new OpenStreetMapTileSource(map);
		else if (this.options.Datasource.Type == 'GoogleStreetMap')
			this._tileSource = new GoogleTileSource(map, this.options.Name);
		else {
			if (this.options.Datasource.Url == "Secure")
				this._tileSource = new SecureTileSource(map, this.options.Dataset);
			else if (this.options.Datasource.Type == 'EsriRestService')
				this._tileSource = new EsriTileSource(map, this.options.Name, this.options.Datasource.Url);
			else if (this.options.Datasource.Type == 'GenericTileService')
				this._tileSource = new GenericTileSource(map, this.options.Name, this.options.Datasource.Url);
			else if (this.options.Datasource.Type == 'WMTS') {
				this._tileSource = new WMTSTileSource(map);
				this._tileSource.setLayer(this.options.Name);
			}
			else
				this._tileSource = new TileSource(map);
    }
	}
	/**
	 * @param  {} map
	 */
	beforeAdd(map) {
		map._addZoomLimit(this);
	}
	/**
	 * @param  {} map
	 */
	onRemove(map) {
		DomUtil.remove(this._container);
		this._container = null;
		this._tileZoom = undefined;
	}

	// @method bringToFront: this
	// Brings the tile layer to the top of all tile layers.
	/**
	 */
	bringToFront() {
		if (this._map) {
			DomUtil.toFront(this._container);
			this._setAutoZIndex(Math.max);
		}
		return this;
	}

	// @method bringToBack: this
	// Brings the tile layer to the bottom of all tile layers.
	bringToBack() {
		if (this._map) {
			DomUtil.toBack(this._container);
			this._setAutoZIndex(Math.min);
		}
		return this;
	}

	// @method setOpacity(opacity: Number): this
	// Changes the [opacity](#gridlayer-opacity) of the grid layer.
	setOpacity(opacity) {
		this.options.opacity = opacity;
		this._updateOpacity();
		return this;
	}

	// @method setZIndex(zIndex: Number): this
	// Changes the [zIndex](#gridlayer-zindex) of the grid layer.
	setZIndex(zIndex) {
		this.options.zIndex = zIndex;
		this._updateZIndex();

		return this;
	}

	// @method isLoading: Boolean
	// Returns `true` if any tile in the grid layer has not finished loading.
	isLoading() {
		return this._loading;
	}

	// @method redraw: this
	// Causes the layer to clear all the tiles and request them again.
	redraw() {
		if (this._map) {
			this._update();
		}
		return this;
	}

  getTileSize() {
		var s = this.options.tileSize;
		return s instanceof Point ? s : new Point(s, s);
	}

	// @method render: canvas
	// Causes the layer to clear all the tiles and request them again.
	render(canvas, force, context, compositeOperation) {
		if ( this._viewport != undefined) {
			this._viewport.render(canvas, force, context, compositeOperation);
		}

		canvas.refreshView();
	}
	
	hitTest(x, y) {
    return false; 
  }
}

export class MgOSMTileLayer extends MgTileLayer {

}