import { MgLine } from "../drawing";
import { MgPoint } from "../drawing";
import { MgPolygon } from "../drawing";
import { MgCircle } from "../drawing";
import { MgMultiPoint } from "../drawing";
import { MgMultiLine } from "../drawing";
import Axios from "axios";
import MapEnums from "../map/enums";

class MgRestAPI {

  //#region -- CONSTRUCTORS
  constructor() {
    this._baseUrl = "localhost";
    this._accessToken = undefined;
    this.debugMode = false;    
  }   
  //#endregion -- CONSTRUCTORS
  
  //#region -- METHODS
  get BaseUrl() {
    return this._baseUrl; 
  }
  set BaseUrl(value) { 
    this._baseUrl = value;
  }

  get AccessToken() {
    return this._accessToken; 
  }
  set AccessToken(value) { 
    this._accessToken = value;
  }

  getFeatures(layerId) {
    return new Promise( (resolve, reject) => {
      console.log(this._baseUrl);
      if(layerId == "VectorLayer 2")
        resolve(FEATURES2);
      else
        resolve(FEATURES);           
    });    
  }

  coordinatesQuery(queryParams){
    var sort = [];

    if (queryParams.sortField == null || queryParams.sortField == undefined) {
      sort = [{"Field":sort, "Ascending":false}];
    }

    var extentsFilter = null;
    if (queryParams.extents != null) {
      extentsFilter = {
        "MinX": queryParams.extents.MinX, 
        "MinY": queryParams.extents.MinY, 
        "MaxX": queryParams.extents.MaxX, 
        "MaxY": queryParams.extents.MaxY,
        "ExtentsProjection": queryParams.extentsProjection,
        "MapProjection": queryParams.mapProjection,
        "GeometryField":queryParams.geometryField
      }
    }

    var params = {
      "token": this.AccessToken == undefined ? "3102c54e-317b-4fca-80b9-d1513547b273" : this.AccessToken,    
      "datasetId": queryParams.datasetId,    
      // "columns": queryParams.columns,
      "thematics": queryParams.thematics,    
      "extentsFilter": extentsFilter,     
      "cellSize": queryParams.cellSize,
      "taskId": queryParams.taskId != undefined ? queryParams.taskId : -1,
      "id": queryParams.id,
      "cellsize": queryParams.cellSize,
      "coordinates": queryParams.coordinates
    };
    
    var url = this.BaseUrl + "CoordinatesQuery";
    return Axios.post(url, params,{cancelToken: queryParams.source.token});       

}
  
  featureQuery(queryParams, source) {
    var sort = [];

    if (queryParams.sortField == null || queryParams.sortField == undefined) {
      sort = [{"Field":sort, "Ascending":false}];
    }

    var extentsFilter = null;
    if (queryParams.extents != null) {
      extentsFilter = {
        "MinX": queryParams.extents.MinX, 
        "MinY": queryParams.extents.MinY, 
        "MaxX": queryParams.extents.MaxX, 
        "MaxY": queryParams.extents.MaxY,
        "ExtentsProjection": queryParams.extentsProjection,
        "MapProjection": queryParams.mapProjection,
        "GeometryField":queryParams.geometryField
      }
    }
        
    var params = {
      "id": queryParams.id,
      "token": this.AccessToken == undefined ? "3102c54e-317b-4fca-80b9-d1513547b273" : this.AccessToken,    
      "datasetId": queryParams.datasetId,    
      "columns": queryParams.columns,
      "thematics": queryParams.thematics,    
      "sort": sort,    
      "sContextFilter": queryParams.sContextFilter,
      "sLayerFilter": queryParams.sLayerFilter,      
      "sUserFilter": queryParams.sUserFilter,    
      "extentsFilter": extentsFilter,     
      "offset": queryParams.offset,    
      "limit": queryParams.limit,
      "cellSize": queryParams.cellSize,
      "contextType": queryParams.contextType,
      "taskId": queryParams.taskId != undefined ? queryParams.taskId : -1,
      "isIdentitySearch": queryParams.isIdentitySearch != undefined ? queryParams.isIdentitySearch : false,
      "contextFilterDatasource": queryParams.contextFilterDatasource != undefined ? queryParams.contextFilterDatasource : MapEnums.DatasourceTypes.None,
    };
    
    var url = this.BaseUrl + "FeatureQuery";
    var req = null;
    
    if (source)
      req = Axios.post(url, params, {cancelToken: source.token});        
    else
      req = Axios.post(url, params);    

    return req;
    }

  getSymbol(geometryType, jStyle, height) {   
    var params = {
      "token": this.AccessToken == undefined ? "3102c54e-317b-4fca-80b9-d1513547b273" : this.AccessToken,    
      "geometryType":geometryType,    
      "jStyle": JSON.stringify(jStyle),
      "height":height,    
      
    };
    
    var url = this.BaseUrl + "GenerateSymbol";
    return Axios.post(url, params);
    //.then(response => Buffer.from(response.data, 'binary').toString('base64'))        
    /*
    .then((response) => {

        var binary = '';
        var bytes = new Uint8Array(response.data);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
      
      //let image = btoa(
      //  new Uint8Array(response.data)
      //    .reduce((data, byte) => data + String.fromCharCode(byte), '')
      //);
      //return image; //`data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
      
    });  
    */      
  }
  
  rasterQuery(datasetId, MinX, MaxX, MinY, MaxY, projection, width, height) {       
    var url = this.BaseUrl + `RasterQuery?token=${this.AccessToken}&datasetId=${datasetId}&MinX=${MinX}&MaxX=${MaxX}&MinY=${MinY}&MaxY=${MaxY}&projection=${projection}&width=${width}&height=${height}`;
    return Axios.get(url, params,{cancelToken: source.token});        
  }

  getCommonTaskDefinition(taskId) {           
    var url = this.BaseUrl + `GetCommonTaskDefinition?token=${this.AccessToken}&taskId=${taskId}`;
    return Axios.get(url);
  }
  
  //#endregion -- METHODS
  
}

export let RestAPI = new MgRestAPI();
  