import {MgShape} from './mgshape';
import MapEnums from '../map/enums';
import {Rectangle, Point} from '../geometry/index';

export class MgPoint extends MgShape {
    
  //#region -- CONSTRUCTORS
  /**
   * @param  {Feature} feature
   * @param  {Wkt} wkt
   */
  constructor(feature) {    
    super(feature);

    this.symbol = null;
    this.fill = null;
    if (feature.ThematicIndex > -1) {
      this.symbol = feature.featureLayer.Thematics[feature.ThematicIndex].PointStyle;
    }
    else if (feature.featureLayer.pointStyle != null) {
      this.symbol = feature.featureLayer.pointStyle;      
    }
  } 
  //#endregion
  
  //#region -- STATIC METHODS
  /**
   * @param  {number} x
   * @param  {number} y
   */
  static createObject(x, y) {    
    return new MgPoint(x, y);    
  } 
  //#endregion  
  
  //#region -- GETTERS and SETTERS   
  /**
   * @returns number
   */  
  get x() { 
    return this._x;
  }
  
  /**
   * @returns number
   */  
  get y() {
    return this._y;
  }
  //#endregion  

  //#region -- OVERRIDES
  /**
   * @param  {MgCanvas} canvas
   * @param  {string} strokeColor
   * @param  {string} fillColor
   * @override
   */
  draw(canvas, opacity, context) {    
    
    if (this._points.length > 0) {
      let pt = this._points[0];

      let offsetX = this.symbol.NormalSymbol.width / 2;
      let offsetY = this.symbol.NormalSymbol.height / 2;
      canvas.drawSymbol(this.symbol.NormalSymbol, pt.x - offsetX, pt.y - offsetY, context);
      this.drawText(new Point(pt.x - offsetX, pt.y - offsetY), canvas, offsetX, offsetY, context);
    }
  }

  drawHover(canvas, opacity, context) {
    context.globalCompositeOperation = 'source-over';

    if (this._points.length > 0) {
      let pt = this._points[0];

      let offsetX = this.symbol.NormalSymbol.width / 2;
      let offsetY = this.symbol.NormalSymbol.height / 2;
      canvas.drawSymbol(this.symbol.HoverSymbol, pt.x - this.symbol.HoverSymbol.width / 2, pt.y -  this.symbol.HoverSymbol.height / 2, context);
      this.drawText(new Point(pt.x - offsetX, pt.y - offsetY), canvas, offsetX, offsetY, context);
    }
  }    

  drawSelected(canvas, opacity, context) {
    context.globalCompositeOperation = 'source-over';

    if (this._points.length > 0) {
      let pt = this._points[0];

      let offsetX = this.symbol.NormalSymbol.width / 2;
      let offsetY = this.symbol.NormalSymbol.height / 2;
      canvas.drawSymbol(this.symbol.SelectedSymbol, pt.x -  this.symbol.SelectedSymbol.width / 2, pt.y -  this.symbol.SelectedSymbol.height / 2, context);
      this.drawText(new Point(pt.x - offsetX, pt.y - offsetY), canvas, offsetX, offsetY, context);
    }
  }

  drawText(pt, canvas, offsetX, offsetY, context) {
    if (this.LabelStyle != null && this.LabelStyle.isVisible(this.Feature.featureLayer.map.scale)) {

      var sLabel = this.labelText;
      var labelRotation = this.LabelStyle.Rotation != null && this.LabelStyle.Rotation.length > 0 ? parseInt(this.LabelStyle.Rotation) : undefined;
      
      let newX = pt.x;
      let newY = pt.y;
      let textAlign = 'start';

      switch (this.LabelStyle.Placement)
      {
        case MapEnums.Placements.AboveLeft:
          newX = pt.x - this.symbol.Width/2;
          newY = pt.y - this.symbol.Height/2;
          textAlign = 'right';
          break;
        case MapEnums.Placements.AboveCentre:
          newY = pt.y - this.symbol.Height/2;
          textAlign = 'left';
          break;
        case MapEnums.Placements.AboveRight:
          newX = pt.x + this.symbol.Width /2;
          newY = pt.y - this.symbol.Height/2;
          textAlign = 'left';

          break;
        case MapEnums.Placements.MiddleLeft:
          newX = pt.x - this.symbol.Width/2;
          newY = pt.y + offsetY;
          textAlign = 'right';
          break;
        case MapEnums.Placements.MiddleCentre:
          textAlign = 'center';
          break;
        case MapEnums.Placements.MiddleRight:
          newX = pt.x + this.symbol.Width;
          newY = pt.y + this.symbol.Height/2;
          textAlign = 'left';
          break;

        case MapEnums.Placements.BelowLeft:
          newX = pt.x - this.symbol.Width/2;
          newY = pt.y + this.symbol.Height;
          textAlign = 'right';
          break;

        case MapEnums.Placements.BelowCentre:
          newY = pt.y + this.symbol.Height + this.LabelStyle.FontSize;
          textAlign = 'center';
          break;  

        case MapEnums.Placements.BelowRight:
          newX = pt.x + this.symbol.Width/2;
          newY = pt.y + this.symbol.Height;
          textAlign = 'left';
          break;
      }      

      if (sLabel != null) {
        canvas.drawText(sLabel, Math.trunc(newX), Math.trunc(newY)+2, labelRotation, this.LabelStyle, textAlign, context); 
      }
    }
  }

  getRectExtents() {
    if (this._points.length > 0) {
      let pt = this._points[0];

      let halfwidth = this.symbol.NormalSymbol.width / 2;
      let halfheight = this.symbol.NormalSymbol.height / 2;
      
      return new Rectangle(pt.x - halfwidth, pt.y - halfheight, pt.x + halfwidth, pt.y + halfheight, true);
    }

    return null;
  }

  hitTest(x, y) {
    this.worldToClient(this._feature.featureLayer.Viewport);
    
    let extents = this.getRectExtents();
    if (extents != null && extents.pointInRect(x, y))
      return true;

    return false;
  }
  //#endregion
}