import {TileSource} from './tilesource';

export class EsriTileSource extends TileSource
{
  // @section Constructors

  constructor(map, name,  url) {
    super(map);
    this.url = url;
    this.name = name.replace(/ /g, '_');
  }

  // @section Properties

  // @section TileSource Methods

  getTileUrl(level, col, row) {
    
    var sTileUrl = `${this.url}/${this.name}/MapServer/tile/${level}/${row}/${col}`;
    return sTileUrl;
  }

}