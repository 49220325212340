import * as Colour from './Colour';
import MapEnums from '../map/enums';

export class Label {
  // @region Constructors

  constructor(labelStyle) {
    this.colour = labelStyle.Colour;
    this.hexColour = Colour.fromString(this.colour);
    this.fontName = labelStyle.FontName;
    this.fontSize = labelStyle.FontSize+2;
    this.position = labelStyle.Position;
    this.placement = this.getPlacement(labelStyle.Position);
    this.rotation = labelStyle.Rotation;
    
    this.minScale = labelStyle.minScale != null ? labelStyle.minScale : Number.NEGATIVE_INFINITY;
    this.maxScale = labelStyle.MaxScale != null ? labelStyle.MaxScale : Number.POSITIVE_INFINITY;

    this.highlight = labelStyle.Highlight;
    
    this.columns = [];
    this.labelFormat = this.getLabelFormat(labelStyle);
  }

  isVisible(scale) {
    return this.maxScale >= scale;  
  }

  getLabelFormat(labelStyle) {
    var labelFormat = "";
    let label = labelStyle.Label != null ? JSON.parse(labelStyle.Label) : null;
    if ( label != null) {
      if (label.Type == 'Field' || label.Type == 'SpatialField') {
        this.columns.push(label.Id);        
        labelFormat += `{${label.Id}}`;
      }
      else if (label.Type == 'Concatenate') {
        if (label.Comparisons != null) {
          for(let i = 0; i < label.Comparisons.length; i++) {
            let comparison = label.Comparisons[i];

            if (comparison.Id != null) {
              this.columns.push(comparison.Id);
              labelFormat += `{${comparison.Id}}`;
            }
            else
              labelFormat += `${comparison}`;
          }
        }
        else {
          if (label.Rhs.Id != null)
            this.columns.push(label.Rhs.Id);
          else 
            labelFormat = label.Rhs;

          if (label.Lhs.Id != null)
            this.columns.push(label.Lhs.Id);         
          else 
            labelFormat = label.Lhs;    

          for (let i = 0; i < this.columns.length; i++) {
            labelFormat += `{${this.columns[i]}}`;
          }  
        }
      } 

      
    }

    return labelFormat;
  }
  
  getPlacement(sType) {    
    if (sType == undefined)
      return MapEnums.Placements.MiddleCentre;
    else if (sType == 'AboveLeft')
      return MapEnums.Placements.AboveLeft;
    else if (sType == 'AboveCentre')
      return MapEnums.Placements.AboveCentre;
    else if (sType == 'AboveRight')
      return MapEnums.Placements.AboveRight;  
    else if (sType == 'MiddleLeft')
      return MapEnums.Placements.MiddleLeft;
    else if (sType == 'MiddleCentre')
      return MapEnums.Placements.MiddleCentre;
    else if (sType == 'MiddleRight')
      return MapEnums.Placements.MiddleRight;  
    else if (sType == 'BelowLeft')
      return MapEnums.Placements.BelowLeft;  
      else if (sType == 'BelowCentre')
      return MapEnums.Placements.BelowCentre;
    else if (sType == 'BelowRight')
      return MapEnums.Placements.BelowRight;      
  }  
  
  getFormattedLabel(feature) {
    var formattedLabel = this.LabelFormat;

    for(let i = 0; i < this.columns.length; i++) {
      let key = this.columns[i];
      let value = feature.dctValues[key];
      if (value != null) {
        let toReplace = `{${this.columns[i]}}`;
        formattedLabel = formattedLabel.replace(toReplace, value);
      }
    }

    return formattedLabel;
  }

  get Colour() {
    return this.colour;
  }
  
  get HexColour() {
    return this.hexColour;
  }

  get FontName() {
    return this.fontName;
  }

  get FontSize() {
    return this.fontSize;
  }

  get Position() {
    return this.position;
  }

  get Placement() {
    return this.placement;
  }

  get Rotation() {
    return this.rotation;
  }
  
  get LabelFormat() {
    return this.labelFormat;
  }
}


