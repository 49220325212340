import {MgShape} from './mgshape';
import { MgPoint } from './mgpoint';
import {MgShapeFactory} from './mgshapefactory';
import { GeoLineString, GeoLinearRing, GeoMultiLineString, GeoPolygon, GeoMultiPolygon, GeoPoint, GeoMultiPoint } from '../geometries';
import MapEnums from '../map/enums';

export class MgGeometryCollection extends MgShape {
    
  //#region -- CONSTRUCTORS
  /**
   * @param  {number[[]]} points
   */
  constructor(feature) {    
    super(feature);
    this._geometries = [];
    this._shapes = [];
    this._geopoints = [];

    this.polygonStroke = null;
    this.polygonFill = null;
    
    this.lineStroke = null;
    this.pointSymbol = null;

    if (feature.ThematicIndex > -1) {      
      if (feature.featureLayer.Thematics[feature.ThematicIndex].PointStyle != null) {
        this.pointSymbol = feature.featureLayer.Thematics[feature.ThematicIndex].PointStyle;
      }

      if (feature.featureLayer.Thematics[feature.ThematicIndex].LineStyle != null) {
        this.lineStroke = feature.featureLayer.Thematics[feature.ThematicIndex].LineStyle;
      }

      if (feature.featureLayer.Thematics[feature.ThematicIndex].PolygonStyle != null) {
        this.polygonStroke = feature.featureLayer.Thematics[feature.ThematicIndex].PolygonStyle.Stroke;
        this.polygonFill = feature.featureLayer.Thematics[feature.ThematicIndex].PolygonStyle.Fill;      
      }
    }
    else {
      if (feature.featureLayer.PointStyle != null) {
        this.pointSymbol = feature.featureLayer.PointStyle;
      }

      if (feature.featureLayer.LineStyle != null) {
        this.lineStroke = feature.featureLayer.LineStyle;
      }

      if (feature.featureLayer.PolygonStyle != null) {
        this.polygonStroke = feature.featureLayer.PolygonStyle.Stroke;
        this.polygonFill = feature.featureLayer.PolygonStyle.Fill;      
      }
    }

    for (let i = 0; i < feature.Geometry.NumGeometries; i++) {
      this._geometries.push(feature.Geometry.GetGeometryN(i));
    }
  } 
  //#endregion

  //#region -- STATIC METHODS
  /**
   * @param  {number[[]]} points
   * @returns MgPolygon
   */
  static createObject(points) {    
    return new MgGeometryCollection(points);    
  } 
  //#endregion  

  //#region -- OVERRIDES
  /**
   * @param  {MgCanvas} canvas
   * @param  {string} strokeColor
   * @param  {string} fillColor   
   * @override
   */
  draw(canvas, opacity, context) {
    for (var i = 0; i < this._geometries.length; i++) {
      let geometry = this._geometries[i];
      if (geometry instanceof GeoPoint || geometry instanceof GeoMultiPoint) {
        if (this.pointSymbol != null)
          geometry.draw(canvas, opacity, this.pointSymbol, null, context);
      }
      else if (geometry instanceof GeoLineString || geometry instanceof GeoLinearRing || geometry instanceof GeoMultiLineString) {
        if (this.lineStroke != null)
          geometry.draw(canvas, opacity, this.lineStroke, null, context);
      }
      else if (geometry instanceof GeoPolygon || geometry instanceof GeoMultiPolygon) {
        if (this.polygonStroke != null || this.polygonFill != null)
          geometry.draw(canvas, opacity, this.polygonStroke, this.polygonFill, context);  
      }
    }    
  }
  
  drawHover(canvas, opacity, context) {
    for (var i = 0; i < this._geometries.length; i++) {
      let geometry = this._geometries[i];
      if (geometry instanceof GeoPoint || geometry instanceof GeoMultiPoint) {
        if (this.pointSymbol != null)
          geometry.drawHover(canvas, opacity, this.pointSymbol, null, context);
      }
      else if (geometry instanceof GeoLineString || geometry instanceof GeoLinearRing || geometry instanceof GeoMultiLineString) {
        if (this.lineStroke != null)
          geometry.draw(canvas, opacity, this.lineStroke.hoverStroke, context);
      }
      else if (geometry instanceof GeoPolygon || geometry instanceof GeoMultiPolygon) {
        if (this.polygonStroke != null || this.polygonFill != null)
          geometry.draw(canvas, opacity, this.polygonStroke.hoverStroke, this.polygonFill, context);  
      }
    }    
  }

  drawSelected(canvas, opacity, context) {
    for (var i = 0; i < this._geometries.length; i++) {
      let geometry = this._geometries[i];
      if (geometry instanceof GeoPoint || geometry instanceof GeoMultiPoint) {
        if (this.pointSymbol != null)
          geometry.drawSelected(canvas, opacity, this.pointSymbol, null, context);
      }
      else if (geometry instanceof GeoLineString || geometry instanceof GeoLinearRing || geometry instanceof GeoMultiLineString) {
        if (this.lineStroke != null)
          geometry.draw(canvas, opacity, this.lineStroke.selectedStroke, context);
      }
      else if (geometry instanceof GeoPolygon || geometry instanceof GeoMultiPolygon) {
        if (this.polygonStroke != null || this.polygonFill != null)
          geometry.draw(canvas, opacity, this.polygonStroke.selectedStroke, this.polygonFill, context);  
      }
    }    
  }
  
  //#endregion  
}