import {MgShape} from './mgshape';
import { MgPoint } from './mgpoint';
import { GeoPolygon } from '../geometries';
import {MapPrimitive, LabelPoint} from './mapprimitive';
import {PolyLabel} from './polylabel';
import {Rectangle, Point} from '../geometry/index';

export class MgPolygon extends MgShape {
    
  //#region -- CONSTRUCTORS
  /**
   * @param  {number[[]]} points
   */
  constructor(feature) {    
    super(feature);
    this.exteriorPoints = [];
    this.interiorPoints = [[]];    

    this.stroke = null;
    this.fill = null;
    this.labelPoint = null;

    if (feature.ThematicIndex > -1) {
      this.stroke = feature.featureLayer.Thematics[feature.ThematicIndex].PolygonStyle.Stroke;
      this.fill = feature.featureLayer.Thematics[feature.ThematicIndex].PolygonStyle.Fill;      
    }
    else if (feature.featureLayer.PolygonStyle != null) {
      this.stroke = feature.featureLayer.PolygonStyle.Stroke;
      this.fill = feature.featureLayer.PolygonStyle.Fill;      
    }
    
  } 
  //#endregion

  //#region -- STATIC METHODS
  /**
   * @param  {number[[]]} points
   * @returns MgPolygon
   */
  static createObject(points) {    
    return new MgPolygon(points);    
  } 
  //#endregion  

  //#region -- OVERRIDES
  /**
   * @param  {MgCanvas} canvas
   * @param  {string} strokeColor
   * @param  {string} fillColor   
   * @override
   */
  draw(canvas, opacity, context) {
    if (this.stroke != null || this.fill != null) {
      for (let i = 0; i < this._points.length; i++) {
        canvas.drawPolygon(this._points[i], this.stroke, this.fill, opacity, context);

        // exterior points
        if (i == 0) {
          this.drawText(this._points[i], canvas, context);          
        }
      }      
    }
  } 

  drawHover(canvas, opacity, context) {
    context.globalCompositeOperation = 'source-over';

    let localFill = this.fill;

    if (this.fill && this.fill.style && this.fill.style.Type === "Hatch" && this.fill.hoverPattern) {
      localFill = Object.assign({}, this.fill, {pattern: this.fill.hoverPattern});
    }

    let hoverFill = (localFill && localFill.hoverFill) ? localFill.hoverFill: null;
    
    if (this.stroke != null || this.fill != null) {
      for (let i = 0; i < this._points.length; i++) {

        canvas.drawPolygon(this._points[i], this.stroke.hoverStroke, hoverFill, opacity, context);

        // exterior points
        if (i == 0) {
          this.drawText(this._points[i], canvas, context);          
        }
      }
    }
  } 

  drawSelected(canvas, opacity, context) {
    context.globalCompositeOperation = 'source-over';

    let selectedFill = (this.fill && this.fill.selectedFill) ? this.fill.selectedFill: null;
    
    if (this.stroke != null || this.fill != null) {
      for (let i = 0; i < this._points.length; i++) {
        canvas.drawPolygon(this._points[i], this.stroke.selectedStroke, selectedFill, opacity, context);

        // exterior points
        if (i == 0) {
          this.drawText(this._points[i], canvas, context);          
        }
      }
    }
  } 

  drawTracked(canvas, opacity, context) {
    context.globalCompositeOperation = 'source-over';

    let trackingFill = (this.fill && this.fill.trackingFill) ? this.fill.trackingFill: null;
    
    if (this.stroke != null || this.fill != null) {
      for (let i = 0; i < this._points.length; i++) {
        canvas.drawPolygon(this._points[i], this.stroke.trackingStroke, trackingFill, opacity, context);

        // exterior points
        if (i == 0) {
          this.drawText(this._points[i], canvas, context);          
        }
      }
    }
  } 

  drawPolygon(points, canvas, opacity, stroke, fill, context) {
    for (let i = 0; i < points.length; i++) {
      canvas.drawPolygon(points[i], stroke, i == 0 ? fill : null, opacity, context);

      // exterior points
      if (i == 0) {        
        this.drawText(points[i], canvas);        
      }
    }        
  }

  drawText(pts, canvas, context) {
    const DEG_TO_RAD = 0.017453292519943296;
    if (this.LabelStyle != null  && this.LabelStyle.isVisible(this.Feature.featureLayer.map.scale)) {
      if (true) { //this.labelPoint == null || canvas.xOffset == null || canvas.yOffset == null ) {
        /*
        var labelRotation = this.LabelStyle.Rotation != null && this.LabelStyle.Rotation.length > 0 ? parseInt(this.LabelStyle.Rotation) : undefined;

        const message = {
          Label : this.labelText,
          TextWidth : canvas.getTextWidth(this.labelText),
          FontSize : this.LabelStyle.FontSize,
          Points :  pts,
          SuggestedAngle :  labelRotation
        }

        this._feature.featureLayer.mpWorker.postMessage(message);
        */
        this.labelPoint = new LabelPoint();
        let rcExtents =  MapPrimitive.CalculateExtents(pts);
        var sLabel = this.labelText;
        var labelRotation = this.LabelStyle.Rotation != null && this.LabelStyle.Rotation.length > 0 ? parseInt(this.LabelStyle.Rotation) : undefined;
        
        var pt = null;
        if (pts == null || pts.Length < 3)
          sLabel = null;
        else if (MapPrimitive.GetPolygonLabelPoint(rcExtents, pts, labelRotation, this.labelPoint)) {
          labelRotation = this.labelPoint.angle;
          pt = this.labelPoint.pt;
        }
        else
          sLabel = null;
        
        if (sLabel != null && sLabel.length > 0 && pt != null) {
          let textWidth = canvas.getTextWidth(sLabel);
          let halfLabelHeight = this.LabelStyle.FontSize / 2 + 2;
          let halfLabelWidth = textWidth / 2;

          let angleInRadians = DEG_TO_RAD * labelRotation;
          let cos = Math.cos(angleInRadians);
          let sin = Math.sin(angleInRadians);

          var tangent = {
            "Width": (cos * halfLabelWidth), 
            "Height": (sin * halfLabelWidth)
          }
          var perpendicular = {
            "Width": sin * halfLabelHeight,
            "Height": cos * halfLabelHeight
          }

          let ptUpperLeft = new Point(pt.x - tangent.Width + perpendicular.Width, pt.y - perpendicular.Height - tangent.Height);
          let ptLowerLeft = new Point(pt.x - tangent.Width - perpendicular.Width, pt.y + perpendicular.Height - tangent.Height);

          let ptUpperRight = new Point(pt.x + tangent.Width + perpendicular.Width, pt.y - perpendicular.Height + tangent.Height);
          let ptLowerRight = new Point(pt.x + tangent.Width - perpendicular.Width, pt.y + perpendicular.Height + tangent.Height);

          // basic test. Note: We should be doing line intersection like mapserv
          if (this.isMouseOver(rcExtents, pts, ptUpperLeft) && this.isMouseOver(rcExtents, pts, ptUpperRight) && 
              this.isMouseOver(rcExtents, pts, ptLowerRight) && this.isMouseOver(rcExtents, pts, ptLowerLeft)) {
            canvas.drawText(sLabel, Math.trunc(pt.x), Math.trunc(pt.y), labelRotation, this.LabelStyle, 'center', context)             
          }
          else {
            this.labelPoint = null;
            //let dist = PolyLabel.pointToPolygonDist(pt.x, pt.y, this._points);
            //if (dist > -1 && rcExtents.width > textWidth*2) {
            //  canvas.drawText(sLabel, Math.trunc(pt.x), Math.trunc(pt.y), labelRotation, this.LabelStyle) 
            //}
          }
        }        
      }
      else if (this.labelText.length > 0) {
        let x = Math.trunc(this.labelPoint.pt.x) + canvas.xOffset; 
        let y = Math.trunc(this.labelPoint.pt.y) + canvas.yOffset; 
        if (x > 0 && y > 0) {
          canvas.drawText(this.labelText, x, y, this.labelPoint.angle, this.LabelStyle, context)   
        }
      }
      
    }
  }

  drawTextCallback(pts, canvas, context) {

  }
  isMouseOver(rcExtents, points, ptMouse) {
    var bFound = false;

    if (rcExtents.pointInRect(ptMouse.x, ptMouse.y)) {
      bFound = MapPrimitive.PolygonContains(points, ptMouse);
      /* 
      if (this._points.length > 1) {
        for (let index = 1; bFound && index < this._points.length; index++)
          bFound = !MapPrimitive.PolygonContains(this._points[index], ptMouse);
      }
      */
    }

    return bFound;
  }

  getRectExtents() {
    if (this._points != null && this._points.length > 0) {
      return MapPrimitive.CalculateExtents(this._points[0]);
    }

    return null;
  }
  //#endregion  
}