
import {Point, toPoint} from '../geometry/Point';
import {Bounds, toBounds} from '../geometry/Bounds';
import {LatLng, toLatLng} from '../geometry/LatLng';
import {LatLngBounds, toLatLngBounds, fromBoundingBox} from '../geometry/LatLngBounds';
import {Envelope} from '../geometry/Envelope';
import {Viewport, Symbol, Fill, Stroke, Label} from '../drawing/index';
import {Rectangle, fromLTRB, fromRect} from '../geometry/rectangle';

export class MeasurementPoint {
  constructor(clientPoint, worldCoordinate, distance, isStartPoint, colour)
  {
    this.ClientPoint = clientPoint;
    this.WorldCoordinate = worldCoordinate;
    this.DistanceInMetresFromPreviousPoint = distance;
    this.IsStartPoint = isStartPoint;
    this.Colour = colour;
  }  
}

export class Measurement {
  constructor(map) {
    this.map = map;
    this.viewPort = new Viewport(this.map.rcMap, this.map.extents, this.map.cellsize, this.map.scale);
    this.linePoints = [];
    this.canvas = map.canvas;
    this.context = map.canvas.context;
    this.selectedPoint = null;
    this.selectedPointIndex = -1;
    this.insertionPoint = null;
    this.measureToolDistanceUnits = 'Meters/Kilometers';
    this.measureToolAreaUnits = 'Use distance unit';
    this.measurementRec = null;

    this.circleStroke = new Stroke({ Type: 'Solid', Colour: 'black', Width : 2 });
    this.lineStroke = new Stroke({ Type: 'Solid', Colour: 'black', Width : 2 });
    this.blackFill = new Fill({ Type: 'Solid', Colour: 'black' });
    this.label = new Label({ FontName: 'Arial', Colour: 'black', FontSize : 10, Position : 0 });
  }

  addPoint(measurementPoint, clientPoint) {
    var distanceInMetres = 0.0;
    
    // if clicked the starting point use the measurementPoint's WorldCoordinate otherwise the clientPoint      
    var worldCoordinate = measurementPoint != null && measurementPoint.IsStartPoint ? measurementPoint.WorldCoordinate : this.viewPort.pointToWorldCoordinate(clientPoint);

    if (this.linePoints.length > 0) {        
      var previousPoint = this.linePoints[this.linePoints.length - 1];           
      // calculate the distance from the previous point to the new point       
      distanceInMetres = previousPoint.WorldCoordinate.distanceTo(worldCoordinate);        
    }

    let newPoint = new MeasurementPoint(clientPoint, worldCoordinate, distanceInMetres, this.linePoints.length == 0, 'black')
    this.linePoints.push(newPoint);

    return newPoint;
    
  }

  /// <summary>
  /// Inserts a point into the this.linePoints list.
  /// </summary>
  insertPoint(clientPoint, indexToInsertAt) {      
    var previousPoint = null;

    if (indexToInsertAt - 1 >= 0)
    {
      previousPoint = this.linePoints[indexToInsertAt - 1];        
      var worldPoint = this.viewPort.pointToWorldCoordinate(clientPoint);
      
      // calculate the distance        
      var distanceInMetres = previousPoint.WorldCoordinate.distanceTo(worldPoint);

      // insert item on indexToInsertAt
      this.linePoints.splice(indexToInsertAt, 0, new MeasurementPoint(clientPoint, worldPoint, distanceInMetres, false, 'black'));
      // update the distance for the point after it        
      if (indexToInsertAt + 1 < this.linePoints.length)
      {          
        var nextPoint = this.linePoints[indexToInsertAt + 1];
        nextPoint.DistanceInMetresFromPreviousPoint = worldPoint.distanceTo(nextPoint.WorldCoordinate);
      }
    }      
  }

  
  
  /// <summary>
  /// Determines whether the client point lies within any of the line segments within the this.linePoints list.
  /// </summary>
  isPointOnLine(clientPoint) {
    let indexToInsertAt = -1;
    
    for (var i = 0; i < this.linePoints.length-1; i++) {
      let start = this.linePoints[i].ClientPoint;
      let end = this.linePoints[i+1].ClientPoint;

      if (this.isPointOnSegment(clientPoint, start, end)) {
        indexToInsertAt = i+1;
        break;
      }
    }  
    
    return  indexToInsertAt;
  }

  /// <summary>
  /// Looks for an existing point in the this.linePoints collection.
  /// </summary>        
  /// <returns>The world <see cref="MeasurementPoint"/> if found, otherwise null.</returns>     
  getExistingPoint(clientPoint) {     
    var foundPoint = null;
    var i = -1;
    for ( i = 0; i < this.linePoints.length; i++) {
      let point =  this.linePoints[i];
    
      var existingPoint = this.viewPort.worldToClient(point.WorldCoordinate.X, point.WorldCoordinate.Y);
      let distance = existingPoint.distanceTo(clientPoint); 

      // we use the radius of the linePoint (circle) drawn as a way
      // to detect if the clientPoint in inside that circle.
      // if distance of clientPoint to linePoint is less than or equal to the radius, then the point is inside.
      if ( distance <= (Measurement.Radius+2)) {
        foundPoint = point;
        break;      
      }
    }

    return {"point":foundPoint, "index": i};
  }

  /// <summary>
  /// Remove the point from the this.linePoints list.
  /// </summary>
  removePoint(worldCoordinate) {    
    // find original point      
    var index = this.linePoints.findIndex( x => x.WorldCoordinate.equals(worldCoordinate));

    if (index > -1)
      this.linePoints.splice(index, 1);

    // if it was a closed polygon and now it's not anymore then remove the last point (which is the same as the starting point)
    if (this.linePoints.length > 1 && this.linePoints.length < 4 && this.linePoints[this.linePoints.length - 1].WorldCoordinate.equals(this.linePoints[0].WorldCoordinate))
      this.linePoints.splice(this.linePoints.length - 1, 1);
  }

  setDistanceType(measureTool){
    this.measureToolDistanceUnits = measureTool.MeasureToolDistanceUnits;
    this.measureToolAreaUnits =  measureTool.MeasureToolAreaUnits;
  }

  measureBoxHitTest(x,y){
    if (this.measurementRec == null) return false;
    return this.measurementRec.pointInRect(x, y);
  }

  drawDetailsBox(context) {
    let ctx = context != undefined ? context : this.context;

    const offset = 20;
    const panelWidth = 200;
    const panelHeight = 55;
    // each line of text is 27.5 height

    var mapContainer = this.map._container;

    let rect = new Rectangle(this.map.rcMap.Width / 2, this.map.rcMap.MinY + offset, panelWidth, panelHeight);     
    this.measurementRec = rect; 
    //this.canvas.drawRectangle(rect.left, rect.top, rect.right, rect.bottom, '#000000', '#FFFFFF', 1, [], ctx);
    this.canvas.drawRoundRectangle(rect.left, rect.top, rect.width, rect.height, 5, '#000000', '#FFFFFF', ctx)

    var totalDistance = 0;
    if (this.linePoints.length > 1) {
      var lastPoint = this.linePoints[0];
      for (let i = 1; i < this.linePoints.length; i++) {
        totalDistance += lastPoint.WorldCoordinate.distanceTo(this.linePoints[i].WorldCoordinate); 
        lastPoint = this.linePoints[i];
      }
    }
    
    this.canvas.drawText(`Total Distance: ${this.getDistanceString(totalDistance, this.measureToolDistanceUnits)}`, rect.left + offset, rect.top + offset, undefined, this.label, 'start', ctx);

    let area = this.calculateArea();
    if (area > 0)
      this.canvas.drawText(`Total Area: ${this.getAreaString(area, this.measureToolAreaUnits)}`, rect.left + offset, rect.top + (offset*2), undefined, this.label, 'start', ctx);      
  }   

  selectAreaUnit(areaUnit){
    this.measureToolAreaUnits = areaUnit;
    this.map.invalidate();
    this.render();
  }

  selectDistanceUnit(distanceUnit){
    this.measureToolDistanceUnits = distanceUnit;
    this.map.invalidate();
    this.render();
  }
  
  drawPoint(clientPoint, drawExtraEllipse, context, colour = this.circleStroke.Colour) {
    let ctx = context != undefined ? context : this.context;
    
    var radius = drawExtraEllipse ? Measurement.Radius - 1 : Measurement.Radius;
    this.canvas.drawCircleShape(ctx, clientPoint.x, clientPoint.y, radius, this.circleStroke.Width, colour, colour);        
    
    if (drawExtraEllipse) {
      radius =  Measurement.Radius + 4;
      this.canvas.drawCircleShape(ctx, clientPoint.x, clientPoint.y, radius, this.circleStroke, colour, null);      
    }    
  }

  drawLine(clientPoint, previousClientPoint, distanceInMetres, context) {    
    let ctx = context != undefined ? context : this.context;

    this.canvas.drawLine([clientPoint, previousClientPoint], this.lineStroke, 1.0, ctx);
    this.canvas.drawTextOverLineSegment("#000000", 12, distanceInMetres, previousClientPoint, clientPoint, true, ctx);    
  }

  getDistanceString(distanceInMetres, distanceUnits) {

    const metresInKilometre = 1000;
    const metresToFeet = 3.28084; 
    const metresToInches = 39.3701;
    const metresToYards = 1.09361;
    const metresToMiles = 0.000621371;
    const feetToMiles = 5280;

    switch(distanceUnits) {

      case 'Metres/Kilometres':
        if (distanceInMetres < metresInKilometre)
        return `${distanceInMetres.toFixed(2)} m`;
      else
        return `${(distanceInMetres / metresInKilometre).toFixed(2)} km`;
      case 'Millimetres':
        return `${(distanceInMetres * 1000).toFixed(2)} mm`;
      case 'Centimetres':
        return `${(distanceInMetres * 100).toFixed(2)} cm`;
      case 'Metres':
        return `${distanceInMetres.toFixed(2)} m`;
      case 'Kilometres':
        return `${(distanceInMetres / metresInKilometre).toFixed(2)} km`;
      case 'Feet/Miles':
        var feet = distanceInMetres * metresToFeet;
        if (feet < feetToMiles) {
          return `${feet.toFixed(2)} ft`
        } else {
          return `${(feet / feetToMiles).toFixed(2)} mi`;
        }
      case 'Inches':
        {
          var inches = distanceInMetres * metresToInches; 
          return `${inches.toFixed(2)} in`;
        }
      case 'Feet':{
        var feet = distanceInMetres * metresToFeet;
        return `${feet.toFixed(2)} ft`
      }
      case 'Yards': {
        var yards = distanceInMetres * metresToYards;
        return `${yards.toFixed(2)} yd`; 
      }
      case 'Miles': {
        var miles = distanceInMetres * metresToMiles;
        return `${miles.toFixed(2)} mi`; 
      }

      default:
        if (distanceInMetres < metresInKilometre)
        return `${distanceInMetres.toFixed(2)} m`;
      else
        return `${(distanceInMetres / metresInKilometre).toFixed(2)} km`;
    }


  }

  getAreaString(areaInMetresSquared, areaUnits) {
    const squareMetresInKilometre = 1000000;
    const squareMetresInHectare = 10000;
    const squareMetresInMillimetre =  0.000001;
    const squareMetresInCentimetre =  0.0001;
    const squareMetresInSquareFeet = 0.092903;
    const squareMetreInAcre = 4047;
    const squareMetreInSquareMile = 2590000;
    const squareMetreInSquareInch = 0.00064516;
    const squareMetreInYard = 1.19599;

    switch(areaUnits){
      
      case "Use distance unit":
        case "UseDistanceUnits":
        return this.getAreaString(areaInMetresSquared, this.measureToolDistanceUnits);
      case "Metres/Hectares/Kilometres":
      case "Metres/Kilometres":

        if (areaInMetresSquared >= squareMetresInKilometre){
          return `${(areaInMetresSquared / squareMetresInKilometre).toFixed(2)} km²`;
        }  else if (areaInMetresSquared >= squareMetresInHectare){
          return `${(areaInMetresSquared / squareMetresInHectare).toFixed(2)} ha`;
        } else {
          return `${(areaInMetresSquared).toFixed(2)} m²`;
        }

      case "Square Millimetres":
      case "Millimetres":
        return `${(areaInMetresSquared / squareMetresInMillimetre).toFixed(2)} mm²`;


      case "Square Centimetres":
      case "Centimetres":
        return `${(areaInMetresSquared / squareMetresInCentimetre).toFixed(2)} cm²`;


      case "Square Metres":
      case "Metres":
        return `${(areaInMetresSquared).toFixed(2)} m²`;

      case "Hectares":
        return `${(areaInMetresSquared / squareMetresInHectare).toFixed(2)} ha`;


      case "Square Kilometres":
      case "Kilometres":
        return `${(areaInMetresSquared / squareMetresInKilometre).toFixed(2)} km²`;


      case "Feet/Acres/Miles":
      case "Feet/Miles":
        if (areaInMetresSquared >= squareMetreInSquareMile){
          return `${(areaInMetresSquared / squareMetreInSquareMile).toFixed(2)} mi²`;
        }  else if (areaInMetresSquared >= squareMetreInAcre){
          return `${(areaInMetresSquared / squareMetreInAcre).toFixed(2)} acres`;
        } else {
          return `${(areaInMetresSquared / squareMetresInSquareFeet).toFixed(2)} ft²`;
        }


      case "Square Inches":
      case "Inches":
        return `${(areaInMetresSquared / squareMetreInSquareInch).toFixed(2)} in²`;


      case "Square Feet":
      case "Feet":
        return `${(areaInMetresSquared / squareMetresInSquareFeet).toFixed(2)} ft²`;


      case "Square Yards":
      case "Yards":
        return `${(areaInMetresSquared / squareMetreInYard).toFixed(2)} yd²`;


      case "Acres":
        return `${(areaInMetresSquared / squareMetreInAcre).toFixed(2)} acres`;


      case "Square Miles":
      case "Miles":
        return `${(areaInMetresSquared / squareMetreInSquareMile).toFixed(2)} mi²`;

      default: 
        if (areaInMetresSquared >= squareMetresInKilometre){
          return `${(areaInMetresSquared / squareMetresInKilometre).toFixed(2)} km²`;
        }  else if (areaInMetresSquared >= squareMetresInHectare){
          return `${(areaInMetresSquared / squareMetresInHectare).toFixed(2)} ha`;
        } else {
          return `${(areaInMetresSquared).toFixed(2)} m²`;
        }
      

    }

  }

  getLastPoint() {
    if (this.linePoints.length > 0)
      return this.linePoints[this.linePoints.length-1];
    
    return null;
  }

  calculateArea() {      
    var area = 0.0;

    if (this.linePoints.length >= 4) // enough points to create a polygon (4 points and 3 sides with 1st and last points being the same)
    {
      var firstPoint = this.linePoints[0].WorldCoordinate;
      var lastPoint = this.linePoints[this.linePoints.length - 1].WorldCoordinate;
      
      if (firstPoint.X == lastPoint.X && firstPoint.Y == lastPoint.Y) // 1st and 4th points are the same so we have a closed polygon
      {
        for (var i = 0; i < this.linePoints.length-1; i++) {
          var p1 = this.linePoints[i].WorldCoordinate;
          var p2 = this.linePoints[i + 1].WorldCoordinate;
          area += this.convertToRadian(p2.Y - p1.Y) * (2 + Math.sin(this.convertToRadian(p1.X)) + Math.sin(this.convertToRadian(p2.X)));
        }

        // returns area in square metres
        area = area * Measurement.RadiusOfEarthInMetres * Measurement.RadiusOfEarthInMetres / 2;
      }
    }

    return Math.abs(area);
  }    

  convertToRadian(input) {
    return input * Math.PI / 180;
  } 
  
  distance(p1, p2) {
    //let a = p1.x - p2.x;
    //let b = p1.y - p2.y;
    //return Math.sqrt( a*a + b*b );
    return Math.hypot(p2.x - p1.x, p2.y - p1.y);
  }

  isPointOnSegment(point, A, B) {
    let d1 = this.distance(point, A);
    let d2 = this.distance(point, B);
    let lineDistance = this.distance(A, B);
    let buffer = 2;  // 2 pixels

    if ( d1+d2 >= lineDistance-buffer &&  d1+d2 <= lineDistance+buffer )
      return true; // point is on the line.

    return false;
  }

  // Mouse Events and Handlers
  hitTest(x, y) {
    let testPoint = new Point(x,y);
    let tuple = this.getExistingPoint(testPoint);

    if (tuple.point == null) {
      for (var i = 0; i < this.linePoints.length-1; i++) {
        let start = this.linePoints[i].ClientPoint;      
        let end = this.linePoints[i+1].ClientPoint;

        if (this.isPointOnSegment(testPoint, start, end))
          return true;
      }
    }  

    return false;
  }   
  
  handleMouseClick(x, y) {
    let lastPoint = this.getLastPoint();

    let pt = new Point(x, y);
    let tuple = this.getExistingPoint(pt);
    
    let existingPoint = tuple.point;
    var needsRefreshView = false;
    if (existingPoint == null) {
      let indexToInsert = this.isPointOnLine(pt);
      if (indexToInsert > -1) {
        this.insertPoint(pt, indexToInsert);
      }
      else
        this.addPoint(null, pt);        
    }      
    else {
      if (existingPoint.IsStartPoint) {
        let distanceInMetres = lastPoint.WorldCoordinate.distanceTo(existingPoint.WorldCoordinate);        
        this.linePoints.push(new MeasurementPoint(existingPoint.ClientPoint, existingPoint.WorldCoordinate, distanceInMetres, false, 'black'));
      }
      else {
        this.removePoint(existingPoint.WorldCoordinate);
      }
    }

    this.map.invalidate();
    this.render();    
  }

  handleMouseDown(x, y) {
    let tuple = this.getExistingPoint(new Point(x, y))
    this.selectedPoint = tuple.point;
    
    this.selectedPointIndex = tuple.point != null ? tuple.index : -1;    

    return this.selectedPoint != null ? true : false;
  }

  handleMouseDragging(x, y) {
    if (this.selectedPoint != null) {
      let lastPoint = this.getLastPoint();
      
      if (lastPoint.ClientPoint.equals(this.selectedPoint.ClientPoint)) {
        lastPoint.ClientPoint = new Point(x, y);
        lastPoint.WorldCoordinate = this.viewPort.pointToWorldCoordinate(lastPoint.ClientPoint);

        this.selectedPoint.ClientPoint = lastPoint.ClientPoint;
        this.selectedPoint.WorldCoordinate = lastPoint.WorldCoordinate;
      }
      else {
        this.selectedPoint.ClientPoint = new Point(x, y);
        this.selectedPoint.WorldCoordinate = this.viewPort.pointToWorldCoordinate(this.selectedPoint.ClientPoint);
      }

      this.map.invalidate();
      this.render();      
    }
  }

  updateExtents() {
    this.viewPort = new Viewport(this.map.rcMap, this.map.extents, this.map.cellsize, this.map.scale);
    this.render();
  }

  render(context) {
    let ctx = context != undefined ? context : this.context;

    var lastPoint = null;
    for (let i = 0; i < this.linePoints.length; i++) {
      let current = this.linePoints[i];

      current.ClientPoint = this.viewPort.worldToClient(current.WorldCoordinate.X, current.WorldCoordinate.Y);

      if (current.IsStartPoint) {      
        this.drawPoint(current.ClientPoint, true, ctx);
      }
      else if (lastPoint != null) {
        let distance = lastPoint.WorldCoordinate.distanceTo(current.WorldCoordinate);
        if (distance > 0) {
          this.drawLine(current.ClientPoint, lastPoint.ClientPoint, this.getDistanceString(distance, this.measureToolDistanceUnits), ctx);
          this.drawPoint(current.ClientPoint, false, ctx);
        }
      }      

      lastPoint = current;
    }

    if (this.insertionPoint) {
      this.drawPoint(new Point(this.insertionPoint.x, this.insertionPoint.y, null), false, null, 'gray');           
      this.insertionPoint = null;
    }

    this.drawDetailsBox(context);
  }

}  

Measurement.Radius = 4;
Measurement.RadiusOfEarthInMetres = 6378137;