import * as Colour from './Colour';
import MapEnums from '../map/enums';

export class Stroke {
  // @region Constructors

  constructor(lineStyle) {
    this.style = lineStyle;
    this.colour = lineStyle.Colour != undefined ? Colour.fromString(lineStyle.Colour) : 'black';
    this.width = lineStyle.Width;
    this.type = this.getStrokeType(lineStyle.Type);    

    this.dashPattern = this.getLineDashStyle(lineStyle);      
    this.pattern = null;
    
    /*
    if (lineStyle.Type == 'Pattern') {
      this.patternId = lineStyle.PatternId;
      this.patternHeight = lineStyle.PatternHeight;      
      this.pattern = new Image();
      
      let img = this.pattern;
      this.pattern.onload = function () {
        img.width = lineStyle.PatternHeight;
        img.height = lineStyle.PatternHeight;
      };

      this.pattern.src = "data:image/svg+xml;charset=utf8, " + this.sanitisedSVG(lineStyle.Pattern);     
    } 
    */       
  }

  //clone(other) {
  //  return new Stroke(other.colour, other.opacity, other.width, other.type, other.endCap, other.lineJoin);
  //}

  getStrokeType(sType) {
    if (sType == undefined)
      return MapEnums.StrokeTypes.None;
    else if (sType == 'Solid')
      return MapEnums.StrokeTypes.Solid;
    else if (sType == 'DashStyle')
      return MapEnums.StrokeTypes.DashStyle;
    else if (sType == 'DashPattern')
      return MapEnums.StrokeTypes.DashPattern;  
    else if (sType == 'Pattern')
      return MapEnums.StrokeTypes.Pattern;
  }
  
  getLineDashStyle(lineStyle) {
    //
    // Solid = 0,
    // Dash = 1,
    // Dot = 2,
    // DashDot = 3,
    // DashDotDot = 4,
    var lineDashStyle = []; // If its solid, just set the array to empty

    if (lineStyle.Type == 'DashStyle') {
      if (lineStyle.DashStyle == 'Dash')
        lineDashStyle = [4,3];
      else if (lineStyle.DashStyle == 'Dot')
        lineDashStyle = [2,2];
      else if (lineStyle.DashStyle == 'DashDot')
        lineDashStyle = [4, 3, 2, 3];
      else if (lineStyle.DashStyle == 'DashDotDot')
        lineDashStyle = [4, 3, 2, 3, 3, 2];
    }
    else if (lineStyle.Type == 'DashPattern') {
      //lineDashStyle.push.apply(arrayNum, lineStyle.DashPattern.split(",").map(Number));
      var strings = lineStyle.DashPattern.split(",");
      for (let i=0; i < strings.length; i++)
        lineDashStyle.push( + strings[i] );
    }       
    

    return lineDashStyle;
  }

  setTrackingStyle(lineStyle, trackingColour, trackingWidth){
    var trackingStyle = {
      'Colour' : trackingColour,
      'Width' : this.width * trackingWidth,
      'Type' : lineStyle.Type,
      'DashStyle': lineStyle.DashStyle,
      'DashPattern': lineStyle.DashPattern,
      'Pattern' : lineStyle.Pattern,
      'PatternId' : lineStyle.PatternId,
      'PatternHeight' : lineStyle.PatternHeight,
    };

    this.trackingStroke = new Stroke(trackingStyle);

  }
   
  setHoverAndSelectedStyles(lineStyle, hoverColour, hoverWidth, selectedColour, selectedWidth) {
    var hoverStyle = {
      'Colour' : hoverColour,
      'Width' : this.width * hoverWidth,
      'Type' : lineStyle.Type,
      'DashStyle': lineStyle.DashStyle,
      'DashPattern': lineStyle.DashPattern,
      'Pattern' : lineStyle.Pattern,
      'PatternId' : lineStyle.PatternId,
      'PatternHeight' : lineStyle.PatternHeight,
    };


    var selectedStyle = {
      'Colour' : selectedColour,
      'Width' : this.width * selectedWidth,
      'Type' : lineStyle.Type,
      'DashStyle': lineStyle.DashStyle,
      'DashPattern': lineStyle.DashPattern,
      'Pattern' : lineStyle.Pattern,
      'PatternId' : lineStyle.PatternId,
      'PatternHeight' : lineStyle.PatternHeight,
    };

    this.hoverStroke = new Stroke(hoverStyle);
    this.selectedStroke = new Stroke(selectedStyle);
  }

  sanitisedSVG(svg) {
    var sanitized = svg.replace(/\</g,'%3C')   //for <
    sanitized = sanitized.replace(/\>/g,'%3E')   //for >
    sanitized = sanitized.replace(/\#/g,'%23')   //for #
    
    return sanitized;
  }
  // @region Properties  
  get IsEmpty() {
    return (type == Types.None || width == 0.0 || opacity == 0 || colour == "none");
  }
    
  get LineCap() {
    var lineCap = "";

    switch (this.endCap)
    {
      case MapEnums.EndCaps.Butt:
        lineCap = "butt";
        break;
      case MapEnums.EndCaps.Round:
        lineCap = "round";
        break;
      case MapEnums.EndCaps.Square:
        lineCap = "square";
        break;
      default:
        lineCap = "square";
        break;
    }

    return lineCap;    
  }  

  get Colour() {
    return this.colour;
  }
  
  get Width() {
    return this.width;
  }

  get DashPattern() {
    return this.dashPattern;
  }

  get Pattern() {
    return this.pattern;
  }

  get StrokeType() {
    return this.type;
  }

  loadPattern(baseUrl, accessToken, debugMode = false) {
    if (this.type == MapEnums.StrokeTypes.Pattern) {
      let base64 = window.btoa(debugMode ? JSON.stringify(this.style) : JSON.stringify(this.style.objJSON));
      let imageUrl = `${baseUrl}\GenerateStrokePattern?token=${accessToken}&jStyle=${base64}&size=24`;
      
      this.pattern = new Image();
      let __this = this;

      this.pattern.onload = function () {
        __this.pattern.width = __this.style.PatternHeight;
        __this.pattern.height = __this.style.PatternHeight;
      };

      this.pattern.src = imageUrl;          
    }
  }
}
//Stroke.None = new Stroke(Colour.CssColours.none,0,0,StrokeTypes.None);
//Stroke.Default = new Stroke(Colour.CssColours.Black,100,2, StrokeTypes.Solid);

