import {Geometry, DimensionTypes} from './geometry';
import {GeoPoint, GeoLineString, GeoLinearRing, GeoPolygon, GeoMultiPoint, GeoMultiLineString, GeoMultiPolygon, GeometryCollection} from './index';
import {Coordinate} from './coordinate';


export class WktReader
{
  constructor(value)
  {
    this.wktTypes = ['POINT', 'LINESTRING', 'LINEARRING', 'POLYGON', 'MULTIPOINT', 'MULTILINESTRING', 'MULTIPOLYGON', 'GEOMETRYCOLLECTION'];
    this.value = value;
    this.position = 0;  
    this.geometry = undefined;
    this.length = this.value.length;
  }

  static parseWKT(value) {
      let reader = new WktReader(value);
      return reader.Read();
  }
  

  Read()
  {
      var geometryType = this.MatchType();
      var dimension = this.MatchDimension();

      var geometry = this.CreateGeometry(geometryType, dimension);

      if (this.IsEmpty())
          return geometry;

      return this.ReadGeometry(geometryType, dimension);
  }

  ReadType(defaultType, defaultDimension, types) {
      var geometryType = this.MatchTypeOrDefault(defaultType, types);
      var dimension = this.MatchDimension(defaultDimension);

      var geometry = this.CreateGeometry(geometryType, dimension);

      if (this.IsEmpty())
          return geometry;

      return this.Read(geometryType, dimension);
  }

  ReadGeometry(geometryType, dimension) {
    switch (geometryType)
    {
      case 'POINT': return this.ReadPoint(dimension);
      case 'LINESTRING': return this.ReadLineString(dimension);
      case 'POLYGON': return this.ReadPolygon(dimension);
      case 'MULTIPOINT': return this.ReadMultiPoint(dimension);
      case 'MULTILINESTRING': return this.ReadMultiLineString(dimension);
      case 'MULTIPOLYGON': return this.ReadMultiPolygon(dimension);
      case 'GEOMETRYCOLLECTION': return this.ReadGeometryCollection(dimension);
      default: throw new (geometryType);
    }
  }

  CreateGeometry(geometryType, dimension)
  {
    var geometry = null;

    switch (geometryType)
    {
      case 'POINT': geometry = new GeoPoint(); break;
      case 'LINESTRING': geometry = new GeoLineString(); break;
      case 'POLYGON': geometry = new GeoPolygon(); break;
      case 'MULTIPOINT': geometry = new GeoMultiPoint(); break;
      case 'MULTILINESTRING': geometry = new GeoMultiLineString(); break;
      case 'MULTIPOLYGON': geometry = new GeoMultiPolygon(); break;
      case 'GEOMETRYCOLLECTION': geometry = new GeometryCollection(); break;
      default: throw new Error(geometryType);
    }

    geometry.Dimension = dimension;
    return geometry;
  }

  ReadPoint(dimension)
  {
    this.ExpectGroupStart();
    var point = new GeoPoint(this.MatchCoordinate(dimension));
    point.Dimension = dimension;
    this.ExpectGroupEnd();

    return point;
  }

  ReadLineString(dimension)
  {
    this.ExpectGroupStart();
    var lineString = new GeoLineString(this.MatchCoordinates(dimension));
    lineString.Dimension = dimension;
    this.ExpectGroupEnd();

    return lineString;
  }

  ReadPolygon(dimension)
  {
    this.ExpectGroupStart();

    this.ExpectGroupStart();
    var polygon = new GeoPolygon(new GeoLinearRing(this.MatchCoordinates(dimension)));
    polygon.Dimension = dimension;
    this.ExpectGroupEnd();

    while (this.IsMatch(","))
    {
      this.ExpectGroupStart();
      polygon.InteriorRings.push(new GeoLinearRing(this.MatchCoordinates(dimension)));
      this.ExpectGroupEnd();
    }

    this.ExpectGroupEnd();
    return polygon;
  }

  ReadMultiPoint(dimension)
  {
    this.ExpectGroupStart();
    var multiPoint = new GeoMultiPoint(this.MatchCoordinates(dimension));
    multiPoint.Dimension = dimension;
    this.ExpectGroupEnd();

    return multiPoint;
  }

  ReadMultiLineString(dimension)
  {
    var multiLineString = new GeoMultiLineString();
    multiLineString.Dimension = dimension;

    this.ExpectGroupStart();

    do {
      multiLineString.Geometries.push(this.ReadLineString(dimension));
    } while (this.IsMatch(","));

    this.ExpectGroupEnd();

    return multiLineString;
  }

  ReadMultiPolygon(dimension)
  {
    var multiPolygon = new GeoMultiPolygon();
    multiPolygon.Dimension = dimension;

    this.ExpectGroupStart();

    do {
      var geometry = this.CreateGeometry('POLYGON', dimension);

      if (this.IsEmpty())
        multiPolygon.Geometries.push(geometry);
      else 
        multiPolygon.Geometries.push(this.ReadPolygon(dimension));
    } while (this.IsMatch(","));

    this.ExpectGroupEnd();

    return multiPolygon;
  }

  ReadGeometryCollection(dimension)
  {
    var geometryCollection = new GeometryCollection();
    geometryCollection.Dimension = dimension;

    this.ExpectGroupStart();

    do {
      geometryCollection.Geometries.push(this.Read());
    } while (this.IsMatch(","));

    this.ExpectGroupEnd();

    return geometryCollection;
  }
  
  MatchType() {
      var geometryType = this.Match(this.wktTypes);

      if (geometryType == null)
        throw new Error("Expected geometry type");

      return geometryType;
  }

  MatchTypeOrDefault(defaultType, types) {
    var geometryType = this.Match(types);

    if (geometryType == null)
      return defaultType;

    return geometryType;
  }

  MatchDimension(defaultDimension) {
    let dimensionMatch = this.Match(["ZM", "Z", "M"]);

    if (dimensionMatch == null)
      return defaultDimension != null ? defaultDimension : DimensionTypes.Xy;

    switch (dimensionMatch)
    {
      case "Z": return DimensionTypes.Xyz;
      case "M": return DimensionTypes.Xym;
      case "ZM": return DimensionTypes.Xyzm;
      default: throw new Error(dimensionMatch);
    }
  }

  ExpectGroupStart() {
    if (!this.IsMatch("(")) {
      console.log(this.value);
      throw new Error("Expected group start");
    }
  }

  ExpectGroupEnd() {
    if (!this.IsMatch(")"))
        throw new Error("Expected group end");
  }

  MatchCoordinate(dimension) {
    var coordinateValue = this.Peek([',', ')']);

    if (coordinateValue == undefined)
      throw new Error("Expected coordinates");

    var coordinates = coordinateValue.split(' ');

    switch (coordinates.length)
    {
      case 2: return new Coordinate(parseFloat(coordinates[0]), parseFloat(coordinates[1]));
      case 3: return new Coordinate(parseFloat(coordinates[0]), parseFloat(coordinates[1]), parseFloat(coordinates[2]));       
      case 4: dimension = Dimension.Xyzm; return new Coordinate(parseFloat(coordinates[0]), parseFloat(coordinates[1]), parseFloat(coordinates[2]));
      default: throw new Error("Expected coordinates");
    }
  }

  MatchCoordinates(dimension) {
    var coordinates = [];

    do {
      var startsWithBracket = this.IsMatch("(");
      coordinates.push(this.MatchCoordinate(dimension));

      if (startsWithBracket)
        this.ExpectGroupEnd();
    } while (this.IsMatch(","));

    return coordinates;
  }

  IsEmpty() {
    return this.IsMatch(["EMPTY"]);
  }

  SkipWhitespaces() {    
    while (this.position < this.value.length && this.IsWhiteSpace(this.value[this.position]))
      this.position++;
  }

  IsWhiteSpace(c) {
    return c == ' ' || c == '\n' || c == '\n';
  }
   
  Match(tokens) {
    this.SkipWhitespaces();

    for (var i = 0; i < tokens.length; i++) {
      if (this.value.indexOf(tokens[i], this.position) == this.position)
      {
        this.position += tokens[i].length;
        return tokens[i];
      }
    }

    return null;
  }

  Peek(tokens) {
    this.SkipWhitespaces();

    let tokenPosition = this.position;
    var foundToken = false;

    do {
      for (let i = 0; i < tokens.length; i++)
      {
        if (tokenPosition + 1 < this.value.length && this.value[tokenPosition + 1] == tokens[i]) {
          foundToken = true;
          break;
        }
      }

      tokenPosition++;
    } while (!foundToken && tokenPosition < this.value.length);

    var peekValue = this.value.substr(this.position, tokenPosition - this.position);
    this.position = tokenPosition;
    return peekValue;
  }

  MatchRegex(tokens) {
    this.SkipWhitespaces();

    var subValue = this.value.substr(this.position);

    for (let i = 0; i < tokens.length; i++) {
      let match = Regex.Match(subValue, tokens[i]);

      if (match != null) {
        this.position += match.length;
        return match;
      }
    }

    return null;
  }

  IsMatch(tokens) {
    this.SkipWhitespaces();

    for (let i = 0; i < tokens.length; i++) {
      if (this.position + tokens[i].length <= this.value.length && this.value.substr(this.position, tokens[i].length) == tokens[i]) {
          this.position += tokens[i].length;
          return true;
      }
    }

    return false;
  }
}
