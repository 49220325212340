require("babel-polyfill");

export class MgFeatures {
  
  //#region -- CONSTRUCTORS
  constructor() {
    this._features = [];
  } 
  //#endregion
  
  //#region -- METHODS
  addFeatures(...features) {
    this._features = this._features.concat(features);
  }

  *[Symbol.iterator]() {
    for(let f of this._features) {
      yield f;
    }
  }   
  //#endregion  
}
