import {Rectangle} from '../geometry/rectangle';
import { Stroke, Fill} from '../drawing/index';
import MapEnums from '../map/enums';
import{Point, toPoint} from '../geometry/Point';
export class MgCanvas {

  //#region -- CONSTRUCTORS
  constructor(canvas,div, map) {    
    this._canvas = canvas;
    this._div = div;
    this._map = map;

    this._ctx = canvas.getContext("2d");    
    
    this._offScreenCanvas = MgCanvas.createHiDPICanvas(this._canvas.width, this._canvas.height, 1);
    this._offScreenCtx = this._offScreenCanvas.getContext("2d");    
    this._offScreenCtx.globalCompositeOperation = 'source-atop';

    this._selectionCanvas = MgCanvas.createHiDPICanvas(this._canvas.width, this._canvas.height, 1);
    this._selectionCtx = this._selectionCanvas.getContext("2d");    
    
    this.xOffset = undefined;
    this.yOffset = undefined;

    this._global = {
      scale	: 1,
      offset	: {
        x : 0,
        y : 0,
      },
    };

    this._pan = {
      start : {
        x : null,
        y : null,
      },
      offset : {
        x : 0,
        y : 0,
      },
    };

    this.isPanning = false;

    let hoverStyle = { Type: 'Solid', Colour: 'blue', Width : 2 };
    let selectedStrokeStyle = { Type: 'DashStyle', DashStyle: 'Dash', Colour: 'black', Width : 1 };
    let selectedFillStyle = { Type: 'Solid', Colour: 'grey', Width : 1 };
    let transparentFillStyle = { Type: 'Solid', Colour: 'rgba(255,255,255,0.1)', Width : 1 };

    this.hoverStroke = new Stroke(hoverStyle);
    this.selectedStroke = new Stroke(selectedStrokeStyle);
    this.selectedFill = new Fill(selectedFillStyle);
    this.transparentFill = new Fill(transparentFillStyle);
  } 
  //#endregion

  //#region -- GETTERS and SETTERS
  /** @returns MgCanvas
   */
  get canvas() {
    return this._canvas;
  }

  get offScreenCanvas() {
    return this._offScreenCanvas;
  }

  get context() {
    return this._ctx;
  }

  get offScreenContext() {
    return this._offScreenCtx;
  }

  get selectionContext() {
    return this._selectionCtx;
  }

  /** 
   * @returns div
   */
  get div() {
    return this._div;
  }
  /**    
   * @returns number
   */
  get width() {    
    return this._canvas.width;
  }
  /**
   * @returns number
   */
  get height() {    
    return this._canvas.height;
  } 

  get pan() {
    return this._pan;
  }

  get global() {
    return this._global;
  }
  //#endregion  

  //#region -- DRAW METHODS
    
  /**
   * @param  {Image} image
   * @param  {number} x
   * @param  {number} y
   */
  drawImage(image, x, y, ctxParam, alpha) {    
    if(image) {      
      let ctx = ctxParam != undefined ? ctxParam : this._ctx;
      let prevAlpha = ctx.globalAlpha;
      ctx.globalAlpha = alpha != undefined ? alpha : 1.0;
      ctx.drawImage(image, x, y);     
      ctx.globalAlpha = prevAlpha; 
    }    
  }    

  drawSymbol(image, x, y, ctxParam) {    
    if(image) {      
      let ctx = ctxParam != undefined ? ctxParam : this._offScreenCtx;
      ctx.drawImage(image, x, y, image.width, image.height);      
    }    
  }
  
  drawShapeSymbol(symbol, x, y, ctxParam) {    
    let ctx = ctxParam != undefined ? ctxParam : this._ctx;
    let fillColour = symbol.SymbolType == MapEnums.SymbolTypes.SolidShape ? symbol.Colour : undefined;
    let lineWidth = symbol.SymbolType == MapEnums.SymbolTypes.SolidShape ? 1 : 2;

    switch(symbol.ShapeType) {
      case MapEnums.Shapes.Circle:
        this.drawCircleShape(ctx, x, y, symbol.Height/2, lineWidth, symbol.Colour, fillColour);
        break;

      case MapEnums.Shapes.Square:
        this.drawSquareShape(ctx, x, y, symbol.Height, lineWidth, symbol.Colour, fillColour, 5);
        break;

      case MapEnums.Shapes.Star:
        this.drawStarShape(ctx, x, y, symbol.Height, lineWidth, symbol.Colour, fillColour, 5);
        break;
  
      case MapEnums.Shapes.Diamond:
        this.drawDiamondShape(ctx, x, y, symbol.Height, lineWidth, symbol.Colour, fillColour);
        break;  
        

      case MapEnums.Shapes.TriangleUp:
        this.drawTriangleShape(ctx, x, y, symbol.Height, lineWidth, symbol.Colour, fillColour, true);
        break;

      case MapEnums.Shapes.TriangleDown:
        this.drawTriangleShape(ctx, x, y, symbol.Height, lineWidth, symbol.Colour, fillColour, false);        
        break;

      case MapEnums.Shapes.Marker:
      case MapEnums.Shapes.Pin:  
        this.drawImage(symbol.Pattern, x-symbol.Height/2, y-symbol.Height, ctxParam);
        break;  
    }    
  }
  /**
   * @param  {string} text
   * @param  {number} x
   * @param  {number} y
   * @param  {number} angle
   */
  drawText(text, x, y, angle, labelStyle, textAlign, ctxParam) {
    let ctx = ctxParam != undefined ? ctxParam : this._offScreenCtx;
    ctx.save();

    ctx.globalAlpha  = 1.0;
    if (angle != undefined) {
      ctx.translate(x, y);
      ctx.rotate(angle * (Math.PI / 180));
    }
        
    if (labelStyle.FontName === undefined || labelStyle.FontSize === undefined)
      ctx.font = "10px Arial";
    else
      ctx.font = `${labelStyle.FontSize}px ${labelStyle.FontName}`;      
    
    if (angle != undefined) {
      ctx.textAlign = textAlign != null ? textAlign : "center"; 
      if (labelStyle.highlight) this.highlightText(ctx, text, 0, 0, labelStyle.highlight);
      ctx.fillStyle = labelStyle.Colour;
      ctx.fillText(text, 0, 0);
    }
    else {           
      ctx.textAlign = textAlign != null ? textAlign : "start"; 
      if (labelStyle.highlight) this.highlightText(ctx, text, x, y, labelStyle.highlight);
      ctx.fillStyle = labelStyle.Colour;
      ctx.fillText(text, x, y);  
      
    }    

    ctx.restore();
  }

  highlightText(ctx, text, x, y, highlightColour){
    ctx.fillStyle = highlightColour;

    ctx.fillText(text, x-1, y);
    ctx.fillText(text, x, y-1);
    ctx.fillText(text, x+1, y);
    ctx.fillText(text, x, y+1);

    ctx.fillText(text, x-1, y-1);
    ctx.fillText(text, x+1, y-1);
    ctx.fillText(text, x+1, y+1);
    ctx.fillText(text, x-1, y+1);
  }
  
  angleToRadians(angle) {
     return (Math.PI / 180) * angle;
  }

  drawTextOverLineSegment(strokeColor, fontSize, text, p1, p2, textAboveSegment, ctxParam) {    
    let ctx = ctxParam != undefined ? ctxParam : this._offScreenCtx;
    ctx.save();

    // get the segment's angle
    let dx = p2.x - p1.x;
    let dy = p2.y - p1.y;
    let angle = (180 * Math.atan2(dy, dx) / Math.PI);

    // find the center point
    let centreX = (p2.x + p1.x) / 2;
    let centreY = (p2.y + p1.y) / 2;

    // if we're going from right to left (dx is negative) then rotate 180 so the text is the right way up (otherwise upside down)
    if (dx < 0)
      angle += 180;

    // translate and rotate the origin to the centre of the segment
    ctx.fillStyle = strokeColor != undefined ? strokeColor : 'black';
    if (angle > 0) {
      ctx.translate(centreX, centreY);
      ctx.rotate(angle * (Math.PI / 180));
    }
    ctx.textAlign = "center"; 

    
    if (fontSize === undefined)
      ctx.font = "10px Arial";
    else
      ctx.font = `${fontSize}px Arial`;  

    let size = ctx.measureText(text);
    let textHeight = 16;
    // make a rectangle to contain the text
    let y = 0;
    if (textAboveSegment)
      y = -textHeight;

    let rect = new Rectangle(-size.width / 2, y, size.width, textHeight);

    if (angle > 0)
      ctx.fillText(text, 0, -4);
    else
      ctx.fillText(text, centreX, centreY);
    ctx.restore();     
  }

  /**
   * @param  {MgPoint} point
   * @param  {string} color
   */
  drawPoint(point, color) {
    
    this.clearStyles();

    if(color) {
      this._ctx.fillStyle = color;
    }      
    
    this._ctx.fillRect(point.x, point.y, 5, 5);
  }
  
  /**
   * @param  {MgPoint[]} points   
   * @param  {string} color
   */
  drawSolidLine(points, strokeColor, strokeWidth, dashStyle, opacity, ctx) {
    ctx.setLineDash(dashStyle);

    ctx.strokeStyle = strokeColor;
    ctx.globalAlpha  = opacity;
    ctx.lineWidth = strokeWidth;
        
    ctx.beginPath();   
    let firstPoint = points[0];
    let nLastPoint = points.length;
    
    ctx.moveTo(firstPoint.x, firstPoint.y);
    for(let i = 1; i < nLastPoint; i++) {
      ctx.lineTo(points[i].x, points[i].y);
    }    

    ctx.stroke();       
  }

  drawPatternLine(points, pattern, opacity, ctx) {
    ctx.strokeStyle = ctx.createPattern(pattern, 'repeat');
    ctx.globalAlpha  = opacity;
    
    ctx.beginPath();   
    let firstPoint = points[0];
    let nLastPoint = points.length;

    ctx.moveTo(firstPoint.x, firstPoint.y);
    for(let i = 1; i < nLastPoint; i++) {
      ctx.lineTo(points[i].x, points[i].y);
    }    

    ctx.stroke(); 
  }

  drawLine(points, stroke, opacity, ctxParam) {
    let ctx = ctxParam != undefined ? ctxParam : this._offScreenCtx;

    if (stroke.StrokeType == MapEnums.StrokeTypes.Pattern) {
      this.drawPatternLine(points, stroke.Pattern, opacity, ctx)
    }
    else if (stroke.StrokeType != MapEnums.StrokeTypes.None) {
      if (points != null && points.length > 0)
      this.drawSolidLine(points, stroke.Colour, stroke.Width, stroke.DashPattern, opacity, ctx);
    }      
  }

  /**
   * @param  {MgPoints[]} points
   * @param  {string} strokeColor   //ex. rgba(0,0,0.0.5) or "yellow"
   * @param  {string} fillColor
   */
  drawPolygon(points, stroke, fill, opacity, ctxParam, isHole) {

    if(points != null && points.length > 3 && points[0].x == points[points.length-1].x) {
      let ctx = ctxParam != undefined ? ctxParam : this._offScreenCtx;
      // Create region
      // Expiremental, not supported in IE
      /*      
      let region = new Path2D();
      region.moveTo(points[0].x, points[0].y);
      for(let i = 1; i < points.length; i++) {
        region.lineTo(points[i].x, points[i].y);
      }
      region.closePath();      
      */
      
      ctx.beginPath();           
      ctx.globalAlpha  = opacity != undefined ? opacity : 1.0; 

      // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/strokeStyle
      // TODO: we may have to change this if we are going to use CanvasGradient or CanvasPattern a strokestyle
      ctx.globalAlpha  = opacity != undefined ? opacity : 1.0;

      if (stroke.StrokeType == MapEnums.StrokeTypes.Pattern) {
        ctx.strokeStyle = ctx.createPattern(stroke.Pattern, 'repeat');
        ctx.lineWidth = stroke.PatternHeight != undefined ? stroke.PatternHeight : 16;
      }
      else if (stroke.StrokeType != MapEnums.StrokeTypes.None) {
        ctx.strokeStyle = stroke.Colour != undefined ? stroke.Colour : 'black';
        ctx.lineWidth = stroke.Width != undefined ? stroke.Width : 2;     
        ctx.setLineDash(stroke.DashPattern);
      }  
      else {
        ctx.lineWidth = 0;          
      }
            
      ctx.moveTo(points[0].x, points[0].y);      
      for(let i = 1; i < points.length; i++) {
        ctx.lineTo(points[i].x, points[i].y);
      }            

      ctx.stroke();
      ctx.closePath();
      
      if (fill != null && (isHole == undefined || isHole == false)) {
       this._fillPolygon(fill, ctx);            
      }
    }
  }  
   

  /**
   * @param  {MgPoints[]} points
   * @param  {string} strokeColor   //ex. rgba(0,0,0.0.5) or "yellow"
   * @param  {string} fillColor
   */
  drawRectangle(p1, p2, strokeColor, fillColor, lineWidth, dashedLine, ctxParam) {

    let ctx = ctxParam != undefined ? ctxParam : this._selectionCtx;

    ctx.beginPath();         
    if (dashedLine == undefined)
      ctx.setLineDash([5, 15]);   
    else
      ctx.setLineDash(dashedLine);   

    ctx.moveTo(p1.x, p1.y);

    ctx.lineTo(p2.x, p1.y);
    ctx.lineTo(p2.x, p2.y);
    ctx.lineTo(p1.x, p2.y);
    ctx.lineTo(p1.x, p1.y);

    ctx.closePath();
    if (fillColor != undefined)
      this._drawFillable(strokeColor, fillColor, lineWidth, 1, ctx);      
    
  }

  /**
   * @param  {MgPoints[]} points
   * @param  {string} strokeColor   //ex. rgba(0,0,0.0.5) or "yellow"
   * @param  {string} fillColor
   */
  drawRectangle(x1, y1, x2, y2, strokeColor, fillColor, lineWidth, dashedLine, ctxParam) {

    /*
    setLineDash([]); ___
    setLineDash([1, 1]); ....
    setLineDash([10, 10]); - - -
    setLineDash([20, 5]); __ __ __
    setLineDash([15, 3, 3, 3]); __ . __ . __ .
    setLineDash([20, 3, 3, 3, 3, 3, 3, 3]); ___ ... ___ ... ___ ...
    setLineDash([12, 3, 3]);  // Equals [12, 3, 3, 12, 3, 3] _.  _.  _.

    */
    console.log(`drawRectangle: x1: ${x1}, y1: ${y1}, x2: ${x2}, y2: ${y2}`)
    let ctx = ctxParam != undefined ? ctxParam : this._selectionCtx;

    ctx.beginPath();   
    
    if (dashedLine == undefined)
      ctx.setLineDash([]);   
    else
      ctx.setLineDash(dashedLine);   

    ctx.moveTo(x1, y1);

    ctx.lineTo(x2, y1);
    ctx.lineTo(x2, y2);
    ctx.lineTo(x1, y2);
    ctx.lineTo(x1, y1);

    ctx.closePath();
    if (fillColor != undefined)
      this._drawFillable(strokeColor, fillColor, lineWidth, 1, ctx);
    
  }

  drawRoundRectangle(x, y, width, height, radius, strokeColor, fillColor, ctxParam) {
    let ctx = ctxParam != undefined ? ctxParam : this._selectionCtx;
    
    if (typeof radius === 'undefined') {
      radius = 5;
    }

    if (typeof radius === 'number') {
      radius = {tl: radius, tr: radius, br: radius, bl: radius};
    } 
    else {
      var defaultRadius = {tl: 0, tr: 0, br: 0, bl: 0};
      for (var side in defaultRadius) {
        radius[side] = radius[side] || defaultRadius[side];
      }
    }

    ctx.setLineDash([]);

    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();

    if (fillColor != undefined)
      this._drawFillable(strokeColor, fillColor, 1, 1, ctx);
  
  }

  /**
   * @param  {number} centerX
   * @param  {number} centerY
   * @param  {number} radius
   * @param  {string} strokeColor
   * @param  {string} fillColor
   */
  drawCircle(centerX, centerY, radius, strokeColor, fillColor, lineWidth) {    
    this._ctx.beginPath();
    this._ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);

    this._drawFillable(strokeColor, fillColor, lineWidth);
  }

  _drawFillable(strokeColor, fillColor, lineWidth, opacity, ctxParam) {
    let ctx = ctxParam != undefined ? ctxParam :this._offScreenCtx;

    ctx.strokeStyle = strokeColor != undefined ? strokeColor : 'black';
    ctx.lineWidth = lineWidth != undefined ? lineWidth : 1;          
    ctx.globalAlpha  = opacity != undefined ? opacity : 1.0;
    ctx.stroke();

    if(fillColor) {      
      ctx.fillStyle = fillColor;
      ctx.fill();              
    }      
  }  

  _fillPolygon(fillStyle, ctx) {    
    if(fillStyle != null) {
      
      //fillStyle.FillType was throwing undefined

      if (fillStyle.type == MapEnums.FillTypes.Solid) {
        if (fillStyle.Colour != null) {
          ctx.fillStyle = fillStyle.Colour;
          ctx.fill();                
        }
      }
      else if (fillStyle.type == MapEnums.FillTypes.Hatch || fillStyle.type == MapEnums.FillTypes.Pattern) {   
        let pattern = ctx.createPattern(fillStyle.pattern, 'repeat');  	
        ctx.fillStyle = pattern;
       
        ctx.fill();  

      }      
    }      
  }

  
 

  //#endregion

  //#region -- METHODS  
  clearRect(x, y, width, height) {
    this._ctx.clearRect(x, y, width, height);
  }

  clearStyles() {
    this._ctx.fillStyle = "rgba(255, 255, 255, 0)";
    this._ctx.strokeStyle = "black";
  } 

  clear() {
    // Store the current transformation matrix
    this._ctx.save();

    // Use the identity matrix while clearing the canvas
    //this._ctx.setTransform(1, 0, 0, 1, 0, 0);
    this._ctx.clearRect(0, 0, this._canvas.width, this._canvas.height);
    this._ctx.width = this._ctx.width;
    this._ctx.beginPath();
    // Restore the transform
    //this._ctx.restore();     
  }

  clearOffscreen() {
    // Store the current transformation matrix
    //this._offScreenCtx.save();

    // Use the identity matrix while clearing the canvas
    //this._offScreenCtx.setTransform(1, 0, 0, 1, 0, 0);
    this._offScreenCtx.clearRect(0, 0, this._canvas.width, this._canvas.height);
    this._offScreenCtx.width = this._offScreenCtx.width;
    this._offScreenCtx.beginPath();
    // Restore the transform
    //this._offScreenCtx.restore();     
  }

  clearSelection() {
    // Store the current transformation matrix
    this._selectionCtx.save();

    // Use the identity matrix while clearing the canvas
    this._selectionCtx.clearRect(0, 0, this._canvas.width, this._canvas.height);

    // Restore the transform
    this._selectionCtx.restore();     
  }

  clearAll() {
    this.clear();
    this.clearOffscreen();
  }
  refreshView(xOffset, yOffset) {       
    this.xOffset = xOffset;
    this.yOffset = yOffset;

    if (this.isPanning && xOffset != undefined && yOffset != undefined) {
      if (this.offScreenCanvas != null) {     
        //window.requestAnimationFrame(this.drawCanvasPanning.bind(this)); 
        this.drawCanvasPanning(); 
      }      
    }
    else  {
      //window.requestAnimationFrame(this.drawCanvas.bind(this));
      this.drawCanvas();      
    }        
  }

  drawCanvas() {        
    if (this.offScreenCanvas.width > 0 && this.offScreenCanvas.height > 0) { 
      this._ctx.save();
      this._ctx.translate(0, 0);          
      this._ctx.clearRect(0, 0, this._canvas.width, this._canvas.height);      
      this._ctx.drawImage(this.offScreenCanvas,0,0);
      this._ctx.restore();
    }
  }  

  drawCanvasNoClear() {         
    this._ctx.save();
    this._ctx.translate(0, 0);          
    this._ctx.drawImage(this.offScreenCanvas,0,0);
    this._ctx.restore();
  }

  drawCanvasPanning() {    
    this._ctx.save();
    if (this.xOffset != undefined && this.yOffset != undefined) {
      this._ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);	      
      this._ctx.setTransform(1, 0, 0, 1, 0, 0);    
      
      this._ctx.translate(this.xOffset, this.yOffset);          
      this._ctx.drawImage(this.offScreenCanvas, 0, 0);     
    }     
    else {
      this._ctx.setTransform(1, 0, 0, 1, 0, 0);
      this._ctx.drawImage(this.offScreenCanvas, 0, 0);       
    }
    this._ctx.restore();
  }

  drawCroppedCanvas(sx, sy, dx, dy, scale, h, w) {    
    this._ctx.save();
    this._ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);	
    
    if (scale < 1.0) { //zooming out
      this._ctx.drawImage(this.offScreenCanvas, sx, sy, h, w, 0, 0, this.canvas.width, this.canvas.height); 
    }
    else if (scale > 1.0) { // zooming in
      this._ctx.drawImage(this.offScreenCanvas, sx, sy, h, w, dx, dy, w - this.canvas.width, h - this.canvas.height); 
    }
    else {
      this._ctx.translate(0, 0);
      this._ctx.drawImage(this.offScreenCanvas, sx, sy, dx, dy, 0, 0, this.canvas.width, this.canvas.height); 
    }
    
    this._ctx.restore();

    console.log('drawCroppedCanvas');
  }

  drawCanvasSelection(pt1, pt2) {    
    this.clear();
    this._ctx.save();
    this._ctx.translate(0, 0);     
    
    this._ctx.drawImage(this.offScreenCanvas,0,0);
    this._ctx.globalCompositeOperation = 'source-over';
    this.drawRectangle(pt1.x, pt1.y, pt2.x, pt2.y, 'blue', 'transparent', 1, [3,3], this._ctx);
       
    this._ctx.restore();
  }

  sanitisedSVG(svg) {
    var sanitized = svg.replace(/\</g,'%3C')   //for <
    sanitized = sanitized.replace(/\>/g,'%3E')   //for >
    sanitized = sanitized.replace(/\#/g,'%23')   //for #
    
    return sanitized;
  }

  // #region Draw Shapes
  drawPolygonShape(ctx, points, strokeWidth, strokeColour, fillColour) {    
    ctx.beginPath();           
    ctx.globalAlpha  = 1.0; 
    ctx.setLineDash([]);

    ctx.moveTo(points[0].x, points[0].y);      
    for(let i = 1; i < points.length; i++) {
      ctx.lineTo(points[i].x, points[i].y);
    }            
    ctx.closePath();

    if (strokeColour != null) {
      ctx.strokeStyle = strokeColour;
      ctx.lineWidth = strokeWidth;     

      ctx.stroke();
    }  
    
    if (fillColour != null) {
      ctx.fillStyle = fillColour;

      ctx.fill();
    }    
  }  

  drawCircleShape(ctx, cx, cy, radius, strokeWidth, strokeColour, fillColour) {    
    ctx.save();
    ctx.beginPath();
    ctx.arc(cx, cy, radius, 0, 2 * Math.PI, false);

    ctx.setLineDash([]);
    
    if (strokeColour != undefined) {
      ctx.strokeStyle = strokeColour;
      ctx.lineWidth = strokeWidth;
      ctx.stroke();        
    }

    if (fillColour != undefined) {
      ctx.fillStyle = fillColour;
      ctx.fill();      
    }

    ctx.restore();
  }

  drawTriangleShape(ctx, cx, cy, height, strokeWidth, strokeColour, fillColour, isUp ) {        
    let radius = Math.trunc(height/2);
    
    var points = isUp == true ? this.getTriangleUpPoints(cx, cy, radius) : this.getTriangleDownPoints(cx, cy, radius);
    this.drawPolygonShape(ctx, points, strokeWidth, strokeColour, fillColour);    
  }

  drawDiamondShape(ctx, cx, cy, height, strokeWidth, strokeColour, fillColour, isUp ) {        
    let radius = Math.trunc(height/2);
    
    var points = [];
    points.push(new Point(cx, cy - radius));
    points.push(new Point(cx + radius - 2, cy));
    points.push(new Point(cx, cy + radius));
    points.push(new Point(cx - radius + 2, cy));

    this.drawPolygonShape(ctx, points, strokeWidth, strokeColour, fillColour);    
  }

  drawSquareShape(ctx, cx, cy, height, strokeWidth, strokeColour, fillColour, isUp ) {        
    let radius = Math.trunc(height/2);
    
    var points = [];
    points.push(new Point(cx - radius, cy - radius));
    points.push(new Point(cx + radius, cy - radius));
    points.push(new Point(cx + radius, cy + radius));
    points.push(new Point(cx - radius, cy + radius));
    
    this.drawPolygonShape(ctx, points, strokeWidth, strokeColour, fillColour);    
  }

  drawStarShape(ctx, cx,cy, height, strokeWidth, strokeColour, fillColour, spikes) {
    var outerRadius = height / 2;
    var innerRadius = height / 4;
    var rot = Math.PI / 2*3;
    var x = cx;
    var y = cy;
    var step = Math.PI/spikes;

    ctx.beginPath();
    ctx.moveTo(cx,cy-outerRadius);

    for(let i=0; i < spikes; i++){
      x=cx+Math.cos(rot) * outerRadius;
      y=cy+Math.sin(rot) * outerRadius;
      ctx.lineTo(x,y);
      rot += step;

      x=cx+Math.cos(rot) * innerRadius;
      y=cy+Math.sin(rot) * innerRadius;
      ctx.lineTo(x,y);
      rot += step;
    }

    ctx.lineTo(cx, cy-outerRadius);
    ctx.closePath();

    if (strokeColour != undefined) {
      ctx.lineWidth = strokeWidth;
      ctx.strokeStyle = strokeColour;
      ctx.stroke();
    }

    if (fillColour != undefined) {
      ctx.fillStyle = fillColour;
      ctx.fill();      
    }
  }

  getTriangleUpPoints(cx, cy, radius) {
    var points = [];
    points.push(new Point(Math.trunc(radius * (-0.866)) + cx, Math.trunc(radius * (0.5))+ cy));
    points.push(new Point(Math.trunc(radius * (0.866)) + cx, Math.trunc(radius * (0.5))+ cy));    
    points.push(new Point(cx, Math.trunc(radius * (-1.0))+ cy));

    return points;
  }

  getTriangleDownPoints(cx, cy, radius) {
    var points = [];
    points.push(new Point(Math.trunc(radius * (-0.866)) + cx, Math.trunc(radius * (-0.5))+ cy));
    points.push(new Point(Math.trunc(radius * (0.866)) + cx, Math.trunc(radius * (-0.5))+ cy));    
    points.push(new Point(cx, radius + cy));

    return points;
  }

  getTextWidth(text) {
    return this._offScreenCtx.measureText(text).width;
  }
  //#endregion        
  
  //#region -- EVENTS
  handleMouseMove(event) {
    event = event || window.event; // IE-ism 
  }    

  resize(newWidth, newHeight) {    
    this.resizeCanvas(this._canvas, newWidth, newHeight);
    this.resizeCanvas(this._offScreenCanvas, newWidth, newHeight);
    this.resizeCanvas(this._selectionCanvas, newWidth, newHeight);    
  }

  resizeCanvas(canvas, w, h) {
    canvas.width = w;
    canvas.height = h;
    canvas.style.width = w  + "px";
    canvas.style.height = h  + "px";
  }
  //#endregion
  
}

export let PIXEL_RATIO = (function () {
  var ctx = document.createElement("canvas").getContext("2d"),
      dpr = window.devicePixelRatio || 1,
      bsr = ctx.webkitBackingStorePixelRatio ||
            ctx.mozBackingStorePixelRatio ||
            ctx.msBackingStorePixelRatio ||
            ctx.oBackingStorePixelRatio ||
            ctx.backingStorePixelRatio || 1;

  return dpr / bsr;
})();


MgCanvas.createHiDPICanvas = function(w, h, ratio, imageSmoothingEnabled) {
  //if (!ratio) { ratio = PIXEL_RATIO; }  
  let canvas = document.createElement("canvas", {alpha: true});
  let ctx = canvas.getContext('2d');
  canvas.width = w * ratio;
  canvas.height = h * ratio;
  canvas.style.width = w  + "px";
  canvas.style.height = h  + "px";

  // prevent anti-aliasing
  ctx.imageSmoothingEnabled = imageSmoothingEnabled != undefined ? imageSmoothingEnabled : false;
  
  //canvas.getContext("2d").setTransform(ratio, 0, 0, ratio, 0, 0);
  //ctx.scale(dpr, dpr);  
  return canvas;
}