import {centroid}  from './centroid';
import {Geometry} from './geometry';
import MapEnums from '../map/enums';

export class GeoMultiPoint extends Geometry {
  constructor(points) {
    super([]);
    this.geopoints = points != null ? points : []; 
  }

  get Geometries() {
    return this.geopoints;
  }

  get GeometryType()  { 
    return MapEnums.GeometryTypes.MULTIPOINT;
  }

  get Coordinate() { 
    return  this.points.length > 0 ? this.points[0] : null; 
  }
    
  get NumPoints() { 
    var numPoints = 0;
    this.geopoints.forEach(element => {
      numPoints += element.NumPoints();
    });

    return numPoints;
  }

  get NumGeometries() { 
    return this.geopoints.length; 
  }

  GetGeometryN(n) {
    return n < this.geopoints.length ? this.geopoints[n] : null;
  }
  
  get IsEmpty() {
    var isEmpty = true;

    for (var i = 0; i < this.geopoints.length; i++) {
      isEmpty = this.geopoints[i].IsEmpty; 
      if (!isEmpty)
        break;
    }

    return isEmpty;
  }

  get Bounds() {
    if (this._bounds == null) {
      
      for (var i = 0; i < this.geopoints.length; i++) {
        let geometry = this.geopoints[i];
        if (geometry.Coordinate == null)
          continue;
          
        if (this._bounds ==null)
          this._bounds = this.calculateBounds([geometry.Coordinate]);
        else
          this._bounds.extend(this.calculateBounds([geometry.Coordinate]));          
      }
      
    }
    return this._bounds;   
  }

  set Bounds(value) {
    this._bounds = value;
  }
  
  worldToClient(viewport) {
    this.points = [];
    for (var i = 0; i < this.geopoints.length; i++) {
      var geopoint = this.geopoints[i];      
      this.points.push(viewport.worldToClient(geopoint.X, geopoint.Y));
    }  
    
    return this.points;
  }

  setWorldToClient(viewport, points)  { 
    this.points = points; 
  }
  
  draw(canvas, opacity, stroke, fill, context) {
    for (var i = 0; i < this.geopoints.length; i++) {
      let geopoint = this.geopoints[i];        
      geopoint.draw(canvas, opacity, stroke, fill, context);
    }
        
  }

  hitTest(x, y) {
    var isInside = false;
    for (var i = 0; i < this.geopoints.length; i++) {
      if ((isInside = this.geopoints[i].hitTest(x,y)) == true)
        break;
    }

    return isInside;    
  }

  isVisible(viewport) {
    for (var i = 0; i < this.geopoints.length; i++) {
      let geopoint = this.geopoints[i];
      if (geopoint.isVisible(viewport))
          return true;        
    }
    
    return false;    
  }

  intersects(bounds) {
    for (var i = 0; i < this.geopoints.length; i++) {
      let geopoint = this.geopoints[i];
      if (geopoint.intersects(bounds))
          return true;        
    }
    
    return false;    
  }

  addGeometry(geometries) {
    for (let i = 0; i < this.geopoints.length; i++) {
      geometries.push(this.geopoints[i]);
    }
  }
}