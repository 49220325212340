import {Point, LatLng, Envelope} from '../geometry/index';
import {Viewport, Symbol, Fill, Stroke, Hover, Label, Markup, MarkupPoint, LineMarkup, PolygonMarkup, PointMarkup} from '../drawing/index';
import MapEnums from '../map/enums';
import {WktReader} from '../geometries/index';

export class GeometryEditor {
  constructor(map, geometryType, sWKT, bounds) {
    this.map = map;
    this.geometryType = geometryType;
    this.originalGeometryType = geometryType;
    this.bounds = bounds;
    this.viewport = new Viewport(this.map.rcMap, this.map.extents, this.map.cellsize, this.map.scale);
    this.canvas = map.canvas;
    this.context = map.canvas.context;
    this.selectedPoint = null;
    this.selectedPointIndex = -1;
    this.overwritePromptDisplayed = false;

    this.circleStroke = new Stroke({ Type: 'Solid', Colour: 'blue', Width : 2 });
    this.lineStroke = new Stroke({ Type: 'Solid', Colour: 'blue', Width : 2 });
    this.blackFill = new Fill({ Type: 'Solid', Colour: 'blue' });
    this.label = new Label({ FontName: 'Arial', Colour: 'blue', FontSize : 10, Position : 0 });

    this.contextLayer = this.map.getContextLayer();

    if (this.contextLayer != null && this.contextLayer.options.Hover != null)
      this.hover = new Hover(this.contextLayer.options.Hover);
      
    this.initialise(sWKT);
    this.selectedMarkup = null;    
    this.markupsWithinSelectionRectangle = [];
    this.markupType = MapEnums.GeometryTypes.NONE;
  }  

  initialise(sWKT) {
    this.markups = [];    
    this.selectedMarkup = null;
    this.hitItems = null;
    this.activeMarkup = null;

    if (sWKT != null && sWKT.length > 0) {
      let geometry = WktReader.parseWKT(sWKT);
      if (geometry != null) {
        var geometries = [];
        var id = 1;
        geometry.addGeometry(geometries);

        for (let i = 0; i < geometries.length; i++) {
          this.addGeometry(geometries[i], id);
          id += 1;
        }
      }

      if (this.bounds != null) {
        //this.map.zoomToGeometryBounds(this.bounds); 
        //this.updateExtents();
        
        this.map.refreshView(true);        
      }
      else {
        this.map.invalidate();
        this.render();
      }
      
    }
  }

  addGeometry(geometry, id) {
    var markup = null;
    if (geometry.GeometryType == MapEnums.GeometryTypes.POINT) {
      markup = new PointMarkup(this.map, this, id);
    }
    else if (geometry.GeometryType == MapEnums.GeometryTypes.LINESTRING) {
      markup = new LineMarkup(this.map, this, id);
    }
    else if (geometry.GeometryType == MapEnums.GeometryTypes.POLYGON || geometry.GeometryType == MapEnums.GeometryTypes.LINEARRING) {
      markup = new PolygonMarkup(this.map, this, id);
    }

    if (markup != null) {
      markup.isGeometryComplete = true;
      this.addPoints(markup, geometry);
      this.markups.push(markup);
    }
  }

  addPoints(markup, geometry) {
    for (let i = 0; i < geometry.Coordinates.length; i++) {
      markup.addWorldCoordinate(new LatLng(geometry.Coordinates[i].Y, geometry.Coordinates[i].X));
    }
  }

  addMarkup(geometryType) {
    this.markupType = geometryType;

    if ( this.selectedMarkup != null) {
      this.selectedMarkup.selectedForEditing = false;
      this.selectedMarkup = null;

      this.map.invalidate();
      this.render();
    }

    if (this.activeMarkup != null) {
      this.activeMarkup.selectedForEditing = false;
    }

    if (geometryType == MapEnums.GeometryTypes.POINT) {
      this.activeMarkup = new PointMarkup(this.map, this);  
    }
    else if (geometryType == MapEnums.GeometryTypes.LINESTRING) {
      this.activeMarkup = new LineMarkup(this.map, this);  
    }
    else if (geometryType == MapEnums.GeometryTypes.POLYGON) {
      this.activeMarkup = new PolygonMarkup(this.map, this);  
    }

    if (this.activeMarkup != null) {
      this.activeMarkup.selectedForEditing = true;
      this.markups.push(this.activeMarkup);
    }

    this.selectedMarkup = null;    
  }

  addPoint(clientPoint) {
    
    if (this.activeMarkup != null) 
      return this.activeMarkup.addPoint(clientPoint);
    
    return null;
  }

  insertPoint(clientPoint, indexToInsertAt) {      
    if (this.activeMarkup != null) 
      return this.activeMarkup.insertPoint(clientPoint, indexToInsertAt);      
  }
    
  isPointOnLine(clientPoint) {
    if (this.activeMarkup != null) 
      return this.activeMarkup.isPointOnLine(clientPoint);
    
    return -1;
  }

  getExistingPoint(clientPoint) {     
    if (this.activeMarkup != null) 
      return this.activeMarkup.getExistingPoint(clientPoint);
   
    return {"point":null, "index": -1};
  }

  removePoint(worldCoordinate) {    
    if (this.activeMarkup != null) 
      return this.activeMarkup.removePoint(worldCoordinate);
  }
        
  getLastPoint() {
    if (this.activeMarkup != null) 
      return this.activeMarkup.getLastPoint();
    
    return null;
  }
   
  toWKT() {
    let geometryType = MapEnums.GeometryTypes.NONE;

    // check the geometry type
    for (var i = 0; i < this.markups.length; i++) {
      let markup = this.markups[i];
      if (geometryType == MapEnums.GeometryTypes.NONE) {
        geometryType = markup.geometryType;
      }
      else if (geometryType == MapEnums.GeometryTypes.POINT || geometryType == MapEnums.GeometryTypes.MULTIPOINT) {
        if (markup.geometryType == MapEnums.GeometryTypes.POINT)
          geometryType = MapEnums.GeometryTypes.MULTIPOINT;
        else
          geometryType = MapEnums.GeometryTypes.GEOMETRYCOLLECTION;
      }
      else if (geometryType == MapEnums.GeometryTypes.LINESTRING || geometryType == MapEnums.GeometryTypes.MULTILINESTRING) {
        if (markup.geometryType == MapEnums.GeometryTypes.LINESTRING)
          geometryType = MapEnums.GeometryTypes.MULTILINESTRING;
        else
          geometryType = MapEnums.GeometryTypes.GEOMETRYCOLLECTION;
      }
      else if (geometryType == MapEnums.GeometryTypes.POLYGON || geometryType == MapEnums.GeometryTypes.MULTIPOLYGON) {
        if (markup.geometryType == MapEnums.GeometryTypes.POLYGON)
          geometryType = MapEnums.GeometryTypes.MULTIPOLYGON;
        else
          geometryType = MapEnums.GeometryTypes.GEOMETRYCOLLECTION;
      }
      else {
        geometryType = MapEnums.GeometryTypes.GEOMETRYCOLLECTION;
      }
    }

    var addTag = true;
    var sWKT = '';
      
    if (this.markups.length == 1) {
      sWKT = this.markups[0].toWKT(true);
    }
    else {
      if (geometryType == MapEnums.GeometryTypes.MULTIPOINT) {
        sWKT = 'MULTIPOINT(';
        addTag = false;
      }
      else if (geometryType == MapEnums.GeometryTypes.MULTILINESTRING) {
        sWKT = 'MULTILINESTRING(';
        addTag = false;
      }
      else if (geometryType == MapEnums.GeometryTypes.MULTIPOLYGON) {
        sWKT = 'MULTIPOLYGON(';
        addTag = false;
      }
      else if (geometryType == MapEnums.GeometryTypes.GEOMETRYCOLLECTION) {
        sWKT = 'GEOMETRYCOLLECTION(';
        addTag = true;
      }

      var nCompleted = 0;
      for (var i = 0; i < this.markups.length; i++) {
        let markup = this.markups[i];

        if (markup.isGeometryComplete) {
          if (nCompleted > 0)
              sWKT += ', ';

          sWKT += markup.toWKT(addTag);
          nCompleted += 1;
        }
      }

      if (sWKT.trim() > 0 
      ||  geometryType == MapEnums.GeometryTypes.MULTILINESTRING
       || geometryType == MapEnums.GeometryTypes.MULTIPOINT 
       || geometryType == MapEnums.GeometryTypes.MULTIPOLYGON 
       || geometryType == MapEnums.GeometryTypes.GEOMETRYCOLLECTION)
        sWKT += ')';
    }
    return sWKT;
  }
  
// here 'selected' is indicated by a property on the markup object, not the selectedMarkup object used elsewhere

  selectMarkupsWithinBounds(topLeftPoint, bottomRightPoint) {        
    for (let markup of this.markups) {   
      if (markup.withinBounds(topLeftPoint, bottomRightPoint)) 
      {
        markup.selected = true;      
        this.markupsWithinSelectionRectangle.push(markup);        
      }
    }       
  }  

  clearSelectedMarkup() {
    if (this.selectedMarkup != null) {
      this.selectedMarkup.selectedForEditing = false;
      this.selectedMarkup = null;              
    }     

    for (let markup of this.markups) {
      markup.selected = false;        
    }    

    this.markupsWithinSelectionRectangle.splice(0, this.markupsWithinSelectionRectangle.length);        
  }

  isActiveMarkupValid() {
    if (this.activeMarkup != null && this.activeMarkup.linePoints != null) {
      if (this.activeMarkup.geometryType == MapEnums.GeometryTypes.LINESTRING)
        return this.activeMarkup.linePoints.length >= 2;
      else if (this.activeMarkup.geometryType == MapEnums.GeometryTypes.POLYGON)
        return this.activeMarkup.linePoints.length >= 3;
      else // point
        return this.activeMarkup.linePoints.length > 0;
    }
    return false;
  }

  saveInCompleteMarkup(markupId) {
    if (markupId) {
      let markup = this.markups.find(o => o.id == markupId);

      if (markup != null && markup.linePoints != null) { 
        if (markup.geometryType == MapEnums.GeometryTypes.POLYGON) {
          // close the polygon
          let startPoint = markup.linePoints[0];
          markup.handleMouseDoubleClick(startPoint.ClientPoint.x, startPoint.ClientPoint.y);        
        }
        else if (markup.geometryType == MapEnums.GeometryTypes.LINESTRING) {
          markup.isGeometryComplete = true;                         
        }

        markup.selectedForEditing = false;
        this.map.refreshView();
        this.render();             
      }
    }    
  } 

  deleteSelectedMarkups(markupIds) {

    if (markupIds != null && markupIds.length > 0) {      
      markupIds.forEach(id => this.unselectMarkupAndDelete(id));  
      
      // when the map is loaded, the 'Inspections area' layer objects are both markups and features
      // so check the Inspections Area layer for these features (tied by the same 'id') and delete them too
    
      // this doesn't remove the predrawn multipolys yet
      
      this.clearSelectedMarkup();
      this.onGeometryChanged();
      this.map.invalidate();
      this.render();
      this.map.refreshView(true);    
    }      
  }
  
  deleteMarkup(markupId) {

    if (markupId == -1) {
      this.markups = [];
      this.activeMarkup = null;
      this.selectedMarkup = null;
      this.hitItems = null;
      //this.initialise();			
    }
    else {
      this.unselectMarkupAndDelete(markupId);      
    }
    
    this.onGeometryChanged();
    this.map.invalidate();
    this.render();
    this.map.refreshView(true);    
  }

  unselectMarkupAndDelete(markupId)
  {
    var index = this.markups.findIndex( x => x.id == markupId);

    if (index > -1) {
      if (this.activeMarkup != null && this.activeMarkup.id == markupId)
        this.activeMarkup = null;
      
      if (this.selectedMarkup != null && this.selectedMarkup.id == markupId)
        this.selectedMarkup = null;
        
      this.markups.splice(index, 1);
    }
  }
  
  // Mouse Events and Handlers
  getHitItems(x, y) {
    this.hitItems = [];
    var dctIds = {};
    for (var i = 0; i < this.markups.length; i++) {
      let markup = this.markups[i];
      if (markup.hitTest(x, y)) {
        if (!dctIds.hasOwnProperty(markup.id)) {
          this.hitItems.push(markup);
          dctIds[markup.id] = markup;
        }                
      }
    }

    return this.hitItems;
  }

  hitTest(x, y) {
    if (this.activeMarkup != null) 
      return this.activeMarkup.hitTest(x, y);

    return false;
  }   
  
  // Return true if mouse click been handles, false otherwise
  handleMouseClick(x, y, inDrawMode) {
    var mouseClickHandled = false;


    if (this.activeMarkup != null) {      
      this.activeMarkup.handleMouseClick(x, y);

      if (this.activeMarkup.isGeometryComplete) {
        this.activeMarkup.selectedForEditing = false;
        this.activeMarkup = null;
        this.onGeometryChanged();
      }                     
      
      mouseClickHandled = true;
    }        
    else if ( this.selectedMarkup != null) {
      let pt = new Point(x, y);
      let tuple = this.selectedMarkup.getExistingPoint(pt);
      
      let existingPoint = tuple.point;
      if (existingPoint == null) {
        let indexToInsert = this.selectedMarkup.isPointOnLine(pt);
        if (indexToInsert > -1) {
          this.selectedMarkup.insertPoint(pt, indexToInsert);
        }
        else {
          this.selectedMarkup.selectedForEditing = false;
          this.selectedMarkup = null;
          if (inDrawMode){
            this.handleMouseClick(x,y,inDrawMode);
          } else {
            this.selectMarkup(x, y, inDrawMode);
          }
        }
      }      
      else {
        if (this.selectedMarkup.geometryType == MapEnums.GeometryTypes.LINESTRING && this.selectedMarkup.linePoints.length <= 2)
          this.onDisplayPrompt(MapEnums.PromptTypes.MINIMUM_REQUIRED_POINTS_LINESTRING);        
        else if (this.selectedMarkup.geometryType == MapEnums.GeometryTypes.POLYGON && this.selectedMarkup.linePoints.length <= 4)
          this.onDisplayPrompt(MapEnums.PromptTypes.MINIMUM_REQUIRED_POINTS_POLYGON);         
        else if (this.selectedMarkup.geometryType != MapEnums.GeometryTypes.POINT) { // don't want to remove points when we click on them when in 'Draw Point' mode
          if (!existingPoint.IsStartPoint)
            this.selectedMarkup.removePoint(existingPoint.WorldCoordinate);        
        }
      }

      mouseClickHandled = true; 
    }
    else if (this.selectMarkup(x, y, inDrawMode)) {
      mouseClickHandled = true; 
    }
    else if (this.activeMarkup == null) {

      // polygon has finished being drawn.

      if (this.markupType != MapEnums.GeometryTypes.NONE && this.canAddMarkup){
        this.addMarkup(this.markupType);
      } else {
        if (inDrawMode && this.markupType != MapEnums.GeometryTypes.GEOMETRYCOLLECTION && this.markupType != MapEnums.GeometryTypes.MULTIPOLYGON){ 
          this.displayOverwritePrompt();
        }
      }

      if (this.activeMarkup != null) {
        this.activeMarkup.handleMouseClick(x, y);

        if (this.activeMarkup.isGeometryComplete) {
          this.activeMarkup.selectedForEditing = false;
          this.activeMarkup = null;
          this.onGeometryChanged();
        }
      }                     
      
      mouseClickHandled = true;
    }
  
    this.map.refreshView();
    this.render();  

    return mouseClickHandled; 
  }

  handleMouseDoubleClick(x, y) {
    if (this.activeMarkup != null) {
      this.activeMarkup.handleMouseDoubleClick(x, y);

      if (this.activeMarkup.isGeometryComplete) {
        this.activeMarkup.selectedForEditing = false;
        this.activeMarkup = null;
        this.onGeometryChanged();
      }
    }
    
    this.map.refreshView();
    this.render(); 
  }

  handleMouseDown(x, y, inDrawMode) {
    this.selectedPoint = null;

    let pt = new Point(x, y);
    for(let i = 0; i < this.markups.length; i++) {
      let markup = this.markups[i];
    
      let tuple = markup.getExistingPoint(pt);
      if (tuple != null && tuple.point != null) {
        this.selectedPoint = tuple.point;      
        this.selectedPointIndex = tuple.index;    

        if (this.selectedMarkup != null)
          this.selectedMarkup.selectedForEditing = false;    

        this.selectedMarkup = markup;
        if (!inDrawMode || this.selectedMarkup.geometryType != MapEnums.GeometryTypes.POINT)         
          this.selectedMarkup.selectedForEditing = true;
        break;
      }
    }

    return this.selectedPoint != null ? true : false;
  }

  handleMouseUp(x, y) {
    this.onGeometryChanged();
  }

  handleMouseMove(x, y) {
    var canShowTooltip = false;

    for(let i = 0; i < this.markups.length; i++) {
      let markup = this.markups[i];
      markup.insertionPoint = null;

      if (markup.isPointOnLine(new Point(x, y)) >= 0) 
        markup.insertionPoint = new Point(x, y);									              

      // if (markup.isHovered) {
      //   markup.isHovered = false;
      //   markup.render(this.context);
      // }

      /* 

        Rather than set 'isHovered' from the markup.handleMouseMove function, 
        that function nows serves as a markup.hitTest() as long as that markup isn't selected for editing
        If any of markups are being hovered, all markups will now have the hover styling applied 

      */

      if (markup.handleMouseMove(x, y)) {   
        this.markups.map(m => {
          m.isHovered = true;
          m.render(this.context);
        });     
        canShowTooltip = true;
        break;
      }else{
        if (this.markups.some(m => m.isHovered)){
          this.markups.map(m => {
            m.isHovered = false;
            m.render(this.context);
          }); 
          break;
        }
      } 

    }

    if (canShowTooltip && this.hover != null) {

    }       
  }

  handleMouseDragging(x, y) {
    if (this.selectedPoint != null && this.selectedMarkup != null) {
      let lastPoint = this.selectedMarkup.getLastPoint();
      
      if (lastPoint.ClientPoint.equals(this.selectedPoint.ClientPoint)) {
        lastPoint.ClientPoint = new Point(x, y);
        lastPoint.WorldCoordinate = this.viewport.pointToWorldCoordinate(lastPoint.ClientPoint);

        this.selectedPoint.ClientPoint = lastPoint.ClientPoint;
        this.selectedPoint.WorldCoordinate = lastPoint.WorldCoordinate;
      }
      else {
        this.selectedPoint.ClientPoint = new Point(x, y);
        this.selectedPoint.WorldCoordinate = this.viewport.pointToWorldCoordinate(this.selectedPoint.ClientPoint);
      }         

      this.map.refreshView(); 
      this.render();            
    }    
  }


  addToSelectedMarkup(x, y){

    let selectedMarkups = [];

    for(let i = 0; i < this.markups.length; i++) {
      let markup = this.markups[i];

      if (markup.hitTest(x, y)) {
        selectedMarkups.push(markup);
        markup.selected = true;
      } 
    }

    return selectedMarkups;

  }

  
  // This 'selectMarkup' markup sets selectedMarkup object but doesn't set the 'selected' property in the markup object itself

  selectMarkup(x, y, inDrawMode) {
    if (inDrawMode) // don't want to select any markup if in drawMode
      return false;

      // violates dry in 'addToSelectedMarkup'
    for(let i = 0; i < this.markups.length; i++) {
      let markup = this.markups[i];

      if (markup.hitTest(x, y)) {
        
        if (this.selectedMarkup != null)
          this.selectedMarkup.selectedForEditing = false;
    
        this.selectedMarkup = markup;
        this.selectedMarkup.selectedForEditing = true;

        return true;
      }        
    } 
        
    return false;
  }

  updateExtents() {
    this.viewport = new Viewport(this.map.rcMap, this.map.extents, this.map.cellsize, this.map.scale);
  }

  render(context) {
    let ctx = context != undefined ? context : this.context;    
    for(let i = 0; i < this.markups.length; i++) {
          
     this.markups[i].render(ctx);
    }
  }

  drawPoint(clientPoint, drawExtraEllipse, context) {  
    let ctx = context != undefined ? context : this.context;        
    var radius = drawExtraEllipse ? Markup.Radius - 1 : Markup.Radius;              
    let pointColour = this.contextLayer != null && this.contextLayer.options != null ? this.contextLayer.options.SelectedColour : this.circleStroke.Colour;      
    this.canvas.drawCircleShape(ctx, clientPoint.x, clientPoint.y, radius, this.circleStroke.Width, pointColour, pointColour);       
    
    if (drawExtraEllipse) {
      radius =  Markup.Radius + 4;
      this.canvas.drawCircleShape(ctx, clientPoint.x, clientPoint.y, radius, this.circleStroke.Width, pointColour, null);   
    }      
  }    
  
  drawLine(clientPoint, previousClientPoint, context, stroke) { 
    let ctx = context != undefined ? context : this.context;    

    if (stroke == null)
      stroke = this.lineStroke;
  
    this.canvas.drawLine([clientPoint, previousClientPoint], stroke, 1.0, ctx);
  }
  
  renderPolygon(pts, context, isHovering = false, isSelected = false) {
    if (this.contextLayer != null && this.contextLayer.PolygonStyle != null) {
      let ctx = context != undefined ? context : this.context;     
      this.canvas.drawPolygon(pts, isHovering ? this.contextLayer.PolygonStyle.Stroke.hoverStroke : isSelected ? this.contextLayer.PolygonStyle.Stroke.selectedStroke : this.contextLayer.PolygonStyle.Stroke, this.contextLayer.PolygonStyle.Fill, 1.0, ctx);
    }
  }

  renderLine(pts, context, isHovering = false, isSelected = false) {
    if (this.contextLayer != null && this.contextLayer.LineStyle != null) {
      let ctx = context != undefined ? context : this.context;
     
      this.canvas.drawLine(pts, isHovering ? this.contextLayer.LineStyle.hoverStroke : isSelected ? this.contextLayer.LineStyle.selectedStroke : this.contextLayer.LineStyle, 1.0, ctx);  
    }
  }
  
  renderPoint(clientPoint, context, isHovering = false, isSelected = false) {
    if (this.contextLayer != null && this.contextLayer.PointStyle != null) {
      let ctx = context != undefined ? context : this.context;
      
      let symbol = isHovering ? this.contextLayer.PointStyle.HoverSymbol : isSelected ? this.contextLayer.PointStyle.SelectedSymbol : this.contextLayer.PointStyle.NormalSymbol;      
      let offsetX = symbol.width / 2;
      let offsetY = symbol.height / 2;
      this.canvas.drawSymbol(symbol, clientPoint.x - offsetX, clientPoint.y - offsetY, ctx);
    }    
  }

  onGeometryChanged() {
    let sWKT = this.toWKT();
    console.log(sWKT);

    if (this.map.cbGeometryEdit) {
      let message = {
        wkt: sWKT,      
      }
      
      this.map.cbGeometryEdit(message);
    }
  }

  onDisplayPrompt(promptType) { 
    console.log(`onDisplayPrompt:${MapEnums.PromptTypes.REPLACE_EXISTING_GEOMETRY}`);

    if (this.map.cbDisplayPrompt) {
      let message = {
        prompt: promptType,              
      }
      
      this.map.cbDisplayPrompt(message);
    }
  }

  endEditMode() {
    this.markupType = MapEnums.GeometryTypes.NONE;
    this.selectedMarkup = null;
    this.activeMarkup = null;
  }

  get canAddMarkup() {
    var bAddMarkup = true;
          
    if (this.geometryType == MapEnums.GeometryTypes.POINT ||
      this.geometryType == MapEnums.GeometryTypes.LINESTRING ||
      this.geometryType == MapEnums.GeometryTypes.POLYGON) {
      
      for(let i = 0; i < this.markups.length; i++) {
        if (!this.markups[i].isEmpty) {
          bAddMarkup = false;
          break;
        }
        
      }  

      // if (!bAddMarkup) {
      //   this.overwritePromptDisplayed = true;
      //   this.onDisplayPrompt(MapEnums.PromptTypes.REPLACE_EXISTING_GEOMETRY);
      // }      
    }

    return bAddMarkup;    
  }

  displayOverwritePrompt(){
    this.overwritePromptDisplayed = true;
    this.onDisplayPrompt(MapEnums.PromptTypes.REPLACE_EXISTING_GEOMETRY);
  }

  getBounds() {
    var rcBounds = null;
    
    if (this.markups.length > 0) {
      var top = null;
      var left = null;
      var right = null;
      var bottom = null;

      for (var i = 0; i < this.markups.length; i++) {
        let markup = this.markups[i];                
               
        for (var j = 0; j < markup.linePoints.length; j++) {
          let pt = markup.linePoints[j].WorldCoordinate;

          if (top == null) {
            top = pt.Y;
            left = pt.X;
            right = left;
            bottom = top;
          }

          
          if (pt.X < left)
            left = pt.X;
          else if (pt.X > right)
            right = pt.X;

          if (pt.Y < top)
            top = pt.Y;
          else if (pt.Y > bottom)
            bottom = pt.Y;
        }
      }

      rcBounds = new Envelope( left, right, top, bottom);            
    }

    return rcBounds;
  }
}  
